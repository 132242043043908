import React from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';

function UsoCard(props) {

    const navigate = useNavigate();

    function ExibirInfoDispositivos(contrato, empresa) {
        //Buscar da API dados dos dispositivos baseado no contrato
        if (contrato?.length > 0) {
            //navegar para page dos dispositivos do cliente
            navigate(`/usosiaac/${contrato}/${empresa}`);
        }
    }

    return (
        <div className="container">
            <div onClick={(e) => ExibirInfoDispositivos(props.contrato, props.cliente)} className='card-uso-container' >
                <div className="container">
                    <div className="row align-items-center">
                        <div className='col-2 col-md-2'>
                            <span>Contrato: <strong>{props.contrato}</strong></span>
                        </div>
                        <div className='col-8 col-md-8'>
                            <span>Cliente: <strong>{props.cliente}</strong></span>
                        </div>
                        <div className='col-2 col-md-2 text-end'>
                            <span>Ocorrências: <strong>{props.total}</strong></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UsoCard;