import React, { useEffect, useState } from 'react';
import styles from './table.module.css';
import { formataData } from '../../../../util/maskdoc.js';
import api from '../../../../service/api.js';
import { toast } from '../../../../constants/toast.jsx';
import { FormataSemana } from '../../../../util/utils.js';
import LoadingComponent from '../../../../components/loading-component/index.jsx';

export default function LiberarRenovacoes({ pagamentos, atualiza, alteracoes, setAlteracoes }) {
  const [loading, setLoading] = useState(false);

  function AtualizaDias(pagamento, dias) {
    const diasValue = dias === '' ? 0 : parseInt(dias);
    const diff = parseInt(diasValue) - parseInt(pagamento.dias);
    const dataFormatada = FormataDataUTC(pagamento.disponibilidade);
    const dataChaveAtual = new Date(dataFormatada);
    const novaData = dataChaveAtual.setDate(dataChaveAtual.getDate() + diff);
    pagamento.dias = dias;
    pagamento.disponibilidade = new Date(novaData).toISOString();

    const novoarray = pagamentos.map((x) => (x.pagamento_licenca_id === pagamento.pagamento_licenca_id ? pagamento : x));
    atualiza(novoarray);

    if (alteracoes.length === 0) {
      setAlteracoes([pagamento]);
    } else {
      let exist = false;
      const newArray = alteracoes.map((x) => {
        if (x.pagamento_licenca_id === pagamento.pagamento_licenca_id) {
          exist = true;
          return {
            ...x,
            dias: dias,
            disponibilidade: pagamento.disponibilidade,
          };
        } else {
          return x;
        }
      });
      if (exist === false) newArray.push(pagamento);
      setAlteracoes([...newArray]);
    }
  }

  function FormataDataUTC(data) {
    const dataSplit = data.split('T')[0];
    const dataNovoHorario = dataSplit + 'T12:00:00';
    return dataNovoHorario;
  }

  async function RequestToApi() {
    setLoading(true);
    let sucesso = new Array();
    if (alteracoes.length > 0) {
      await api
        .patch('/v1/financeiro/pagamentos', alteracoes)
        .then(() => {
          setAlteracoes([]);
          sucesso.push({ sucesso: true, mensagem: 'Sucesso na alteração de pagamentos' });
        })
        .catch((error) => {
          sucesso.push({ sucesso: false, mensagem: 'Erro na alteração de pagamentos: ' + (error.response ? error.response : error) });
        });
    }
    if (pagamentos.length > 0) {
      pagamentos = pagamentos.map((x) => {
        return {
          ...x,
          liberado: 'S',
          liberacao: 'PARAM_WEB',
        };
      });
      const tamanhoLote = 150;
      for (let i = 0; i < pagamentos.length; i += tamanhoLote) {
        const lote = pagamentos.slice(i, i + tamanhoLote);

        await api
          .post('/v1/financeiro/renovacoes', lote)
          .then(() => {
            atualiza([]);
            sucesso.push({ sucesso: true, mensagem: 'Sucesso na geração de renovações' });
          })
          .catch((error) => {
            sucesso.push({ sucesso: false, mensagem: 'Erro na geração de renovaçoes : ' + (error.response ? error.response : error) });
          });
      }
    }
    setLoading(false);
    sucesso.map((x) => {
      return toast.fire({
        icon: x.sucesso ? 'success' : 'error',
        title: x.mensagem,
      });
    });
  }

  return loading ? (
    <LoadingComponent />
  ) : (
    <div>
      <table className="table table-sm table-hover table-striped table-bordered">
        <thead>
          <tr>
            <th scope="col">Cliente</th>
            <th scope="col">ID Documento</th>
            <th scope="col">Vencimento</th>
            <th scope="col">Pagamento</th>
            <th scope="col">Data Chave</th>
            <th scope="col">Dias de Chave</th>
            <th scope="col">Semana</th>
          </tr>
        </thead>
        <tbody>
          {pagamentos.map((pagamento) => (
            <tr key={`${pagamento.codigo} ${pagamento.pagamento_licenca_id}`} className={Number(pagamento.dias)<=0?'table-danger':''}>
              <td>{pagamento.cliente.substring(0, 40)}</td>
              <td>{pagamento.codigo}</td>
              <td>{formataData(pagamento.data_vencimento)}</td>
              <td>{formataData(pagamento.data_pagamento)}</td>
              <td>{formataData(pagamento.disponibilidade)}</td>
              <td className="d-flex justify-content-center">
                <input
                  type="number"
                  className={'form-control ' + styles.inputDiasLiberar}
                  value={pagamento.dias}
                  onChange={(e) => AtualizaDias(pagamento, e.target.value)}
                />
              </td>
              <td>{FormataSemana(new Date(FormataDataUTC(pagamento.disponibilidade)).getDay())}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="row">
        <button className="btn btn-success" onClick={RequestToApi}>
          Atualizar Chaves
        </button>
      </div>
    </div>
  );
}
