import api from '../api.js';

async function InsertCalendario(data) {
  try {
    const response = await api.post('/v1/calendario', data);
    return response.data;
  } catch (error) {
    throw new Error(error.response.data.message);
  }
}

async function editarEventoCalendario(data) {
  try {
    const response = await api.patch('/v1/calendario', data);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
}

export { InsertCalendario, editarEventoCalendario };
