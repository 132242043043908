import Modal from 'react-modal/lib/components/Modal';
import Close from '../../../assets/close.png';
import styles from './chaveParam.module.css';
import BtnCopy from '../../../components/btn-copy/index.jsx';

Modal.setAppElement('#root');

export default function ModalChaveParam(props) {
  const Cancelar = () => {
    props.onRequestClose();
  };

  const customStyles = {
    content: { width: '400px' },
  };
  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      preventScroll={false}
      className="react-modal-content"
      overlayClassName="react-modal-overlay"
      style={customStyles}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <button type="button" onClick={Cancelar} className="react-modal-close">
        <img src={Close} alt="Fechar" />
      </button>
      <div className="container-fluid h-100 endereco-modal">
        <div className="col-12 mt-0 d-flex align-items-center justify-content-center">
          <h4 className="mt-2-mb-4">Chave PARAM</h4>
        </div>
        <div className="mt-1 mb-1 d-flex align-items-center justify-content-center">
          <h6 className="mt-2-mb-4">Copie os dados antes de fechar!</h6>
        </div>
        <div className="d-flex align-items-center my-4 justify-content-center">
          <p className="p-0 m-0">
            Chave: <b>{props.chave}</b>
          </p>
          <BtnCopy text={props.chave} />
        </div>
        <div className="row">
          <div className="col-12 mt-3 d-flex justify-content-center align-items-center div-botoes">
            <button type="button" className="btn btn-sm btn-danger" onClick={Cancelar}>
              Fechar
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
