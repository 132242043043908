import { useState } from 'react';
import Modal from 'react-modal/lib/components/Modal';
import { maskdoc } from '../../util/maskdoc';
import Close from '../../assets/close.png';
import './style.css';
import { toast } from '../../constants/toast.jsx';
import { validate } from 'gerador-validador-cpf';
import api from '../../service/api.js';

Modal.setAppElement('#root');

export default function FormsModalPedidoMobile(props) {
  const [codigo, setCodigo] = useState('');
  const [nome, setNome] = useState('');
  const [CPF, setCPF] = useState('');

  const FinalizadoCadastro = () => {
    const validadoCPF = validate(CPF);
    if (validadoCPF === false) {
      return toast.fire({
        icon: 'error',
        title: 'Erro!',
        text: 'Informe um CPF válido',
      });
    }
    api
      .post('/v1/mobile/vendedor', {
        codigoVendedor: codigo,
        nome,
        cpf: CPF,
        cnpj: props.cnpj,
        idEmpresa: props.id,
      })
      .then((result) => {
        FecharCadastro();
        toast.fire({
          icon: 'success',
          title: 'Vendedor cadastrado com sucesso!',
        });
      })
      .catch((error) => {
        toast.fire({
          icon: 'error',
          title: error.response.data,
        });
      });
  };

  const FecharCadastro = () => {
    setCPF('');
    setCodigo('');
    setNome('');
    props.onRequestClose();
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      preventScroll={false}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button type="button" onClick={FecharCadastro} className="react-modal-close">
        <img src={Close} alt="Fechar" />
      </button>
      <div className="container-fluid h-100 endereco-modal">
        <div className="col-12 mt-4">
          <h4 className="mt2-mb-4">Cadastro de Vendedor</h4>
        </div>
        <div className="form-floating mb-3">
          <input
            type="text"
            autoComplete="off"
            className="form-control"
            id="InputCodigo"
            placeholder="Código do Vendedor"
            value={codigo}
            onChange={(e) => setCodigo(e.target.value)}
          />
          <label for="InputCodigo">Código</label>
        </div>
        <div className="form-floating mb-3">
          <input
            type="text"
            autoComplete="off"
            className="form-control"
            id="InputNome"
            placeholder="Nome"
            value={nome.toUpperCase()}
            onChange={(e) => {
              setNome(e.target.value);
            }}
          />
          <label for="InputNome">Nome</label>
        </div>
        <div className="form-floating mb-3">
          <input
            type="text"
            autoComplete="off"
            className="form-control"
            id="InputCPF"
            placeholder="CPF"
            value={maskdoc(CPF)}
            maxLength={14}
            onChange={(e) => setCPF(e.target.value)}
          />
          <label for="InputCPF">CPF</label>
        </div>
        <div className="row">
          <div className="col-12 mt-3 d-flex justify-content-center align-items-center div-botoes">
            <button type="button" className="btn btn-lg btn-danger" onClick={FecharCadastro}>
              Cancelar
            </button>
            <button type="button" className="btn btn-lg btn-outline-primary" onClick={(e) => FinalizadoCadastro(e)}>
              Gravar
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
