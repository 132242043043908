import './styles.css';
import NotificationCard from './notification-card';
import { useContext } from 'react';
import { NotificacoesContext } from '../../../context/notificacoesContext.jsx';
import { useQuery } from '@tanstack/react-query';
import { fetchNotificacoesLidas } from '../../../service/fetchers/notificacoes.js';
import { FaChevronDown } from 'react-icons/fa6';

function Notification({ open }) {
  const { notificacoes, lerNotificacao } = useContext(NotificacoesContext);

  const { data: notificacoesLidas } = useQuery({
    queryKey: ['notificacoes', 'lidas'],
    queryFn: fetchNotificacoesLidas,
    staleTime: 1000 * 60 * 15,
  });

  return (
    <>
      <div className={`notification-area ${open ? 'notification-area-open' : ''}`}>
        <div className="d-flex flex-column align-items-center">
          {notificacoes.length > 0 ? (
            <>
              <h4>Notificações:</h4>
              {notificacoes.map((notification) => {
                return <NotificationCard key={notification.notificacao_id} lerNotificacao={lerNotificacao} notification={notification} />;
              })}
            </>
          ) : (
            <>
              <span>Sem novas notificações!</span>
              <img src="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" alt="Sem notificações!" title="Sem notificações" />
            </>
          )}
        </div>
        <div className="d-flex flex-column align-items-center">
          {notificacoesLidas && notificacoesLidas.length > 0 ? (
            <>
              <div className="text-secondary" style={{ width: '100%', borderBottom: '1px solid #000', marginBottom: '5px' }}>
                <a
                  className="d-flex justify-content-between"
                  data-bs-toggle="collapse"
                  href="#collapseNotificacoesLidas"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseNotificacoesLidas"
                >
                  Últimas dez notificações lidas: <FaChevronDown />
                </a>
              </div>
              <div className="collapse" id="collapseNotificacoesLidas" style={{ width: '100%' }}>
                {notificacoesLidas.map((notification) => {
                  return <NotificationCard key={notification.notificacao_id} notification={notification} />;
                })}
              </div>
            </>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default Notification;
