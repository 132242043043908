import React, { useContext, useEffect, useState } from 'react';
import {
  FaBolt,
  FaChevronRight,
  FaCircleXmark,
  FaDisplay,
  FaGear,
  FaKey,
  FaLocationDot,
  FaPhone,
  FaTriangleExclamation,
} from 'react-icons/fa6';
import { formataData, formataHora, maskphone } from '../../util/maskdoc.js';
import './style.css';
import SelectAtendimento from './StatusAtendimento/index.jsx';
import ModalSolucaoRapida from './modalSolucaoRapida/index.jsx';
import ModalSenhasClientes from '../modalSenhas/index.jsx';
import { UsuarioContext } from '../../context/userContext.jsx';

function AtendimentoCard({ atendimento, navegacao, onClickButton }) {
  const { setorUsuario, nomeUsuario, visualizacao } = useContext(UsuarioContext);
  const [cardOpen, setCardOpen] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [cardSlim, setCardSlim] = useState(visualizacao.cardSlim);

  const iconeTipo = {
    L: <FaLocationDot title="Visita ao Local" />,
    R: <FaDisplay title="Acesso Remoto" />,
    O: <FaGear title="Alteração do Sistema" />,
    T: <FaPhone title="Atedimento por telefone" />,
    A: <FaBolt title="Atualização do sistema" />,
  };

  const erroOuAlteracao = {
    E: <FaCircleXmark className="icone-erro" title="Erro" />,
    A: <FaTriangleExclamation className="icone-alteracao" title="Alteração" />,
  };

  const situacaoObjeto = {
    F: <small className="badge text-bg-success me-1 span-situacao ">Finalizado</small>,
    C: <small className="badge text-bg-danger me-1 span-situacao ">Cancelado</small>,
    E: <small className="badge text-bg-secondary me-1 span-situacao ">Testes</small>,
    A: <small className="badge text-bg-info me-1 span-situacao ">Atualizar</small>,
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  function Ocorrencia(ocorrencia) {
    const regex =
      /\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}:\d{2} \| [^\n|]+ \| ([\s\S]*?)(?=\d{2}\/\d{2}\/\d{4} \d{2}:\d{2}:\d{2} \| [^\n|]+ \||$)/g;
    const match = regex.exec(ocorrencia);
    if (match !== null) {
      return match[1];
    }
  }

  const toogleCard = () => {
    setCardOpen(!cardOpen);
  };

  useEffect(() => {
    if (cardSlim && window.screen.width <= 900) {
      setCardSlim(false);
    }
  }, []);

  const formatTelefone = () => {
    const tel1 = atendimento.telefone1 ? maskphone(atendimento.telefone1) : null;
    const tel2 = atendimento.telefone2 ? maskphone(atendimento.telefone2) : null;
    let string = '';
    if (tel1) string = tel1;
    if (tel2 && !tel1) string = tel2;
    if (tel2 && tel1) string += ' / ' + tel2;

    return string;
  };

  const handleDoubleClick = (e) => {
    if (atendimento.encaminhado === nomeUsuario && atendimento.situacao !== 'F' && atendimento.situacao !== 'C') setModalIsOpen(true);
  };

  function StatusAtendimento() {
    return (
      <>
        <strong>Status: </strong>
        {['F', 'C', 'E', 'A'].includes(atendimento.situacao) ? (
          situacaoObjeto[atendimento.situacao]
        ) : (
          <SelectAtendimento
            situacao={atendimento.situacao}
            ocorrencia={atendimento.ocorrencia}
            id={atendimento.id}
            encaminhado={atendimento.encaminhado}
            navegacao={navegacao}
            lancamento={atendimento.lancamento}
            clienteId={atendimento.id_cliente}
            tipo={atendimento.tipo}
          />
        )}
        {iconeTipo[atendimento.tipo]} {erroOuAlteracao[atendimento.tipo_os]}
      </>
    );
  }

  return (
    <>
      <ModalSolucaoRapida isOpen={modalIsOpen} onRequestClose={closeModal} infoAtendimento={atendimento} />
      <div
        className={`row mx-2 item-atendimento-card ${cardSlim ? 'slim' : ''} ${cardOpen ? 'cardOpen' : null} ${
          atendimento.situacao === 'C' ? 'atendimento-cancelado' : ''
        }`}
        onDoubleClick={(e) => handleDoubleClick(e)}
      >
        <div className={cardSlim ? 'col informacoes-card-slim' : 'col informacoes-card'}>
          {cardSlim ? (
            <>
              <div className="row-1-card-slim">
                <div>
                  <span>
                    {' '}
                    <strong>Número: {atendimento.id}</strong>
                  </span>
                </div>
                <div className="ps-2 coluna align-items-center">
                  <StatusAtendimento />
                </div>
                <div className="row coluna">
                  <div className="col-auto nome-equipe">
                    <span>
                      <strong>Lançado:</strong> {atendimento.lancamento}{' '}
                    </span>
                  </div>
                  <div className="col-auto data">
                    <span title="Data de lançamento">
                      {window.screen.width <= 991 ? '' : 'Dt. Lançamento:'} {formataData(atendimento.data_lancamento)}{' '}
                      {formataHora(atendimento.data_lancamento)}{' '}
                    </span>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row">
                <div className="col-auto col-sm-6 col-md-5 col-lg-5">
                  <span>
                    {' '}
                    <strong>Número: {atendimento.id}</strong>
                  </span>
                </div>
                <div className="col-auto col-sm-6 col-md-7 col-lg-7">
                  <div>
                    <StatusAtendimento />
                  </div>
                </div>
              </div>
            </>
          )}
          {cardSlim ? (
            <>
              <div className="row-2-card-slim">
                <div className="coluna justify-content-start">
                  <span>
                    <strong>Cliente: </strong>
                    {setorUsuario === 'D' ? (
                      atendimento.cliente.toUpperCase()
                    ) : (
                      <a href={`/clientes/${atendimento.id_cliente}`}>{atendimento.cliente.toUpperCase()}</a>
                    )}
                    <ModalSenhasClientes
                      classeCSS={'btn-infos-acessos'}
                      telefones={formatTelefone()}
                      senhas={atendimento.senhas_acesso}
                      cliente={atendimento.cliente.toUpperCase()}
                    >
                      <FaKey />
                    </ModalSenhasClientes>
                  </span>
                </div>
                <div className="row coluna">
                  <div className="col-auto nome-equipe">
                    <span>
                      <strong>Enc.: </strong>
                      {atendimento.encaminhado}{' '}
                    </span>
                  </div>
                  <div className="col-auto data">
                    <span title="Data de abertura">
                      {window.screen.width <= 991 ? '' : 'Dt. Abertura:'} {formataData(atendimento.data_abertura)}{' '}
                      {formataHora(atendimento.data_abertura)}{' '}
                    </span>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="row linha-cliente">
                <span className="span-linha-cliente">
                  <strong>Cliente: </strong>
                  {setorUsuario === 'D' ? (
                    atendimento.cliente.toUpperCase()
                  ) : (
                    <a href={`/clientes/${atendimento.id_cliente}`}>{atendimento.cliente.toUpperCase()}</a>
                  )}
                  <ModalSenhasClientes
                    classeCSS={'btn-infos-acessos'}
                    telefones={formatTelefone()}
                    senhas={atendimento.senhas_acesso}
                    cliente={atendimento.cliente.toUpperCase()}
                  >
                    <FaKey />
                  </ModalSenhasClientes>
                </span>
              </div>
              <div className="row">
                <div className="col-auto nome-equipe">
                  <span>
                    <strong>Lançado:</strong> {atendimento.lancamento}{' '}
                  </span>
                </div>
                <div className="col-auto data">
                  <span title="Data de lançamento">
                    {formataData(atendimento.data_lancamento)} {formataHora(atendimento.data_lancamento)}{' '}
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-auto nome-equipe">
                  <span>
                    <strong>Enc.: </strong>
                    {atendimento.encaminhado}{' '}
                  </span>
                </div>
                <div className="col-auto data">
                  <span title="Data de abertura">
                    {formataData(atendimento.data_abertura)} {formataHora(atendimento.data_abertura)}{' '}
                  </span>
                </div>
              </div>
            </>
          )}
          <div className="row">
            <span>
              <strong>Ocorrência:</strong>
            </span>
          </div>
          <div className="row">
            <div className={cardSlim ? 'col ocorrencia-slim' : 'col ocorrencia'}>
              <span>{Ocorrencia(atendimento.ocorrencia)}</span>
            </div>
          </div>
          <div className="row linha-botao">
            <div className="col div-button-collapse">
              <button className="button-collapse" onClick={toogleCard}></button>
            </div>
          </div>
        </div>
        <div className="col div-button">
          <button
            className="btn-edicao-atendimento"
            onClick={(e) => {
              onClickButton(atendimento.id);
            }}
          >
            <FaChevronRight className="text-light fs-5 fw-bold" />
          </button>
        </div>
      </div>
    </>
  );
}

export default React.memo(AtendimentoCard);
