import { createContext, useEffect, useState } from 'react';
import api from '../service/api.js';
import { ST__LISTA_CLIENTE__ATIVO, ST__TOKEN } from '../constants/storagekey.js';

export const ClientesContext = createContext({
  clientes: null,
  setClientes: () => undefined,
  GetListaClientes: () => undefined,
  AtualizarListagemClientes: () => undefined,
});

export function ClientesContextProvider({ children }) {
  const [clientes, setClientes] = useState([]);

  function SalvarListagem(listagem) {
    const listaFiltrada = listagem?.map((cliente) => ({
      id: cliente.id,
      nome_fantasia: cliente.nome_fantasia,
      razao_social: cliente.razao_social,
      cnpj: cliente.cnpj,
    }));
    setClientes(listaFiltrada);
    const listaLocalStorage = {
      time: new Date().getTime(),
      listagem: listaFiltrada,
    };
    localStorage.setItem(ST__LISTA_CLIENTE__ATIVO, JSON.stringify(listaLocalStorage));
  }

  function VerificaLocalStorage() {
    const listaLocalStorage = JSON.parse(localStorage.getItem(ST__LISTA_CLIENTE__ATIVO));
    if (listaLocalStorage && listaLocalStorage.time) {
      const time = new Date().getTime();
      const diff = time - listaLocalStorage.time;
      if (diff < 1000 * 60 * 30) {
        setClientes(listaLocalStorage.listagem);
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  async function GetListaClientesAPI() {
    api
      .get('/v1/clientes', {
        params: {
          ativo: 1,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          SalvarListagem(response.data);
        } else {
          if (response.status === 401) {
            console.log('Ocorreu algum erro na requisição de clientes: ' + response.statusText);
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setClientes([]);
        } else {
          console.log(err);
        }
      });
  }

  function GetListaClientes() {
    if (!VerificaLocalStorage()) {
      GetListaClientesAPI();
    }
  }

  useEffect(() => {
    if (clientes.length === 0 && localStorage.getItem(ST__TOKEN)) {
      GetListaClientes();
    }
  }, [clientes]);

  return (
    <ClientesContext.Provider
      value={{
        clientes,
        setClientes,
        GetListaClientes,
        AtualizarListagemClientes: GetListaClientesAPI,
      }}
    >
      {children}
    </ClientesContext.Provider>
  );
}
