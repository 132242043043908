import { useContext, useEffect, useState } from 'react';
import './App.css';
import { UsuarioContext, UsuarioContextProvider } from './context/userContext';
import Rotas from './routes/rotas';
import {
  ST__EMAILUSUARIO,
  ST__IDUSUARIO,
  ST__ISADMIN,
  ST__NOMEUSUARIO,
  ST__PERMISSOES,
  ST__SETORUSUARIO,
  ST__TOKEN,
} from './constants/storagekey';
import { ClientesContextProvider } from './context/clientesContext.jsx';
import { UsuariosContextProvider } from './context/usersContext.jsx';
import { SituacaoEquipeContextProvider } from './context/equipeContext.jsx';
import { NotificacoesContextProvider } from './context/notificacoesContext.jsx';
import api from './service/api.js';
import LoadingComponent from './components/loading-component/index.jsx';
import Footer from './components/footer/index.jsx';
import { DownloadsContextProvider } from './context/downloadsContext.jsx';

function App() {
  const {
    setEmailUsuario,
    setIdUsuario,
    setIsAdminUsuario,
    setNomeUsuario,
    setPermissoesUsuario,
    setSetorUsuario,
    setTokenUsuario,
    tokenUsuario,
  } = useContext(UsuarioContext);
  const [carregando, setCarregando] = useState(true);

  useEffect(() => {
    if (!tokenUsuario || tokenUsuario === '') {
      setEmailUsuario(localStorage.getItem(ST__EMAILUSUARIO));
      setIdUsuario(localStorage.getItem(ST__IDUSUARIO));
      setIsAdminUsuario(localStorage.getItem(ST__ISADMIN));
      setNomeUsuario(localStorage.getItem(ST__NOMEUSUARIO));
      setPermissoesUsuario(localStorage.getItem(ST__PERMISSOES));
      setSetorUsuario(localStorage.getItem(ST__SETORUSUARIO));
      setTokenUsuario(localStorage.getItem(ST__TOKEN));
    }
  }, []);

  useEffect(() => {
    const token = localStorage.getItem(ST__TOKEN);
    if (token) {
      api
        .get('/verify')
        .then(() => {
          setCarregando(false);
        })
        .catch(() => {
          setCarregando(false);
        });
    } else {
      setCarregando(false);
    }
  }, []);

  if (carregando) {
    return (
      <div
        style={{
          display: 'flex',
          height: '100vh',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <LoadingComponent />
      </div>
    );
  } else {
    return (
      <UsuarioContextProvider>
        <ClientesContextProvider>
          <UsuariosContextProvider>
            <NotificacoesContextProvider>
              <DownloadsContextProvider>
                <SituacaoEquipeContextProvider>
                  <Rotas />
                  <Footer />
                </SituacaoEquipeContextProvider>
              </DownloadsContextProvider>
            </NotificacoesContextProvider>
          </UsuariosContextProvider>
        </ClientesContextProvider>
      </UsuarioContextProvider>
    );
  }
}

export default App;
