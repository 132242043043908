import { useEffect, useState } from 'react';
import ScrollButton from '../../../components/scrollButton/index.jsx';
import RowsConciliacao from './rows.jsx';
import api from '../../../service/api.js';
import { toast } from '../../../constants/toast.jsx';
import Titulo from '../../../components/titulo/index.jsx';

const initialState = {
  pesquisa: '',
  clientes: [],
  listagem: [],
  ativos: null,
  chave: null,
  codigo: null,
  edits: [],
};

function ConciliacaoCliente() {
  const [state, setState] = useState(initialState);

  async function GetListaClientes() {
    api
      .get('/v1/clientes')
      .then((response) => {
        if (response.status === 200) {
          const responseSort = response.data.sort((a, b) =>
            a.razao_social < b.razao_social ? -1 : a.razao_social > b.razao_social ? 1 : 0
          );
          setState((prevState) => ({
            ...prevState,
            clientes: responseSort,
            listagem: responseSort,
          }));
        } else {
          if (response.status === 401) {
            console.log('Ocorreu algum erro na requisição de clientes: ' + response.statusText);
            setState(initialState);
          }
        }
      })
      .catch((err) => {
        if (err.response.status === 401) {
          setState(initialState);
        } else {
          console.log(err);
        }
      });
  }

  const handleEdit = (clienteId, field, value) => {
    const indexEditedClient = state.edits.findIndex((x) => x.id === clienteId);

    if (indexEditedClient === -1) {
      const editedClient = {
        id: clienteId,
        [field]: value,
      };
      setState((prevState) => ({
        ...prevState,
        edits: [...prevState.edits, editedClient],
      }));
    } else {
      setState((prevState) => {
        const updateEdits = [...prevState.edits];
        updateEdits[indexEditedClient][field] = value;
        return {
          ...prevState,
          edits: updateEdits,
        };
      });
    }
  };

  function SaveEditsInApi() {
    if (state.edits.length > 0) {
      try {
        for (const edit of state.edits) {
          api.patch('/v1/clientes', edit);
        }
        toast.fire({
          icon: 'success',
          title: 'Alterações salvas com sucesso!',
          didDestroy: () => {
            setState((prev) => ({
              ...prev,
              edits: [],
            }));
            GetListaClientes();
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          },
        });
      } catch (error) {
        toast.fire({
          icon: 'error',
          title: error,
        });
      }
    }
  }

  function CancelEdit() {
    setState((prev) => ({
      ...prev,
      edits: [],
      listagem: [],
    }));
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    setState((prev) => ({
      ...prev,
      listagem: prev.clientes,
    }));
  }

  useEffect(() => {
    GetListaClientes();
  }, []);

  return (
    <>
      <div className="container shadow-lg rounded-2 mt-3 pb-3">
        <Titulo principal titulo="Conciliação de Clientes" />
        <div className="p-3">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Cliente</th>
                <th scope="col">Código Financeiro</th>
                <th scope="col">Opções</th>
              </tr>
            </thead>
            <tbody>
              {state.listagem.map((cliente) => (
                <RowsConciliacao key={cliente.id} cliente={cliente} onEdit={handleEdit} />
              ))}
            </tbody>
          </table>
        </div>
        <div className="pb-3 d-flex justify-content-center">
          <button className="btn btn-primary mx-2" onClick={SaveEditsInApi}>
            Salvar
          </button>
          <button className="btn btn-danger mx-2" onClick={CancelEdit}>
            Cancelar
          </button>
        </div>
      </div>
      <ScrollButton />
    </>
  );
}

export default ConciliacaoCliente;
