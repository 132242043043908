import React, { useEffect, useState } from 'react';
import api from '../../../service/api.js';
import AtendimentoPorClientePDF from '../../../components/pdf-make/PDFAtendimentoPorCliente.jsx';

function AtendimentoPorCliente() {
  const [dataInicial, setDataInicial] = useState(new Date().toISOString().split('T')[0]);
  const [dataFinal, setDataFinal] = useState(new Date().toISOString().split('T')[0]);
  const [total, setTotal] = useState(0);
  const [listagemTotal, setListagemTotal] = useState([]);

  const GetDadosAtendimentos = () => {
    api
      .get('/v1/atendimentos-clientes', {
        params: {
          dataInicial,
          dataFinal,
        },
      })
      .then((response) => {
        setListagemTotal(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    GetDadosAtendimentos();
  }, []);

  useEffect(() => {
    let total = 0;
    listagemTotal.forEach((atendimento) => {
      total += atendimento.quantidade;
    });
    setTotal(total);
  }, [listagemTotal]);

  return (
    <>
      <div className="container shadow-lg rounded-2 mt-3">
        <div className="row justify-content-between align-items-center mb-2 mt-3">
          <div className="col-lg-11 col-sm-10 col-auto ms-1 mb-1">
            <a className="btn-colapse" data-bs-toggle="collapse" role="button" aria-expanded="false">
              <h5 className="text-dark mt-3 titulo">Relatório de atendimento por cliente</h5>
            </a>
          </div>
        </div>
        <div className="mb-3" id="collapseDashBoard">
          <div className="row py-2">
            <div className="col-lg-2">
              <div className="form-floating">
                <input
                  value={dataInicial}
                  type="date"
                  className="form-control"
                  id="dataInicial"
                  name="dataInicial"
                  placeholder="Data Inicial"
                  onChange={(e) => setDataInicial(e.target.value)}
                />
                <label htmlFor="dataAdmissao" className="">
                  Data Inicial
                </label>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="form-floating">
                <input
                  value={dataFinal}
                  type="date"
                  className="form-control"
                  id="DataFinal"
                  name="dataFinal"
                  placeholder="Data Final"
                  onChange={(e) => setDataFinal(e.target.value)}
                />
                <label htmlFor="dataAdmissao" className="">
                  Data Final
                </label>
              </div>
            </div>

            <div className="col-lg-2 my-3 my-lg-0  d-flex justify-content-center">
              <button className="btn btn-primary my-auto" onClick={GetDadosAtendimentos}>
                Buscar
              </button>
            </div>
          </div>

          {listagemTotal.length > 0 ? (
            <>
              <div className="row mt-2">
                <div className="col">
                  <p>Total de atendimentos finalizados: {total}</p>
                </div>
                <div className="col">
                  <AtendimentoPorClientePDF atendimentos={{ clientes: listagemTotal, total }} filtros={{ dataInicial, dataFinal }} />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr className="text-center">
                        <th className="text-start">Cliente</th>
                        <th>Total</th>
                        <th>Remoto</th>
                        <th>Telefone</th>
                        <th>Local</th>
                        <th>O.S.</th>
                        <th>Atualização</th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {listagemTotal.map((atendimento) => (
                        <tr key={atendimento.id_cliente}>
                          <td className="text-start">{atendimento.cliente}</td>
                          <td>{atendimento.quantidade}</td>
                          <td>{atendimento.remoto}</td>
                          <td>{atendimento.telefone}</td>
                          <td>{atendimento.local}</td>
                          <td>{atendimento.os}</td>
                          <td>{atendimento.atualizacao}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          ) : (
            <div className="text-center my-4 pb-4">
              <h6>Nenhum atendimento encontrado</h6>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default AtendimentoPorCliente;
