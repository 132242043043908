import { useContext, useState } from 'react';
import Swal from 'sweetalert2';
import { formataData, formataHora } from '../../../util/maskdoc';
import './style.css';
import { UsuarioContext } from '../../../context/userContext';
import { UsuariosContext } from '../../../context/usersContext.jsx';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from '../../../constants/toast.jsx';
import { AlterarAtendimento } from '../../../service/mutations/atendimento.js';

export default function SelectAtendimento({ situacao, ocorrencia, id, encaminhado, navegacao, lancamento, clienteId, tipo }) {
  const queryClient = useQueryClient();
  const { nomeUsuario, permissoesUsuario, idUsuario } = useContext(UsuarioContext);
  const { usuarios, dicionario } = useContext(UsuariosContext);

  const [sameUser] = useState(encaminhado === nomeUsuario);
  const [estado] = useState(situacao);

  const objetoEstiloSituacao = {
    P: `${!sameUser ? 'badge me-1' : ''} text-bg-danger`,
    T: 'text-bg-warning',
    W: 'text-bg-primary',
  };

  const estiloSituacao = (param) => {
    if (navegacao === 'PWT' || navegacao === undefined) {
      return objetoEstiloSituacao[param];
    }
  };

  const estadosSemPermissaoEncaminhar = {
    P: <span className="badge me-1 text-bg-danger">Pendente </span>,
    T: <span className="badge me-1 text-bg-warning">{tipo === 'O' ? 'Desenvolvendo' : 'Atendendo'} </span>,
    W: <span className="badge me-1 text-bg-primary">Aguardando </span>,
  };

  const retornaEstadoNaoPermitidoEncaminhamento = (estado) => {
    return estadosSemPermissaoEncaminhar[estado];
  };

  const mensagemTrocaSituacao = (param) => {
    const date = new Date().toISOString();
    let message = `\n${formataData(date)} ${formataHora(date)} | ${nomeUsuario} | ${tipo === 'O' ? 'O.S.' : 'Atendimento'} `;
    message += !sameUser ? 'encaminhado para mim e ' : '';
    message += param === 'T' ? 'retomado.' : '';
    message += param === 'W' ? 'alterado para aguardando.' : '';
    return message;
  };

  const editarSituacaoDoAtendimento = (e) => {
    let date = new Date().toISOString();

    let message =
      estado === 'P' && e.target.value === 'T'
        ? `\n${formataData(date)} ${formataHora(date)} | ${nomeUsuario} | ${tipo === 'O' ? 'O.S.' : 'Atendimento'} iniciado`
        : mensagemTrocaSituacao(e.target.value);

    const usuario = usuarios.find((x) => x.NOME === lancamento);
    let alterStatus = {
      id,
      id_cliente: clienteId,
      situacao: e.target.value,
      ocorrencia: ocorrencia + message,
      data_abertura: estado === 'P' && e.target.value === 'T' ? date : null,
      data_alteracao: estado === 'P' && e.target.value === 'T' ? null : date,
      id_equipe_lancamento: usuario.ID,
      id_equipe_suporte: idUsuario,
    };

    if (!sameUser && permissoesUsuario.encaminhar_atendimento_terceiro === 1) {
      Swal.fire({
        icon: 'question',
        title: `Deseja encaminhar o ${tipo === 'O' ? 'O.S.' : 'Atendimento'} para você e prosseguir com a alteração?`,
        showDenyButton: true,
        confirmButtonText: 'Sim',
        denyButtonText: 'Não',
      }).then((result) => {
        if (result.isConfirmed) {
          editarSiatuacaoFn(alterStatus);
        } else {
          return;
        }
      });
    }
    if (sameUser) {
      editarSiatuacaoFn(alterStatus);
    }
  };

  const { mutateAsync: editarSiatuacaoFn } = useMutation({
    mutationFn: AlterarAtendimento,
    onSuccess: (_, variables) => {
      queryClient.setQueryData(['atendimentos'], (old) => {
        return old.map((atendimento) => {
          if (atendimento.id === id) {
            if (atendimento.data_abertura) {
              delete variables.data_abertura;
            }
            if (atendimento.id_equipe_suporte !== variables.id_equipe_suporte) {
              variables.encaminhado = dicionario.get(Number(variables.id_equipe_suporte));
            }
            return { ...atendimento, ...variables };
          }
          return atendimento;
        });
      });
      const queryKeys = queryClient
        .getQueryCache()
        .getAll()
        .map((cache) => cache.queryKey);
      queryKeys.forEach((key) => {
        if (key[0] === `atendimento-${id}`) {
          queryClient.setQueryData([`atendimento-${id}`], (old) => {
            if (old.data_abertura) {
              delete variables.data_abertura;
            }
            if (old.id_equipe_suporte !== variables.id_equipe_suporte) {
              variables.encaminhado = dicionario.get(Number(variables.id_equipe_suporte));
            }
            return { ...old, ...variables };
          });
        }
      });
    },
    onError: (error) => {
      console.log('error :>> ', error);
      toast.fire({
        icon: 'error',
        title: `Erro ao alterar ${tipo === 'O' ? 'a O.S.' : 'o Atendimento'}!`,
        text: 'Não foi possível alterar a situação do atendimento',
      });
    },
  });

  return (
    <>
      {sameUser ? (
        <select
          className={`${estiloSituacao(estado)} select-situacao`}
          onChange={(e) => editarSituacaoDoAtendimento(e)}
          defaultValue={situacao}
        >
          {estado === 'P' ? (
            <>
              <option value="P">Pendente</option>
              <option value="T">Iniciar</option>
              {}
            </>
          ) : estado === 'W' ? (
            <>
              <option value="W">Aguardando</option>
              <option value="T">Retomar</option>
            </>
          ) : (
            <>
              <option value="T">{tipo === 'O' ? 'Desenvolvendo' : 'Atendendo'}</option>
              <option value="W">Aguardando</option>
            </>
          )}
        </select>
      ) : permissoesUsuario.encaminhar_atendimento_terceiro === 1 ? (
        estado === 'P' ? (
          <span className={`${estiloSituacao(estado)}`}>Pendente </span>
        ) : (
          <select
            className={`${estiloSituacao(estado)} select-situacao`}
            onChange={(e) => editarSituacaoDoAtendimento(e)}
            defaultValue={situacao}
          >
            <option value="T">{tipo === 'O' ? 'Desenvolvendo' : 'Atendendo'}</option>
            <option value="W">Aguardando</option>
          </select>
        )
      ) : (
        retornaEstadoNaoPermitidoEncaminhamento(estado)
      )}
    </>
  );
}
