import React, { useEffect, useState } from 'react';
import { Calendario } from '../../calendario/index.jsx';
import { getCalendarioSAC } from '../../../service/fetchers/calendario.js';
import { useQuery } from '@tanstack/react-query';

function AbaCalendario() {
  const [eventos, setEventos] = useState([]);

  const { data, isLoading } = useQuery({
    queryKey: ['eventos', 'listagem', new Date().getFullYear()],
    queryFn: () => getCalendarioSAC({ dia: new Date().getFullYear() }),
    staleTime: 1000 * 60 * 60,
  });

  useEffect(() => {
    if (data && !isLoading) {
      setEventos([]);
      data.forEach((item) => {
        const title = item.descricao;
        const start = new Date(item.dia);
        const end = new Date(item.dia.split('T')[0] + 'T23:59:59');
        setEventos((prevState) => [...prevState, { title, start, end }]);
      });
    }
  }, [data, isLoading]);

  return (
    <div>
      <Calendario dados={eventos} />
    </div>
  );
}

export default AbaCalendario;
