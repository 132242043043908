import React, { useState } from 'react';
import styles from './btnCopy.module.css';
import { toast } from '../../constants/toast.jsx';
import { FaCopy } from 'react-icons/fa6';

function BtnCopy({ text }) {
  const [copiado, setCopiado] = useState(false);

  const copyToClipboard = async (texto) => {
    if (texto) {
      const permissions = await navigator.permissions.query({ name: 'clipboard-write' });
      if (permissions.state === 'granted' || permissions.state === 'prompt') {
        await navigator.clipboard.writeText(text);
        setCopiado(true);
      } else {
        toast.fire({
          icon: 'info',
          text: 'Dispositivo sem permissão para a Área de transferencia',
        });
      }
    }
  };
  return (
    <button className={!copiado ? styles.btnCopy : styles.btnCopied} onClick={() => copyToClipboard(text)} title={`Copiar ${text}`}>
      <FaCopy />
    </button>
  );
}

export default BtnCopy;
