import React from 'react';
import { formataData } from '../../util/maskdoc.js';
import PdfMake from './PdfMake.jsx';

export default function AtendimentoPorClientePDF({ atendimentos, filtros }) {
  const info = {
    title: 'Atendimento por cliente',
    author: 'CM Sistemas',
  };

  function formataFiltros() {
    let filtro = [];
    if (filtros.dataInicial && filtros.dataFinal) {
      filtro.push({ text: `Data Inicial: ${formataData(filtros.dataInicial)} - Data Final: ${formataData(filtros.dataFinal)}` });
      filtro.push({ text: `Intervalo de dias: ${intervaloDatas()}` });
      filtro.push({ text: `Média de atendimentos por dia: ${mediaAtendimentos().toFixed(2)}` });
    }
    return filtro.flat();
  }

  function intervaloDatas() {
    const dataInicial = new Date(filtros.dataInicial);
    const dataFinal = new Date(filtros.dataFinal);
    const diff = Math.abs(dataFinal - dataInicial);
    return Math.ceil(diff / (1000 * 60 * 60 * 24));
  }

  function mediaAtendimentos() {
    return atendimentos.total / intervaloDatas();
  }

  function formataHeaderTabela() {
    return [
      { text: 'Cliente'.toUpperCase(), bold: true },
      { text: 'Total'.toUpperCase(), bold: true },
      { text: 'Remoto'.toUpperCase(), bold: true },
      { text: 'Telefone'.toUpperCase(), bold: true },
      { text: 'Local'.toUpperCase(), bold: true },
      { text: 'O.S.'.toUpperCase(), bold: true },
      { text: 'Atualização'.toUpperCase(), bold: true },
    ];
  }

  function formataWidthTabela() {
    return ['*', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'];
  }

  function formataCorpoTabela(atendimento) {
    return [
      { text: atendimento.cliente.toUpperCase(), alignment: 'start' },
      { text: atendimento.quantidade, alignment: 'center' },
      { text: atendimento.remoto, alignment: 'center' },
      { text: atendimento.telefone, alignment: 'center' },
      { text: atendimento.local, alignment: 'center' },
      { text: atendimento.os, alignment: 'center' },
      { text: atendimento.atualizacao, alignment: 'center' },
    ];
  }

  const content = [
    {
      text: 'Listagem Atendimentos por Cliente',
      style: { fontSize: 18, bold: true, alignment: 'justify' },
      alignment: 'center',
      margin: [0, 15, 0, 5],
    },
    { text: `Total de atendimentos: ${atendimentos.total}`, style: { fontSize: 14, bold: true, alignment: 'justify' } },
    formataFiltros(),
    {
      table: {
        headerRows: 1,
        widths: formataWidthTabela(),
        body: [formataHeaderTabela(), ...atendimentos.clientes.map((atendimento) => formataCorpoTabela(atendimento))],
      },
      margin: [0, 15, 0, 0],
    },
  ];

  return <PdfMake content={content} info={info} orientation="portrait" />;
}
