import React, { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal/lib/components/Modal';
import Close from '../../../assets/close.png';
import { UsuariosContext } from '../../../context/usersContext.jsx';
import moment from 'moment';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { editarEventoCalendario } from '../../../service/mutations/calendario.js';
import { toast } from '../../../constants/toast.jsx';

function ModalAlterarEvento({ isOpen, onRequestClose, evento }) {
  const queryClient = useQueryClient();
  const [jornada, setJornada] = useState(0);
  const [usuariosSelecionados, setUsuariosSelecionados] = useState([]);
  const [feriado, setFeriado] = useState(false);
  const [tipoFeriado, setTipoFeriado] = useState('0');
  const [descricao, setDescricao] = useState('');
  const [dia, setDia] = useState('');

  const { usuarios } = useContext(UsuariosContext);

  function handlerJornadaTrabalho(input, req = false) {
    if (input === undefined) return;
    if (typeof input === 'number') {
      const horas = Math.floor(input / 60);
      const minutos = input % 60;
      if (!req) return `${String(horas).padStart(2, '0')}h ${String(minutos).padStart(2, '0')}m`;

      return `${String(horas).padStart(2, '0')}:${String(minutos).padStart(2, '0')}:00`;
    } else {
      input = input.split(':');
      const horas = Number(input[0]);
      const minutos = Number(input[1]);
      const num = horas * 60 + minutos;
      return num;
    }
  }

  function formataData(data) {
    if (!data) return '';
    return moment(data).format('YYYY-MM-DD');
  }

  function addUsuariosForm(e) {
    const { value } = e.target;
    if (value === 0) {
      if (usuariosSelecionados.length === usuarios?.filter((x) => x.registra_ponto === 1).length) {
        setUsuariosSelecionados([]);
      } else {
        setUsuariosSelecionados(usuarios?.filter((x) => x.registra_ponto === 1).map((x) => x.ID));
      }
    } else {
      if (usuariosSelecionados.findIndex((x) => Number(x) === Number(value)) !== -1) {
        setUsuariosSelecionados(usuariosSelecionados.filter((x) => Number(x) !== Number(value)));
      } else {
        setUsuariosSelecionados([...usuariosSelecionados, Number(value)]);
      }
    }
  }

  function initValues() {
    if (evento !== undefined) {
      setDescricao(evento.descricao);
      setDia(formataData(evento.dia));
      setJornada(handlerJornadaTrabalho(evento.jornada));
      setFeriado(evento.feriado);
      setTipoFeriado(evento.tipo);
      setUsuariosSelecionados(evento.usuarios || []);
    }
  }

  const { mutateAsync } = useMutation({
    mutationFn: (eventoUpdate) => editarEventoCalendario(eventoUpdate),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['eventos', 'listagem'],
      });
      toast.fire({
        icon: 'success',
        title: 'Evento alterado com sucesso!',
      });
      onRequestClose();
    },
    onError: (error) => {
      console.error(error);
      toast.fire({
        icon: 'error',
        title: 'Erro ao alterar evento!',
        text: error.message,
      });
    },
  });

  function handleUpdateEvent() {
    if (feriado && (tipoFeriado === '0' || tipoFeriado === null))
      return toast.fire({ icon: 'error', title: 'Selecione o tipo de feriado' });

    const eventoUpdate = {
      calendarioId: evento.calendarioId,
      descricao,
      dia,
      jornada: handlerJornadaTrabalho(jornada, true),
      feriado,
      tipo: tipoFeriado,
      usuarios: usuariosSelecionados,
    };

    mutateAsync(eventoUpdate);
  }

  useEffect(() => {
    initValues();
  }, [evento]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      preventScroll={true}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button type="button" onClick={onRequestClose} className="react-modal-close">
        <img src={Close} alt="Fechar" />
      </button>
      <div className="mb-4 mt-2">
        <div className="row">
          <div className="col-12">
            <h5 className="text-black">Alterar Evento</h5>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="input-group mb-3">
              <span className="input-group-text">Descrição</span>
              <input type="text" className="form-control" value={descricao} onChange={(e) => setDescricao(e.target.value)} />
            </div>
          </div>
        </div>
        <div className="row align-items-end">
          <div className="col-6">
            <div className="input-group mb-3">
              <span className="input-group-text">Data</span>
              <input type="date" className="form-control" value={dia} onChange={(e) => setDia(e.target.value)} />
            </div>
          </div>
          <div className="col-6 mb-3">
            <label htmlFor="tempo-trabalho" className="input-label">
              Jornada de Trabalho: {handlerJornadaTrabalho(jornada)}
            </label>
            <input
              id="tempo-trabalho"
              type="range"
              max={480}
              min={0}
              value={jornada}
              className="form-control"
              onChange={(e) => setJornada(Number(e.target.value))}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-5 mb-3 d-flex align-self-end">
            <div className="form-check form-switch">
              <label htmlFor="feriado" className="input-label">
                Feriado
              </label>
              <input
                id="feriado"
                type="checkbox"
                role="switch"
                className="form-check-input"
                checked={feriado}
                onChange={(e) => setFeriado(e.target.checked)}
              />
            </div>
          </div>
          <div className="col-7 mb-3">
            <div className="input-group">
              <span className="input-group-text">Tipo de Feriado</span>
              <select
                id="tipo-feriado"
                className="form-select"
                value={tipoFeriado}
                onChange={(e) => setTipoFeriado(e.target.value)}
                disabled={!feriado}
              >
                <option value="0">{feriado ? 'Selecione o tipo de feriado' : 'Não se aplica'}</option>
                <option value="N">Nacional</option>
                <option value="E">Estadual</option>
                <option value="M">Municipal</option>
                <option value="F">Facultativo</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-6 d-flex dropup">
            <button
              className="btn dropdown-toggle nowrap align-self-center"
              type="button"
              data-bs-auto-close="outside"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {usuariosSelecionados.length > 0 ? `${usuariosSelecionados.length} usuários selecionados.` : 'Selecione os usuários'}
            </button>
            <ul className="dropdown-menu overflow-auto" style={{ height: '50vh' }}>
              <li className="dropdown-item d-flex justify-content-center" value={0} onClick={addUsuariosForm}>
                <button style={{ border: 'none', background: 'none' }} type="button" className="me-1" value={0}>
                  {usuariosSelecionados.length === usuarios?.filter((x) => x.registra_ponto === 1).length
                    ? 'Desmarcar todos'
                    : 'Marcar todos'}
                </button>
              </li>
              {usuarios?.map((usuario) => {
                if (usuario.registra_ponto === 1)
                  return (
                    <li className="dropdown-item" key={usuario.ID}>
                      <input
                        id={usuario.ID}
                        type="checkbox"
                        className="me-1"
                        checked={usuariosSelecionados.findIndex((x) => Number(x) === Number(usuario.ID)) !== -1 ? true : false}
                        value={usuario.ID}
                        onChange={addUsuariosForm}
                      />
                      <label htmlFor={usuario.ID}>{usuario.NOME}</label>
                    </li>
                  );

                return null;
              })}
            </ul>
          </div>
        </div>
        <div className="row mt-3">
          <div className="d-flex justify-content-center">
            <button type="button" className="btn btn-primary w-25" onClick={handleUpdateEvent}>
              Salvar
            </button>
            <button type="button" className="btn btn-danger ms-2 w-25" onClick={onRequestClose}>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalAlterarEvento;
