import api from '../api.js';

export async function GetAtendimentos({ usuarioId, setorUsuario, indReset = null, filtros = null }) {
  try {
    if (indReset === null) {
      const result = await api.get('/v1/atendimentos', {
        params: {
          dataInicial: -1,
          dataFinal: -1,
          idUserEncaminhado: usuarioId,
          posicao: ['P', 'T', 'W'],
          setor: setorUsuario,
        },
      });
      return result.data;
    } else {
      const result = await api.get('/v1/atendimentos', {
        params: {
          numeroAtendimento: filtros.numeroAtendimento,
          cliente: filtros.cliente,
          tipoData: filtros.tipoData,
          dataInicial: filtros.dataInicial,
          dataFinal: filtros.dataFinal,
          idUserLancado: filtros.idUserLancado,
          setor: filtros.setor,
          idUserEncaminhado: filtros.idUserEncaminhado,
          posicao: filtros.posicao,
          pagina: filtros.pagina, //PARA BUSCAR ATENDIMENTOS DE UMA PÁGINA ESPECÍFICA
          paginaPesquisa: filtros.paginaPesquisa, //PARA BUCAR TODOS OS ATENDIMENTOS ATÉ A PÁGINA
          conteudo: filtros.conteudo,
          tipo: filtros.tipo,
        },
      });
      return result.data;
    }
  } catch (error) {
    throw new Error(error);
  }
}

export async function GetAtendimentoById(id) {
  try {
    const result = await api.get(`/v1/atendimentos/${id}`, {
      params: {
        numeroAtendimento: id,
        dataInicial: -1,
        dataFinal: -1,
        idUserLancado: 0,
        idUserEncaminhado: 0,
      },
    });
    return result.data;
  } catch (error) {
    throw new Error(error);
  }
}

export async function GetListaOs(filtros) {
  try {
    const result = await api.get('/v1/osFinalizadas', {
      params: filtros,
    });
    return result.data;
  } catch (error) {
    throw new Error(error);
  }
}
