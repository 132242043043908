import React, { useState } from 'react';
import Titulo from '../../../components/titulo/index.jsx';
import { useQueryClient } from '@tanstack/react-query';
import { GetClientes } from '../../../service/fetchers/clientes.js';
import LoadingComponent from '../../../components/loading-component/index.jsx';
import { formataData, formataHora, maskdoc } from '../../../util/maskdoc.js';
import { ROUTE_CLIENTES } from '../../../constants/routes.js';
import { FaExternalLinkAlt } from 'react-icons/fa';
import PDFClientesSemCodFinanceiro from '../../../components/pdf-make/PDFClientesDtCadastro.jsx';

const initialState = {
  dataInicial: new Date().toISOString().split('T')[0],
  dataFinal: new Date().toISOString().split('T')[0],
  ativo: 1,
  codigoFinanceiro: '',
  listagemClientes: [],
};

function ListagemClientesPorDtCadastro() {
  const queryClient = useQueryClient();
  const [state, setState] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);

  function handleChange(e) {
    const { name, value } = e.target;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  function handleChangeCodigoFinanceiro(nao) {
    setState((prevState) => ({
      ...prevState,
      codigoFinanceiro: nao ? '' : null,
    }));
  }

  async function GetListagemClientes() {
    setIsLoading(true);
    const filtros = {
      dtInicioCadastro: state.dataInicial + 'T00:00:00.000Z',
      dtFimCadastro: state.dataFinal + 'T23:59:59.999Z',
      ativo: state.ativo,
      codigoFinanceiro: state.codigoFinanceiro,
    };

    const result = await queryClient.fetchQuery({
      queryKey: ['clientes', filtros],
      queryFn: () => GetClientes(filtros),
      staleTime: 1000 * 60 * 5,
    });

    if (result) {
      setState((prevState) => ({
        ...prevState,
        listagemClientes: result,
      }));
    }
    setIsLoading(false);
  }

  return (
    <div className="container shadow-lg rounded-2 mt-3 px-3">
      <Titulo titulo="Clientes por Data de Cadastro" principal={true} />
      <div className="mb-3" id="collapseDashBoard">
        <div className="row py-2">
          <h6>Data de cadastro do cliente</h6>
          <div className="col-lg-2">
            <div className="form-floating">
              <input
                type="date"
                className="form-control"
                id="dataInicial"
                name="dataInicial"
                placeholder="Data Inicial"
                value={state.dataInicial}
                onChange={(e) => handleChange(e)}
              />
              <label htmlFor="dataInicial" className="">
                Data Inicial
              </label>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="form-floating">
              <input
                type="date"
                className="form-control"
                id="DataFinal"
                name="dataFinal"
                placeholder="Data Final"
                value={state.dataFinal}
                onChange={(e) => handleChange(e)}
              />
              <label htmlFor="dataFinal" className="">
                Data Final
              </label>
            </div>
          </div>
          <div className="col-lg-3 d-flex align-items-center justify-content-center">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                id="codigoFinanceiro"
                name="codigoFinanceiro"
                defaultChecked
                onChange={(e) => {
                  handleChangeCodigoFinanceiro(e.target.checked);
                }}
              />
              <label className="form-check-label" htmlFor="ativo">
                Sem código financeiro
              </label>
            </div>
          </div>
          <div className="col-lg-2 my-3 my-lg-0  d-flex justify-content-center">
            <button className="btn btn-primary my-auto" onClick={GetListagemClientes}>
              Buscar
            </button>
          </div>
          <div className="col-lg-2 my-3 my-lg-0 d-flex justify-content-center align-items-center">
            <PDFClientesSemCodFinanceiro
              listagemClientes={state.listagemClientes}
              filtros={{ codigoFinanceiro: state.codigoFinanceiro, dataFim: state.dataFinal, dataInicio: state.dataInicial }}
            />
          </div>
        </div>
        <div className="row py-2"></div>
        <div className="row mt-2">
          {isLoading ? (
            <LoadingComponent />
          ) : state.listagemClientes.length === 0 ? (
            <div className="row my-5">
              <div className="col d-flex align-items-center justify-content-center">
                <h5 className="text-secondary">Sem clientes na listagem</h5>
              </div>
            </div>
          ) : (
            <>
              <div className="row mt-2">
                <div className="">
                  <h6 className="text-secondary">Total de clientes: {state.listagemClientes.length}</h6>
                  <h6 className="text-secondary">
                    Total de clientes sem código financeiro: {state.listagemClientes.filter((x) => !x.codigo_sac_local).length}
                  </h6>
                </div>
              </div>
              <div className="row my-3">
                <div className="col">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Contrato</th>
                        <th>Nome</th>
                        <th>CPF/CNPJ</th>
                        <th>Cód. Financeiro</th>
                        <th>Dt. Cadastro</th>
                      </tr>
                    </thead>
                    <tbody>
                      {state.listagemClientes.map((cliente) => (
                        <tr key={cliente.id}>
                          <td>
                            <a href={ROUTE_CLIENTES + '/' + cliente.id} target="_blank" rel="noreferrer">
                              {cliente.id} <FaExternalLinkAlt size={10} />
                            </a>
                          </td>
                          <td className="text-center">{cliente.contrato}</td>
                          <td>{cliente.nome_fantasia}</td>
                          <td>{maskdoc(cliente.cnpj)}</td>
                          <td className="text-center">{cliente.codigo_sac_local ? cliente.codigo_sac_local : 'Não'}</td>
                          <td>
                            {formataData(cliente.data_inc)} {formataHora(cliente.data_inc)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ListagemClientesPorDtCadastro;
