import React from 'react';
import { Chart } from 'react-google-charts';
import { formataData } from '../../../util/maskdoc.js';

function LineChart(props) {
  const content = [];
  const header = [
    'Dia',
    'Total',
    'Quantidade de Usuários',
    'Média por Usuario',
    'Médiana',
    'Usuario maior Quantidade',
    'Usuario menor Quantidade',
  ];
  content.push(header);

  props.data.forEach((item) => {
    content.push([formataData(item.data), item.total, item.qntEquipe, item.media, item.mediana, item.max, item.min]);
  });
  return <Chart chartType="Line" data={content} height={'400px'}/>;
}

export default LineChart;
