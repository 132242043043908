import React, { useState } from "react";
import { FaKey } from "react-icons/fa6";
import ModalGerarSenhaBackup from "./modalSenha.jsx";

function BtnGerarSenha() {
	const [openModalSenha, setOpenModalSenha] = useState(false);
	function handleOpenModalSenha() {
		setOpenModalSenha(true);
	}

	function handleCloseModalSenha() {
		setOpenModalSenha(false);
	}
	return (
		<>
			<ModalGerarSenhaBackup
				isOpen={openModalSenha}
				onRequestClose={handleCloseModalSenha}
			/>

			<div className="d-flex align-items-center mt-2">
				<button
					type="button"
					className="btn btn-primary d-flex align-items-center py-2"
					title="Gerar senha do backup"
					onClick={handleOpenModalSenha}
				>
					<FaKey />
				</button>
			</div>
		</>
	);
}

export default BtnGerarSenha;
