import PropTypes from 'prop-types';
import './style.css';

function ResumoCard(props) {
  return (
    <>
      <div className={`card-financeiro ${props.color}`}>
        <h6>{props.title}</h6>
        <span>Quantidade: {props.quantidade}</span>
      </div>
    </>
  );
}

export default ResumoCard;

ResumoCard.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string,
  quantidade: PropTypes.number,
  chaves: PropTypes.number,
};
