import { useEffect, useState } from 'react';
import Modal from 'react-modal/lib/components/Modal';

import Close from '../../../../assets/close.png';
import LoadingComponent from '../../../loading-component';
import api from '../../../../service/api';
import Swal from 'sweetalert2';

Modal.setAppElement('#root');

export default function ModalAjusteDeHorario(props) {
  const [loading, setLoading] = useState(false);
  const [novoHorario, setNovoHorario] = useState('');
  const [justificativa, setJustificativa] = useState('');
  const [erro, setErro] = useState({ erro: false, mensagem: '' });
  const { registro } = props;
  const Cancelar = () => {
    props.onRequestClose();
  };

  const request = () => {
    setLoading(true);
    const horario = new Date(novoHorario).toISOString().substring(0, 16);
    api
      .post('/v1/horario/ajuste', {
        id_usuario_alterado: registro.idUsuario,
        usuario_responsavel_id: localStorage.getItem('sessionIdUsuario'),
        controle: registro.controle,
        registro_horario_id: registro.registroHorarioId,
        novo_horario: horario,
        justificativa: justificativa,
      })
      .then(() => {
        setLoading(false);

        props.onRequestClose();
      })
      .catch((err) => {
        setLoading(false);
        setErro({ erro: true, mensagem: err.response.data.message });
        console.log('err :>> ', err);
      });
  };

  useEffect(() => {
    if (erro.erro === true) {
      Swal.fire({
        title: 'Erro',
        text: erro.mensagem + '. Verifique o horário selecionado.',
      });
    }
  }, [erro]);

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      preventScroll={false}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
      shouldCloseOnOverlayClick={false}
    >
      <button type="button" onClick={Cancelar} className="react-modal-close">
        <img src={Close} alt="Fechar" />
      </button>
      {!loading ? (
        <div className="container">
          <h5>
            <strong>Alterar horário</strong>
          </h5>
          <div className="row">
            <div className="col">
              <span className="fs-5">
                <strong>ID:</strong> {registro.registroHorarioId}
              </span>
            </div>
            <div className="col">
              <span className="fs-5">
                <strong>Controle:</strong> {registro.controle}
              </span>
            </div>
          </div>
          <div className="mt-3">
            <div className="form-floating">
              <input
                type="text"
                className="form-control"
                id="floatingMotivo"
                placeholder="Motivo"
                value={justificativa}
                onChange={(e) => setJustificativa(e.target.value)}
              />
              <label for="floatingPassword">Motivo</label>
            </div>
            <div className="mt-3">
              <label htmlFor="novo-horario">Selecione o novo horário:</label>
              <input
                type="datetime-local"
                id="novo-horario"
                className="form-control"
                value={novoHorario}
                onChange={(e) => setNovoHorario(e.target.value)}
              />
            </div>
          </div>
          <div className="mt-3 d-flex justify-content-center">
            <button className="btn btn-primary mx-4" onClick={request}>
              Enviar
            </button>
            <button className="btn btn-danger">Cancelar</button>
          </div>
        </div>
      ) : (
        <LoadingComponent />
      )}
    </Modal>
  );
}
