import './style.css';
const LoadingComponent = () => {
  return (
    <div className="area-loading">
      <h4>Carregando..</h4>
      <div className="loading-animation"></div>
    </div>
  );
};

export default LoadingComponent;
