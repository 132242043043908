import React, { useState } from 'react';
import styles from './calendario.module.css';
import AbaListagem from './listagem.jsx';
import AbaAdicionar from './adicionar.jsx';
import AbaCalendario from './calendario.jsx';

function Calendario() {
  const [abaAtiva, setAbaAtiva] = useState('L');

  function retornaAbaAtiva(aba) {
    return abaAtiva === aba ? styles.calendarioMenuButtonActive : '';
  }

  function alterarAba(aba) {
    setAbaAtiva(aba);
  }

  const abaAtivaComponente = {
    L: <AbaListagem alterarAba={alterarAba} />,
    A: <AbaAdicionar />,
    C: <AbaCalendario />,
  };

  return (
    <div className={styles.calendarioContainer}>
      <div className={styles.calendarioMenu}>
        <ul className="nav flex-column">
          <li className="nav-item">
            <button onClick={() => alterarAba('L')} className={`${styles.calendarioMenuButton} ${retornaAbaAtiva('L')}`}>
              Listagem
            </button>
          </li>
          <li className="nav-item">
            <button onClick={() => alterarAba('A')} className={`${styles.calendarioMenuButton} ${retornaAbaAtiva('A')}`}>
              Adicionar
            </button>
          </li>
          <li className="nav-item">
            <button onClick={() => alterarAba('C')} className={`${styles.calendarioMenuButton} ${retornaAbaAtiva('C')}`}>
              Calendário
            </button>
          </li>
        </ul>
      </div>

      <div className={styles.calendarioContent}>{abaAtivaComponente[abaAtiva]}</div>
    </div>
  );
}

export default Calendario;
