import React from 'react';
import { formataData, maskphone } from '../../util/maskdoc.js';
import PdfMake from './PdfMake.jsx';

export default function ListaParamPDF({ listagem, filtros }) {
  const info = {
    title: 'Listagem chave de clientes',
    author: 'CM Sistemas',
  };

  const formatTelefone = (telefone1, telefone2) => {
    const tel1 = telefone1 ? maskphone(telefone1) : null;
    const tel2 = telefone2 ? maskphone(telefone2) : null;
    let string = '';
    if (tel1) string = tel1;
    if (tel2 && !tel1) string = tel2;
    if (tel2 && tel1) string += ' / ' + tel2;
    return string;
  };

  const trataString = (string) => {
    if (string) {
      string = String(string).replace('Chave', '');
      string = String(string).replace('expira em', '');
      string = String(string).replace('expira hoje', 'Hoje');
      string = String(string).replace('.', '');
      string = String(string).trim();
    }

    return string;
  };

  const dicionarioFiltros = {
    tipoPesquisa: {
      0: 'Contrato/Razão Social/Nome Fantasia/CNPJ',
      1: 'Código do Cliente',
      2: 'Razão Social/Nome Fantasia',
      3: 'CNPJ',
      4: 'Contrato',
    },
    situacao: {
      0: 'Expiradas',
      1: 'A expirar',
    },
    financeiro: {
      0: 'Inadimplente',
      1: 'Adimplente',
    },
    disponibilidade: {
      0: 'Não',
      1: 'Sim',
    },
    menorQue: {
      1: 'Menor ou igual que',
      0: 'Maior ou igual que',
    },
  };

  function formataFiltros() {
    let filtro = [];
    if (filtros.tipoPesquisa || filtros.pesquisa) {
      filtro.push({
        text: `${dicionarioFiltros.tipoPesquisa[filtros.tipoPesquisa] || dicionarioFiltros.tipoPesquisa[0]}: ${filtros.pesquisa}`,
      });
    }
    if (filtros.situacao) {
      filtro.push({ text: `Situação da Chave: ${dicionarioFiltros.situacao[filtros.situacao]}` });
    }
    if (filtros.financeiro) {
      filtro.push({ text: `Situação Financeira: ${dicionarioFiltros.financeiro[filtros.financeiro]}` });
    }
    if (filtros.disponibilidade) {
      filtro.push({ text: `Disponibilidade: ${dicionarioFiltros.disponibilidade[filtros.disponibilidade]}` });
    }
    if (filtros.menorQue && filtros.dias) {
      filtro.push({ text: `${dicionarioFiltros.menorQue[filtros.menorQue]}: ${filtros.dias}` });
    }
    return filtro.flat();
  }

  const content = [
    {
      text: 'Listagem Situação de Chaves',
      style: { fontSize: 18, bold: true, alignment: 'justify' },
      alignment: 'center',
      margin: [0, 15, 0, 5],
    },
    formataFiltros(),
    {
      table: {
        headerRows: 1,
        widths: [65, '*', 'auto', 'auto', 'auto'],
        body: [
          [
            { text: 'Contrato'.toUpperCase(), bold: true },
            { text: 'Nome'.toUpperCase(), bold: true },
            { text: 'Validade'.toUpperCase(), bold: true },
            { text: 'Disponibilidade'.toUpperCase(), bold: true },
            { text: 'Telefones', bold: true },
          ],
          ...listagem.map((cliente) => [
            { text: cliente.contrato, alignment: 'center' },
            cliente.cliente.toUpperCase(),
            { text: trataString(cliente.status_chave), alignment: 'center' },
            {
              text:
                cliente.disponibilidade !== '0000-00-00' &&
                new Date(
                  cliente.disponibilidade.substring(0, 4),
                  Number(cliente.disponibilidade.substring(5, 7)) - 1,
                  cliente.disponibilidade.substring(8, 10)
                ) >= new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
                  ? formataData(cliente.disponibilidade)
                  : null,
              alignment: 'center',
            },
            { text: formatTelefone(cliente.telefone1, cliente.telefone2), alignment: 'center' },
          ]),
        ],
      },
      margin: [0, 15, 0, 0],
    },
  ];

  return <PdfMake content={content} info={info} orientation="landscape" />;
}
