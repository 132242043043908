import api from '../api.js';

export async function getRegistrosDia() {
  try {
    const response = await api.get('/v1/horario/RegistrosDia');

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
}
