import React from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import logo from './logo.js';
import { formataData, formataHora } from '../../util/maskdoc.js';
import { FaFilePdf } from 'react-icons/fa6';

export default function PdfMake({ content, info, orientation = 'portrait' }) {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const logoDataUrl = `data:image/png;base64,${logo}`;
  const dataAtual = new Date();

  function contadorPag(currentPage, pageCount) {
    return {
      margin: [0, 0, 0, 10],
      canvas: [{ type: 'line', x1: 10, y1: 1, x2: 550 - 2 * 40, y2: 1, lineWidth: 1 }],
      columns: [
        { text: `CM SISTEMAS - ${dataAtual.getFullYear()}`, alignment: 'center', fontSize: 9, italics: true },
        {
          text: 'Página ' + currentPage.toString() + ' de ' + pageCount,
          alignment: 'center',
          fontSize: 8,
          italics: true,
        },
      ],
    };
  }

  const reportTitle = {
    columns: [
      [
        { text: 'SAC - CM SISTEMAS', fontSize: 16, bold: true, margin: [15, 15, 0, 5] },
        {
          text: `Gerado em ${formataData(dataAtual.toISOString())} às ${formataHora(dataAtual.toISOString())}`,
          fontSize: 11,
          italics: true,
          margin: [15, 15, 0, 5],
        },
      ],
      [
        {
          image: logoDataUrl,
          width: 50,
          alignment: 'right',
          margin: [0, 15, 15, 5],
        },
      ],
    ],
    canvas: [{ type: 'line', x1: 10, y1: 1, x2: 550 - 2 * 40, y2: 1, lineWidth: 1 }],
    margin: [0, 0, 0, 10],
  };

  const docDefinition = {
    pageSize: 'A4',
    pageMargins: [15, 80, 15, 20],
    header: [reportTitle],
    content: content,
    footer: contadorPag,
    info,
    pageOrientation: orientation,
  };

  return (
    <div>
      <button
        className="btn btn-outline-success border-0 p-1 d-flex align-items-center justify-content-center"
        onClick={() => pdfMake.createPdf(docDefinition).open()}
      >
        <FaFilePdf size={23} style={{ marginLeft: 2 }} />
      </button>
    </div>
  );
}
