import React, { useEffect, useState } from 'react';
import api from '../../../service/api.js';
import AtendimentoPorUsuarioPDF from '../../../components/pdf-make/PDFAtendimentoPorUsuario.jsx';

function AtendimentoPorEquipe() {
  const [dataInicial, setDataInicial] = useState(new Date().toISOString().split('T')[0]);
  const [dataFinal, setDataFinal] = useState(new Date().toISOString().split('T')[0]);
  const [setor, setSetor] = useState('');
  const [atendimentosUsuarios, setAtendimentoUsuarios] = useState([]);
  const [total,setTotal] = useState(0);
  const [listagemTotal, setListagemTotal] = useState([]);

  const GetDadosAtendimentos = () => {
    api
      .get('/v1/atendimentos-usuarios', {
        params: {
          dataInicial,
          dataFinal
        },
      })
      .then((response) => {
        const listagem = response.data.usuarios;
        response.data.usuarios = listagem.filter(
          (x) => !['Administrador SAC', 'Ana Carolina', 'Joaquim', 'Cristiano '].includes(x.usuario)
        );
        setListagemTotal(response.data.usuarios);
        setTotal(response.data.Total)
      });
  };

  useEffect(() => {
    GetDadosAtendimentos();
  }, []);

  useEffect(() => {
    if (setor === '') return setAtendimentoUsuarios(listagemTotal);
    const listagemFiltrada = listagemTotal.filter((x) => x.setor === setor);
    setAtendimentoUsuarios(listagemFiltrada);
  }, [setor]);

  useEffect(()=>{
    if(setor === '') return setAtendimentoUsuarios(listagemTotal);
    if(setor === 'D') return setAtendimentoUsuarios(listagemTotal.filter(x=>x.setor === 'D'))
    if(setor === 'S') return setAtendimentoUsuarios(listagemTotal.filter(x=>x.setor === 'S'))
  },[listagemTotal])

  return (
    <>
      <div className="container shadow-lg rounded-2 mt-3">
        <div className="row justify-content-between align-items-center mb-2 mt-3">
          <div className="col-lg-11 col-sm-10 col-auto ms-1 mb-1">
            <a className="btn-colapse" data-bs-toggle="collapse" role="button" aria-expanded="false">
              <h5 className="text-dark mt-3 titulo">Relatório de atendimento por usuário</h5>
            </a>
          </div>
        </div>
        <div className="mb-3" id="collapseDashBoard">
          <div className="row py-2">
            <div className="col-lg-2">
              <div className="form-floating">
                <input
                  value={dataInicial}
                  type="date"
                  className="form-control"
                  id="dataInicial"
                  name="dataInicial"
                  placeholder="Data Inicial"
                  onChange={(e) => setDataInicial(e.target.value)}
                />
                <label htmlFor="dataAdmissao" className="">
                  Data Inicial
                </label>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="form-floating">
                <input
                  value={dataFinal}
                  type="date"
                  className="form-control"
                  id="DataFinal"
                  name="dataFinal"
                  placeholder="Data Final"
                  onChange={(e) => setDataFinal(e.target.value)}
                />
                <label htmlFor="dataAdmissao" className="">
                  Data Final
                </label>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="form-floating">
                <select
                  value={setor}
                  type="date"
                  className="form-control"
                  id="setor"
                  name="setor"
                  placeholder="Data Final"
                  onChange={(e) => setSetor(e.target.value)}
                >
                  <option value="">Qualquer</option>
                  <option value="D">Desenvolvimento</option>
                  <option value="S">Suporte</option>
                </select>
                <label htmlFor="setor" className="">
                  Setor
                </label>
              </div>
            </div>
            <div className="col-lg-2 my-3 my-lg-0  d-flex justify-content-center">
              <button className="btn btn-primary my-auto" onClick={GetDadosAtendimentos}>
                Buscar
              </button>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col">
              <p>Total de atendimentos lançados: {total}</p>
            </div>
            <div className="col">
              <AtendimentoPorUsuarioPDF atendimentos={{usuarios:atendimentosUsuarios, Total:total}} filtros={{ dataInicial, dataFinal, setor }} />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th>Usuario</th>
                    <th>Lançados</th>
                    <th>Pendentes</th>
                    <th>Atendendo</th>
                    <th>Aguardando</th>
                    {setor === 'D' ? (
                      <>
                        <th>Testes</th>
                        <th>Atualizar</th>
                      </>
                    ) : null}
                    <th>Finalizados</th>
                    <th>Cancelados</th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {atendimentosUsuarios.length>0 &&
                    atendimentosUsuarios.map((atendimento) => (
                      <tr key={atendimento.usuario}>
                        <td className="text-start">{atendimento.usuario}</td>
                        <td>{atendimento.lancados}</td>
                        <td>{atendimento.pendente}</td>
                        <td>{atendimento.atendendo}</td>
                        <td>{atendimento.aguardando}</td>
                        {setor === 'D' ? (
                          <>
                            <td>{atendimento.testes}</td>
                            <td>{atendimento.atualizacao}</td>
                          </>
                        ) : null}
                        <td>{atendimento.finalizado}</td>
                        <td>{atendimento.cancelado}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AtendimentoPorEquipe;
