import { BrowserRouter, Routes, Route } from 'react-router-dom';
import routes from '../constants/routes';
import { useContext, useEffect } from 'react';
import { UsuarioContext } from '../context/userContext';
import {
  ST__EMAILUSUARIO,
  ST__IDUSUARIO,
  ST__ISADMIN,
  ST__NOMEUSUARIO,
  ST__PERMISSOES,
  ST__SETORUSUARIO,
  ST__TOKEN,
} from '../constants/storagekey';

function Rotas() {
  const {
    tokenUsuario,
    setEmailUsuario,
    setIdUsuario,
    setTokenUsuario,
    setIsAdminUsuario,
    setNomeUsuario,
    setPermissoesUsuario,
    setSetorUsuario,
  } = useContext(UsuarioContext);

  useEffect(() => {
    if (localStorage && !tokenUsuario) {
      setEmailUsuario(localStorage.getItem(ST__EMAILUSUARIO));
      setIdUsuario(localStorage.getItem(ST__IDUSUARIO));
      setTokenUsuario(localStorage.getItem(ST__TOKEN));
      setIsAdminUsuario(localStorage.getItem(ST__ISADMIN));
      setNomeUsuario(localStorage.getItem(ST__NOMEUSUARIO));
      setSetorUsuario(localStorage.getItem(ST__SETORUSUARIO));
      setPermissoesUsuario(JSON.parse(localStorage.getItem(ST__PERMISSOES)));
    }
  }, [localStorage, tokenUsuario]);

  return (
    <>
      <BrowserRouter>
        <Routes>
          {routes.map((route) => (
            <Route key={route.key} path={route.path} element={route.element}>
              {route.children?.map((childRoute) => (
                <Route key={childRoute.key} path={childRoute.path} element={childRoute.element} />
              ))}
            </Route>
          ))}
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default Rotas;
