import { useEffect, useState } from 'react';
import api from '../../../service/api.js';
import PermissoesComponent from '../../../components/permissoes/index.jsx';
import { ST__IDUSUARIO } from '../../../constants/storagekey.js';
import { useNavigate } from 'react-router-dom';
import { ROUTE_404 } from '../../../constants/routes.js';
import Titulo from '../../../components/titulo/index.jsx';

export default function PermissoesPage() {
  const navigate = useNavigate();
  const [abaAtiva, setAbaAtiva] = useState('U');
  const [usuarios, setUsuarios] = useState([]);
  const [setores, setSetores] = useState([]);
  const getUsuarios = () => {
    api
      .get('/v1/usuarios', {
        params: {
          tipo_busca: '2',
          ativo: 'D',
        },
      })
      .then((res) => {
        setUsuarios(res.data);
      });
  };
  const getSetores = () => {
    api.get('/v1/setores').then((res) => {
      setSetores(res.data);
    });
  };

  useEffect(() => {
    const VERIFICA = [6, 13, 20, 37];
    const havePermission = VERIFICA.findIndex((x) => x == localStorage.getItem(ST__IDUSUARIO));
    if (havePermission === -1) {
      navigate(ROUTE_404);
    }
    getUsuarios();
    getSetores();
  }, []);

  return (
    <>
      <div className="container shadow-lg rounded-2 mt-3 pb-3">
        <Titulo principal titulo="Permissões de Usuário" />
        <div className="row ps-2 mt-3">
          <ul className="nav nav-tabs justify-content-center" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${abaAtiva === 'U' ? 'active' : ''}`}
                id="usuario"
                data-bs-toggle="tab"
                data-bs-target="#usuario-tab-pane"
                type="button"
                role="tab"
                aria-controls="usuario-tab-pane"
                aria-selected="false"
                onClick={() => setAbaAtiva('U')}
              >
                Usuarios
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={`nav-link ${abaAtiva === 'S' ? 'active' : ''}`}
                id="setor"
                data-bs-toggle="tab"
                data-bs-target="#setor-tab-pane"
                type="button"
                role="tab"
                aria-controls="setor-tab-pane"
                aria-selected="true"
                onClick={() => setAbaAtiva('S')}
              >
                Setor
              </button>
            </li>
          </ul>
        </div>
        {abaAtiva === 'U' ? (
          <PermissoesComponent items={usuarios} tipo={'usuario'} />
        ) : (
          <PermissoesComponent items={setores} tipo={'setor'} />
        )}
      </div>
    </>
  );
}
