import { Calendario } from '../../../components/calendario/index.jsx';
import { useContext, useEffect, useState } from 'react';
import { UsuarioContext } from '../../../context/userContext.jsx';
import TabelaLiberacao from './liberacaoTable/tabela.jsx';
import api from '../../../service/api.js';
import moment from 'moment/moment.js';
import { UsuariosContext } from '../../../context/usersContext.jsx';
import { formataData } from '../../../util/maskdoc.js';
import { toast } from '../../../constants/toast.jsx';
import Titulo from '../../../components/titulo/index.jsx';
import { ST__IDUSUARIO } from '../../../constants/storagekey.js';

function ControleFerias() {
  const [lista, setLista] = useState([]);
  const [liberacaoFerias, setLiberacaoFerias] = useState([]);
  const [feriasTimeLine, setFeriasTimeLine] = useState([]);
  const [filtro, setFiltro] = useState({ dataInicial: '', dataFinal: '', idUsuario: -1 });
  const [lancamentoInicial, setLancamentoInicial] = useState('');
  const [lancamentoFinal, setLancamentoFinal] = useState('');
  const [calculoFerias, setCalculoFerias] = useState({});

  const { isAdminUsuario, idUsuario } = useContext(UsuarioContext);
  const { usuarios } = useContext(UsuariosContext);

  function formatarDados(dados) {
    const dadosFormatados = [];

    dados.forEach((item) => {
      const title = `Férias ${item.nome} - ${item.status !== 'L' ? 'Analise' : 'Liberado'}`;

      const start = new Date(item.data_inicio.split('T')[0]);
      start.setDate(start.getDate() + 1);
      const end = new Date(item.data_fim.split('T')[0]);
      end.setDate(end.getDate() + 1);
      dadosFormatados.push({ title, start, end });
    });
    return dadosFormatados;
  }

  function GetFeriasPesquisa({ dataInicial, dataFinal, idUsuario }) {
    if (!dataInicial) dataInicial = new Date().toISOString().split('T')[0];
    if (!dataFinal) {
      dataFinal = new Date();
      dataFinal.setMonth(dataFinal.getMonth() + 6);
    }
    if (idUsuario === -1) {
      idUsuario = null;
    }
    api
      .get('/v1/ferias', {
        params: {
          dataInicial,
          dataFinal,
          idUsuario,
        },
      })
      .then((result) => {
        const dadosTimeLine = formatarDados(result.data);
        setLista(result.data);
        setFeriasTimeLine(dadosTimeLine);
      });
  }

  function GetCalculoFeriasUsuario() {
    api
      .get('/v1/ferias/calculo', {
        params: {
          id_usuario: idUsuario || localStorage.getItem(ST__IDUSUARIO),
        },
      })
      .then((result) => {
        setCalculoFerias(result.data);
      });
  }

  function GetFerias() {
    const dataInicial = new Date();
    dataInicial.setMonth(dataInicial.getMonth() - 2);

    const dataFinal = new Date();
    dataFinal.setMonth(dataFinal.getMonth() + 6);

    api
      .get('/v1/ferias', {
        params: {
          dataInicial: dataInicial.toISOString().split('T')[0],
          dataFinal,
        },
      })
      .then((result) => {
        const dadosTimeLine = formatarDados(result.data);
        setLista(result.data);
        setFeriasTimeLine(dadosTimeLine);
        const liberacaoLista = [];
        const statusForLiberation = ['P', 'N'];
        result.data.forEach((item) => {
          statusForLiberation.includes(item.status) && liberacaoLista.push(item);
        });
        setLiberacaoFerias(liberacaoLista);
      });
  }

  const formataSigla = (sigla) => {
    switch (sigla) {
      case 'P':
        return 'Pendente';
      case 'L':
        return 'Liberado';
      case 'C':
        return 'Cancelado';
      default:
        return 'Liberado';
    }
  };

  function diffDays(Inicio, Fim) {
    const dInicio = moment(Inicio.split('T')[0]);
    const dFim = moment(Fim.split('T')[0]);
    const diffDias = dFim.diff(dInicio, 'days') + 1; // +1 para incluir o último dia
    return diffDias;
  }

  const lancarFerias = () => {
    api
      .post('/v1/ferias', {
        dataInicio: lancamentoInicial,
        dataFim: lancamentoFinal,
        idUsuario,
      })
      .then(() => {
        toast.fire({
          icon: 'success',
          text: 'Férias lançadas com sucesso!\nAguarde a liberação do Administrador',
        });
      })
      .catch((error) => {
        console.log('error :>> ', error);
        toast.fire({
          icon: 'error',
          text: error.response.data.message || error.response.data || 'Erro ao lançar férias',
        });
      });
  };

  const handleSetDataInicio = (ferias_id, value) => {
    setLiberacaoFerias((prevFerias) =>
      prevFerias.map((feria) => (feria.ferias_id === ferias_id ? { ...feria, data_inicio: value } : feria))
    );
  };

  const handleSetDataFim = (ferias_id, value) => {
    setLiberacaoFerias((prevFerias) => prevFerias.map((feria) => (feria.ferias_id === ferias_id ? { ...feria, data_fim: value } : feria)));
  };

  const handleDataInicialChange = (e) => {
    setFiltro({ ...filtro, dataInicial: e.target.value });
  };

  const handleDataFinalChange = (e) => {
    setFiltro({ ...filtro, dataFinal: e.target.value });
  };

  const handleUsuarioChange = (e) => {
    setFiltro({ ...filtro, idUsuario: e.target.value });
  };

  useEffect(() => {
    GetFerias();
    GetCalculoFeriasUsuario();
  }, []);

  return (
    <>
      <div className="container shadow-lg rounded-2 mt-3 pb-3">
        <Titulo titulo="Controle de Férias" retorno="usuarios" />
        <div className="collapse" id="collapseExample">
          <div className="card card-body mt-3">
            <div className="row">
              <div className="col-md-3 col-sm-12 nowrap">
                <>
                  <label htmlFor="edDataInicial">Data Inicial</label>
                  <input
                    value={filtro.dataInicial}
                    type="date"
                    className="form-control mb-2"
                    id="edDataInicial"
                    placeholder=""
                    onChange={handleDataInicialChange}
                  />
                </>
              </div>

              <div className="col-md-3 col-sm-12 nowrap">
                <>
                  <label htmlFor="edDataFinal">Data Final</label>
                  <input
                    value={filtro.dataFinal}
                    type="date"
                    className="form-control mb-2"
                    id="edDataFinal"
                    placeholder=""
                    onChange={handleDataFinalChange}
                  />
                </>
              </div>
              <div className="col-md-3 col-sm-12 nowrap">
                <label htmlFor="usuario">Usuarios</label>
                <select id="usuario" className="form-select mb-2" aria-label="Selecionar" defaultValue={-1} onChange={handleUsuarioChange}>
                  <option value={-1}>Qualquer um</option>
                  {usuarios.map((usuario) => {
                    return (
                      <option key={usuario.ID} value={usuario.ID}>
                        {usuario.NOME}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-2 col-sm-12 d-flex align-items-center justify-content-around">
                <button className="btn btn-primary mt-2" onClick={() => GetFeriasPesquisa(filtro)}>
                  Pesquisar
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-3 border-top border-secondary-subtle">
          <a
            className="fs-5 text text-decoration-none"
            data-bs-toggle="collapse"
            href="#listagem"
            aria-expanded="false"
            aria-controls="listagem"
          >
            Listagem
          </a>
          <div className="collapse mt-2" id="listagem">
            {lista.map((item) => {
              return (
                <div key={item.ferias_id} className="row border border-success border-opacity-50">
                  <div className="col">
                    <span>Usuario:</span> <span>{item.nome}</span>
                  </div>
                  <div className="col">
                    <span>Inicio:</span> <span>{formataData(item.data_inicio)}</span>
                  </div>
                  <div className="col">
                    <span>Fim:</span> <span>{formataData(item.data_fim)}</span>
                  </div>
                  <div className="col">
                    <span>Dias:</span> <span>{diffDays(item.data_inicio, item.data_fim)}</span>
                  </div>
                  <div className="col">
                    <span>Status:</span> <span>{formataSigla(item.status)}</span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="mt-3 border-top border-secondary-subtle">
          <a
            className="fs-5 text text-decoration-none"
            data-bs-toggle="collapse"
            href="#calendario"
            aria-expanded="false"
            aria-controls="calendario"
          >
            Calendário
          </a>
          <div className="collapse" id="calendario">
            <Calendario dados={feriasTimeLine} />
          </div>
        </div>

        {isAdminUsuario && isAdminUsuario === 'S' ? (
          <div className="mt-3 border-top border-secondary-subtle">
            <a
              className="fs-5 text text-decoration-none"
              data-bs-toggle="collapse"
              href="#liberacoes"
              aria-expanded="false"
              aria-controls="liberacoes"
            >
              Liberações
            </a>
            <div className="collapse" id="liberacoes">
              <TabelaLiberacao ferias={liberacaoFerias} onSetDataInicio={handleSetDataInicio} onSetDataFim={handleSetDataFim} />
            </div>
          </div>
        ) : liberacaoFerias.filter((item) => item.funcionario_id === Number(idUsuario)).length > 0 ? (
          <div className="mt-3 border-top border-secondary-subtle">
            <a
              className="fs-5 text text-decoration-none"
              data-bs-toggle="collapse"
              href="#liberacoes1"
              aria-expanded="false"
              aria-controls="liberacoes1"
            >
              Liberações
            </a>
            <div className="collapse" id="liberacoes1">
              <TabelaLiberacao
                ferias={liberacaoFerias.filter((item) => item.funcionario_id === Number(idUsuario))}
                onSetDataInicio={handleSetDataInicio}
                onSetDataFim={handleSetDataFim}
              />
            </div>
          </div>
        ) : null}
        <div className="mt-3 border-top border-secondary-subtle">
          <p className="fs-5 text text-decoration-none">Lançar férias</p>
          <p className="fs-6 text text-decoration-none">Dias de Férias Restantes: {calculoFerias.diasFeriasRestantes || 0} dias</p>
          <div className="row mb-4">
            <div className="col">
              <label htmlFor="inicioFeriasLancamento">Data de Início</label>
              <input
                id="inicioFeriasLancamento"
                value={lancamentoInicial}
                type="date"
                className="form-control"
                onChange={(e) => setLancamentoInicial(e.target.value)}
                disabled={calculoFerias.diasFeriasRestantes === 0}
              />
            </div>

            <div className="col">
              <label htmlFor="terminoFeriasLancamento">Data de Término</label>
              <input
                id="terminoFeriasLancamento"
                value={lancamentoFinal}
                type="date"
                className="form-control"
                onChange={(e) => setLancamentoFinal(e.target.value)}
                disabled={calculoFerias.diasFeriasRestantes === 0}
              />
            </div>

            <div className="col d-flex align-items-center">
              <button className="btn btn-primary mt-2" onClick={lancarFerias} disabled={calculoFerias.diasFeriasRestantes === 0}>
                Enviar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ControleFerias;
