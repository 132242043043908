import { useContext, useState } from 'react';
import Modal from 'react-modal/lib/components/Modal';
import Swal from 'sweetalert2';
import Close from '../../../assets/close.png';
import { UsuarioContext } from '../../../context/userContext.jsx';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AlterarAtendimento } from '../../../service/mutations/atendimento.js';
import { maskVersao } from '../../../util/maskdoc.js';

Modal.setAppElement('#root');

export default function ModalSolucaoRapida(props) {
  const queryClient = useQueryClient();
  const [solucao, setSolucao] = useState('');
  const [versao, setVersao] = useState(props.infoAtendimento.versao_atualizacao);

  const { idUsuario } = useContext(UsuarioContext);

  const Cancelar = () => {
    props.onRequestClose();
  };

  const SolucionarAtendimento = () => {
    const erros = [];
    if (!solucao) {
      erros.push(`Nenhuma solução escrita!`);
    }
    if (props.infoAtendimento.tipo === 'A' && !versao) {
      erros.push(`Atendimento do tipo atualização e nenhuma versão definida!`);
    }
    if (erros.length > 0) return Swal.fire({ icon: 'question', title: 'Campos obrigatórios', text: erros.join('\n') });

    SolucionarAtendimentoFn();
  };

  const { mutateAsync: SolucionarAtendimentoFn } = useMutation({
    mutationFn: async () => {
      await AlterarAtendimento({
        id: props.infoAtendimento.id,
        situacao: props.infoAtendimento.tipo === 'O' ? 'E' : 'F',
        solucao,
        id_cliente: props.infoAtendimento.id_cliente,
        id_equipe_lancamento: props.infoAtendimento.id_equipe_lancamento,
        id_equipe_suporte: idUsuario,
        tipo: props.infoAtendimento.tipo,
        versao_atualizacao: props.infoAtendimento.tipo === 'A' ? versao : null,
        data_agenda: props.infoAtendimento.tipo === 'L' ? props.infoAtendimento.data_agenda : null,
      });
    },
    onSuccess: () => {
      queryClient.setQueryData(['atendimentos'], (old) =>
        old.filter((atendimento) => Number(atendimento.id) !== Number(props.infoAtendimento.id))
      );
      queryClient.invalidateQueries({ queryKey: [`atendimento-${props.infoAtendimento.id}`] });
      return props.onRequestClose();
    },
    onError: (err) => {
      Swal.fire({
        icon: 'error',
        title: 'Erro ao solucionar o atendimento!',
        text: err.response.data || 'Erro ao solucionar o atendimento',
      });
    },
  });

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      preventScroll={false}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
      shouldCloseOnOverlayClick={false}
    >
      <button type="button" onClick={Cancelar} className="react-modal-close">
        <img src={Close} alt="Fechar" />
      </button>
      <div className="container">
        {/* <h5>Atalho Solucionar</h5> */}
        <div className="mt-2">
          <h3>Atendimento {props.infoAtendimento.id}</h3>
          <h5>Cliente: {props.infoAtendimento.cliente}</h5>
          {props.infoAtendimento.tipo === 'A' ? (
            <>
              <label htmlFor="versao" className="obrigatorio">
                Versão do sistema:
              </label>
              <input type="text" className="form-control" value={maskVersao(versao)} onChange={(e) => setVersao(e.target.value)} />
            </>
          ) : null}
          <label htmlFor="ocorrencia" className="obrigatorio">
            Solução:
          </label>
          <textarea
            className="form-control"
            maxLength={400}
            id="texto"
            rows={3}
            value={solucao}
            onChange={(e) => setSolucao(e.target.value)}
          />
          <div>{solucao.length >= 400 ? <small className="text-danger">Limite de 400 caracteres</small> : null}</div>
        </div>
        <div className="mt-4">
          <div className="col-12 mt-3 d-flex justify-content-center align-items-center div-botoes">
            <button type="button" className="btn btn-lg btn-outline-primary" onClick={SolucionarAtendimento}>
              Enviar
            </button>
            <button type="button" className="btn btn-lg btn-danger" onClick={Cancelar}>
              Cancelar
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
