import { useContext, useState } from 'react';
import Modal from 'react-modal/lib/components/Modal';
import Close from '../../../assets/close.png';
import './style.css';
import api from '../../../service/api.js';
import { formataData } from '../../../util/maskdoc.js';
import { UsuarioContext } from '../../../context/userContext.jsx';
import { toast } from '../../../constants/toast.jsx';
import { FormataSemana } from '../../../util/utils.js';

Modal.setAppElement('#root');

const initialState = {
  dias: '',
  chave: '',
  data: '',
  teste: false,
};

export default function ModalGerarChave(props) {
  const [state, setState] = useState(initialState);
  const [chaveParam, setChaveParam] = useState(false);
  const { permissoesUsuario, idUsuario, setorUsuario } = useContext(UsuarioContext);

  const Cancelar = () => {
    setState(initialState);
    props.onRequestClose();
  };

  const verificarDataFDS = (data) => {
    if (data.getDay() === 6) {
      toast.fire({
        icon: 'info',
        title: 'Quantidade de dias cairá no fim de semana, adicione 2 dias para cair em um dia útil.',
      });
    } else if (data.getDay() === 0) {
      toast.fire({
        icon: 'info',
        title: 'Quantidade de dias cairá no fim de semana, adicione 1 dia para cair em um dia útil.',
      });
    } else if (data.getDay() === 5) {
      toast.fire({
        icon: 'info',
        title: 'Quantidade de dias cairá na sexta-feira, o cliente ficará com a chave expirada no sábado.',
      });
    }
  };

  const UpdateDiasState = (value) => {
    if (Number(value) <= permissoesUsuario.max_dias_gerar_chave || (Number(value) === Number(props.cliente.dias_expirar) && chaveParam)) {
      const data = new Date();
      data.setDate(data.getDate() + Number(value));
      verificarDataFDS(data);
      setState((prevState) => ({
        ...prevState,
        dias: value,
        data: data.toISOString(),
      }));
    } else {
      toast.fire({
        icon: 'info',
        title: `Máximo de dias permitido ${permissoesUsuario.max_dias_gerar_chave}`,
      });
    }
  };

  function handleSetChaveDoCliente(e) {
    e.preventDefault();
    if (props.cliente.dias_expirar !== null && Number(props.cliente.dias_expirar) >= 0) {
      const data = new Date();
      data.setDate(data.getDate() + Number(props.cliente.dias_expirar));

      setState((prevState) => ({
        ...prevState,
        dias: props.cliente.dias_expirar,
        data: data.toISOString(),
      }));
    }
  }

  const GerarChave = () => {
    const dataChave = new Date(state.data);
    if (state.dias !== '' && dataChave) {
      !chaveParam
        ? api
            .post('/v1/financeiro/provisoria', {
              cliente: { contrato: props.cliente.contrato, id: props.cliente.cliente_id },
              dias: state.dias,
            })
            .then((result) => {
              setState({ ...state, chave: result.data.chave });
            })
            .catch((erro) => {
              toast.fire({
                icon: erro.response.status === 500 ? 'error' : 'warning',
                title: 'Chave do sistema não gerada!',
                text: erro.response.status === 500 ? '' : erro.response.data.message,
              });
            })
        : api
            .post('/v1/financeiro/renovacoes', [
              {
                contrato: props.cliente.contrato,
                dias: state.dias,
                disponibilidade: dataChave.toISOString().split('T')[0],
                cliente_id: props.cliente.cliente_id,
                liberado: 'S',
                liberacao: 'SAC_WEB',
                usuario_id: idUsuario,
              },
            ])
            .then(() => {
              props.cliente.dias_liberacao = state.dias;
              props.cliente.disponibilidade = dataChave.toISOString().split('T')[0];
              props.cliente.ultima_liberacao = 'SAC_WEB';
              props.cliente.data_hora_liberacao = new Date().toISOString();
              toast.fire({
                icon: 'success',
                title: 'Gerado chave para coleta do PARAM.',
              });
            })
            .catch((erro) => {
              toast.fire({
                icon: erro.response.status === 500 ? 'error' : 'warning',
                title: 'Chave para o PARAM não gerada!',
                text: erro.response.status === 500 ? '' : erro.response.data.join('. '),
              });
            });
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    GerarChave();
  };

  const handleCheckChaveParam = (e) => {
    setChaveParam(e.target.checked);
    if (!e.target.checked) {
      setState(initialState);
    }
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      preventScroll={false}
      className="react-modal-content"
      overlayClassName="react-modal-overlay"
    >
      <form onSubmit={handleFormSubmit}>
        <button type="button" onClick={Cancelar} className="react-modal-close">
          <img src={Close} alt="Fechar" />
        </button>
        <div className="container-fluid h-100 endereco-modal">
          <div className="col-12 mt-0">
            <h4 className="mt-2-mb-4">Geração de Chave</h4>
          </div>
          <div className="mt-1 mb-1">
            <span>Cliente: {props.cliente.cliente}</span>
            <br />
            <span>Contrato: {props.cliente.contrato}</span>
            <br />
            <span>Situação: {props.cliente.situacao}</span>
            <br />
            <span>Param Web: {props.cliente.param_web === 'S' ? 'Sim' : 'Não'}</span>
            {permissoesUsuario.financeiro || setorUsuario === 'S' ? (
              <>
                <br />
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={handleCheckChaveParam}
                    id="flexCheckChecked"
                    checked={chaveParam}
                  />
                  <label className="form-check-label" htmlFor="flexCheckChecked">
                    Chave para Param
                  </label>
                  {setorUsuario === 'S' && chaveParam ? (
                    <button className="btn btn-sm p-1 py-0 ms-2 btn-outline-secondary border-0" onClick={(e) => handleSetChaveDoCliente(e)}>
                      Chave do cliente
                    </button>
                  ) : null}
                </div>
              </>
            ) : null}
          </div>
          <div className="row">
            <div className="col-2">
              <div className="form-floating">
                <input
                  type="number"
                  min={0}
                  value={state.dias}
                  className="form-control"
                  id="dias"
                  name="dias"
                  onChange={(e) => UpdateDiasState(e.target.value)}
                  placeholder="Dias"
                />
                <label htmlFor="dias">Dias</label>
              </div>
            </div>
            <div className="col-3">
              <div className="form-floating">
                <input
                  type="text"
                  value={formataData(state.data)}
                  disabled
                  className="form-control"
                  id="data"
                  name="data"
                  readOnly
                  placeholder="data"
                />
                <label htmlFor="data">Data</label>
              </div>
            </div>
            <div className="col-3">
              <div className="form-floating">
                <input
                  type="text"
                  value={FormataSemana(new Date(state.data).getDay())}
                  disabled
                  className="form-control"
                  id="dia-semana"
                  name="dia-semana"
                  readOnly
                  placeholder="Dia da Semana"
                />
                <label htmlFor="data">Dia da Semana</label>
              </div>
            </div>
            <div className="col-4">
              <div className="form-floating">
                <input
                  type="text"
                  value={state.chave}
                  readOnly
                  className="form-control"
                  disabled
                  id="chave"
                  name="chave"
                  placeholder="chave"
                />
                <label htmlFor="chave">Chave</label>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 mt-3 d-flex justify-content-center align-items-center div-botoes">
              <button type="submit" className="btn btn-sm btn-primary">
                Gerar
              </button>
              <button type="button" className="btn btn-sm btn-danger" onClick={Cancelar}>
                Cancelar
              </button>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}
