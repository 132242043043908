import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Howl } from 'howler';
import { fetchNotificacoes } from '../service/fetchers/notificacoes.js';
import { ST__NOTIFICACOES, ST__SETORUSUARIO, ST__TOKEN, ST__ULT_REQ_NOTIFICATION } from '../constants/storagekey.js';
import { lerNotificacoes } from '../service/mutations/notificacoes.js';
import { UsuarioContext } from './userContext.jsx';

export const NotificacoesContext = createContext({
  notificacoes: null,
  lerNotificacao: () => undefined,
  setNotificacaoGerada: () => undefined,
  notificacaoGerada: false,
});

const NOTIFICATION_CHANNEL = 'notification_channel';

export function NotificacoesContextProvider({ children }) {
  const queryClient = useQueryClient();
  const [notificacoes, setNotificacoes] = useState([]);
  const [notificacaoGerada, setNotificacaoGerada] = useState(false);
  const [tabId] = useState(Date.now() + Math.random());
  const soundRef = useRef(null);
  const broadcastChannelRef = useRef(null);
  const { tokenUsuario } = useContext(UsuarioContext);

  function broadcastNotificacoes(notificacoes) {
    if (broadcastChannelRef.current) {
      broadcastChannelRef.current.postMessage({ tabId, notificacoes });
    }
  }

  function handleBroadcastMessage(event) {
    const { notificacoes: receivedNotificacoes, tabId: receveidId } = event.data;
    if (tabId !== receveidId) {
      const newNotificacoes = receivedNotificacoes.filter((x) => {
        return !notificacoes.some((n) => n.notificacao_id === x.notificacao_id);
      });
      setNotificacoes(receivedNotificacoes);
      if (newNotificacoes.length > 0) {
        queryClient.invalidateQueries({ queryKey: ['atendimentos'] });
      }
    }
  }

  function playNotificationSound() {
    const loop = localStorage.getItem(ST__SETORUSUARIO) === 'D' ? false : true;
    if (!soundRef.current) {
      soundRef.current = new Howl({
        src: [require('../assets/notificacao.mp3')],
        loop,
      });
    }

    if (!soundRef.current.playing()) {
      soundRef.current.play();
    }
  }

  function stopNotificationSound() {
    if (soundRef.current) {
      soundRef.current.stop();
    }
  }

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['notificacoes'],
    queryFn: () => {
      const atual = notificacoes.length === 0 ? null : notificacoes;
      return fetchNotificacoes(atual);
    },
    staleTime: Infinity,
    enabled: !!localStorage.getItem(ST__TOKEN) || !!tokenUsuario,
  });

  useEffect(() => {
    if (data && !isLoading) {
      const newNotificacoes = data.filter((x) => !notificacoes.some((n) => n.notificacao_id === x.notificacao_id));
      setNotificacoes((prevNotificacoes) => [...prevNotificacoes, ...newNotificacoes]);
      if (newNotificacoes.length > 0) {
        broadcastNotificacoes(data);
        localStorage.setItem(ST__NOTIFICACOES, JSON.stringify(data));
        playNotificationSound();
        let isNewAtendimento = false;
        newNotificacoes.forEach((notificacao) => {
          notificacao.titulo === 'Encaminhamento de OS' && (isNewAtendimento = true);
          notificacao.titulo === 'Atendimento encaminhado' && (isNewAtendimento = true);
        });
        isNewAtendimento && queryClient.invalidateQueries({ queryKey: ['atendimentos'] });
      }
      setNotificacaoGerada(false);
    }
  }, [data, isLoading]);

  function getSituacaoNotificacao() {
    if (!localStorage.getItem(ST__TOKEN)) {
      localStorage.clear();
      document.location = '/login';
      return;
    }
    const time = new Date();
    const currentHour = time.getHours();
    const currentMinutes = time.getMinutes();
    if ((currentHour === 22 && currentMinutes >= 30) || currentHour > 22 || currentHour < 6 || (currentHour === 6 && currentMinutes < 30)) {
      return;
    }
    const currentTime = time.getTime();
    const lastRequestTime = parseInt(localStorage.getItem(ST__ULT_REQ_NOTIFICATION), 10) || 0;
    const diff = currentTime - lastRequestTime;
    if (diff >= 45 * 1000) {
      refetch();
      localStorage.setItem(ST__ULT_REQ_NOTIFICATION, currentTime.toString());
    }
  }

  const { mutateAsync: lerNotificacao } = useMutation({
    mutationFn: (notificaoId) => lerNotificacoes(notificaoId),
    onSuccess: (_, variables) => {
      const filterNotificacoes = notificacoes.filter((x) => x.notificacao_id !== variables);

      const notificacaoLida = notificacoes.find((x) => x.notificacao_id === variables);
      if (notificacaoLida) {
        handleReadNotification(notificacaoLida);
      }
      setNotificacoes(filterNotificacoes);
      broadcastNotificacoes(filterNotificacoes);
      localStorage.setItem(ST__NOTIFICACOES, JSON.stringify(filterNotificacoes));
      if (filterNotificacoes.length === 0) {
        document.title = 'SAC - CM Sistemas';
        if (soundRef.current) {
          soundRef.current.stop();
        }
      }
    },
  });

  const handleReadNotification = (notificacao) => {
    const notificacoesCached = queryClient.getQueryData(['notificacoes', 'lidas']);
    if (notificacoesCached) {
      notificacoesCached.pop();

      notificacao.lida = 1;

      queryClient.setQueryData(['notificacoes', 'lidas'], [notificacao, ...notificacoesCached]);
    }
    if (!notificacoesCached) {
      queryClient.invalidateQueries({
        queryKey: ['notificacoes', 'lidas'],
      });
    }
  };

  useEffect(() => {
    broadcastChannelRef.current = new BroadcastChannel(NOTIFICATION_CHANNEL);
    broadcastChannelRef.current.onmessage = handleBroadcastMessage;
  }, []);

  useEffect(() => {
    if (notificacoes.length > 0) {
      document.title = 'SAC - CM Sistemas' + ` (${notificacoes.length})`;
    } else {
      document.title = 'SAC - CM Sistemas';
      stopNotificationSound();
    }
  }, [notificacoes]);

  useEffect(() => {
    const interval = setInterval(() => {
      getSituacaoNotificacao();
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  return (
    <NotificacoesContext.Provider
      value={{
        notificacoes,
        lerNotificacao,
        setNotificacaoGerada,
        notificacaoGerada,
      }}
    >
      {children}
    </NotificacoesContext.Provider>
  );
}
