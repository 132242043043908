import { useContext, useEffect, useState } from 'react';
import { UsuarioContext } from '../../context/userContext';
import Titulo from '../../components/titulo/index.jsx';
import { Link, Outlet } from 'react-router-dom';
import { ROUTE_HORARIOS } from '../../constants/routes.js';

function RegistroDeHorario() {
  const { permissoesUsuario } = useContext(UsuarioContext);
  const [abaMenuAtiva, setAbaMenuAtiva] = useState('E');

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    const url = location.pathname.toLowerCase();
    if (url !== ROUTE_HORARIOS.toLowerCase()) {
      url === ROUTE_HORARIOS.toLowerCase() && setAbaMenuAtiva('E');
      url === ROUTE_HORARIOS.toLowerCase() + '/registros' && setAbaMenuAtiva('R');
      url === ROUTE_HORARIOS.toLowerCase() + '/ajuste' && setAbaMenuAtiva('A');
      url === ROUTE_HORARIOS.toLowerCase() + '/calendario' && setAbaMenuAtiva('C');
    }
  }, []);

  return (
    <>
      <div className="container shadow-lg rounded-2 mt-3">
        <Titulo principal titulo="Registro de Horário" />
        <ul className="nav nav-tabs justify-content-center">
          <li className="nav-item" role="presentation">
            <Link className={`nav-link ${abaMenuAtiva === 'E' ? 'active' : ''}`} to={ROUTE_HORARIOS} onClick={() => setAbaMenuAtiva('E')}>
              Expediente
            </Link>
          </li>
          <li className="nav-item" role="presentation">
            <Link
              className={`nav-link ${abaMenuAtiva === 'R' ? 'active' : ''}`}
              to={ROUTE_HORARIOS + '/registros'}
              onClick={() => setAbaMenuAtiva('R')}
            >
              Registros
            </Link>
          </li>
          <li className="nav-item" role="presentation" hidden={permissoesUsuario.ajuste_horario === 0}>
            <Link
              className={`nav-link ${abaMenuAtiva === 'A' ? 'active' : ''}`}
              to={ROUTE_HORARIOS + '/ajuste'}
              onClick={() => setAbaMenuAtiva('A')}
            >
              Ajuste
            </Link>
          </li>
          <li className="nav-item" role="presentation">
            <Link
              className={`nav-link ${abaMenuAtiva === 'C' ? 'active' : ''}`}
              to={ROUTE_HORARIOS + '/calendario'}
              onClick={() => setAbaMenuAtiva('C')}
            >
              Calendário
            </Link>
          </li>
        </ul>
        <div className="m-2">
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default RegistroDeHorario;
