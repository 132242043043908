import { createContext, useEffect, useState } from 'react';
import {
  ST__CONFIG_USUARIO,
  ST__EMAILUSUARIO,
  ST__IDUSUARIO,
  ST__ISADMIN,
  ST__NOMEUSUARIO,
  ST__PERMISSOES,
  ST__SETORUSUARIO,
  ST__TOKEN,
} from '../constants/storagekey.js';

export const UsuarioContext = createContext({
  tokenUsuario: null,
  setTokenUsuario: () => undefined,
  idUsuario: null,
  setIdUsuario: () => undefined,
  nomeUsuario: null,
  setNomeUsuario: () => undefined,
  emailUsuario: null,
  setEmailUsuario: () => undefined,
  setorUsuario: null,
  setSetorUsuario: () => undefined,
  isAdminUsuario: null,
  setIsAdminUsuario: () => undefined,
  permissoesUsuario: null,
  setPermissoesUsuario: () => undefined,
  visualizacao: null,
  setVisualizacao: () => undefined,
});

export function UsuarioContextProvider({ children }) {
  const [tokenUsuario, setTokenUsuario] = useState('');
  const [idUsuario, setIdUsuario] = useState('');
  const [nomeUsuario, setNomeUsuario] = useState('');
  const [emailUsuario, setEmailUsuario] = useState('');
  const [setorUsuario, setSetorUsuario] = useState('');
  const [isAdminUsuario, setIsAdminUsuario] = useState('');
  const [permissoesUsuario, setPermissoesUsuario] = useState({});
  const [visualizacao, setVisualizacao] = useState({ cardSlim: false, listagemSetor: false });

  function updateStateVisualizacao(chave, valor) {
    setVisualizacao((prevState) => ({
      ...prevState,
      [chave]: valor,
    }));
  }

  useEffect(() => {
    const userIsLoggedIn = localStorage.getItem(ST__TOKEN);

    if (userIsLoggedIn && (!tokenUsuario || !isAdminUsuario)) {
      setTokenUsuario(userIsLoggedIn);
      setIdUsuario(localStorage.getItem(ST__IDUSUARIO));
      setNomeUsuario(localStorage.getItem(ST__NOMEUSUARIO));
      setEmailUsuario(localStorage.getItem(ST__EMAILUSUARIO));
      setSetorUsuario(localStorage.getItem(ST__SETORUSUARIO));
      setIsAdminUsuario(localStorage.getItem(ST__ISADMIN));
      setPermissoesUsuario(JSON.parse(localStorage.getItem(ST__PERMISSOES)));
      const config = JSON.parse(localStorage.getItem(ST__CONFIG_USUARIO));
      if (config) {
        const { cardSlim, listagemSetor } = config;
        cardSlim && updateStateVisualizacao('cardSlim', Number(cardSlim) === 1);
        listagemSetor && updateStateVisualizacao('listagemSetor', Number(listagemSetor) === 1);
      }
    }
  }, [tokenUsuario, permissoesUsuario, idUsuario, nomeUsuario, emailUsuario, setorUsuario, isAdminUsuario]);

  return (
    <UsuarioContext.Provider
      value={{
        tokenUsuario,
        setTokenUsuario,
        idUsuario,
        setIdUsuario,
        nomeUsuario,
        setNomeUsuario,
        emailUsuario,
        setEmailUsuario,
        setorUsuario,
        setSetorUsuario,
        isAdminUsuario,
        setIsAdminUsuario,
        permissoesUsuario,
        setPermissoesUsuario,
        visualizacao,
        setVisualizacao: updateStateVisualizacao,
      }}
    >
      {children}
    </UsuarioContext.Provider>
  );
}
