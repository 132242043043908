import styles from './list.module.css';

function RowsConciliacao({ cliente, onEdit }) {
  const handleInputChange = (field, value) => {
    if (value === true) {
      value = 1;
    }
    if (value === false) {
      value = 0;
    }
    cliente[field] = value;
    onEdit(cliente.id, field, value);
    if (field === 'ativo') {
      handleInputChange('gerar_chave', value);
    }
  };
  return (
    <>
      <tr
        className={`${styles.row} ${
          (cliente.codigo_sac_local === '' || !cliente.codigo_sac_local) && cliente.ativo === 1 ? styles.noCode : ''
        } ${cliente.ativo === 0 ? styles.inactive : ''}`}
      >
        <th>
          <span className={styles.textRazao}>RAZAO SOCIAL: {cliente.razao_social}</span>
          <br />
          <span className={styles.textFantasia}>NOME FANTASIA: {cliente.nome_fantasia}</span>
        </th>
        <th>
          <div className="mb-3">
            <input
              type="number"
              className="form-control"
              id={'codigo' + cliente.id}
              placeholder="Codigo"
              onChange={(e) => handleInputChange('codigo_sac_local', e.target.value)}
              value={cliente.codigo_sac_local}
            />
          </div>
        </th>
        <th>
          <div className="form-check">
            <input
              className="form-check-input "
              type="checkbox"
              value=""
              id={'ativo' + cliente.id}
              onChange={(e) => handleInputChange('ativo', e.target.checked)}
              checked={cliente.ativo === 1}
            />
            <label className={`form-check-label ${styles.labelInput}`} for={'ativo' + cliente.id}>
              Ativo
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              value=""
              id={'param' + cliente.id}
              onChange={(e) => handleInputChange('gerar_chave', e.target.checked)}
              checked={cliente.gerar_chave === 1}
            />
            <label className={`form-check-label ${styles.labelInput}`} for={'param' + cliente.id}>
              Chave
            </label>
          </div>
        </th>
      </tr>
    </>
  );
}

export default RowsConciliacao;
