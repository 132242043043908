import React, { useCallback, useEffect, useState } from "react";
import Titulo from "../../components/titulo/index.jsx";
import { useQuery } from "@tanstack/react-query";
import { GetListagemUltimosBackups } from "../../service/fetchers/clientes.js";
import LoadingComponent from "../../components/loading-component/index.jsx";
import CardBackup from "./card-backup.jsx";
import BtnGerarSenha from "./btnGerarSenha.jsx";
import { ST__PERMISSOES } from "../../constants/storagekey.js";
import { useNavigate } from "react-router-dom";
import { ROUTE_404 } from "../../constants/routes.js";



function ListaBackupsClientes() {
	const [filtro, setFiltro] = useState("0"); // Estado para o tipo de filtro
	const [pesquisa, setPesquisa] = useState(""); // Estado para o valor da pesquisa
	const [resultadosFiltrados, setResultadosFiltrados] = useState(null); // Estado para armazenar os resultados da pesquisa

	const navigate = useNavigate();

	const { data, isLoading, error } = useQuery({
		queryKey: ["lista-backups-clientes"],
		queryFn: () => GetListagemUltimosBackups({}),
		staleTime: 1000 * 60 * 5,
	});

	function parseDataToCard(backup) {
		return {
			banco: backup.nome_banco,
			cnpj: backup.cnpj,
			contrato: backup.contrato,
			dtRegistro: backup.data,
			nomeCliente: backup.nome_cliente,
			status: backup.sucesso === 1,
			ultimaRotina: backup.nome_rotina,
			frequencia: JSON.parse(backup.frequencia),
		};
	}

	const handleSearch = useCallback(() => {
		if (!data) return;

		const resultados = data.filter((cliente) => {
			switch (filtro) {
				case "0":
					return (
						cliente.nome_cliente
							?.toLowerCase()
							.includes(pesquisa.toLowerCase()) ||
						cliente.id_cliente === Number.parseInt(pesquisa, 10) ||
						cliente.cnpj?.includes(pesquisa) ||
						cliente.contrato?.includes(pesquisa)
					);
				case "1":
					return cliente.nome_cliente
						?.toLowerCase()
						.includes(pesquisa.toLowerCase());
				case "2":
					return cliente.id_cliente === Number.parseInt(pesquisa, 10);
				case "3":
					return cliente.cnpj?.includes(pesquisa);
				case "4":
					return cliente.contrato?.includes(pesquisa);
				default:
					return true;
			}
		});
		setResultadosFiltrados(resultados);
	}, [data, filtro, pesquisa]);

	useEffect(() => {
		handleSearch();
	}, [handleSearch]);

	useEffect(() => {
		const permissao = JSON.parse(localStorage.getItem(ST__PERMISSOES));
		if (permissao?.backup !== 1) {
			navigate(ROUTE_404);
		}
	}, [navigate]);

	return (
		<div className="container ml-1 mr-1 shadow-lg rounded-2 mt-3 pb-3">
			
			<Titulo
				titulo="Backups de Clientes"
				retorno="clientes"
				complementoTitulo={<BtnGerarSenha />}
			/>
			<div className="card m-2">
				<div className="row m-2 ">
					<div className="col-8">
						<div className="row">
							<div className="col-md-4 col-sm-12">
								<label htmlFor="cmbPosicao">Pesquisar por</label>
								<select
									id="cmbPosicao"
									className="form-select mb-2"
									aria-label="Selecionar"
									value={filtro}
									onChange={(e) => setFiltro(e.target.value)}
								>
									<option value="0" selected>
										Qualquer
									</option>
									<option value="1">Razão Social/Fantasia</option>
									<option value="2">Código</option>
									<option value="3">CNPJ</option>
									<option value="4">Contrato</option>
								</select>
							</div>
							<div className="col-md-8 col-sm-12">
								<label htmlFor="floatingInput">Pesquisa</label>
								<input
									type="text"
									className="form-control mb-2"
									id="floatingInput"
									placeholder=""
									value={pesquisa}
									onChange={(e) => setPesquisa(e.target.value)}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div className="container-mt3">
				<h6>Clientes com backups ativos</h6>
				{isLoading ? (
					<LoadingComponent />
				) : error ? (
					<div>Erro ao carregar os dados</div>
				) : (
					data ?
					((resultadosFiltrados || data)?.map((cliente) => (
						<CardBackup
							key={cliente.id_cliente}
							backup={parseDataToCard(cliente)}
						/>
					))): <div className="d-flex align-items-center justify-content-center my-3">Nenhum cliente encontrado</div>
				)}
			</div>
		</div>
	);
}

export default ListaBackupsClientes;
