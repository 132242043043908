import api from "../api.js";

export async function lerNotificacoes(notificacaoId) {
  try {
    api
      .patch('/v1/notificacoes', null, {
        params: {
          id: notificacaoId,
        },
      })
  } catch (error) {
    throw new Error('Erro ao ler notificação');
  }
}