import { useContext, useState } from 'react';
import api from '../../../service/api';
import './style.css';
import ModalAjusteDeHorario from './modalAjusteHorario';
import { UsuariosContext } from '../../../context/usersContext.jsx';
import { FaPenToSquare } from 'react-icons/fa6';

const Ajuste = () => {
  const [idUsuario, setIdUsuario] = useState('');
  const [horariosUsuario, setHorariosUsuario] = useState([]);
  const [controle, setControle] = useState('');
  const [registroHorarioId, setRegistroHorarioId] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const { usuarios } = useContext(UsuariosContext);

  const controleSelecionado = (e) => {
    setControle(e.target.value);
  };

  const usuarioSelecionado = (e) => {
    setControle('');
    const id = e.target.value;
    if (id === '') {
      setHorariosUsuario([]);
    }
    setIdUsuario(id);
    api.get(`/v1/horario/listarRegistroPorUsuario/${id}`).then((result) => {
      setHorariosUsuario(AgruparObjetoPorControle(result.data));
    });
  };

  const AgruparObjetoPorControle = (objetos) => {
    const agrupadoPorControle = objetos.reduce((agrupado, objeto) => {
      const { controle, tipo, motivo, registro_horario_id, data_registro } = objeto;

      if (!agrupado[controle]) {
        agrupado[controle] = { numero: controle };
      }

      let data_convertida = new Date(data_registro);
      data_convertida = new Intl.DateTimeFormat('pt-br', {
        dateStyle: 'short',
        timeStyle: 'medium',
        timeZone: 'America/Sao_Paulo',
      }).format(data_convertida);

      if (tipo === 'E' && motivo === 'Expediente') {
        agrupado[controle].inicio = {
          registro_horario_id,
          horario: data_convertida,
        };
      } else if (tipo === 'S' && motivo === 'Expediente') {
        agrupado[controle].fim_expediente = {
          registro_horario_id,
          horario: data_convertida,
        };
      } else if (tipo === 'E' && motivo === 'Almoço') {
        agrupado[controle].retorno_almoco = {
          registro_horario_id,
          horario: data_convertida,
        };
      } else if (tipo === 'S' && motivo === 'Almoço') {
        agrupado[controle].almoco = {
          registro_horario_id,
          horario: data_convertida,
        };
      } else if (motivo === 'Saida Extra') {
        if (tipo === 'E') {
          if (!agrupado[controle].saidasExtras) {
            agrupado[controle].saidasExtras = [];
          }
          agrupado[controle].saidasExtras.push({
            tipo: 'E',
            registro_horario_id,
            horario: data_convertida,
          });
        } else if (tipo === 'S') {
          if (!agrupado[controle].saidasExtras) {
            agrupado[controle].saidasExtras = [];
          }
          agrupado[controle].saidasExtras.push({
            tipo: 'S',
            registro_horario_id,
            horario: data_convertida,
          });
        }
      }

      return agrupado;
    }, {});
    const resultado = Object.values(agrupadoPorControle);
    return resultado;
  };

  const edicaoDeHoras = (registro) => {
    setRegistroHorarioId(registro.registro_horario_id);
    setModalIsOpen(true);
  };

  return (
    <>
      <ModalAjusteDeHorario isOpen={modalIsOpen} onRequestClose={closeModal} registro={{ idUsuario, controle, registroHorarioId }} />
      <div className="col-md-3 col-sm-12 nowrap">
        <label htmlFor="usuarioAlterado">Selecione o usuário</label>
        <select id="usuarioAlterado" className="form-select mb-2" aria-label="Selecionar" onChange={usuarioSelecionado} value={idUsuario}>
          <option value={''}>Selecione</option>
          {usuarios.map((usuario) => {
            return (
              <option key={usuario.ID} value={usuario.ID}>
                {usuario.NOME}
              </option>
            );
          })}
        </select>
      </div>
      <div className="col-md-3 col-sm-12 nowrap">
        <label htmlFor="controle">Selecione o controle</label>
        <select id="controle" className="form-select mb-2" aria-label="Selecionar" onChange={controleSelecionado} value={controle}>
          <option value={''}>Selecione</option>
          {horariosUsuario.map((horario) => {
            return (
              <option key={horario.numero} value={horario.numero}>
                {horario.numero} - Inicio: {horario.inicio.horario}
              </option>
            );
          })}
        </select>
      </div>
      {controle !== '' ? (
        <div className="container-horarios">
          {'inicio' in horariosUsuario[controle - 1] ? (
            <p>
              Início: {horariosUsuario[controle - 1]['inicio']['horario']}
              <button className="btn-ajuste-horario" onClick={() => edicaoDeHoras(horariosUsuario[controle - 1]['inicio'])}>
                <FaPenToSquare/>
              </button>
            </p>
          ) : null}
          {'almoco' in horariosUsuario[controle - 1] ? (
            <p>
              Almoço: {horariosUsuario[controle - 1]['almoco']['horario']}
              <button className="btn-ajuste-horario" onClick={() => edicaoDeHoras(horariosUsuario[controle - 1]['almoco'])}>
                <FaPenToSquare/>
              </button>
            </p>
          ) : null}
          {'retorno_almoco' in horariosUsuario[controle - 1] ? (
            <p>
              Retorno almoço: {horariosUsuario[controle - 1]['retorno_almoco']['horario']}
              <button className="btn-ajuste-horario" onClick={() => edicaoDeHoras(horariosUsuario[controle - 1]['retorno_almoco'])}>
                <FaPenToSquare/>
              </button>
            </p>
          ) : null}
          {'fim_expediente' in horariosUsuario[controle - 1] ? (
            <p>
              Fim expediente: {horariosUsuario[controle - 1]['fim_expediente']['horario']}
              <button className="btn-ajuste-horario" onClick={() => edicaoDeHoras(horariosUsuario[controle - 1]['fim_expediente'])}>
                <FaPenToSquare/>
              </button>
            </p>
          ) : null}
        </div>
      ) : null}
    </>
  );
};

export default Ajuste;
