import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useContext, useEffect, useState } from 'react';
import { getCalendarioSAC } from '../../../service/fetchers/calendario.js';
import LoadingComponent from '../../loading-component/index.jsx';
import { FaCircleXmark, FaPenToSquare } from 'react-icons/fa6';
import { formataData } from '../../../util/maskdoc.js';
import { UsuariosContext } from '../../../context/usersContext.jsx';
import { editarEventoCalendario } from '../../../service/mutations/calendario.js';
import ModalAlterarEvento from './modalAlterarEvento.jsx';

const modalInitialState = {
  isOpen: false,
  evento: {},
};

function AbaListagem({ alterarAba }) {
  const queryClient = useQueryClient();
  const [ano, setAno] = useState(new Date().getFullYear());
  const [listagem, setListagem] = useState([]);
  const [modal, setModal] = useState(modalInitialState);

  const { dicionario } = useContext(UsuariosContext);

  const { data, isLoading } = useQuery({
    queryKey: ['eventos', 'listagem', ano],
    queryFn: () => getCalendarioSAC({ dia: ano }),
    staleTime: 1000 * 60 * 60,
  });

  async function getEventos() {
    const result = await queryClient.fetchQuery({
      queryKey: ['eventos', 'listagem', ano],
      queryFn: async () => await getCalendarioSAC({ dia: ano }),
    });

    setListagem(result);
  }

  useEffect(() => {
    if (data && !isLoading) {
      setListagem(data);
    }
  }, [data, isLoading]);

  const dicionarioTipoFeriado = {
    N: 'Nacional',
    E: 'Estadual',
    M: 'Municipal',
    F: 'Facultativo',
  };

  const { mutateAsync: EditarEvento } = useMutation({
    mutationFn: (evento) => editarEventoCalendario(evento),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['eventos', 'listagem', ano],
      });
    },
  });

  function inativarEvento(evento) {
    EditarEvento({
      calendarioId: evento.calendarioId,
      ativo: 'N',
    });
  }

  function abrirModal(evento) {
    setModal({ isOpen: true, evento });
  }

  function fecharModal() {
    setModal(modalInitialState);
  }

  return (
    <>
      <ModalAlterarEvento isOpen={modal.isOpen} onRequestClose={fecharModal} evento={modal.evento} />

      <div className="pt-3 row text-center">
        <h5 className="text-black">Listagem de Eventos</h5>
      </div>
      <div className="row">
        <div className="col-4 input-group mb-3 w-25">
          <span htmlFor="ano" className="input-group-text">
            Ano
          </span>
          <input
            id="ano"
            type="number"
            min={2000}
            max={2099}
            value={ano}
            onChange={(e) => setAno(e.target.value)}
            className="form-control"
            placeholder="Filtrar por nome"
          />
        </div>
        <div className="col-2">
          <button className="btn btn-primary" onClick={getEventos}>
            Filtrar
          </button>
        </div>
      </div>
      <div className="row">
        {isLoading ? (
          <LoadingComponent />
        ) : listagem?.length === 0 ? (
          <div>
            <span className="d-block text-center">
              <h5 className="text-secondary">Sem eventos!</h5>
              <div onClick={() => alterarAba('A')} style={{ cursor: 'pointer' }}>
                <h5 className={`text-secondary pb-5`}>Deseja adicionar algum?</h5>
              </div>
            </span>
          </div>
        ) : (
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Descrição</th>
                  <th>Dia</th>
                  <th>Feriado</th>
                  <th>Jornada</th>
                  <th className="text-center">Usuários</th>
                  <th>Criado Por</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody className="table-light">
                {listagem.map((evento, index) => (
                  <tr key={evento.dia + index}>
                    <td>{evento.descricao}</td>
                    <td>{formataData(evento.dia)}</td>
                    <td>{evento.feriado ? 'Feriado ' + dicionarioTipoFeriado[evento.tipo] : 'Não'}</td>
                    <td className="">{evento.jornada}</td>
                    <td className="text-center">
                      {evento.feriado && evento.tipo === 'N'
                        ? 'Todos'
                        : Array.isArray(evento.usuarios) && evento.usuarios.length > 0
                        ? evento.usuarios?.map((id) => dicionario.get(Number(id))).join(', ')
                        : 'Nenhum'}
                    </td>
                    <td>{dicionario.get(evento.criadoPor) || 'Não registrado'}</td>
                    <td>
                      <div className="col d-flex align-items-center justify-content-start">
                        <button
                          className={`btn btn-outline-primary  border-0`}
                          title="Atualizar"
                          disabled={evento.feriado && evento.tipo === 'N'}
                          onClick={() => abrirModal(evento)}
                        >
                          <FaPenToSquare />
                        </button>

                        <button
                          className={`btn btn-outline-danger  border-0`}
                          title="Excluir"
                          onClick={() => inativarEvento(evento)}
                          disabled={evento.feriado && evento.tipo === 'N'}
                        >
                          <FaCircleXmark />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
}

export default AbaListagem;
