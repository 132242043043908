import React from 'react';

function FormOs({ setFocus, setTipoOs }) {
  return (
    <>
      <div className="mb-3 col-md-4 col-lg-4 col-sm-4">
        <div className="form-floating">
          <select
            id="tipo_OS floatingSelectGrid"
            className="form-select mb-2"
            aria-label="Selecionar"
            onFocus={(e) => setFocus(false)}
            onChange={(e) => setTipoOs(e.target.value)}
          >
            <option value="">Selecionar</option>
            <option value="E">Erro</option>
            <option value="A">Alteração</option>
          </select>
          <label htmlFor="tipo" className="obrigatorio">
            Tipo OS
          </label>
        </div>
      </div>
    </>
  );
}

export default FormOs;
