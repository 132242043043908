import React, { useEffect, useState } from 'react';
import styles from './cardMaster.module.css';
import BtnCopy from '../btn-copy/index.jsx';
import { ST__SENHA_MASTER } from '../../constants/storagekey.js';
import api from '../../service/api.js';
import { FaUpDownLeftRight } from 'react-icons/fa6';

function CardSenhaMaster() {
  const [customStyle, setCustomStyle] = useState({ top: 5, left: window.innerWidth - 350, cursor: 'grab', zIndex: 15000 });
  const [senha, setSenha] = useState(999999);
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  const handleDragStart = (e) => {
    const { clientX, clientY } = e;
    const rect = e.target.getBoundingClientRect();
    const offsetX = clientX - rect.left;
    const offsetY = clientY - rect.top;

    document.addEventListener('mousemove', handleDragMove);
    document.addEventListener('mouseup', handleDragEnd);

    function handleDragMove(e) {
      const newTop = (e.clientY || e.touches[0].clientY) - offsetY;
      const newLeft = (e.clientX || e.touches[0].clientX) - offsetX;

      if (newTop >= 0) {
        setCustomStyle((prev) => ({
          ...prev,
          top: newTop,
        }));
      }
      if (newLeft >= 0 && newLeft <= window.innerWidth - 110) {
        setCustomStyle((prev) => ({
          ...prev,
          left: newLeft,
        }));
      }
    }

    function handleDragEnd() {
      document.removeEventListener('mousemove', handleDragMove);
      document.removeEventListener('mouseup', handleDragEnd);
    }
  };

  const getSenha = () => {
    const master = localStorage.getItem(ST__SENHA_MASTER);
    if (master) {
      setSenha(master);
    } else {
      api.get('/v1/usosiaac/master').then((result) => {
        setSenha(result.data);
        localStorage.setItem(ST__SENHA_MASTER, result.data);
      });
    }
  };

  useEffect(() => {
    setIsTouchDevice('ontouchstart' in window);
    if (window.innerWidth <= 768) {
      setCustomStyle((prev) => ({ ...prev, top: 4, left: window.innerWidth - 200, zIndex: 1000 }));
    }
  }, []);

  useEffect(() => {
    getSenha();
  }, []);

  return (
    <div
      className={styles.cardSenha}
      style={{ top: customStyle.top + 'px', left: customStyle.left + 'px', cursor: customStyle.cursor, zIndex: customStyle.zIndex }}
      onMouseDown={handleDragStart}
      onTouchStart={handleDragStart}
    >
      <div className={styles.label}>
        <span>
          Senha Master <FaUpDownLeftRight style={{ color: 'black' }}/>
        </span>
      </div>
      <div className={styles.senha}>
        <span>{senha}</span>
        <BtnCopy text={senha} />
      </div>
    </div>
  );
}

export default CardSenhaMaster;
