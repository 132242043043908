const FormataSemana = (dia) => {
	switch (dia) {
		case 0:
			return "Domingo";
		case 1:
			return "Segunda";
		case 2:
			return "Terça";
		case 3:
			return "Quarta";
		case 4:
			return "Quinta";
		case 5:
			return "Sexta";
		case 6:
			return "Sábado";
		default:
			break;
	}
};

function isValidDate(str) {
	const regex = /^\d{4}-\d{2}-\d{2}$/;
	if (!regex.test(str)) {
		return false;
	}

	const data = new Date(str);
	if (Number.isNaN(data.getTime())) {
		return false; // Data inválida
	}

	const dataMinima = new Date("2000-01-01");
	return data >= dataMinima;
}

export { FormataSemana, isValidDate };
