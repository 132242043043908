import { Fragment, useContext, useEffect, useState } from 'react';
import Modal from 'react-modal/lib/components/Modal';
import Close from '../../assets/close.png';
import LoadingComponent from '../loading-component/index.jsx';
import styles from './style.module.css';
import api from '../../service/api.js';
import { formataData, formataHora, maskdoc } from '../../util/maskdoc.js';
import { toast } from '../../constants/toast.jsx';
import { FaCheckCircle, FaRedoAlt } from 'react-icons/fa';
import { UsuarioContext } from '../../context/userContext.jsx';

Modal.setAppElement('#root');

export default function ModalListagemVendedores(props) {
  const [loading, setLoading] = useState(false);
  const [vendedores, setVendedores] = useState([]);

  const { permissoesUsuario } = useContext(UsuarioContext);

  const CancelarCadastro = () => {
    props.onRequestClose();
  };

  function getListagemVendedores() {
    setLoading(true);
    api
      .get(`/v1/mobile/vendedor/${props.cnpj}`)
      .then((result) => {
        setVendedores(result.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (props.isOpen) getListagemVendedores();
  }, [props.isOpen]);

  const legendaStatus = {
    S: 'Ativo',
    N: 'Inativo',
  };

  function LiberarVendedor({ cpf, cnpj }) {
    api
      .patch(`/v1/mobile/liberar/vendedor`, {
        cpf,
        cnpj,
      })
      .then((result) => {
        if (result.status === 200) {
          getListagemVendedores();
          toast.fire({ icon: 'success', title: 'Ativação liberada com sucesso' });
        }
      })
      .catch((error) => {
        toast.fire({ icon: 'error', title: error.response.data });
      });
  }

  function ResetarVendedor({ cpf, cnpj }) {
    api
      .patch(`/v1/mobile/reset/vendedor`, {
        cpf,
        cnpj,
      })
      .then((result) => {
        if (result.status === 200) {
          getListagemVendedores();
          toast.fire({ icon: 'success', title: 'Vendedor resetado com sucesso' });
        }
      })
      .catch((error) => {
        toast.fire({ icon: 'error', title: error.response.data });
      });
  }

  function CheckShowBtnLiberarAtivacao({ ativo, dt_inativacao }) {
    if (ativo === 'S') return false;

    const dtInativacao = new Date(dt_inativacao);
    const dtAtual = new Date();
    const diff = Math.abs(dtAtual - dtInativacao);
    const diffDays = Math.ceil(diff / (1000 * 60 * 60 * 24));
    if (diffDays > 3) return false;
    return true;
  }

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      preventScroll={true}
      overlayClassName="react-modal-overlay"
      className="react-modal-content-vendedores"
    >
      <button type="button" onClick={CancelarCadastro} className="react-modal-close">
        <img src={Close} alt="Fechar" />
      </button>
      <div className="container-fluid h-100 endereco-modal">
        <div className="col-12 mt-4">
          <h3 className="mt2-mb-4">Listagem de Vendedores</h3>
        </div>
        <div className={styles.container}>
          {loading ? (
            <LoadingComponent />
          ) : vendedores.length === 0 ? (
            <div className="text-center w-100 mt-4 mb-4">
              <h5>Nenhum vendedor encontrado</h5>
            </div>
          ) : (
            <>
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div>
                  <span>Vendedores Cadastrados: {vendedores.length}</span>
                  <br />
                  <span>Vendedores Ativos: {vendedores.filter((vendedor) => vendedor.ativo === 'S').length}</span>
                  <br />
                  <span>Vendedores Inativos: {vendedores.filter((vendedor) => vendedor.ativo === 'N').length}</span>
                  <br />
                </div>
                <div>
                  <div className="d-flex flex-row justify-content-between  align-items-center me-3">
                    <div className={styles.divlegenda} style={{ '--backgroundColor': '#D1E7DD' }} />
                    <span>Ativo</span>
                  </div>
                  <div className="d-flex flex-row justify-content-between  align-items-center me-3">
                    <div className={styles.divlegenda} style={{ '--backgroundColor': '#F8D7DA' }} />
                    <span>Inativo</span>
                  </div>
                </div>
              </div>
              <table className="table w-100">
                <thead>
                  <tr>
                    <th scope="col">Código</th>
                    <th scope="col">Nome</th>
                    <th scope="col" className="text-center px-2">
                      CPF
                    </th>
                    <th scope="col" className="text-center">
                      Informações
                    </th>
                    <th scope="col" className="text-center">
                      Ações
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {vendedores.map((vendedor) => (
                    <Fragment key={vendedor.codigo_vendedor}>
                      <tr className={vendedor.ativo === 'S' ? 'table-success' : 'table-danger'} title={legendaStatus[vendedor.ativo]}>
                        <th scope="row" className="text-center">
                          {vendedor.codigo_vendedor}
                        </th>
                        <td>{vendedor.nome}</td>
                        <td className="nowrap px-2">{maskdoc(vendedor.cpf)}</td>
                        <td className="text-center">
                          <div className="d-flex flex-column">
                            {vendedor.ativo === 'S' ? (
                              vendedor.dispositivo ? (
                                <span>Dispositivo {vendedor.dispositivo}</span>
                              ) : (
                                <span>Sem Dispositivo</span>
                              )
                            ) : vendedor.dt_inativacao ? (
                              <span>
                                Inativado: {formataData(vendedor.dt_inativacao)} {formataHora(vendedor.dt_inativacao)}
                              </span>
                            ) : null}
                          </div>
                        </td>
                        <td>
                          <div className="d-flex align-items-center justify-content-center">
                            {vendedor.dispositivo ? (
                              <button
                                type="button"
                                className="btn btn-outline-primary border-0 m-0 d-flex align-items-center justify-content-center"
                                onClick={() => ResetarVendedor({ cpf: vendedor.cpf, cnpj: props.cnpj })}
                                title="Resetar Dispositivo Vendedor"
                              >
                                <FaRedoAlt />
                              </button>
                            ) : null}
                            {CheckShowBtnLiberarAtivacao({ ativo: vendedor.ativo, dt_inativacao: vendedor.dt_inativacao }) &&
                            permissoesUsuario.financeiro === 1 ? (
                              <button
                                type="button"
                                className="btn btn-outline-success border-0 m-0 ms-1 d-flex align-items-center justify-content-center"
                                onClick={() => LiberarVendedor({ cpf: vendedor.cpf, cnpj: props.cnpj })}
                                title={'Liberar Ativação do Vendedor'}
                              >
                                <FaCheckCircle />
                              </button>
                            ) : null}
                          </div>
                        </td>
                      </tr>
                    </Fragment>
                  ))}
                </tbody>
              </table>
            </>
          )}
        </div>
      </div>
    </Modal>
  );
}
