import React, { useContext, useEffect, useState } from 'react';
import ResumoCard from './cards/Resumo.jsx';
import ContasReceberTable from './table/ContasReceberTable.jsx';
import LiberacoesTable from './table/LiberacoesTable.jsx';
import api from '../../../service/api.js';
import ClientesInadimplentesTable from './table/ClientesInadimplentes.jsx';
import styles from './index.module.css';
import LiberarRenovacoes from './table/LiberarRenovacoes.jsx';
import ScrollButton from '../../../components/scrollButton/index.jsx';
import ProcuraCliente from '../../../components/procura-cliente/index.jsx';
import { BuscaClienteListaAutoCompleteContext } from '../../../context/BuscaClienteListaAutoCompleteContext.jsx';
import Titulo from '../../../components/titulo/index.jsx';

function ConferenciaRecebimentos() {
  const [cntReceber, setCntReceber] = useState([]);
  const [cntVencidas, setCntVencidas] = useState([]);
  const [cntParaVencer, setCntParaVencer] = useState([]);
  const [renovacoes, setRenovacoes] = useState([]);
  const [renovacoesLiberar, setRenovacoesLiberar] = useState([]);
  const [pagamentosAlterados, setPagamentosAlterados] = useState([]);

  const [dataInicial, setDataInicial] = useState('');
  const [dataFinal, setDataFinal] = useState('');
  const [quantidade, setQuatidade] = useState('20');

  const [tableMidle, setTableMidle] = useState({ open: false, table: null });

  const { clienteId } = useContext(BuscaClienteListaAutoCompleteContext);

  function GetCntReceber() {
    api.get('/v1/financeiro/cntreceber').then((result) => {
      setCntReceber(result.data);
      const vencidas = result.data.filter((x) => new Date(x.dt_vencimento) < new Date() && x.posicao === 'CR');
      const agrupado = Object.values(
        vencidas.reduce((agrupado, atual) => {
          const { codigo_sac_local, contrato, documento, dt_vencimento, id_cliente, nome, nossonum, posicao } = atual;
          if (!agrupado[id_cliente]) {
            agrupado[id_cliente] = {
              id_cliente,
              nome,
              contrato,
              codigo_sac_local,
              cnts: [],
            };
          }

          agrupado[id_cliente].cnts.push({ documento, dt_vencimento, posicao, nossonum });

          return agrupado;
        }, {})
      );
      const paraVencer = result.data.filter((x) => new Date(x.dt_vencimento) >= new Date() && x.posicao === 'CR');
      agrupado.forEach((x) => {
        let menorData = new Date();
        for (let i = 0; i < x.cnts.length; i++) {
          const dataLaco = new Date(x.cnts[i].dt_vencimento);
          menorData = dataLaco < menorData ? dataLaco : menorData;
        }
        x.dias_inadimplencia = Math.ceil(Math.abs(menorData.getTime() - new Date().getTime()) / (1000 * 3600 * 24));
      });
      setCntVencidas(agrupado);
      setCntParaVencer(
        paraVencer.sort((a, b) =>
          new Date(a.dt_vencimento) < new Date(b.dt_vencimento) ? -1 : new Date(a.dt_vencimento) > new Date(b.dt_vencimento) ? 1 : 0
        )
      );
    });
  }

  function GetClientesAdimplentes() {
    let agrupado = [];
    if (cntParaVencer.length > 0) {
      agrupado = Object.values(
        cntParaVencer.reduce((agrupado, atual) => {
          const { codigo_sac_local, contrato, documento, dt_vencimento, id_cliente, nome, nossonum, posicao } = atual;
          if (!agrupado[id_cliente]) {
            agrupado[id_cliente] = {
              id_cliente,
              nome,
              contrato,
              codigo_sac_local,
              cnts: [],
            };
          }

          agrupado[id_cliente].cnts.push({ documento, dt_vencimento, posicao, nossonum });

          return agrupado;
        }, {})
      );

      const idsClientesVencidos = new Set(cntVencidas.map((x) => x.id_cliente));
      agrupado = agrupado.filter((x) => !idsClientesVencidos.has(x.id_cliente));
    }

    return agrupado.length;
  }

  function FormataDataUTC(data) {
    const dataSplit = data.split('T')[0];
    const dataNovoHorario = dataSplit + 'T12:00:00';
    return dataNovoHorario;
  }

  function DiffDays(data) {
    const hoje = new Date();
    const dataFormatada = new Date(FormataDataUTC(data));
    const diff = dataFormatada - hoje;
    const days = Math.round(diff / (1000 * 3600 * 24));
    return days;
  }

  function GetRenovacoes() {
    api.get('/v1/financeiro/renovacoes').then((result) => {
      setRenovacoes(result.data);
    });

    api.get('/v1/financeiro/pagamentos').then((result) => {
      const pagamentos = result.data;
      if (pagamentos.length > 0) {
        pagamentos.map((pagamento) => {
          pagamento.dias = DiffDays(pagamento.disponibilidade);
        });
      }
      setRenovacoesLiberar(pagamentos);
    });
  }

  function FiltroRenovacoes() {
    api
      .get('/v1/financeiro/renovacoes', {
        params: {
          idCliente: clienteId ? clienteId : null,
          diaInicial: dataInicial ? dataInicial : null,
          diaFinal: dataFinal ? dataFinal : null,
          quantidade,
        },
      })
      .then((result) => {
        setRenovacoes(result.data);
      })
      .catch((err) => console.log('err :>> ', err));
  }

  useEffect(() => {
    GetRenovacoes();
    GetCntReceber();
  }, []);

  return (
    <>
      <div className="container shadow-lg rounded-2 mt-3 pb-3">
        <Titulo principal titulo="Conferência de Recebimentos e Renovações" />
        <div className="container overflow-hidden text-center">
          <div className="row row-cols-2 row-cols-lg-3 g-2 mb-3">
            <div className="col">
              <ResumoCard title={'Clientes Adimplentes'} quantidade={GetClientesAdimplentes()} color={'primary'} />
            </div>
            <div className="col" onClick={() => setTableMidle({ open: true, table: 'I' })}>
              <ResumoCard title={'Clientes Inadimplentes'} quantidade={cntVencidas.length} color={'danger'} />
            </div>
            <div
              className="col"
              onClick={() => {
                if (renovacoesLiberar.length > 0) setTableMidle({ open: true, table: 'P' });
              }}
            >
              <ResumoCard title={'Pagamentos sem liberação'} quantidade={renovacoesLiberar.length} color={'info'} />
            </div>
          </div>

          {tableMidle.open ? (
            <div className="row mb-3">
              <button className={styles.closeButton} onClick={() => setTableMidle({ open: false, table: null })}>
                &times;
              </button>
              {tableMidle.table === 'I' ? (
                <ClientesInadimplentesTable
                  lista={cntVencidas.sort((a, b) =>
                    a.dias_inadimplencia > b.dias_inadimplencia ? -1 : a.dias_inadimplencia < b.dias_inadimplencia ? 1 : 0
                  )}
                />
              ) : tableMidle.table === 'P' ? (
                <LiberarRenovacoes
                  key={1}
                  pagamentos={renovacoesLiberar}
                  atualiza={setRenovacoesLiberar}
                  alteracoes={pagamentosAlterados}
                  setAlteracoes={setPagamentosAlterados}
                />
              ) : null}
            </div>
          ) : null}

          <div className="row">
            <div className="col col-lg-6 border-end">
              <div className="row text-start mb-2 mt-3">
                <a
                  href="#collapseExample"
                  className="btn-colapse"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseExample"
                >
                  <h6 className="text-dark">Renovações:</h6>
                </a>
              </div>
              <div className="collapse text-start" id="collapseExample">
                <div className="card card-body my-3 p-2">
                  <div className="row">
                    <div className="col">
                      <ProcuraCliente editar={true} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-12 nowrap">
                      <label htmlFor="edDataInicial">Data Inicial</label>
                      <input
                        onChange={(e) => setDataInicial(e.target.value)}
                        value={dataInicial}
                        type="date"
                        className="form-control mb-2"
                        id="edDataInicial"
                        placeholder=""
                      />
                    </div>
                    <div className="col-md-6 col-sm-12 nowrap">
                      <label htmlFor="edDataFinal">Data Final</label>
                      <input
                        onChange={(e) => setDataFinal(e.target.value)}
                        value={dataFinal}
                        type="date"
                        className="form-control mb-2"
                        id="edDataFinal"
                        placeholder=""
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6 col-sm-12 nowrap">
                      <label htmlFor="edDataInicial">Quantidade</label>
                      <select
                        onChange={(e) => setQuatidade(e.target.value)}
                        value={quantidade}
                        type="date"
                        className="form-control mb-2 text-center"
                        id="edDataInicial"
                        placeholder=""
                      >
                        <option value={'20'}>Últimos 20</option>
                        <option value={'50'}>Últimos 50</option>
                        <option value={'100'}>Últimos 100</option>
                      </select>
                    </div>
                    <div className="col-md-6 col-sm-12 nowrap d-flex align-items-center justify-content-center">
                      <button className="btn btn-primary" onClick={() => FiltroRenovacoes()}>
                        Pesquisar
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <LiberacoesTable liberacoes={renovacoes} />
              </div>
            </div>
            <div className="col col-lg-6">
              <div className="row text-start mb-2 mt-3">
                <h6 className="text-dark">Contas a Vencer:</h6>
              </div>
              <div>
                <ContasReceberTable contas={cntParaVencer.slice(0, 20)} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <ScrollButton />
    </>
  );
}

export default ConferenciaRecebimentos;
