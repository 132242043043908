import Modal from 'react-modal/lib/components/Modal';

import Close from '../../../assets/close.png';
import './style.css';
import { formataData, formataHora } from '../../../util/maskdoc';

export default function ModalInfoOs({ isOpen, onRequestClose, info }) {
  const Cancelar = (e) => {
    e.stopPropagation();
    onRequestClose();
  };

  const customStyles = {
    overlay: {
      position: 'fixed',
      width: '100vw',
      height: '100vh',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.397)',
    },
    content: {
      height: '90%',
      position: 'absolute',
      margin: 'auto',
      border: '1px solid #ccc',
      background: '#fff',

      borderRadius: '4px',
      outline: 'none',
      padding: '20px',
    },
  };

  const formatarOcorrencia = (ocorrencia) => {
    if (ocorrencia)
      return ocorrencia.split('\n').map((linha, index) => (
        <p className="p-0 m-1" key={index}>
          {linha}
        </p>
      ));
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      preventScroll={true}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
      shouldCloseOnOverlayClick={false}
      style={customStyles}
    >
      <button type="button" onClick={(e) => Cancelar(e)} className="react-modal-close">
        <img src={Close} alt="Fechar" />
      </button>
      <div className="container infos">
        <div className="row pb-1 border-bottom">
          <div className="col-3">
            <h6>
              <strong>Numero:</strong>
            </h6>
            {info.id}
          </div>
          <div className="col-5">
            <h6>
              <strong>Cliente:</strong>
            </h6>
            {info.cliente}
          </div>
          <div className="col-4">
            <h6>
              <strong>Contato:</strong>
            </h6>
            {info.contato}
          </div>
        </div>
        <div className="row py-1 border-bottom">
          <div className="col-6">
            <h6>
              <strong>Lançado:</strong>
            </h6>
            {info.lancamento}
          </div>
          <div className="col-6">
            <h6>
              <strong>Encaminhado:</strong>
            </h6>
            {info.encaminhado}
          </div>
        </div>
        <div className="row py-1 border-bottom">
          <div className="col">
            <h6>
              <strong>Ocorrencia:</strong>
            </h6>
            {formatarOcorrencia(info.ocorrencia)}
          </div>
        </div>
        <div className="row py-1 border-bottom">
          <div className="col">
            <h6>
              <strong>Solução:</strong>
            </h6>
            {formatarOcorrencia(info.solucao)}
          </div>
        </div>
        <div className="row py-1 border-bottom">
          <div className="col-4">
            <h6>
              <strong>Lançamento:</strong>
            </h6>
            {formataData(info.data_lancamento)} {formataHora(info.data_lancamento)}
          </div>
          <div className="col-4">
            <h6>
              <strong>Abertura:</strong>
            </h6>
            {formataData(info.data_abertura)} {formataHora(info.data_abertura)}
          </div>
          <div className="col-4">
            <h6>
              <strong>Fechamento:</strong>
            </h6>
            {formataData(info.data_fechamento)} {formataHora(info.data_fechamento)}
          </div>
        </div>
      </div>
    </Modal>
  );
}
