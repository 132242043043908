import React from 'react';
import { formataData, maskphone } from '../../util/maskdoc.js';
import PdfMake from './PdfMake.jsx';

export default function AtendimentoPorUsuarioPDF({ atendimentos, filtros }) {
  const info = {
    title: 'Atendimento por usuario',
    author: 'CM Sistemas',
  };

  const dicionarioFiltros = {
    setor:{
      S:'Suporte',
      D:'Desenvolvimento',
    }
  };

  function formataFiltros() {
    let filtro = [];
    if (filtros.dataInicial && filtros.dataFinal) {
      filtro.push({ text: `Data Inicial: ${formataData(filtros.dataInicial)} - Data Final: ${formataData(filtros.dataFinal)}` });
    }
    if (filtros.setor) {
    filtro.push({ text: `Setor: ${dicionarioFiltros.setor[filtros.setor]? dicionarioFiltros.setor[filtros.setor]: 'Todos'}` });
    }
    return filtro.flat();
  }

  function formataHeaderTabela(){
    if(filtros.setor === 'D'){
      return [
        { text: 'Usuario'.toUpperCase(), bold: true },
        { text: 'lançados'.toUpperCase(), bold: true },
        { text: 'pendentes'.toUpperCase(), bold: true },
        { text: 'atendendo'.toUpperCase(), bold: true },
        { text: 'aguardando'.toUpperCase(), bold: true },
        { text: 'testes'.toUpperCase(), bold: true },
        { text: 'atualizar'.toUpperCase(), bold: true },
        { text: 'finalizados'.toUpperCase(), bold: true },
        { text: 'cancelados'.toUpperCase(), bold: true },
      ]
    } else {
      return [
        { text: 'Usuario'.toUpperCase(), bold: true },
        { text: 'lançados'.toUpperCase(), bold: true },
        { text: 'pendentes'.toUpperCase(), bold: true },
        { text: 'atendendo'.toUpperCase(), bold: true },
        { text: 'aguardando'.toUpperCase(), bold: true },
        { text: 'finalizados'.toUpperCase(), bold: true },
        { text: 'cancelados'.toUpperCase(), bold: true },
      ]
    }
  }

  function formataWidthTabela(){
    if(filtros.setor === 'D'){
      return ['*','auto','auto','auto','auto','auto','auto','auto','auto']
    } else {
      return ['*','auto','auto','auto','auto','auto','auto']
    }
  }

  function formataCorpoTabela(atendimento){
    if(filtros.setor === 'D'){
      return [
        { text: atendimento.usuario.toUpperCase(), alignment: 'start' },
        { text: atendimento.lancados, alignment: 'center' },
        { text: atendimento.pendente, alignment: 'center' },
        { text: atendimento.atendendo, alignment: 'center' },
        { text: atendimento.aguardando, alignment: 'center' },
        { text: atendimento.testes, alignment: 'center' },
        { text: atendimento.atualizacao, alignment: 'center' },
        { text: atendimento.finalizado, alignment: 'center' },
        { text: atendimento.cancelado, alignment: 'center' },  
      ]
    } else {
      return [
        { text: atendimento.usuario.toUpperCase(), alignment: 'start' },
        { text: atendimento.lancados, alignment: 'center' },
        { text: atendimento.pendente, alignment: 'center' },
        { text: atendimento.atendendo, alignment: 'center' },
        { text: atendimento.aguardando, alignment: 'center' },
        { text: atendimento.finalizado, alignment: 'center' },
        { text: atendimento.cancelado, alignment: 'center' },  
      ]
    }
  }

  const content = [
    {
      text: 'Listagem Atendimentos por Usuário',
      style: { fontSize: 18, bold: true, alignment: 'justify' },
      alignment: 'center',
      margin: [0, 15, 0, 5],
    },
    { text: `Total de atendimentos: ${atendimentos.Total}`, style: { fontSize: 14, bold: true, alignment: 'justify' }},
    formataFiltros(),
    {
      table: {
        headerRows: 1,
        widths: formataWidthTabela(),
        body: [
          formataHeaderTabela(),
          ...atendimentos.usuarios.map((atendimento) => formataCorpoTabela(atendimento))
        ],
      },
      margin: [0, 15, 0, 0],
    },
  ];

  return <PdfMake content={content} info={info} orientation="landscape" />;
}
