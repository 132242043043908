import React, { useState } from 'react';
import { formataData, formataHora } from '../../../../util/maskdoc.js';
import styles from './table.module.css';

function ContasReceberTable(props) {
  const { contas } = props;
  const [openStates, setOpenStates] = useState({});

  const toogleInfos = (codigo) => {
    setOpenStates((prevState) => ({
      ...prevState,
      [codigo]: !prevState[codigo],
    }));
  };

  return (
    <div className={`${styles.border} shadow`}>
      <table className="table table-sm table-hover">
        <thead>
          <tr className={styles.bgReceber}>
            <th scope="col">ID(Conta local)</th>
            <th scope="col">Cliente</th>
            <th scope="col">Dias</th>
            <th scope="col">Vencimento</th>
          </tr>
        </thead>
        <tbody>
          {contas.map((conta) => (
            <React.Fragment key={conta.codigo}>
              <tr className={styles.tableHeader} onClick={() => toogleInfos(conta.codigo)}>
                <th scope="row">{conta.documento}</th>
                <td title={conta.nome}>{conta.nome.length > 20 ? conta.nome.substring(0, 20) + '...' : conta.nome}</td>
                <td>{Math.ceil(Math.abs(new Date(conta.dt_vencimento).getTime() - new Date().getTime()) / (1000 * 3600 * 24))}</td>
                <td>{formataData(conta.dt_vencimento)}</td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ContasReceberTable;
