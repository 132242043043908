import React, { useCallback, useEffect, useState } from 'react';
import Modal from 'react-modal/lib/components/Modal';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import './style.css';
import Close from '../../assets/close.png';

import api from '../../service/api.js';

Modal.setAppElement('#root');

function UsoModal(props) {
  const [ipAddress, setIpAddress] = useState('');
  const [loading, setLoading] = useState(true);
  const [erro, setErro] = useState(false);
  const [countryNameNative, setCountryNameNative] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [regionCode, setRegionCode] = useState('');
  const [regionName, setRegionName] = useState('');
  const [city, setCity] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [latitude, setLatitude] = useState('');
  const [longitude, setLongitude] = useState('');
  const [capital, setCapital] = useState('');

  async function GetLocation() {
    if (props.isOpen) {
      api
        .get(`/v1/ipaddress/${props.endereco}`)
        .then((response) => {
          setLoading(false);
          setCountryNameNative(response.data.countryNameNative);
          setCountryCode(response.data.countryCode);
          setRegionCode(response.data.regionCode);
          setRegionName(response.data.regionName);
          setCity(response.data.city);
          setPostalCode(response.data.postalCode);
          setLatitude(response.data.latitude);
          setLongitude(response.data.longitude);
          setCapital(response.data.capital);
        })
        .catch((err) => {
          console.log('Erro ao obter localização', err);
        });
    }
  }

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: 'AIzaSyChaUoUc-T8PueQ4ivltjFDWlrYfFuUEUk',
  });

  useEffect(() => {
    setIpAddress(props.endereco);
    GetLocation();
  }, [props.isOpen]);

  const containerStyle = {
    width: '100%',
    height: '300px',
  };

  const center = {
    lat: latitude,
    lng: longitude,
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      preventScroll={false}
      overlayClassName="react-modal-overlay"
      className="react-modal-content-uso"
    >
      <button type="button" onClick={() => props.onRequestClose()} className="react-modal-close">
        <img src={Close} alt="Fechar" />
      </button>

      <div className="container-fluid h-100 uso-modal">
        <div className="col-12 mt-4">
          <h4 className="mt-2 mb-4">Dados da Localização do Acesso</h4>

          {loading ? (
            'CARREGANDO..'
          ) : (
            <form>
              <div className="row">
                <div className="mb-3 col-12 d-inline-block">
                  <div className="row mb-3">
                    <div className="col-lg-3">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="txtEndereco"
                          placeholder="Ip de Acesso"
                          value={ipAddress}
                          disabled
                        />
                        <label htmlFor="txtEndereco">Ip de Acesso</label>
                      </div>
                    </div>

                    <div className="col-lg-5">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="txtBairro"
                          placeholder="País"
                          value={`${countryNameNative} (${countryCode})`}
                          disabled
                        />
                        <label htmlFor="txtBairro">País</label>
                      </div>
                    </div>

                    <div className="col-lg-4">
                      <div className="form-floating">
                        <input
                          type="text"
                          className="form-control"
                          id="txtCEP"
                          placeholder="UF"
                          value={`${regionName} (${regionCode})`}
                          disabled
                        />
                        <label htmlFor="txtCEP">UF</label>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-3">
                    <div className="col-lg-5">
                      <div className="form-floating">
                        <input type="text" className="form-control" id="txtCEP" placeholder="Cidade" value={city} disabled />
                        <label htmlFor="txtCEP">Cidade</label>
                      </div>
                    </div>

                    <div className="col-lg-3">
                      <div className="form-floating">
                        <input type="text" className="form-control" id="txtCEP" placeholder="CEP" value={postalCode + '-***'} disabled />
                        <label htmlFor="txtCEP">CEP</label>
                      </div>
                    </div>

                    <div className="col-lg-2">
                      <div className="form-floating">
                        <input type="text" className="form-control" id="txtCEP" placeholder="Cidade" value={latitude} disabled />
                        <label htmlFor="txtCEP">Latitude</label>
                      </div>
                    </div>

                    <div className="col-lg-2">
                      <div className="form-floating">
                        <input type="text" className="form-control" id="txtCEP" placeholder="CEP" value={longitude} disabled />
                        <label htmlFor="txtCEP">Logitude</label>
                      </div>
                    </div>
                  </div>

                  <div className="row mb-3">
                    {isLoaded ? (
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={15}
                        clickableIcons={true}
                        streetView={true}
                        tilt={45}
                      >
                        <Marker position={center} />
                      </GoogleMap>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>

        <div className="row mb-1">
          <div className="col-12 d-flex justify-content-end align-items-center">
            <button className="btn btn-lg btn-danger" onClick={() => props.onRequestClose()}>
              Fechar
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default UsoModal;
