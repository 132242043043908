export const ST__TOKEN = 'sessionToken';
export const ST__IDUSUARIO = 'sessionIdUsuario';
export const ST__NOMEUSUARIO = 'sessionNomeUsuario';
export const ST__EMAILUSUARIO = 'sessionEmailUsuario';
export const ST__SETORUSUARIO = 'sessionSetorUsuario';
export const ST__ISADMIN = 'sessionUsuarioAdmin';
export const ST__PERMISSOES = 'sessionUsuarioPermissoes';
export const ST__STORAGESEARCH = 'storage__search';
export const ST__STORAGESEARCH_CARDS = 'storage__search__cards';
export const ST__NOTIFICACOES = 'lista__notificacoes';
export const ST__ULT_REQ_NOTIFICATION = 'ultima__req__notificacao__ts';
export const ST__ULT_REQ_SIT_EQUIPE = 'ultima__req__sit_equipe__ts';
export const ST__CONFIG_USUARIO = '_config__user_';
export const ST__LISTA_EQUIPE = '__lista__equipe__';
export const ST__LISTA_CLIENTE__ATIVO = 'd1c04a959f59ddfd6736a7bd268c'
export const ST__SENHA_MASTER = 'fc613b4dfd6736a7bd268c8a0e74ed0d1c04a959f59dd74ef2874983fd443fc9';
