import React, { useContext, useEffect, useState } from 'react';
import LineChart from '../../../components/chart/linePicoAtendimento/index.jsx';
import api from '../../../service/api.js';
import { formataData } from '../../../util/maskdoc.js';
import { UsuariosContext } from '../../../context/usersContext.jsx';
import { UsuarioContext } from '../../../context/userContext.jsx';
import LoadingComponent from '../../../components/loading-component/index.jsx';

const equipeInitial = {
  maioresQuantidades: [],
  menoresQuantidades: [],
  maioresMedias: [],
  menoresMedias: [],
};

function PicoDeAtendimentos() {
  const { idUsuario } = useContext(UsuarioContext);
  const [dataInicial, setDataInicial] = useState(new Date().toISOString().split('T')[0]);
  const [dataFinal, setDataFinal] = useState(new Date().toISOString().split('T')[0]);
  const [totalAtendimentos, setTotalAtendimentos] = useState(0);
  const [atendimentos, setAtendimentos] = useState([]);
  const [maisAtendimentos, setMaisAtendimentos] = useState([]);
  const [menosAtendimentos, setMenosAtendimentos] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [equipe, setEquipe] = useState(equipeInitial);
  const [openStates, setOpenStates] = useState({});
  const { dicionario } = useContext(UsuariosContext);

  function getRelatorio(e) {
    e.preventDefault();
    setIsFetching(true);
    api
      .get('/v1/pico-atendimentos', {
        params: {
          dataInicial,
          dataFinal,
        },
      })
      .then((response) => {
        setTotalAtendimentos(response.data.total);
        setAtendimentos(response.data.atendimentos);
        const atendimentosSort = Object.values(response.data.atendimentos).sort((a, b) => b.total - a.total);
        setMaisAtendimentos(atendimentosSort.slice(0, 5));
        setMenosAtendimentos(atendimentosSort.slice(-5));
        setIsLoaded(true);
        setEquipe(TratamentoDadosParaEquipe(response.data.atendimentos));
      })
      .finally(() => {
        setIsFetching(false);
      });
  }

  function mediaTempoEmSegundos(tempo = '00:00:00') {
    const [hora, minuto, segundo] = tempo.split(':');
    return parseInt(segundo) + parseInt(minuto) * 60 + parseInt(hora) * 3600;
  }

  function TratamentoDadosParaEquipe(lista) {
    const result = {
      maioresQuantidades: [],
      menoresQuantidades: [],
      maioresMedias: [],
      menoresMedias: [],
    };

    lista.forEach((dia) => {
      for (let id in dia.equipe) {
        let equipe = dia.equipe[id];
        equipe.data = dia.data;
        equipe.id = id;
        result.maioresQuantidades.push(equipe);
        result.maioresMedias.push(equipe);
        result.menoresQuantidades.push(equipe);
        result.menoresMedias.push(equipe);
      }
    });

    result.maioresQuantidades = result.maioresQuantidades.sort((a, b) => b.quantidade - a.quantidade).slice(0, 10);
    result.menoresQuantidades = result.menoresQuantidades.sort((a, b) => a.quantidade - b.quantidade).slice(0, 10);
    result.menoresMedias = result.menoresMedias
      .sort((a, b) => mediaTempoEmSegundos(a.mediaTempoAtendimento) - mediaTempoEmSegundos(b.mediaTempoAtendimento))
      .slice(0, 10);
    result.maioresMedias = result.maioresMedias
      .sort((a, b) => mediaTempoEmSegundos(b.mediaTempoAtendimento) - mediaTempoEmSegundos(a.mediaTempoAtendimento))
      .slice(0, 10);
    return result;
  }

  useEffect(() => {
    const masters = [6, 20, 13];
    if (masters.includes(Number(idUsuario))) {
      setIsAdmin(true);
    }
  }, [idUsuario]);

  const semana = {
    0: 'Domingo',
    1: 'Segunda',
    2: 'Terça',
    3: 'Quarta',
    4: 'Quinta',
    5: 'Sexta',
    6: 'Sábado',
  };

  function formataDataUTC(data) {
    const dataSplit = data.split('T')[0];
    const dataNovoHorario = dataSplit + 'T12:00:00';
    return dataNovoHorario;
  }

  function formataDataSemana(data) {
    data = formataDataUTC(data);
    return semana[new Date(data).getDay()];
  }

  const toogleInfos = (codigo) => {
    isAdmin && setOpenStates((prevState) => ({
      ...prevState,
      [codigo]: !prevState[codigo],
    }));
  };

  return (
    <>
      <div className="container shadow-lg rounded-2 pb-3 mt-3">
        <div className="row justify-content-between align-items-center mb-2 mt-3">
          <div className="col-lg-11 col-sm-10 col-auto ms-1 mb-1">
            <a className="btn-colapse" data-bs-toggle="collapse" role="button" aria-expanded="false">
              <h5 className="text-dark mt-3 titulo">Relatório de Pico de Atendimentos Suporte</h5>
            </a>
          </div>
        </div>
        <div className="mb-3" id="collapseDashBoard">
          <div className="row py-2">
            <div className="col-lg-2">
              <div className="form-floating">
                <input
                  value={dataInicial}
                  type="date"
                  className="form-control"
                  id="dataInicial"
                  name="dataInicial"
                  placeholder="Data Inicial"
                  onChange={(e) => setDataInicial(e.target.value)}
                />
                <label htmlFor="dataAdmissao" className="">
                  Data Inicial
                </label>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="form-floating">
                <input
                  value={dataFinal}
                  type="date"
                  className="form-control"
                  id="DataFinal"
                  name="dataFinal"
                  placeholder="Data Final"
                  onChange={(e) => setDataFinal(e.target.value)}
                />
                <label htmlFor="dataAdmissao" className="">
                  Data Final
                </label>
              </div>
            </div>

            <div className="col-lg-2 my-3 my-lg-0  d-flex justify-content-center">
              <button className="btn btn-primary my-auto" onClick={(e) => getRelatorio(e)}>
                Buscar
              </button>
            </div>
          </div>

          {!isLoaded ? (
            ''
          ) : isFetching ? (
            <LoadingComponent />
          ) : (
            <>
              <div className="row mt-2">
                <div className="col">
                  <p>Total de atendimentos: {totalAtendimentos}</p>
                </div>
              </div>
              <div className="row">
                <LineChart data={atendimentos} />
              </div>
              <div className="row mt-4">
                <div className="col p-3">
                  <div className="card">
                    <div className="card-body">
                      <h6 className="card-title">Dia(s) com Mais Atendimentos:</h6>
                      {maisAtendimentos.map((atendimento) => (
                        <div className="m-1 d-flex justify-content-around" key={atendimento.data}>
                          <span>{formataData(atendimento.data)}</span>
                          <span>{formataDataSemana(atendimento.data)}</span>
                          <span>{atendimento.total} atendimentos</span>
                          <span>T médio: {atendimento.mediaTempoAtendimento}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="col p-3">
                  <div className="card">
                    <div className="card-body">
                      <h6 className="card-title">Dia(s) com Menos Atendimentos:</h6>
                      {menosAtendimentos
                        .sort((a, b) => a.total - b.total)
                        .map((atendimento) => (
                          <div className="m-1 d-flex justify-content-around" key={atendimento.data}>
                            <span>{formataData(atendimento.data)}</span>
                            <span>{formataDataSemana(atendimento.data)}</span>
                            <span>{atendimento.total} atendimentos</span>
                            <span>T médio: {atendimento.mediaTempoAtendimento}</span>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
              {isAdmin ? (
                <div className="">
                  <h5 className="">Equipe</h5>
                  <div className="row row-cols-2 mt-2">
                    <div className="col p-3">
                      <div className="card">
                        <div className="card-body">
                          <h6 className="card-title mb-2">Maiores quantidades de atendimento:</h6>
                          {equipe.maioresQuantidades.map((usuario, index) => (
                            <div className="m-1 d-flex justify-content-around" key={usuario.id + index}>
                              <span>{formataData(usuario.data)}</span>
                              <span>{String(dicionario.get(Number(usuario.id))).split(' ')[0]}</span>
                              <span>{usuario.quantidade} atendimentos</span>
                              <span>T médio: {usuario.mediaTempoAtendimento}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="col p-3">
                      <div className="card">
                        <div className="card-body">
                          <h6 className="card-title mb-2">Menores quantidades de atendimento:</h6>
                          {equipe.menoresQuantidades.map((usuario, index) => (
                            <div className="m-1 d-flex justify-content-around" key={usuario.id + index}>
                              <span>{formataData(usuario.data)}</span>
                              <span>{String(dicionario.get(Number(usuario.id))).split(' ')[0]}</span>
                              <span>{usuario.quantidade} atendimentos</span>
                              <span>T médio: {usuario.mediaTempoAtendimento}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="col p-3">
                      <div className="card">
                        <div className="card-body">
                          <h6 className="card-title mb-2">Menores tempos médios de atendimento:</h6>
                          {equipe.menoresMedias.map((usuario, index) => (
                            <div className="m-1 d-flex justify-content-around" key={usuario.id + index}>
                              <span>{formataData(usuario.data)}</span>
                              <span>{String(dicionario.get(Number(usuario.id))).split(' ')[0]}</span>
                              <span>{usuario.quantidade} atendimentos</span>
                              <span>T médio: {usuario.mediaTempoAtendimento}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div className="col p-3">
                      <div className="card">
                        <div className="card-body">
                          <h6 className="card-title mb-2">Maiores tempos médios de atendimento:</h6>
                          {equipe.maioresMedias.map((usuario, index) => (
                            <div className="m-1 d-flex justify-content-around" key={usuario.id + index}>
                              <span>{formataData(usuario.data)}</span>
                              <span>{String(dicionario.get(Number(usuario.id))).split(' ')[0]}</span>
                              <span>{usuario.quantidade} atendimentos</span>
                              <span>T médio: {usuario.mediaTempoAtendimento}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="row mt-5">
                <div className="col">
                  <h6 className="text-center">Tabela Geral</h6>
                  <table className="table table-hover">
                    <thead>
                      <tr className="text-center">
                        <th>Dia</th>
                        <th>Total</th>
                        <th>Quantidade de Usuários</th>
                        <th>Média por Usuario</th>
                        <th>Tempo M</th>
                        <th>Mediana</th>
                        <th>Max Atendimento usuário</th>
                        <th>Min Atendimento usuário</th>
                      </tr>
                    </thead>
                    <tbody>
                      {atendimentos.map((atendimento) => (
                        <>
                          <tr key={atendimento.data} className="text-center" onClick={()=>toogleInfos(atendimento.data)}>
                            <td>{formataData(atendimento.data)}</td>
                            <td>{atendimento.total}</td>
                            <td>{atendimento.qntEquipe}</td>
                            <td>{Number(atendimento.media).toFixed(2)}</td>
                            <td>{atendimento.mediaTempoAtendimento}</td>
                            <td>{atendimento.mediana}</td>
                            <td>
                              {atendimento.max}
                              {isAdmin ? ` - ${dicionario.get(Number(atendimento.equipeMax))}` : ''}
                            </td>
                            <td>
                              {atendimento.min}
                              {isAdmin ? ` - ${dicionario.get(Number(atendimento.equipeMin))}` : ''}
                            </td>
                          </tr>
                          {isAdmin && openStates[atendimento.data] ? (
                            <tr>
                              <td colSpan={2}></td>
                              <td colSpan={5}>
                                <table className="table table-sm">
                                  <thead>
                                    <tr>
                                      <th>Usuário</th>
                                      <th>Quantidade</th>
                                      <th>Média</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {Object.values(atendimento.equipe).map((usuario) => (
                                      <tr key={usuario.id}>
                                        <td>{dicionario.get(Number(usuario.id))}</td>
                                        <td>{usuario.quantidade}</td>
                                        <td>{usuario.mediaTempoAtendimento}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          ):null}
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default PicoDeAtendimentos;
