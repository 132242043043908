import { createContext, useContext, useEffect, useState } from 'react';
import api from '../service/api.js';
import { ST__LISTA_EQUIPE, ST__TOKEN } from '../constants/storagekey.js';
import { useQueryClient } from '@tanstack/react-query';
import { UsuarioContext } from './userContext.jsx';

export const UsuariosContext = createContext({
  usuarios: null,
  setUsuarios: () => undefined,
  GetUsers: () => undefined,
  dicionario: new Map(),
});

export function UsuariosContextProvider({ children }) {
  const queryClient = useQueryClient();
  const [usuarios, setUsuarios] = useState([]);
  const [dicionario, setDicionario] = useState(new Map());
  const { tokenUsuario } = useContext(UsuarioContext);

  async function GetUsers() {
    try {
      if (localStorage.getItem(ST__LISTA_EQUIPE)) {
        setUsuarios(JSON.parse(localStorage.getItem(ST__LISTA_EQUIPE)));
        return;
      }
      const filtros =  {
        tipo_busca: '2',
        ativo: 'D',
      }
      const result = await queryClient.fetchQuery({
        queryKey: ['usuarios', filtros],
        queryFn: () =>
          api.get('v1/usuarios', {
            params:filtros,
          }),
        enabled: !!localStorage.getItem(ST__TOKEN),
        staleTime: Infinity,
      });
      if (result.status === 200) {
        setUsuarios(result.data);
        localStorage.setItem(ST__LISTA_EQUIPE, JSON.stringify(result.data));
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  useEffect(() => {
    const userMap = new Map();
    usuarios.forEach((user) => {
      userMap.set(user.ID, user.NOME);
    });
    setDicionario(userMap);
  }, [usuarios]);

  useEffect(() => {
    const isLogged = !!localStorage.getItem(ST__TOKEN) || !!tokenUsuario;
    if (isLogged && usuarios.length === 0) {
      GetUsers();
    }
  }, [tokenUsuario, usuarios]);

  return (
    <UsuariosContext.Provider
      value={{
        usuarios,
        setUsuarios,
        GetUsers,
        dicionario,
      }}
    >
      {children}
    </UsuariosContext.Provider>
  );
}
