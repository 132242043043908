import React, { useState, useEffect } from 'react';
import api from '../../service/api';
import './style.css';
import Swal from 'sweetalert2';
import LoadingComponent from '../loading-component';
import { FaChevronRight } from 'react-icons/fa6';

const dtoPermissoes = {
  ajuste_horario: false,
  editar_cliente: false,
  editar_incluir_usuario: false,
  editar_obs_cliente: false,
  encaminhar_atendimento: false,
  encaminhar_atendimento_terceiro: false,
  financeiro: false,
  incluir_alerta_cliente: false,
  liberar_chave: false,
  listagem_param: false,
  remover_alerta_cliente: false,
  uso_siaac: false,
  max_dias_gerar_chave: 0,
};

function PermissoesComponent({ items, tipo }) {
  const [loading, setLoading] = useState(false);
  const [selecionado, setSelecionado] = useState({ item: null, tipo: tipo, ativo: false });
  const [permissoesAtual, setPermissoesAtual] = useState(dtoPermissoes);
  const [permissoesApi, setPermissoesApi] = useState(dtoPermissoes);

  const atualizarEstadoDasPermissoes = (obj) => {
    for (const chave in obj) {
      if (chave !== 'id' && chave !== 'setor' && chave !== 'usuario_id' && chave !== 'chave' && chave !== 'max_dias_gerar_chave') {
        obj[chave] = Number(obj[chave]) === 1;
      }
    }
    setPermissoesApi(obj);
    setPermissoesAtual(obj);
  };

  useEffect(() => {
    if (tipo !== selecionado.tipo) {
      setSelecionado({ item: null, tipo, ativo: false });
      setPermissoesAtual(dtoPermissoes);
    }
  }, [tipo, selecionado]);

  const fetchPermissions = (command) => {
    if (tipo === 'setor') {
      return api.get('/v1/permissoes', { params: { setor: command.sigla } });
    }
    if (tipo === 'usuario') {
      return api.get('/v1/permissoes', { params: { usuario_id: command.id, setor: command.sigla } });
    }
  };

  const reqPermissoes = (command) => {
    setLoading(true);
    setSelecionado({ ...selecionado, item: tipo === 'setor' ? command.sigla : command.id, ativo: true });
    fetchPermissions(command)
      .then((response) => {
        atualizarEstadoDasPermissoes(response.data[0]);
      })
      .catch(() => {
        if (tipo === 'setor') {
          setPermissoesAtual(dtoPermissoes);
        } else {
          api
            .get('/v1/permissoes', { params: { setor: command.sigla } })
            .then((response) => {
              atualizarEstadoDasPermissoes(response.data[0]);
            })
            .catch(() => {
              setPermissoesAtual(dtoPermissoes);
            });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const tooglePermissoes = (chave) => {
    setPermissoesAtual((prevPermissoes) => ({
      ...prevPermissoes,
      [chave]: !prevPermissoes[chave],
    }));
    if (chave === 'editar_cliente' && !permissoesAtual.editar_obs_cliente && !permissoesAtual.editar_cliente) {
      tooglePermissoes('editar_obs_cliente');
    }
    if (chave === 'liberar_chave' && !permissoesAtual.liberar_chave) {
      setPermissoesAtual((prevPermissoes) => ({
        ...prevPermissoes,
        max_dias_gerar_chave: 0,
      }));
    }
  };

  function ObjetosIguais(obj1, obj2) {
    for (const key in obj1) {
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
    return true;
  }

  const updatePermissoes = () => {
    if (!ObjetosIguais(permissoesAtual, permissoesApi)) {
      const { id, setor, usuario_id, ...restPermissoes } = permissoesAtual;
      restPermissoes.usuario_id = tipo === 'usuario' ? selecionado.item : null;
      restPermissoes.setor = tipo === 'setor' ? selecionado.item : null;
      api
        .post('/v1/permissoes', restPermissoes)
        .then(() => {
          setPermissoesApi(permissoesAtual);
          const toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            timer: 1500,
            timerProgressBar: true,
            showConfirmButton: false,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer);
              toast.addEventListener('mouseleave', Swal.resumeTimer);
            },
          });
          toast.fire({
            icon: 'success',
            title: 'Criado com sucesso',
          });
        })
        .catch((erro) => {
          console.log('erro :>> ', erro);
          Swal.fire({
            title: 'Erro',
            text: erro.message,
          });
        });
    } else {
      Swal.fire({
        title: 'Sem alterações',
      });
    }
  };

  return (
    <>
      <div className="listagem-container m-2">
        <div className="listagem-itens">
          {items.length
            ? items.map((item) => {
                return (
                  <button key={item.ID || item.id}
                    type='button'
                    className={`d-flex justify-content-between align-items-center btn-selecionar ${
                      selecionado.item === item.ID || selecionado.item === item.sigla ? 'btn-selecionado' : ''
                    }`}
                    style={{ borderBottom: '1px solid #adadadb7', marginBottom: '5px' }}
                    onClick={() => reqPermissoes({ id: item.ID || item.id, sigla: item.SETOR || item.sigla })}
                  >
                    {item.nome || item.NOME} <FaChevronRight/>
                  </button>
                );
              })
            : null}
        </div>
        <div className="listagem-permissoes">
          {!loading ? (
            <div>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  aria-checked={permissoesAtual.encaminhar_atendimento}
                  id="permissaoCheck001"
                  checked={permissoesAtual.encaminhar_atendimento}
                  onChange={() => {
                    tooglePermissoes('encaminhar_atendimento');
                  }}
                  disabled={!selecionado.ativo}
                />
                <label className="form-check-label" htmlFor="permissaoCheck001">
                  Encaminhar atendimento
                </label>
              </div>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  aria-checked={permissoesAtual.encaminhar_atendimento_terceiro}
                  id="permissaoCheck002"
                  checked={permissoesAtual.encaminhar_atendimento_terceiro}
                  onChange={() => {
                    tooglePermissoes('encaminhar_atendimento_terceiro');
                  }}
                  disabled={!selecionado.ativo}
                />
                <label className="form-check-label" htmlFor="permissaoCheck002">
                  Encaminhar atendimento de Terceiros
                </label>
              </div>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  aria-checked={permissoesAtual.editar_cliente}
                  id="permissaoCheck003"
                  checked={permissoesAtual.editar_cliente}
                  onChange={() => {
                    tooglePermissoes('editar_cliente');
                  }}
                  disabled={!selecionado.ativo}
                />
                <label className="form-check-label" htmlFor="permissaoCheck003">
                  Cadastrar/Editar cliente
                </label>
              </div>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  aria-checked={permissoesAtual.editar_obs_cliente}
                  id="permissaoCheck005"
                  checked={permissoesAtual.editar_obs_cliente}
                  onChange={() => {
                    tooglePermissoes('editar_obs_cliente');
                  }}
                  disabled={!selecionado.ativo}
                />
                <label className="form-check-label" htmlFor="permissaoCheck005">
                  Editar observações do cliente
                </label>
              </div>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  aria-checked={permissoesAtual.incluir_alerta_cliente}
                  id="permissaoCheck006"
                  checked={permissoesAtual.incluir_alerta_cliente}
                  onChange={() => {
                    tooglePermissoes('incluir_alerta_cliente');
                  }}
                  disabled={!selecionado.ativo}
                />
                <label className="form-check-label" htmlFor="permissaoCheck006">
                  Incluir alerta para o cliente
                </label>
              </div>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  aria-checked={permissoesAtual.remover_alerta_cliente}
                  id="permissaoCheck007"
                  checked={permissoesAtual.remover_alerta_cliente}
                  onChange={() => {
                    tooglePermissoes('remover_alerta_cliente');
                  }}
                  disabled={!selecionado.ativo}
                />
                <label className="form-check-label" htmlFor="permissaoCheck007">
                  Remover alerta para o cliente
                </label>
              </div>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  aria-checked={permissoesAtual.editar_incluir_usuario}
                  id="permissaoCheck008"
                  checked={permissoesAtual.editar_incluir_usuario}
                  onChange={() => {
                    tooglePermissoes('editar_incluir_usuario');
                  }}
                  disabled={!selecionado.ativo}
                />
                <label className="form-check-label" htmlFor="permissaoCheck008">
                  Incluir/Editar usuário
                </label>
              </div>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  aria-checked={permissoesAtual.ajuste_horario}
                  checked={permissoesAtual.ajuste_horario}
                  onChange={() => {
                    tooglePermissoes('ajuste_horario');
                  }}
                  id="permissaoCheck011"
                  disabled={!selecionado.ativo}
                />
                <label className="form-check-label" htmlFor="permissaoCheck011">
                  Ajuste de horário
                </label>
              </div>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  aria-checked={permissoesAtual.uso_siaac}
                  checked={permissoesAtual.uso_siaac}
                  onChange={() => {
                    tooglePermissoes('uso_siaac');
                  }}
                  id="permissaoCheck012"
                  disabled={!selecionado.ativo}
                />
                <label className="form-check-label" htmlFor="permissaoCheck012">
                  Uso SIAAC
                </label>
              </div>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  aria-checked={permissoesAtual.listagem_param}
                  id="permissaoCheck013"
                  checked={permissoesAtual.listagem_param}
                  onChange={() => {
                    tooglePermissoes('listagem_param');
                  }}
                  disabled={!selecionado.ativo}
                />
                <label className="form-check-label" htmlFor="permissaoCheck013">
                  Listagem PARAM
                </label>
              </div>

              <div className="form-check form-switch d-flex justify-content-start align-items-center">
                <input
                  className="form-check-input "
                  type="checkbox"
                  role="switch"
                  aria-checked={permissoesAtual.liberar_chave}
                  id="permissaoCheck014"
                  checked={permissoesAtual.liberar_chave}
                  onChange={() => {
                    tooglePermissoes('liberar_chave');
                  }}
                  disabled={!selecionado.ativo}
                />
                <label className="form-check-label ms-2" htmlFor="permissaoCheck014">
                  Liberar Chave
                </label>
                <input
                  type="number"
                  min={0}
                  className="form-control ms-2"
                  style={{ width: '70px' }}
                  id="dias"
                  placeholder="Dias"
                  hidden={!permissoesAtual.liberar_chave}
                  value={permissoesAtual.max_dias_gerar_chave}
                  onChange={(e) =>
                    setPermissoesAtual((prevPermissoes) => ({
                      ...prevPermissoes,
                      max_dias_gerar_chave: e.target.value,
                    }))
                  }
                />
              </div>

              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  aria-checked={permissoesAtual.financeiro}
                  id="permissaoCheck015"
                  checked={permissoesAtual.financeiro}
                  onChange={() => {
                    tooglePermissoes('financeiro');
                  }}
                  disabled={!selecionado.ativo}
                />
                <label className="form-check-label" htmlFor="permissaoCheck015">
                  Financeiro
                </label>
              </div>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  aria-checked={permissoesAtual.lancar_chave_param_cliente}
                  id="permissaoCheck016"
                  checked={permissoesAtual.lancar_chave_param_cliente}
                  onChange={() => {
                    tooglePermissoes('lancar_chave_param_cliente');
                  }}
                  disabled={!selecionado.ativo}
                />
                <label className="form-check-label" htmlFor="permissaoCheck016">
                  Lançar chave PARAM para cliente, acima do máximo permitido
                </label>
              </div>
              <div className="form-check form-switch">
                <input
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  aria-checked={permissoesAtual.backup}
                  id="permissaoCheck017"
                  checked={permissoesAtual.backup}
                  onChange={() => {
                    tooglePermissoes('backup');
                  }}
                  disabled={!selecionado.ativo}
                />
                <label className="form-check-label" htmlFor="permissaoCheck017">
                  Acesso ao backup
                </label>
              </div>
            </div>
          ) : (
            <LoadingComponent />
          )}
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center m-3">
        <button type='button' className="btn btn-outline-primary mx-2" onClick={() => updatePermissoes()}>
          Gravar
        </button>
        <button type='button' className="btn btn-outline-danger mx-2" onClick={() => setPermissoesAtual(permissoesApi)}>
          Cancelar
        </button>
      </div>
    </>
  );
}

export default PermissoesComponent;
