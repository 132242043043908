// import { Chart } from 'react-google-charts';

import 'react-big-calendar/lib/css/react-big-calendar.css';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { useCallback } from 'react';
require('moment/locale/pt-br.js');

export function Calendario({ dados, options }) {
  const messages = {
    week: 'Semana',
    work_week: 'Semana de trabalho',
    day: 'Dia',
    month: 'Mês',
    previous: 'Anterior',
    next: 'Depois',
    today: 'Hoje',
    agenda: 'Agenda',
  };
  const localizer = momentLocalizer(moment);
  const views = {
    month: true,
    week: true,
    day: true,
    agenda: true,
  };

  const eventPropGetter = useCallback(
    (event, start, end, isSelected) => ({
      ...(isSelected && {
        style: {
          backgroundColor: '#a6ff00',
          color: 'black',
        },
      }),
      ...(event.title.includes('Analise') && {
        style: {
          backgroundColor: '#FFFF99',
          color: '#000',
        },
      }),
    }),
    []
  );

  return (
    <Calendar
      localizer={localizer}
      messages={messages}
      events={dados}
      // views={views}
      startAccessor="start"
      endAccessor="end"
      style={{ height: 600 }}
      eventPropGetter={eventPropGetter}
    />
  );
}
