import api from '../api.js';

export async function fetchNotificacoes(atual) {
  try {
    const result = await api.get('/v1/notificacoes');
    return result.data;
  } catch (error) {
    throw new Error(error);
  }
}

export async function fetchNotificacoesLidas() {
  try {
    const result = await api.get('/v1/notificacoes/lidas');
    return result.data;
  } catch (error) {
    throw new Error(error);
  }
}
