import { useContext, useEffect, useState } from 'react';
import UsuariosOnline from './usuarios-online';
import Notification from './notification';
import './style.css';
import { NotificacoesContext } from '../../context/notificacoesContext.jsx';
import { FaBell, FaDownload, FaUsers } from 'react-icons/fa6';
import { SituacaoEquipeContext } from '../../context/equipeContext.jsx';
import { ST__ULT_REQ_SIT_EQUIPE } from '../../constants/storagekey.js';
import { DownloadsContext } from '../../context/downloadsContext.jsx';
import DownloadsProgress from './downloads/index.jsx';

function BtnLaterais() {
  const { notificacoes } = useContext(NotificacoesContext);
  const {setManualRefresh} = useContext(SituacaoEquipeContext);
  const {downloads} = useContext(DownloadsContext);

  const [areaOpen, setAreaOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [usuariosOnlineOpen, setUsuariosOnlineOpen] = useState(false);
  const [downloadsOpen, setDownloadsOpen] = useState(false);

  const RequestAtualizacaoSituacaoEquipe = () => {
    const time = new Date().getTime();
    const lastRequestTime = Number.parseInt(localStorage.getItem(ST__ULT_REQ_SIT_EQUIPE), 10) || 0;
    const diff = time - lastRequestTime;
    if(diff > 1000 * 60 * 5){
      setManualRefresh();
      return;
    }    
  }

  const toogleUsuariosScreen = () => {
    if (areaOpen && !usuariosOnlineOpen && (notificationOpen || downloadsOpen)) {
      setDownloadsOpen(false);
      setNotificationOpen(false);
      setUsuariosOnlineOpen(true);
    }
    if (areaOpen && usuariosOnlineOpen && !notificationOpen && !downloadsOpen) {
      setUsuariosOnlineOpen(false);
      setAreaOpen(false);
    }
    if (!areaOpen && !usuariosOnlineOpen) {
      setAreaOpen(true);
      setUsuariosOnlineOpen(true);
      RequestAtualizacaoSituacaoEquipe();
    }
    if (areaOpen && !usuariosOnlineOpen) {
      setUsuariosOnlineOpen(true);
      RequestAtualizacaoSituacaoEquipe();
    }
  };

  const toogleNotificacoesScreen = () => {
    if (areaOpen && !notificationOpen && (usuariosOnlineOpen || downloadsOpen)) {
      setDownloadsOpen(false);
      setUsuariosOnlineOpen(false);
      setNotificationOpen(true);
    }
    if (areaOpen && notificationOpen && !usuariosOnlineOpen && !downloadsOpen) {
      setNotificationOpen(false);
      setAreaOpen(false);
    }
    if (!areaOpen && !notificationOpen) {
      setAreaOpen(true);
      setNotificationOpen(true);
    }
    if (areaOpen && !notificationOpen) {
      setNotificationOpen(true);
    }
  };

  const toggleDownloadsScreen = () => {
    if (areaOpen && !downloadsOpen && (usuariosOnlineOpen || notificationOpen)) {
      setUsuariosOnlineOpen(false);
      setNotificationOpen(false);
      setDownloadsOpen(true);
    }
    if (areaOpen && downloadsOpen && !usuariosOnlineOpen && !notificationOpen) {
      setDownloadsOpen(false);
      setAreaOpen(false);
    }
    if (!areaOpen && !downloadsOpen) {
      setAreaOpen(true);
      setDownloadsOpen(true);
    }
    if (areaOpen && !downloadsOpen) {
      setDownloadsOpen(true);
    }
  };

  useEffect(()=>{
    if(downloads.length === 0 && downloadsOpen && areaOpen){
      setDownloadsOpen(false);
      setAreaOpen(false);
    }
  },[downloads, downloadsOpen, areaOpen]);
  

  return (
    <>
      <button
      type='button'
        className={`btn-laterais ${areaOpen ? 'btn-laterais-open' : ''} primeiro ${notificacoes.length ? 'qty' : ''}`}
        onClick={toogleNotificacoesScreen}
        data-qty={notificacoes.length}
      >
        <FaBell className={`${notificacoes.length ? 'fa-shake' : ''}`}/>
      </button>

      <button type='button' className={`btn-laterais ${areaOpen ? 'btn-laterais-open' : ''} segundo `} onClick={toogleUsuariosScreen}>
        <FaUsers />
      </button>

      {downloads.length > 0 ? (
        <button
          type='button'
          className={`btn-laterais ${areaOpen ? 'btn-laterais-open' : ''} terceiro qty`}
          onClick={toggleDownloadsScreen}
          data-qty={downloads.length}
        >
          <FaDownload />
        </button>
      ):null}
      <div className={`area ${areaOpen ? 'area-open' : ''}`}>
        <UsuariosOnline open={usuariosOnlineOpen} />
        <Notification open={notificationOpen} />
        <DownloadsProgress open={downloadsOpen} />
      </div>
    </>
  );
}

export default BtnLaterais;
