import { useContext, useEffect, useState } from 'react';
import { maskphone } from '../../util/maskdoc';
import './style.css';
import { UsuarioContext } from '../../context/userContext.jsx';
import api from '../../service/api.js';
import { ST__IDUSUARIO } from '../../constants/storagekey.js';
import Swal from 'sweetalert2';
import Titulo from '../../components/titulo/index.jsx';

export default function PerfilUsuario() {
  const { idUsuario, setNomeUsuario } = useContext(UsuarioContext);
  const [nome, setNome] = useState('');
  const [nomeExibicao, setNomeExibicao] = useState('');
  const [Telefone1, setTelefone1] = useState('');
  const [Telefone2, setTelefone2] = useState('');
  const [senhaAtual, setSenhaAtual] = useState('');
  const [novaSenha, setNovaSenha] = useState('');
  const [confirmacaoNovaSenha, setConfirmacaoNovaSenha] = useState('');
  const [password, setPassword] = useState(false);
  const [url, setUrl] = useState('');

  function GetInfoUsuario() {
    let id = idUsuario;
    if (!idUsuario) {
      id = localStorage.getItem(ST__IDUSUARIO);
    }
    api.get(`/v1/usuarios/${id}`).then((result) => {
      const data = result.data[0];
      setNome(data.nome_funcionario);
      setNomeExibicao(data.NOME);
      setNomeUsuario(data.NOME);
      setTelefone1(data.telefone_1);
      setTelefone2(data.telefone_2);
    });
  }

  function EditUsuarioInfo() {
    const toast = Swal.mixin({
      toast: true,
      position: 'top-end',
      timer: 2000,
      timerProgressBar: true,
      showConfirmButton: false,
    });
    api
      .patch('/v1/usuarios/editar', {
        id: idUsuario,
        nome: nomeExibicao,
        nome_funcionario: nome,
        telefone_1: Telefone1,
        telefone_2: Telefone2,
      })
      .then(() => {
        toast.fire({
          icon: 'success',
          title: 'Alterado com sucesso',
          didDestroy: () => {
            GetInfoUsuario();
          },
        });
      })
      .catch((error) => {
        toast.fire({
          icon: 'error',
          text: error.response.data.message,
        });
      });
  }

  function RedefinirSenha() {
    if (novaSenha === '' && confirmacaoNovaSenha === '') {
      return;
    }
    const toast = Swal.mixin({
      toast: true,
      position: 'top',
      timer: 2500,
      timerProgressBar: true,
      showConfirmButton: false,
      didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer);
      },
    });
    api
      .patch('/v1/usuarios/alterarsenha', {
        atual_senha: senhaAtual,
        nova_senha: novaSenha,
        confirm_nova_senha: confirmacaoNovaSenha,
        id_usuario: parseInt(idUsuario),
      })
      .then(() => {
        setSenhaAtual('');
        setConfirmacaoNovaSenha('');
        setNovaSenha('');
        toast.fire({
          icon: 'success',
          title: 'Alterado com sucesso',
        });
      })
      .catch((error) => {
        console.log('error :>> ', error);
        toast.fire({
          icon: 'error',
          text: error.response.data.erro,
        });
      });
  }

  useEffect(() => {
    GetInfoUsuario();
  }, []);

  useEffect(() => {
    setUrl(window.location.href);
    if (url.includes('password')) {
      setPassword(true);
    }
  }, [url]);

  return (
    <>
      <div className="container shadow-lg rounded-2 mt-3 pb-3">
        <Titulo principal titulo="Perfil do Usuário"/>
        <div className="row mt-3 mb-4">
          <div className="col-12 col-lg-3 col-xl-2 my-3">
            <div className="card">
              <div className="card-header p-0">
                <h5 className="card-title mt-2">Configurações</h5>
              </div>
              <div className="list-group list-group-flush" role="tablist">
                <a
                  className={`list-group-item list-group-item-action ${password ? '' : 'active'}`}
                  data-bs-toggle="list"
                  href="#account"
                  role="tab"
                >
                  Conta
                </a>
                <a
                  className={`list-group-item list-group-item-action ${!password ? '' : 'active'}`}
                  data-bs-toggle="list"
                  href="#password"
                  role="tab"
                >
                  Senha
                </a>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-9 col-xl-10 d-flex justify-content-center">
            <div className="tab-content">
              {/* CONTA */}
              <div className={`tab-pane fade ${password ? '' : 'show active'}`} id="account" role="tabpanel">
                <div className="card card-editado">
                  {/* <div className="card-body"> */}
                  <div className="card-header mb-2">
                    <h5 className="card-title">Conta</h5>
                  </div>
                  <div className="p-2">
                    <div className="row">
                      <div className="col-12">
                        <div className="form-floating">
                          <input
                            value={nome}
                            type="text"
                            className="form-control"
                            id="nomeUsuario"
                            name="nomeUsuario"
                            placeholder="Nome"
                            onChange={(e) => setNome(e.target.value)}
                          />
                          <label htmlFor="nomeUsuario" className="">
                            Nome
                          </label>
                        </div>
                      </div>
                      <div className="col-12 mt-2">
                        <div className="form-floating">
                          <input
                            value={nomeExibicao}
                            type="text"
                            className="form-control"
                            id="nomeUsuario"
                            name="nomeUsuario"
                            placeholder="Nome"
                            onChange={(e) => setNomeExibicao(e.target.value)}
                          />
                          <label htmlFor="nomeUsuario" className="">
                            Nome para exibição
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6 mt-2">
                        <div className="form-floating">
                          <input
                            value={maskphone(Telefone1)}
                            type="text"
                            className="form-control"
                            id="telefone1Usuario"
                            name="telefone1Usuario"
                            placeholder="Telefone"
                            onChange={(e) => setTelefone1(e.target.value)}
                          />
                          <label htmlFor="telefone1Usuario" className="">
                            Telefone
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-6 mt-2">
                        <div className="form-floating">
                          <input
                            value={maskphone(Telefone2)}
                            type="text"
                            className="form-control"
                            id="telefone1Usuario"
                            name="telefone1Usuario"
                            placeholder="Telefone"
                            onChange={(e) => setTelefone2(e.target.value)}
                          />
                          <label htmlFor="telefone1Usuario" className="">
                            Telefone
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="my-3">
                      <button type="button" className="btn btn-primary" onClick={EditUsuarioInfo}>
                        Salvar Alterações
                      </button>
                    </div>
                  </div>

                  {/* </div> */}
                </div>
              </div>
              {/* SENHA */}
              <div className={`tab-pane fade ${!password ? '' : 'show active'}`} id="password" role="tabpanel">
                <div className="card card-editado">
                  <div className="card-header mb-2">
                    <h5 className="card-title">Senha</h5>
                  </div>
                  <div className="p-2">
                    <div className="row">
                      <div className="form-floating mb-2">
                        <input
                          value={senhaAtual}
                          type="password"
                          className="form-control"
                          id="inputPasswordCurrent"
                          placeholder="Senha Atual"
                          onChange={(e) => setSenhaAtual(e.target.value)}
                        />
                        <label htmlFor="inputPasswordCurrent" className="ms-2">
                          Senha Atual
                        </label>
                      </div>
                      <div className="form-floating mb-2">
                        <input
                          value={novaSenha}
                          type="password"
                          className="form-control"
                          id="inputPasswordNew"
                          placeholder="Nova Senha"
                          onChange={(e) => setNovaSenha(e.target.value)}
                        />
                        <label htmlFor="inputPasswordNew" className="ms-2">
                          Nova senha
                        </label>
                      </div>
                      <div className="form-floating mb-2">
                        <input
                          value={confirmacaoNovaSenha}
                          type="password"
                          className="form-control"
                          id="inputPasswordNew2"
                          placeholder="Confirmar nova senha"
                          onChange={(e) => setConfirmacaoNovaSenha(e.target.value)}
                        />
                        <label htmlFor="inputPasswordNew2" className="ms-2">
                          Confirmar nova senha
                        </label>
                      </div>
                      {novaSenha &&
                      confirmacaoNovaSenha &&
                      novaSenha !== confirmacaoNovaSenha &&
                      novaSenha.length > 0 &&
                      confirmacaoNovaSenha.length > 0 ? (
                        <span className="fs-6">Please select a valid state.</span>
                      ) : (
                        <br />
                      )}
                    </div>
                    <div className="my-3">
                      <button
                        disabled={novaSenha !== confirmacaoNovaSenha || novaSenha.length === 0 || confirmacaoNovaSenha.length === 0}
                        type="button"
                        className="btn btn-primary"
                        onClick={RedefinirSenha}
                      >
                        Redefinir Senha
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
