import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';
import { FaArrowsRotate, FaCaretDown, FaCircleXmark, FaFilter, FaGears, FaMagnifyingGlass, FaPlus, FaRegFaceDizzy } from 'react-icons/fa6';
import AtendimentoCard from '../../../components/atendimento-card';
import { UsuarioContext } from '../../../context/userContext';
import {
  ST__CONFIG_USUARIO,
  ST__IDUSUARIO,
  ST__SETORUSUARIO,
  ST__STORAGESEARCH,
  ST__STORAGESEARCH_CARDS,
} from '../../../constants/storagekey';
import { UsuariosContext } from '../../../context/usersContext.jsx';
import { ROUTE_ATENDIMENTOS } from '../../../constants/routes.js';
import Titulo from '../../../components/titulo/index.jsx';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { GetAtendimentos } from '../../../service/fetchers/atendimentos.js';

function Main() {
  const queryClient = useQueryClient();
  const { idUsuario, nomeUsuario, setorUsuario, isAdminUsuario, visualizacao: visualizacaoContexto } = useContext(UsuarioContext);

  const [conteudoAtendimento, setConteudoAtendimento] = useState('');
  const [cliente, setCliente] = useState('');
  const [dataInicial, setDataInicial] = useState(-1);
  const [dataFinal, setDataFinal] = useState(-1);
  const [idUserLancado, setIdUserLancado] = useState('');
  const [idUserEncaminhado, setIdUserEncaminhado] = useState(idUsuario);
  const [posicao, setPosicao] = useState(['P', 'T', 'W']);
  const [setor, setSetor] = useState(setorUsuario || localStorage.getItem(ST__SETORUSUARIO));
  const [tipo, setTipo] = useState('0');
  const [tipoData, setTipoData] = useState('');
  const [numeroAtendimento, setNumeroAtendimento] = useState('');
  const [paginaPesquisa, setPaginaPesquisa] = useState(1);
  const [processando, setProcessando] = useState(false);
  const [maisAtendimentoDeFiltro, setMaisAtendimentoDeFiltro] = useState(true);
  const [showSearchs, setShowSearchs] = useState(false);

  const [atendimentosPesquisa, setAtendimentosPesquisa] = useState([]);
  const [meusAtendimentosSetor, setMeusAtendimentosSetor] = useState([]);

  const [navegacaoSituacao, setNavegacaoSituacao] = useState('PWT');
  const [qntPorSituacao, setQntPorSituacao] = useState('');
  const [semAtendimentos, setSemAtendimentos] = useState(false);

  const [isRefetched, setIsRefetched] = useState(false);

  const visualizacao = JSON.parse(localStorage.getItem(ST__CONFIG_USUARIO));
  for (let key in visualizacao) {
    if (visualizacao[key] === '1') visualizacao[key] = true;
    if (visualizacao[key] === '0') visualizacao[key] = false;
  }
  const usuarioId =
    Number(localStorage.getItem(ST__IDUSUARIO)) === 6 ||
    Number(localStorage.getItem(ST__IDUSUARIO)) === 20 ||
    visualizacao.listagemSetor === true
      ? -1
      : localStorage.getItem(ST__IDUSUARIO);

  const setorReq = visualizacao.listagemSetor === true ? localStorage.getItem(ST__SETORUSUARIO) : null;

  const navigate = useNavigate();

  const { usuarios } = useContext(UsuariosContext);

  function GetLancado(e) {
    setIdUserLancado(e.target.value);
  }

  function GetEncaminhado(e) {
    setIdUserEncaminhado(e.target.value);
  }

  function GetPosicao(e) {
    const value = e.target.value;
    const isChecked = e.target.checked;

    if (isChecked) {
      setPosicao((prevSelectedItems) => [...prevSelectedItems, value]);
    } else {
      setPosicao((prevSelectedItems) => prevSelectedItems.filter((item) => item !== value));
    }
  }

  const mapeamentoSiglas = {
    P: 'Pendente',
    T: 'Atendendo/Desenvolvendo',
    W: 'Aguardando',
    F: 'Finalizado',
    C: 'Cancelado',
    E: 'Testes',
    A: 'Atualização',
  };

  function GetSetor(e) {
    setSetor(e.target.value);
    verificaSetor(e.target.value);
  }

  const verificaSetor = (setorSelecionado) => {
    if (setorSelecionado !== setorUsuario) {
      setIdUserEncaminhado(-1);
    } else {
      setIdUserEncaminhado(idUsuario);
    }
  };

  function GetTipoData(e) {
    verificacaoTipoData(e.target.value);
    if (tipoData === '') {
      const date = new Date();
      setDataFinal(
        `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
      );
      setDataInicial(
        `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
      );

      setTipoData(e.target.value);
    } else if (e.target.value === '') {
      setTipoData(e.target.value);
      setDataFinal(-1);
      setDataInicial(-1);
    } else {
      setTipoData(e.target.value);
    }
  }
  const verificacaoTipoData = (tipo) => {
    const situacaoDeAcordoTipoData = tipo === 'F' ? ['F'] : ['P', 'T', 'W'];
    setPosicao(situacaoDeAcordoTipoData);
  };

  function SelAtendimento(id) {
    if (id > 0) {
      if (showSearchs)
        localStorage.setItem(
          ST__STORAGESEARCH,
          JSON.stringify({
            cliente,
            dataInicial,
            dataFinal,
            idUserEncaminhado,
            idUserLancado,
            posicao,
            setor,
            tipoData,
            numeroAtendimento,
            paginaPesquisa,
            conteudo: conteudoAtendimento,
            tipo: tipo === '0' ? null : tipo,
          })
        );
      navigate(`${ROUTE_ATENDIMENTOS}/${id}`);
    } else {
      navigate(`${ROUTE_ATENDIMENTOS}/0`);
    }
  }

  queryClient.setQueryDefaults(['atendimentos'], {
    queryFn: () =>
      GetAtendimentos({
        usuarioId: usuarioId,
        setorUsuario: setorReq,
      }),
  });

  const {
    data: atendimentos,
    error,
    isFetching,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['atendimentos'],
    staleTime: 1000 * 60 * 30,
  });

  const findAtendimentoSituacao = useCallback(
    (situacao) => {
      return atendimentos?.filter((atendimento) => atendimento.situacao === situacao);
    },
    [atendimentos]
  );

  const filterAtendimentos = useMemo(() => {
    if (navegacaoSituacao === 'PWT') {
      return atendimentos;
    }
    if (navegacaoSituacao === 'T') {
      return findAtendimentoSituacao('T');
    }
    if (navegacaoSituacao === 'W') {
      return findAtendimentoSituacao('W');
    }
    if (navegacaoSituacao === 'P') {
      return findAtendimentoSituacao('P');
    }
  }, [navegacaoSituacao, atendimentos, findAtendimentoSituacao]);

  async function pesquisa(indReset, permanence = null) {
    setProcessando(true);
    setMaisAtendimentoDeFiltro(true);
    let pagina = indReset ? 1 : paginaPesquisa + 1;
    if (!indReset && permanence) {
      setPaginaPesquisa(permanence.paginaPesquisa);
    } else {
      setPaginaPesquisa(pagina);
    }
    const filtrosPesquisa = permanence
      ? permanence
      : {
          numeroAtendimento,
          cliente,
          tipoData,
          dataInicial,
          dataFinal,
          idUserLancado,
          setor,
          idUserEncaminhado,
          posicao,
          pagina,
          conteudo: conteudoAtendimento,
          tipo: tipo === '0' ? null : tipo,
        };

    const resultPesquisa = await queryClient.fetchQuery({
      queryKey: ['pesquisaAtendimento', filtrosPesquisa],
      queryFn: () => GetAtendimentos({ indReset: indReset, filtros: filtrosPesquisa }),
      staleTime: 1000 * 60 * 50,
    });
    if (indReset) {
      setAtendimentosPesquisa(resultPesquisa);
    } else {
      setAtendimentosPesquisa((oldArray) => [...oldArray, ...resultPesquisa]);
    }
    if (resultPesquisa.length < 30) {
      setMaisAtendimentoDeFiltro(false);
    }
    setProcessando(false);
    setNavegacaoSituacao('-');
  }

  useEffect(() => {
    if (!visualizacao.listagemSetor && !isLoading && !isFetching && !error) {
      const count = atendimentos.reduce((acc, atendimento) => {
        const situacao = atendimento.situacao;
        if (situacao) {
          acc[situacao] = (acc[situacao] || 0) + 1;
        }
        return acc;
      }, {});

      setQntPorSituacao(count);
    }
    if (
      visualizacao.listagemSetor &&
      !isLoading &&
      !isFetching &&
      !error &&
      atendimentos.length === meusAtendimentosSetor.length &&
      !isRefetched
    ) {
      refetch();
      setIsRefetched(true);
    }
  }, [atendimentos, visualizacao.listagemSetor, isLoading, isFetching, error, meusAtendimentosSetor]);

  //LÓGICA PARA MOSTRAR DIV DE SEM ATENDIMENTOS CASO ABA DE NAVEGAÇÃO NÃO TENHA ATENDIMENTOS
  useEffect(() => {
    if (!isLoading && !isFetching && !error) {
      if (!visualizacao.listagemSetor) {
        if (qntPorSituacao !== '') {
          if (!qntPorSituacao.hasOwnProperty(navegacaoSituacao)) setSemAtendimentos(true);
          else setSemAtendimentos(false);
        }
        if (navegacaoSituacao === 'PWT' && atendimentos.length > 0) setSemAtendimentos(false);
        if (navegacaoSituacao === '-' && atendimentosPesquisa.length > 0) setSemAtendimentos(false);
      } else {
        if (navegacaoSituacao === 'PWT' && atendimentos.length > 0) setSemAtendimentos(false);
        if (navegacaoSituacao === '-' && atendimentosPesquisa.length > 0) setSemAtendimentos(false);
      }
    }
  }, [
    atendimentos,
    atendimentosPesquisa.length,
    navegacaoSituacao,
    qntPorSituacao,
    visualizacao.listagemSetor,
    isLoading,
    isFetching,
    error,
    meusAtendimentosSetor,
  ]);

  useEffect(() => {
    const storageSearch = localStorage.getItem(ST__STORAGESEARCH);

    if (!storageSearch) return;

    const parsedStorageSearch = JSON.parse(storageSearch);

    setCliente(parsedStorageSearch.cliente);
    setDataFinal(parsedStorageSearch.dataFinal);
    setDataInicial(parsedStorageSearch.dataInicial);
    setIdUserEncaminhado(parsedStorageSearch.idUserEncaminhado);
    setIdUserLancado(parsedStorageSearch.idUserLancado);
    setPosicao(parsedStorageSearch.posicao);
    setSetor(parsedStorageSearch.setor);
    setTipoData(parsedStorageSearch.tipoData);
    setNumeroAtendimento(parsedStorageSearch.numeroAtendimento);
    setPaginaPesquisa(parsedStorageSearch.paginaPesquisa);
    setConteudoAtendimento(parsedStorageSearch.conteudo);
    setTipo(parsedStorageSearch.tipo);

    pesquisa(false, parsedStorageSearch);

    localStorage.removeItem(ST__STORAGESEARCH_CARDS);
    localStorage.removeItem(ST__STORAGESEARCH);
  }, []);

  useEffect(() => {
    if (atendimentosPesquisa.length > 0 && !showSearchs) {
      setShowSearchs(true);
      setNavegacaoSituacao('-');
    }
    if (atendimentosPesquisa.length === 0 && !showSearchs) {
      setNavegacaoSituacao('PWT');
    }
  }, [atendimentosPesquisa]);

  useEffect(() => {
    if (visualizacao.listagemSetor && !isLoading && !isFetching && !error) {
      const atendimentosProprios = atendimentos?.filter((atendimento) => Number(atendimento.id_equipe_suporte) === Number(idUsuario));
      setMeusAtendimentosSetor(atendimentosProprios);
    }
  }, [atendimentos, visualizacao.listagemSetor, isLoading, isFetching, error]);

  useEffect(() => {
    if (visualizacao.listagemSetor === true) {
      setNavegacaoSituacao('M');
    }
  }, [visualizacao.listagemSetor]);

  return (
    <div>
      <div className="container shadow-lg rounded-2 mt-3">
        {/* BOTÕES DE NOVO REGISTRO + ATUALIZAR LISTA + PESQUISA */}
        <Titulo
          principal={true}
          titulo={`Olá ${nomeUsuario}, aqui estão seus atendimentos.`}
          complementoTitulo={
            <div className="col-md-3 col-xl-4 mb-1">
              <div className="d-flex btn-group mt-3" role="group" aria-label="Empresas">
                <button
                  className="btn btn-outline-dark d-flex justify-content-around align-items-center"
                  onClick={(e) => SelAtendimento(0)}
                >
                  <FaPlus />
                  <span>Novo Atendimento</span>
                </button>

                <button
                  className="btn btn-outline-dark d-flex justify-content-around align-items-center"
                  onClick={() => navigate('/atendimentos/osfinalizadas')}
                >
                  <FaGears size={20} />
                  <span>O. S.</span>
                </button>
              </div>
            </div>
          }
        >
          <div className="collapse" id="collapseExample">
            <div className="card card-body mt-3">
              <div className="row">
                <div className="col nowrap">
                  <label htmlFor="conteudoAtendimento">Conteúdo do atendimento</label>
                  <input
                    value={conteudoAtendimento}
                    onChange={(e) => setConteudoAtendimento(e.target.value)}
                    type="text"
                    className="form-control mb-2"
                    id="conteudoAtendimento"
                    placeholder="Ocorrência e Solução"
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 col-sm-12 nowrap">
                  <label htmlFor="edNumAtendimento">Número do Atendimento</label>
                  <input
                    value={numeroAtendimento}
                    onChange={(e) => setNumeroAtendimento(e.target.value)}
                    type="number"
                    className="form-control mb-2"
                    id="edNumAtendimento"
                    placeholder=""
                  />
                </div>
                <div className="col-md-6 col-sm-12 nowrap">
                  <label htmlFor="floatingInput">Cliente</label>
                  <input
                    onChange={(e) => setCliente(e.target.value)}
                    type="text"
                    className="form-control mb-2"
                    id="floatingInput"
                    placeholder="Razão Social, nome fantasia, etc."
                    value={cliente}
                  />
                </div>
                <div className="col-md-3 col-sm-12 nowrap">
                  <label htmlFor="cmbLancado">Lançado por...</label>
                  <select id="cmbLancado" className="form-select mb-2" aria-label="Selecionar" onChange={GetLancado} value={idUserLancado}>
                    <option value={-1}>Qualquer um</option>
                    {usuarios.map((usuario) => {
                      return (
                        <option key={usuario.ID} value={usuario.ID}>
                          {usuario.NOME}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 col-sm-12 nowrap">
                  <label htmlFor="cmbSetor">Setor</label>
                  {isAdminUsuario === 'S' ? (
                    <>
                      <select
                        onChange={GetSetor}
                        id="cmbSetor"
                        className="form-select mb-2"
                        aria-label="Selecionar"
                        disabled={isAdminUsuario !== 'S'}
                        value={setor}
                      >
                        {/* <option value={0}>Selecione</option> */}
                        <option value={'T'}>Todos</option>
                        <option value={'D'}>Desenvolvimento</option>
                        <option value={'S'}>Suporte</option>
                      </select>
                    </>
                  ) : null}

                  {isAdminUsuario !== 'S' ? (
                    <>
                      <select
                        onChange={GetSetor}
                        id="cmbSetor"
                        className="form-select mb-2"
                        aria-label="Selecionar"
                        disabled={true}
                        value={setor}
                      >
                        {/* <option value={0}>Selecione</option> */}
                        {setorUsuario === 'S' ? <option>Suporte</option> : null}
                        {setorUsuario === 'D' ? <option>Desenvolvimento</option> : null}
                      </select>
                    </>
                  ) : null}
                </div>
                <div className="col-md-3 col-sm-12 nowrap">
                  <label htmlFor="cmbEncaminhado">Encaminhado para...</label>
                  <select
                    id="cmbEncaminhado"
                    className="form-select mb-2"
                    aria-label="Selecionar"
                    disabled={isAdminUsuario !== 'S'}
                    onChange={GetEncaminhado}
                    value={idUserEncaminhado}
                    defaultValue={idUsuario}
                  >
                    <option value={-1}>Qualquer um</option>
                    {usuarios.map((usuario) => {
                      if (setor !== 'T' && usuario.SETOR === setor) {
                        return (
                          <option key={usuario.ID} value={usuario.ID}>
                            {usuario.NOME}
                          </option>
                        );
                      }
                      if (setor === 'T') {
                        return (
                          <option key={usuario.ID} value={usuario.ID}>
                            {usuario.NOME}
                          </option>
                        );
                      }
                    })}
                  </select>
                </div>
                <div className="col-md-3 col-sm-12 dropdown-filtro">
                  <label htmlFor="dropdown">Posição</label>
                  <div className="dropdown">
                    <button
                      className="btn dropdown-toggle nowrap"
                      type="button"
                      data-bs-auto-close="outside"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {posicao.length === 0
                        ? 'Selecione'
                        : posicao
                            .map((sigla) => mapeamentoSiglas[sigla])
                            .join(', ')
                            .replace(/,(?=[^,]*$)/, ' e')}
                    </button>
                    <ul className="dropdown-menu">
                      <li className="dropdown-item">
                        <input
                          id="Pendente"
                          type="checkbox"
                          className="me-1"
                          checked={posicao.findIndex((x) => x === 'P') !== -1 ? true : false}
                          value={'P'}
                          onChange={GetPosicao}
                        />
                        <label htmlFor="Pendente">Pendente</label>
                      </li>
                      <li className="dropdown-item">
                        <input
                          id="Atendendo"
                          type="checkbox"
                          className="me-1"
                          checked={posicao.findIndex((x) => x === 'T') !== -1 ? true : false}
                          value={'T'}
                          onChange={GetPosicao}
                        />
                        <label htmlFor="Atendendo">Atendendo/Desenvolvendo</label>
                      </li>
                      <li className="dropdown-item">
                        <input
                          id="Aguardando"
                          type="checkbox"
                          className="me-1"
                          checked={posicao.findIndex((x) => x === 'W') !== -1 ? true : false}
                          value={'W'}
                          onChange={GetPosicao}
                        />
                        <label htmlFor="Aguardando">Aguardando</label>
                      </li>
                      <li className="dropdown-item">
                        <input
                          id="Testes"
                          type="checkbox"
                          className="me-1"
                          checked={posicao.findIndex((x) => x === 'E') !== -1 ? true : false}
                          value={'E'}
                          onChange={GetPosicao}
                        />
                        <label htmlFor="Testes">Testes</label>
                      </li>
                      <li className="dropdown-item">
                        <input
                          id="Atualizar"
                          type="checkbox"
                          className="me-1"
                          checked={posicao.findIndex((x) => x === 'A') !== -1 ? true : false}
                          value={'A'}
                          onChange={GetPosicao}
                        />
                        <label htmlFor="Atualizar">Atualizar</label>
                      </li>
                      <li className="dropdown-item">
                        <input
                          id="Finalizado"
                          type="checkbox"
                          className="me-1"
                          checked={posicao.findIndex((x) => x === 'F') !== -1 ? true : false}
                          value={'F'}
                          onChange={GetPosicao}
                        />
                        <label htmlFor="Finalizado">Finalizado</label>
                      </li>
                      <li className="dropdown-item">
                        <input
                          id="Cancelado"
                          type="checkbox"
                          className="me-1"
                          checked={posicao.findIndex((x) => x === 'C') !== -1 ? true : false}
                          value={'C'}
                          onChange={GetPosicao}
                        />
                        <label htmlFor="Cancelado">Cancelado</label>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-3 col-sm-12 nowrap">
                  <label htmlFor="tipo">Tipo</label>
                  <select
                    onChange={(e) => setTipo(e.target.value)}
                    value={tipo}
                    id="tipo"
                    className="form-select mb-2"
                    aria-label="Selecionar"
                  >
                    <option value="0">Todos</option>
                    <option value="R">Remoto</option>
                    <option value="T">Telefone</option>
                    <option value="L">Visita ao Local</option>
                    <option value="O">O.S.</option>
                    <option value="A">Atualização</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 col-sm-12 nowrap">
                  <label htmlFor="cmbTipoData">Data</label>
                  <select onChange={GetTipoData} id="cmbTipoData" className="form-select mb-2" aria-label="Selecionar" value={tipoData}>
                    <option value="">Selecione</option>
                    <option value="L">Lançamento</option>
                    <option value="A">Abertura</option>
                    <option value="F">Fechamento</option>
                    <option value="G">Agendamento</option>
                  </select>
                </div>
                <div className="col-md-3 col-sm-12 nowrap">
                  {tipoData === '' ? null : (
                    <>
                      <label htmlFor="edDataInicial">Data Inicial</label>
                      <input
                        onChange={(e) => setDataInicial(e.target.value)}
                        value={dataInicial}
                        type="date"
                        className="form-control mb-2"
                        id="edDataInicial"
                        placeholder=""
                      />
                    </>
                  )}
                </div>

                <div className="col-md-3 col-sm-12 nowrap">
                  {tipoData === '' ? null : (
                    <>
                      <label htmlFor="edDataFinal">Data Final</label>
                      <input
                        onChange={(e) => setDataFinal(e.target.value)}
                        value={dataFinal}
                        type="date"
                        className="form-control mb-2"
                        id="edDataFinal"
                        placeholder=""
                      />
                    </>
                  )}
                </div>
                <div className="col-md-3 col-sm-12 text-center d-flex justify-content-center align-items-center p-md-4 p-sm-4">
                  <button
                    onClick={(e) => {
                      if (!showSearchs) setShowSearchs(true);
                      pesquisa(true);
                    }}
                    className="btn btn btn-primary w-100"
                  >
                    <div>
                      <FaMagnifyingGlass style={{ marginRight: 4 }} />
                      <small>Pesquisar...</small>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Titulo>

        {/* LISTAGEM DE REGISTROS */}
        <div className="row px-2 mt-3">
          <div className="row mb-3">
            <ul className="nav nav-tabs justify-content-center" role="tablist">
              {visualizacao.listagemSetor ? (
                <>
                  <li className="nav-item ms-auto" role="presentation">
                    <button
                      className={`nav-link link-dark ${navegacaoSituacao === 'M' ? 'active' : null}`}
                      id="atendendo"
                      data-bs-toggle="tab"
                      data-bs-target="#atendendo-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="atendendo-tab-pane"
                      aria-selected="true"
                      onClick={() => setNavegacaoSituacao('M')}
                    >
                      Meus Atendimentos <span className="badge text-bg-dark">{meusAtendimentosSetor.length}</span>
                    </button>
                  </li>
                </>
              ) : (
                <>
                  <li className="nav-item ms-auto" role="presentation">
                    <button
                      className={`nav-link link-warning ${navegacaoSituacao === 'T' ? 'active' : null}`}
                      id={setorUsuario === 'D' ? 'desenvolvendo' : 'atendendo'}
                      data-bs-toggle="tab"
                      data-bs-target={setorUsuario === 'D' ? '#desenvolvendo-tab-pane' : '#atendendo-tab-pane'}
                      type="button"
                      role="tab"
                      aria-controls={setorUsuario === 'D' ? '#desenvolvendo-tab-pane' : '#atendendo-tab-pane'}
                      aria-selected="true"
                      onClick={() => setNavegacaoSituacao('T')}
                    >
                      {setorUsuario === 'D' ? 'Desenvolvendo' : 'Atendendo'}{' '}
                      <span className="badge text-bg-warning">{qntPorSituacao.T || 0}</span>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link link-primary ${navegacaoSituacao === 'W' ? 'active' : null}`}
                      id="aguardando"
                      data-bs-toggle="tab"
                      data-bs-target="#aguardando-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="aguardando-tab-pane"
                      aria-selected="false"
                      onClick={() => setNavegacaoSituacao('W')}
                    >
                      Aguardando <span className="badge text-bg-primary">{qntPorSituacao.W || 0}</span>
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={`nav-link link-danger ${navegacaoSituacao === 'P' ? 'active' : null}`}
                      id="pendente"
                      data-bs-toggle="tab"
                      data-bs-target="#pendente-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="pendente-tab-pane"
                      aria-selected="false"
                      onClick={() => setNavegacaoSituacao('P')}
                    >
                      Pendente <span className="badge text-bg-danger">{qntPorSituacao.P || 0}</span>
                    </button>
                  </li>
                </>
              )}
              <li className="nav-item" role="presentation">
                <button
                  className={`nav-link link-dark ${navegacaoSituacao === 'PWT' ? 'active' : null}`}
                  id="todos"
                  data-bs-toggle="tab"
                  data-bs-target="#todos-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="todos-tab-pane"
                  aria-selected="false"
                  onClick={() => setNavegacaoSituacao('PWT')}
                >
                  <b>
                    {visualizacao.listagemSetor ? 'Atendimentos do Setor' : 'Todos Atendimentos'}{' '}
                    <span className="badge text-bg-dark">{isLoading ? 0 : !error ? atendimentos.length : 0}</span>
                  </b>
                </button>
              </li>
              <li className="nav-item" style={{ display: `${!showSearchs ? 'none' : 'block'}` }} role="presentation">
                <button
                  className={`nav-link link-secondary ${navegacaoSituacao === '-' ? 'active' : null}`}
                  id="pesquisa-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#pesquisa-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="pesquisa-tab-pane"
                  aria-selected="false"
                  onClick={() => setNavegacaoSituacao('-')}
                >
                  <span className={processando ? 'placeholder-glow' : ''}>
                    Pesquisa{' '}
                    <span className={`badge text-bg-secondary ${processando ? 'placeholder' : ''}`}>
                      {processando ? 'CARREGANDO' : atendimentosPesquisa.length}
                    </span>
                    <FaCircleXmark
                      className="ms-3"
                      onClick={() => {
                        setShowSearchs(!showSearchs);
                        setAtendimentosPesquisa([]);
                      }}
                    />
                  </span>
                </button>
              </li>
              <li className="nav-item ms-auto" role="presentation">
                <button className="btn btn-outline-success" onClick={() => refetch()}>
                  <FaArrowsRotate size={20} className={isFetching && !isLoading ? 'icon-spin' : ''} />
                </button>
              </li>
            </ul>
          </div>
          <div
            className={
              !error
                ? `row mb-3 gx-2 gy-2 row-cols-2 ${visualizacao.cardSlim ? '' : 'atendimentos'} justify-content-around`
                : 'row d-flex align-items-center justify-content-center mb-3'
            }
            data-bss-baguettebox
          >
            {!isLoading && !isFetching ? (
              !error ? (
                navegacaoSituacao === '-' ? (
                  atendimentosPesquisa.map((a) => {
                    return <AtendimentoCard key={a.id} atendimento={a} onClickButton={SelAtendimento} />;
                  })
                ) : navegacaoSituacao === 'M' ? (
                  meusAtendimentosSetor.map((a) => {
                    return <AtendimentoCard key={a.id} atendimento={a} onClickButton={SelAtendimento} />;
                  })
                ) : (
                  filterAtendimentos.map((a) => {
                    return <AtendimentoCard key={a.id} atendimento={a} navegacao={navegacaoSituacao} onClickButton={SelAtendimento} />;
                  })
                )
              ) : (
                <div className="row d-flex align-items-center justify-content-center">
                  <div className="d-block text-center">
                    <div>
                      <h5 className="text-secondary">Houve um erro ao buscar os atendimentos</h5>
                      <FaRegFaceDizzy size={30} className="text-secondary" />
                    </div>
                  </div>
                </div>
              )
            ) : null}
          </div>
        </div>

        {/* BUSCAR MAIS REGISTROS */}
        {(atendimentos?.length === 0 && navegacaoSituacao !== '-') ||
        semAtendimentos === true ||
        (visualizacao.listagemSetor && navegacaoSituacao === 'M' && meusAtendimentosSetor.length === 0) ? (
          <a
            href="#collapseExample"
            className="btn-colapse"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            <div>
              <span className="d-block text-center">
                <div>
                  {navegacaoSituacao === '-' ? (
                    <>
                      <h5 className="text-secondary">Nenhum atendimento encontrado!</h5>
                      <h6 className="pb-5 text-secondary">
                        Tente outro filtro <FaFilter />
                      </h6>
                    </>
                  ) : (
                    <>
                      <h5
                        className={`text-secondary ${
                          navegacaoSituacao !== 'PWT' ? 'pb-5' : navegacaoSituacao === 'PWT' && showSearchs ? 'pb-5' : null
                        }`}
                      >{`Nenhum atendimento${navegacaoSituacao !== 'PWT' ? ' nesta situação' : ''}!`}</h5>
                      {navegacaoSituacao === 'PWT' && !showSearchs ? (
                        <h6 className="pb-5 text-secondary">
                          Tente algum filtro! <FaFilter />
                        </h6>
                      ) : null}
                    </>
                  )}
                </div>
              </span>
            </div>
          </a>
        ) : null}
        {navegacaoSituacao === '-' && maisAtendimentoDeFiltro ? (
          <span className="d-block text-center mt-3 mb-5">
            <button onClick={(e) => pesquisa(false)} className="btn btn-lg btn-outline-secondary mb-3">
              <div>
                <FaCaretDown />
                <small>Carregar mais atendimentos...</small>
              </div>
            </button>
          </span>
        ) : null}
      </div>
    </div>
  );
}

export default Main;
