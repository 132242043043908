import React from 'react';
import TabelaLinha from './tabelaLinha.jsx';

function TabelaLiberacao({ ferias, onSetDataInicio, onSetDataFim }) {
  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Nome</th>
            <th scope="col">Inicio</th>
            <th scope="col">Fim</th>
            <th scope="col">Dias</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {ferias.map((item, index) => {
            return <TabelaLinha key={index} ferias={item} onSetDataInicio={onSetDataInicio} onSetDataFim={onSetDataFim} />;
          })}
        </tbody>
      </table>
    </div>
  );
}

export default TabelaLiberacao;
