import React from 'react';
import { maskdoc, maskphone } from '../../util/maskdoc.js';
import './style.css';

function ClienteCard(props) {
  return (
    <div className="container">
      <div
        className="card-cliente-container"
        onClick={(e) => props.onClickCliente(props.id)}
        onAuxClick={(e) => props.onClickAuxCliente(props.id)}
      >
        <div className={`tag-card-cliente-${props.ativo === 1 ? 'ativo' : 'inativo'}`}></div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-6 col-md-6">
              <span>
                Código: <strong>{props.id}</strong>
              </span>
            </div>
            <div className="col-6 col-md-6">
              <span>
                Contrato: <strong>{props.contrato}</strong>
              </span>
            </div>
          </div>

          <div className="row align-items-center">
            <div className="col-12 col-md-12 col-lg-6 nowrap">
              <span>
                Razão Social: <strong>{props.razao_social}</strong>
              </span>
            </div>
            <div className="col-12 col-md-12 col-lg-6 nowrap">
              <span>
                Fantasia: <strong>{props.nome_fantasia}</strong>
              </span>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-12 col-md-12 col-lg-3">
              <span>
                CNPJ: <strong>{maskdoc(props.cnpj)}</strong>
              </span>
            </div>
            <div className="col-12 col-md-4 col-lg-3">
              <span>
                Telefone:{' '}
                <a href={`tel:+55${props.telefone1}`}>
                  <strong>{maskphone(props.telefone1)}</strong>
                </a>
              </span>
            </div>
            <div className="col-12 col-md-4 col-lg-3">
              <span>
                Telefone:{' '}
                <strong>
                  <a href={`tel:+55${props.telefone2}`}>
                    <strong>{maskphone(props.telefone2)}</strong>
                  </a>
                </strong>
              </span>
            </div>
            <div className="col-12 col-md-4 col-lg-3">
              <span>
                Celular:{' '}
                <strong>
                  <a href={`tel:+55${props.celular}`}>
                    <strong>{maskphone(props.celular)}</strong>
                  </a>
                </strong>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ClienteCard;
