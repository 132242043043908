import React from 'react';
import './style.css';

function Footer() {
  const ano = new Date().getFullYear();

  return (
    <footer className="text-center py-4">
      <div className="container">
        <div className="row row-cols-1 row-cols-lg-3">
          <div className="col"></div>
          <div className="col">
            <p className="text-muted my-2">Copyright&nbsp;© {ano} CM Sistemas</p>
          </div>
          <div className="col"></div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
