import { useState, useEffect } from 'react';
import styles from './scroll.module.css';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa6';

const ScrollButton = () => {
  const [showButtonUp, setShowButtonUp] = useState(false);
  const [showButtonDown, setShowButtonDown] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const pageHeight = document.body.clientHeight;
      const scrollY = window.scrollY;
      const isShowUp = scrollY > pageHeight / 4;
      const isShowDown = scrollY < (pageHeight / 4) * 3;
      setShowButtonUp(isShowUp);
      setShowButtonDown(isShowDown);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  };

  return (
    <div className={styles.container}>
      <div>
        {showButtonUp ? (
          <button className={styles.button} onClick={scrollToTop}>
            <FaArrowUp/>
          </button>
        ) : null}
        {showButtonDown ? (
          <button className={styles.button} onClick={scrollToBottom}>
            <FaArrowDown/>
          </button>
        ) : null}
      </div>
    </div>
  );
};

export default ScrollButton;
