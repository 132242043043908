import { FaChevronLeft, FaChevronRight } from "react-icons/fa6";

function Carousel({ id, images, style }) {
  return (
    <div id={id} className="carousel slide h-100">
      <div className="carousel-inner h-100">
        {images.map((image, index) => (
          <div key={index} className={`carousel-item h-100 ${index === 0 ? 'active' : ''}`}>
            <div className="d-flex justify-content-center align-items-center h-100">
              <img src={image} alt={`Card ${index}`} style={style} />
            </div>
          </div>
        ))}
      </div>
      <button className="carousel-control-prev" type="button" data-bs-target={`#${id}`} data-bs-slide="prev">
        <span className="carousel-control-prev-icon text-dark" aria-hidden="true">
          <FaChevronLeft/>
        </span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target={`#${id}`} data-bs-slide="next">
        <span className="carousel-control-next-icon text-dark" aria-hidden="true">
          <FaChevronRight/>
        </span>
      </button>
    </div>
  );
}

export default Carousel;
