import React, { useContext } from 'react';
import style from './usuario-config.module.css';
import cardFatFechado from '../../../assets/card_fat_fechado.jpg';
import cardFatAberto from '../../../assets/card_fat_aberto.jpg';
import cardSlimFechado from '../../../assets/card_slim_fechado.jpg';
import cardSlimAberto from '../../../assets/card_slim_aberto.jpg';
import abaPadrao from '../../../assets/visualizacao_padrao.jpg';
import abaSetor from '../../../assets/visualizacao_setor.jpg';
import { UsuarioContext } from '../../../context/userContext.jsx';
import Carousel from './carousel.jsx';
import api from '../../../service/api.js';
import { toast } from '../../../constants/toast.jsx';
import { ST__CONFIG_USUARIO } from '../../../constants/storagekey.js';
import Titulo from '../../../components/titulo/index.jsx';
import { useQueryClient } from '@tanstack/react-query';

function ConfigUsuario() {
  const queryClient = useQueryClient();
  const { visualizacao, setVisualizacao } = useContext(UsuarioContext);

  const EnviarConfiguracoesApi = () => {
    api
      .post('/v1/usuario/configuracao', visualizacao)
      .then((result) => {
        toast.fire({
          icon: 'success',
          title: 'Configuração salva com sucesso',
        });
        const configs = JSON.parse(localStorage.getItem(ST__CONFIG_USUARIO));
        if (configs) {
          configs.cardSlim = visualizacao.cardSlim;
          configs.listagemSetor = visualizacao.listagemSetor;

          localStorage.setItem(ST__CONFIG_USUARIO, JSON.stringify(configs));
        } else {
          localStorage.setItem(ST__CONFIG_USUARIO, JSON.stringify(visualizacao));
        }
        queryClient.invalidateQueries({ queryKey: ['atendimentos'] });
        queryClient.prefetchQuery({
          queryKey: ['atendimentos'],
        });
      })
      .catch((error) => {
        toast.fire({
          icon: 'error',
          title: error.response.data,
        });
        console.log('error :>> ', error);
      });
  };

  return (
    <>
      <div className="container shadow-lg rounded-2 mt-3 pb-3">
        <Titulo principal titulo="Configurações de Visualização" />
        <h6 className="fw-medium mt-4">Card de atendimento:</h6>
        <div className={`${style.cardContainer} row card m-2`}>
          <div className="col-4 h-100 d-flex align-items-center justify-content-center">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="cardCurto"
                onChange={() => setVisualizacao('cardSlim', !visualizacao.cardSlim)}
                checked={!visualizacao.cardSlim}
              />
              <label className="form-check-label" htmlFor="cardCurto">
                Card curto, visualização em colunas
              </label>
            </div>
          </div>
          <div className="col-8 h-100">
            <Carousel id="cardFatCarousel" images={[cardFatFechado, cardFatAberto]} />
          </div>
        </div>
        <div className={`${style.cardContainer} row card m-2`}>
          <div className="col-4 h-100 d-flex align-items-center justify-content-center">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="cardLongo"
                onChange={() => setVisualizacao('cardSlim', !visualizacao.cardSlim)}
                checked={visualizacao.cardSlim}
              />
              <label className="form-check-label" htmlFor="cardLongo">
                Card longo, visualização em linhas
              </label>
            </div>
          </div>
          <div className="col-8 h-100">
            <Carousel id="cardSlimCarousel" images={[cardSlimFechado, cardSlimAberto]} style={{ width: '80%' }} />
          </div>
        </div>
        <h6 className="fw-medium mt-4">Visualização de Atendimentos</h6>
        <div className={`${style.cardContainer} row card m-2`}>
          <div className="col-4 h-100 d-flex align-items-center justify-content-center">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="visualizacaoPadrao"
                onChange={() => setVisualizacao('listagemSetor', !visualizacao.listagemSetor)}
                checked={!visualizacao.listagemSetor}
              />
              <label className="form-check-label" htmlFor="visualizacaoPadrao">
                Padrão, atendimentos individuais.
              </label>
            </div>
          </div>
          <div className="col-8 h-100 d-flex justify-content-center align-items-center">
            <img src={abaPadrao} title="Padrão" />
          </div>
        </div>
        <div className={`${style.cardContainer} row card m-2`}>
          <div className="col-4 h-100 d-flex align-items-center justify-content-center">
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="visualizacaoSetor"
                onChange={() => setVisualizacao('listagemSetor', !visualizacao.listagemSetor)}
                checked={visualizacao.listagemSetor}
              />
              <label className="form-check-label" htmlFor="visualizacaoSetor">
                Setor, atendimentos do setor.
              </label>
            </div>
          </div>
          <div className="col-8 h-100 d-flex justify-content-center align-items-center">
            <img src={abaSetor} title="Setor" />
          </div>
        </div>
        <div className="pb-3 mt-5 d-flex justify-content-center">
          <button className="btn btn-primary mx-2" onClick={EnviarConfiguracoesApi}>
            Salvar
          </button>
        </div>
      </div>
    </>
  );
}

export default ConfigUsuario;
