import { FaLocationDot, FaUser } from "react-icons/fa6";

export default function UsuariosOnlineCard({ usuario }) {
  return (
    <div className="usuario-card">
      <div className="row" style={{ borderBottom: '1px solid lightgray' }}>
        <div className="col">
          <FaUser
            style={{ color: usuario.status === 'Online' ? 'green' : 'red', fontSize: 15 }}
            alt={usuario.status}
            title={usuario.status}
          />{' '}
          <span alt={usuario.status} title={usuario.status} style={{ fontSize: 15 }}>
            {usuario.usuario}
          </span>
        </div>
      </div>
      <div className="row mt-1">Atendimentos:</div>
      <div className="row">
        <div className="col d-flex flex-column">
          <div className="d-flex align-items-center justify-content-between">
            <span>Atendendo: {usuario.qtd_atendendo}</span>
            {Number(usuario.atendimento_atendendo_externo) !== 0 ? (
              <span>
                <FaLocationDot /> Externo: {usuario.atendimento_atendendo_externo}
              </span>
            ) : null}
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <span>Aguardando: {usuario.qtd_aguardando}</span>
            {Number(usuario.atendimento_aguardando_externo) !== 0 ? (
              <span>
                <FaLocationDot /> Externo: {usuario.atendimento_aguardando_externo}
              </span>
            ) : null}
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <span>Pendente: {usuario.qtd_pendentes}</span>
            {Number(usuario.atendimento_pendente_externo) !== 0 ? (
              <span>
                <FaLocationDot /> Externo: {usuario.atendimento_pendente_externo}
              </span>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
