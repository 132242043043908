import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { FaCircleInfo, FaKey, FaTrashCan, FaTriangleExclamation, FaUpload } from 'react-icons/fa6';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import ProcuraCliente from '../../../../components/procura-cliente';
import Timeline from '../../../../components/time-line';
import { BuscaClienteListaAutoCompleteContext } from '../../../../context/BuscaClienteListaAutoCompleteContext';
import { formataData, formataHora, maskphone, maskVersao } from '../../../../util/maskdoc';
import './style.css';
import AlteracoesDeCamposNoAtendimento from '../../../../util/validacaoEdicaoAtendimento';
import ModalSenhasClientes from '../../../../components/modalSenhas';
import CardAlerta from '../../../../components/alerta-card';
import { UsuarioContext } from '../../../../context/userContext';
import ModalUploadArquivos from '../../../../components/modalUpload/index.jsx';
import ModalInfoOs from '../../../../components/os-finalizadas-card/modal-info-os/index.jsx';
import { ROUTE_ATENDIMENTOS } from '../../../../constants/routes.js';
import Titulo from '../../../../components/titulo/index.jsx';
import { GetAtendimentoById, GetListaOs } from '../../../../service/fetchers/atendimentos.js';
import { AlterarAtendimento } from '../../../../service/mutations/atendimento.js';
import { toast } from '../../../../constants/toast.jsx';
import moment from 'moment';

export default function EditarAtendimento({ usuarios, id }) {
  const queryClient = useQueryClient();
  const { idUsuario, permissoesUsuario } = useContext(UsuarioContext);

  const navigate = useNavigate();

  const [solucionar, setSolucionar] = useState(false);
  const [editar, setEditar] = useState(false);
  const [ocorrencia, setOcorrencia] = useState('');
  const [texto, setTexto] = useState('');
  const [alertas, setAlertas] = useState([]);
  const [showAlerts, setShowAlerts] = useState(false);

  const [registrarOcorrenciaTexto, setRegistrarOcorrenciaTexto] = useState('');

  const [atendimentoEditado, setAtendimentoEditado] = useState({});
  const [atendimentoDaApi, setAtendimentoDaApi] = useState({});

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [infoModalOs, setInfoModalOs] = useState({ ocorrencia: '' });
  const [modalOpen, setModalOpen] = useState(false);

  const [registrouMudancas, setRegistrouMudancas] = useState(false);

  const { setFocus, cliente, clienteId, setCliente, setClienteId } = useContext(BuscaClienteListaAutoCompleteContext);

  const PermissionValidate = (idUser) => {
    const sameUser = parseInt(idUsuario, 10) === idUser;

    if (permissoesUsuario.encaminhar_atendimento_terceiro === 1 || sameUser) {
      return true;
    }
    return false;
  };

  const GetAtendimento = async () => {
    const data = await GetAtendimentoById(id);
    if (data[0].alertas_ativos_cliente && data[0].alertas_ativos_cliente !== null) {
      const alertas = JSON.parse(data[0].alertas_ativos_cliente);
      if (alertas.length > 0) {
        setAlertas(alertas);
      } else {
        setAlertas([{ id: 1, alerta: 'Não havia alerta para o cliente quando foi lançado!' }]);
      }
    } else {
      setAlertas([{ id: 1, alerta: 'Não havia alerta para o cliente quando foi lançado!' }]);
    }
    return data[0];
  };

  const { data: FetchedAtendimento, isLoading } = useQuery({
    queryKey: [`atendimento-${id}`],
    queryFn: GetAtendimento,
    staleTime: 1000 * 60 * 30,
  });

  const {
    data: listaOsSelecionar,
    isLoading: isLoadingOS,
    isFetching: isFetchingOS,
    isError: isErrorOS,
  } = useQuery({
    queryKey: ['ListaOsAtualizar'],
    queryFn: () => GetListaOs({ posicao: ['A'] }),
    staleTime: 1000 * 60 * 10,
  });

  function openModal(infos) {
    setInfoModalOs(infos);
    setModalOpen(true);
  }

  function modalClose() {
    setInfoModalOs({ ocorrencia: '' });
    setModalOpen(false);
  }

  function addLista(e) {
    const value = e.target.value;
    const isChecked = e.target.checked;
    if (isChecked) {
      setAtendimentoEditado((prevSstate) => ({ ...prevSstate, os_relacionadas: prevSstate.os_relacionadas.concat(value) }));
    } else {
      setAtendimentoEditado((prevSstate) => ({
        ...prevSstate,
        os_relacionadas: prevSstate.os_relacionadas.filter((item) => item !== value),
      }));
    }
  }

  const AtualizacaoDoAtendimento = (chave, valor) => {
    if (chave === 'id_equipe_suporte') {
      const index = usuarios.findIndex((x) => x.ID === valor);
      const nome = usuarios[index].NOME;
      AtualizacaoDoAtendimento('encaminhado', nome);
    }

    if (chave === 'tipo' && valor === 'A' && !atendimentoEditado.os_relacionadas) {
      setAtendimentoEditado((old) => {
        return {
          ...old,
          os_relacionadas: [],
        };
      });
    }

    setAtendimentoEditado((anterior) => ({
      ...anterior,
      [chave]: valor,
    }));
  };

  const montagemDaOcorrencia = (boolean) => {
    const date = new Date();
    const dateToIsoString = date.toISOString();
    const situacaoSolucionar = atendimentoEditado.tipo === 'O' ? 'E' : 'F';
    if (boolean) {
      setRegistrarOcorrenciaTexto('');
      AtualizacaoDoAtendimento('solucao', texto);
      AtualizacaoDoAtendimento('situacao', situacaoSolucionar);
    }
    if (boolean === false) {
      AtualizacaoDoAtendimento('solucao', '');
      setRegistrarOcorrenciaTexto(
        `\n${formataData(dateToIsoString)} ${formataHora(dateToIsoString)} | ${localStorage.getItem('sessionNomeUsuario')} | ${texto}`
      );
    }
  };

  function VerificarSeHaMudancas() {
    for (const chave in atendimentoEditado) {
      if (atendimentoEditado[chave] !== atendimentoDaApi[chave] && chave !== 'ocorrencia') {
        return true;
      }
      if (chave === 'ocorrencia' && texto !== '') {
        return true;
      }
    }
    return false;
  }

  const EditarAtendimentoRequest = (e) => {
    e.preventDefault();
    setEditar(false);
    if (!VerificarSeHaMudancas()) {
      setEditar(true);
      return Swal.fire({
        icon: 'error',
        title: 'Não possui alteração no atendimento!',
      });
    }
    if (atendimentoEditado.situacao === 'F' && atendimentoEditado.solucao === atendimentoDaApi.solucao) {
      setEditar(true);
      return Swal.fire({
        icon: 'error',
        title: 'Não possui texto na solução do atendimento!',
      });
    }

    if (atendimentoEditado.tipo !== 'O') {
      atendimentoEditado.tipo_os = null;
    }
    if (atendimentoEditado.tipo === 'O') {
      atendimentoEditado.data_agenda = null;
    }
    if (atendimentoEditado.tipo !== 'A') {
      atendimentoEditado.versao_atualizacao = null;
      atendimentoEditado.os_relacionadas = null;
    }
    if (
      atendimentoEditado.tipo === 'A' &&
      (atendimentoEditado.versao_atualizacao === '' || atendimentoEditado.versao_atualizacao === null) &&
      solucionar
    ) {
      setEditar(true);
      return Swal.fire({
        icon: 'error',
        title: 'Versão de atualização não informada!',
      });
    }

    if (!registrouMudancas) {
      AlteracoesDeCamposNoAtendimento(atendimentoEditado, atendimentoDaApi, solucionar);
      setRegistrouMudancas(true);
    }

    if (atendimentoEditado.situacao !== 'F') {
      atendimentoEditado.data_alteracao = new Date();
    }
    if ((atendimentoDaApi.situacao === 'P' && atendimentoEditado.situacao === 'P') || atendimentoDaApi.situacao !== 'P') {
      delete atendimentoEditado.data_abertura;
    }
    solucionar
      ? Swal.fire({
          title: 'Deseja realmente solucionar o atendimento?',
          showDenyButton: true,
          denyButtonText: 'Não',
          confirmButtonText: 'Sim',
        }).then((result) => {
          if (result.isConfirmed) {
            AlterarAtendimentoFn(atendimentoEditado);
          } else {
            setEditar(true);
          }
        })
      : AlterarAtendimentoFn(atendimentoEditado);
  };

  const handleSuccess = ({ type, variables }) => {
    if (['F', 'E'].includes(variables.situacao) || variables.encaminhado !== atendimentoDaApi.encaminhado || type === 'cancel') {
      queryClient.setQueryData(['atendimentos'], (old) =>
        Array.isArray(old) ? old.filter((atendimento) => atendimento.id !== variables.id) : old
      );
      if (variables.situacao === 'E' && variables.tipo === 'O') {
        queryClient.invalidateQueries({ queryKey: ['ListagemOs'] });
      }
      queryClient.removeQueries({ queryKey: [`atendimento-${id}`] });
      if (variables.encaminhado !== atendimentoDaApi.encaminhado) {
        toast.fire({
          icon: 'success',
          title: 'Atendimento encaminhado com sucesso!',
        });
      }
      return navigate(ROUTE_ATENDIMENTOS);
    }
    queryClient.setQueryData([`atendimento-${id}`], Object.assign(atendimentoDaApi, variables));
    queryClient.setQueryData(['atendimentos'], (old) =>
      old?.map((atendimento) => {
        if (atendimento.id === variables.id) {
          return { ...atendimento, ...variables };
        }
        return atendimento;
      })
    );

    navigate(ROUTE_ATENDIMENTOS);
  };

  const { mutateAsync: AlterarAtendimentoFn } = useMutation({
    mutationFn: () => AlterarAtendimento(atendimentoEditado),
    onSuccess: (_, variables) => handleSuccess({ type: 'edit', variables }),
    onError: (error) => {
      setEditar(true);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao editar o atendimento!',
        text:
          error.response ||
          error ||
          error.message ||
          error.data ||
          error.response.data ||
          error.response.data.message ||
          'Erro não identificado',
      });
    },
  });

  const { mutateAsync: CancelamentoAtendimentoFn } = useMutation({
    mutationFn: async (result) =>
      await AlterarAtendimento({
        id: atendimentoDaApi.id,
        situacao: 'C',
        id_cliente: atendimentoDaApi.id_cliente,
        solucao: result.value,
        cliente: atendimentoDaApi.cliente,
        id_equipe_suporte: idUsuario,
        id_equipe_lancamento: atendimentoDaApi.id_equipe_lancamento,
      }),
    onSuccess: () => {
      Swal.fire({
        icon: 'success',
        title: 'Cancelamento efetuado!',
        confirmButtonText: 'OK',
        preConfirm: () => handleSuccess({ type: 'cancel', variables: { id: atendimentoDaApi.id, situacao: 'C' } }),
      });
    },
    onError: (error) => {
      Swal.fire({
        icon: 'error',
        title: error.message,
      });
    },
  });

  const enterSolucionar = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      const checkbox = document.getElementById('solucionar');
      checkbox.checked = !checkbox.checked;

      setSolucionar(checkbox.checked);
      if (checkbox.checked === true) {
        AtualizacaoDoAtendimento('situacao', 'F');
      }
      if (checkbox.checked === false) {
        AtualizacaoDoAtendimento('situacao', atendimentoDaApi.situacao);
      }
    }
  };

  const cancelarVoltar = (e) => {
    e.preventDefault();
    navigate(ROUTE_ATENDIMENTOS);
  };

  const cancelarAtendimento = () => {
    Swal.fire({
      title: 'Deseja cancelar o atendimento?',
      text: 'Informe o motivo:',
      input: 'text',
      inputValidator: (value) => {
        if (!value) {
          return 'Precisa informar um motivo.';
        }
      },
      showDenyButton: true,
      denyButtonText: 'Não',
      confirmButtonText: 'Sim',
    }).then(async (result) => {
      if (result.isConfirmed) {
        await CancelamentoAtendimentoFn(result);
      }
    });
  };
  const closeAlertaCard = (id) => {
    setAlertas(alertas.filter((x) => x.id !== id));
  };

  const toogleAlerts = () => {
    if (
      alertas !== atendimentoDaApi.alertas_ativos_cliente &&
      Array.isArray(atendimentoDaApi.alertas_ativos_cliente) &&
      atendimentoDaApi.alertas_ativos_cliente.length > 0
    ) {
      setAlertas(atendimentoDaApi.alertas_ativos_cliente);
    }
    setShowAlerts(true);
  };

  function fecharModalUpload() {
    setModalIsOpen(false);
  }
  function abrirModalUpload() {
    setModalIsOpen(true);
  }

  const formatTelefone = () => {
    const tel1 = atendimentoDaApi.telefone1 ? maskphone(atendimentoDaApi.telefone1) : null;
    const tel2 = atendimentoDaApi.telefone2 ? maskphone(atendimentoDaApi.telefone2) : null;
    let string = new String();
    if (tel1) string = tel1;
    if (tel2 && !tel1) string = tel2;
    if (tel2 && tel1) string += ' / ' + tel2;
    return string;
  };

  useEffect(() => {
    const validPermission = PermissionValidate(atendimentoDaApi.id_equipe_suporte);

    if (validPermission && atendimentoDaApi.situacao !== 'F') {
      setEditar(true);
    }
    if (['F', 'C', 'E', 'A'].includes(atendimentoDaApi.situacao)) {
      setEditar(false);
    }
  }, [atendimentoDaApi]);

  useEffect(() => {
    setAtendimentoEditado((anterior) => ({
      ...anterior,
      cliente,
      id_cliente: clienteId,
    }));
  }, [cliente, clienteId]);

  useEffect(() => {
    if (atendimentoDaApi.hasOwnProperty('ocorrencia')) {
      const arraySeparado = atendimentoDaApi.ocorrencia.split(/\n(?=\d{2}\/\d{2}\/\d{4})/);
      const objeto = arraySeparado.map((entry) => {
        const [datetime, nome, ...texto] = entry.split(' | ');
        return { data: datetime, nome, texto: texto.join(' | ') };
      });
      setOcorrencia(objeto);
    }
  }, [atendimentoDaApi]);

  useEffect(() => {
    if (!isLoading && FetchedAtendimento) {
      setAtendimentoDaApi(FetchedAtendimento);
      setAtendimentoEditado(FetchedAtendimento);
      setCliente(FetchedAtendimento.cliente);
      setClienteId(FetchedAtendimento.id_cliente);
    }
  }, [isLoading, FetchedAtendimento]);

  useEffect(() => {
    if (solucionar && texto.length >= 400) {
      setTexto(texto.slice(0, 400));
    }
    montagemDaOcorrencia(solucionar);
  }, [solucionar, texto]);

  useEffect(() => {
    AtualizacaoDoAtendimento('ocorrencia', `${atendimentoDaApi.ocorrencia + registrarOcorrenciaTexto}`);
  }, [solucionar, texto, registrarOcorrenciaTexto]);

  function dataFormatada(data) {
    return moment(data).format('YYYY-MM-DDTHH:mm');
  }

  function setSolucionarAtendimento() {
    const situacaoSolucionar = atendimentoEditado.tipo === 'O' ? 'E' : 'F';
    AtualizacaoDoAtendimento('situacao', situacaoSolucionar);
    AtualizacaoDoAtendimento('id_equipe_suporte', idUsuario);
  }

  return (
    <>
      <ModalUploadArquivos isOpen={modalIsOpen} onRequestClose={fecharModalUpload} atendimento={atendimentoDaApi} id={id} />
      <ModalInfoOs onRequestClose={modalClose} isOpen={modalOpen} info={infoModalOs} />
      <div className="container-alerta">
        {showAlerts ? alertas.map((x) => <CardAlerta alerta={x} key={x.id} onClose={closeAlertaCard} />) : null}
      </div>

      <Titulo
        titulo={`Atendimento ${id}`}
        retorno="atendimentos"
        complementoTitulo={
          <div className="3 row w-100 m-0 p-0">
            <div className="col-4 m-0 p-0">
              <button
                className={`btn btn-outline-primary btn-alertas ${atendimentoDaApi.quantidade_anexos > 0 ? 'qty' : ''}`}
                onClick={abrirModalUpload}
                title="Enviar arquivos"
                data-count={atendimentoDaApi.quantidade_anexos}
              >
                <span>
                  <FaUpload />
                </span>
              </button>
            </div>
            <div className="col-4 m-0 p-0">
              <button className="btn btn-outline-warning btn-alertas" onClick={toogleAlerts} title="Mostrar Alertas">
                <span>
                  <FaTriangleExclamation />
                </span>
              </button>
            </div>
            <div className="col-4 m-0 p-0">
              <ModalSenhasClientes
                classeCSS="btn-edicao"
                cliente={atendimentoDaApi.cliente}
                telefones={formatTelefone()}
                senhas={atendimentoDaApi.senhas_acesso}
                title="Senhas de acesso"
              >
                <FaKey />
              </ModalSenhasClientes>
            </div>
          </div>
        }
      />
      <div className="container-form">
        <form disabled>
          <div className="informacoes-atendimento">
            <div className="row">
              <ProcuraCliente editar={editar} />
            </div>
            <div className="row mb-2">
              <div className="col">
                <div className="form-floating">
                  <input
                    onFocus={(e) => setFocus(false)}
                    onKeyDown={(e) => (e.key === 'Enter' ? e.preventDefault() : null)}
                    type="text"
                    autoComplete="off"
                    className="form-control"
                    id="contato"
                    name="contato"
                    placeholder="Contato"
                    disabled={!editar ? true : solucionar ? true : false}
                    value={atendimentoEditado.contato}
                    onChange={(e) => AtualizacaoDoAtendimento('contato', e.target.value)}
                  />
                  <label htmlFor="contato" className="obrigatorio">
                    Contato
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-floating">
                  <select
                    id="encaminhado floatingSelectGrid"
                    onFocus={(e) => setFocus(false)}
                    className="form-select mb-2"
                    aria-label="Selecionar"
                    onChange={(e) => {
                      AtualizacaoDoAtendimento('id_equipe_suporte', parseInt(e.target.value, 10));
                    }}
                    disabled={!editar ? true : solucionar ? true : false}
                    value={atendimentoEditado.id_equipe_suporte}
                  >
                    {usuarios.map((usuario) => {
                      return (
                        <option key={usuario.ID} value={usuario.ID}>
                          {usuario.NOME}
                        </option>
                      );
                    })}
                  </select>
                  <label htmlFor="encaminhado" className="obrigatorio">
                    Encaminhar
                  </label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="form-floating">
                  <select
                    id="situacao floatingSelectGrid"
                    className="form-select mb-2"
                    aria-label="Selecionar"
                    onChange={(e) => AtualizacaoDoAtendimento('situacao', e.target.value)}
                    disabled={!editar ? true : solucionar ? true : false}
                    value={atendimentoEditado.situacao}
                  >
                    <option value="P" disabled>
                      Pendente
                    </option>
                    <option value="W">Aguardando</option>
                    {atendimentoEditado.tipo === 'O' ? (
                      <>
                        <option value="T">Desenvolvendo</option>
                        <option disabled value="E">
                          Teste
                        </option>
                        <option disabled value="A">
                          Atualização
                        </option>
                      </>
                    ) : (
                      <option value="T">Atendendo</option>
                    )}
                    <option disabled value="F">
                      Finalizado
                    </option>
                    <option disabled value="C">
                      Cancelado
                    </option>
                  </select>
                  <label htmlFor="situacao" className="">
                    Situacao
                  </label>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="form-floating">
                  <select
                    id="tipo"
                    className="form-select mb-2"
                    aria-label="tipo"
                    onChange={(e) => AtualizacaoDoAtendimento('tipo', e.target.value)}
                    disabled={!editar ? true : solucionar ? true : ['O'].includes(atendimentoDaApi.tipo) ? true : false}
                    value={atendimentoEditado.tipo}
                  >
                    <option value="R">Acesso Remoto</option>
                    <option value="T">Telefone</option>
                    <option value="L">Visita ao local</option>
                    <option value="O">Alteração do Sistema</option>
                    <option value="A">Atualização de Sistema</option>
                  </select>
                  <label>Tipo</label>
                </div>
              </div>
            </div>
            {atendimentoEditado.tipo === 'A' ? (
              <div className="row">
                <div className="col-12 col-md-6">
                  <div className="form-floating">
                    <input
                      onFocus={(e) => setFocus(false)}
                      onKeyDown={(e) => (e.key === 'Enter' ? e.preventDefault() : null)}
                      type="text"
                      autoComplete="off"
                      className="form-control"
                      id="versao"
                      name="versao"
                      placeholder="versao"
                      disabled={!editar ? true : false}
                      value={maskVersao(atendimentoEditado.versao_atualizacao)}
                      onChange={(e) => AtualizacaoDoAtendimento('versao_atualizacao', e.target.value)}
                    />
                    <label htmlFor="versao" className="obrigatorio">
                      Versão
                    </label>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <label htmlFor="dropdown">Lista de OS Relacionadas</label>
                  <div className="dropdown">
                    <button
                      className="btn dropdown-toggle nowrap"
                      type="button"
                      data-bs-auto-close="outside"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      disabled={!editar ? true : solucionar ? true : false}
                    >
                      {atendimentoEditado.hasOwnProperty('os_relacionadas') && atendimentoEditado.os_relacionadas.length === 0
                        ? 'Selecione'
                        : atendimentoEditado.os_relacionadas.join(', ').replace(/,(?=[^,]*$)/, ' e')}
                    </button>
                    <ul className="dropdown-menu" style={{ zIndex: 1002 }}>
                      {!isLoadingOS && !isFetchingOS ? (
                        !isErrorOS ? (
                          listaOsSelecionar.length === 0 ? (
                            <li className="dropdown-item">
                              <label htmlFor="os">Nenhuma OS encontrada</label>
                            </li>
                          ) : (
                            listaOsSelecionar?.map((os) => (
                              <li className="dropdown-item" key={os.id}>
                                <input
                                  id={os.id}
                                  type="checkbox"
                                  className="me-1"
                                  checked={
                                    atendimentoEditado.os_relacionadas.findIndex((x) => Number(x) === Number(os.id)) !== -1 ? true : false
                                  }
                                  value={os.id}
                                  onChange={addLista}
                                />
                                <label htmlFor={os.id}>
                                  <strong>{os.id}</strong> - {os.cliente}
                                  <button
                                    style={{ border: 0, backgroundColor: 'transparent' }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      openModal(os);
                                    }}
                                  >
                                    <FaCircleInfo />
                                  </button>
                                </label>
                              </li>
                            ))
                          )
                        ) : (
                          <li className="dropdown-item">
                            <label htmlFor="os">Houve um erro ao buscar a listagem de OS</label>{' '}
                          </li>
                        )
                      ) : (
                        <li className="dropdown-item">
                          <label htmlFor="os">Carregando...</label>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            ) : null}
            <div className="row">
              {atendimentoEditado.tipo === 'O' ? (
                <div className="col-12 col-md-6">
                  <div className="form-floating">
                    <select
                      id="Tipo-OS"
                      className="form-select mb-2"
                      aria-label="tipo-os"
                      onChange={(e) => AtualizacaoDoAtendimento('tipo_os', e.target.value)}
                      disabled={!editar ? true : solucionar ? true : false}
                      value={atendimentoEditado.tipo_os}
                    >
                      <option value="0" disabled={atendimentoDaApi.tipo === 'O'}>
                        Não se aplica
                      </option>
                      <option value="A">Alteração</option>
                      <option value="E">Erro</option>
                    </select>
                    <label>Tipo O.S.</label>
                  </div>
                </div>
              ) : (
                <div className="col-12 col-md-6">
                  <div className="form-floating">
                    <input
                      onChange={(e) => AtualizacaoDoAtendimento('data_agenda', e.target.value)}
                      value={dataFormatada(atendimentoEditado.data_agenda)}
                      type="datetime-local"
                      className="form-control mb-2"
                      id="edDataInicial"
                      disabled={!editar ? true : solucionar ? true : false}
                      placeholder=""
                    />
                    <label htmlFor="edDataInicial">Data de Agendamento</label>
                  </div>
                </div>
              )}
            </div>
            <div className="row">
              <div className="">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    role="switch"
                    id="solucionar"
                    onChange={(e) => {
                      setSolucionar(e.target.checked);
                      if (e.target.checked === true) {
                        setSolucionarAtendimento();
                      }
                      if (e.target.checked === false) {
                        AtualizacaoDoAtendimento('situacao', atendimentoDaApi.situacao);
                      }
                    }}
                    onKeyDown={enterSolucionar}
                    disabled={editar ? (atendimentoDaApi.encaminhado === localStorage.getItem('sessionNomeUsuario') ? false : true) : true}
                  />
                  <label className="form-check-label" htmlFor="solucionar">
                    Solucionar
                  </label>
                </div>
              </div>
              <div className="">
                <label htmlFor="ocorrencia" className="obrigatorio">
                  {solucionar ? 'Solução:' : 'Complementar:'}
                </label>
                <textarea
                  className="form-control"
                  id="texto"
                  rows={4}
                  value={texto}
                  maxLength={solucionar ? 400 : 1000}
                  onChange={(e) => {
                    setTexto(e.target.value);
                  }}
                  onFocus={(e) => setFocus(false)}
                  disabled={editar ? false : true}
                />
                <div>
                  {solucionar && texto.length >= 400 ? (
                    <small className="text-danger">Limite de 400 caracteres</small>
                  ) : !solucionar && texto.length >= 1000 ? (
                    <small className="text-danger">Limite de 1000 caracteres</small>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-12 mt-3 d-flex justify-content-center align-items-center div-botoes">
              <input
                className="btn btn-outline-primary"
                type="submit"
                disabled={editar ? false : true}
                onClick={(e) => EditarAtendimentoRequest(e)}
              />
              <button onClick={(e) => cancelarVoltar(e)} className="btn btn-outline-danger">
                Voltar
              </button>
            </div>
          </div>
        </form>
        <div>
          <div className="div-timeline">
            <div className="row">
              {ocorrencia.length > 0
                ? ocorrencia.map((item, index, array) => {
                    if (index === 0) {
                      return <Timeline posicao="P" encaminhado={item.nome} key={item.data} ocorrencia={item.texto} data={item.data} />;
                    }
                    if (index === array.length - 1 && !['F', 'E', 'A', 'C'].includes(atendimentoDaApi.situacao)) {
                      return <Timeline posicao="U" encaminhado={item.nome} key={item.data} ocorrencia={item.texto} data={item.data} />;
                    }
                    return <Timeline encaminhado={item.nome} key={item.data} ocorrencia={item.texto} data={item.data} />;
                  })
                : null}
              {atendimentoDaApi.hasOwnProperty('solucao') && atendimentoDaApi.solucao.length > 0 ? (
                <Timeline
                  posicao={['F', 'E', 'A'].includes(atendimentoDaApi.situacao) ? 'S' : 'C'}
                  encaminhado={atendimentoDaApi.encaminhado}
                  ocorrencia={atendimentoDaApi.solucao}
                  data={atendimentoDaApi.data_fechamento}
                />
              ) : null}
            </div>
          </div>
          <div className="row my-3">
            <div className="col-12 mt-3 d-flex justify-content-center align-itens-center">
              {['F', 'E', 'A', 'C'].includes(atendimentoDaApi.situacao) ? null : (
                <button className="btn btn-danger excluir" onClick={cancelarAtendimento} disabled={editar ? false : true}>
                  <FaTrashCan /> Cancelar
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
