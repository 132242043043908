import React, { useContext, useEffect, useState } from 'react';
import { UsuariosContext } from '../../../context/usersContext.jsx';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getFeriadosDoAno } from '../../../service/fetchers/calendario.js';
import LoadingComponent from '../../loading-component/index.jsx';
import { formataData } from '../../../util/maskdoc.js';
import { FaChevronDown, FaCircleXmark, FaPenToSquare } from 'react-icons/fa6';
import { InsertCalendario, editarEventoCalendario } from '../../../service/mutations/calendario.js';
import Swal from 'sweetalert2';
import { GetUfs } from '../../../service/fetchers/clientes.js';
import { toast } from '../../../constants/toast.jsx';

function AbaAdicionar() {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const [descricao, setDescricao] = useState('');
  const [dia, setDia] = useState(new Date().toDateString());
  const [jornadaTrabalho, setJornadaTrabalho] = useState(0);
  const [feriado, setFeriado] = useState(false);
  const [tipo, setTipo] = useState('');
  const [usuariosSelecionados, setUsuariosSelecionados] = useState([]);
  const [listagemFeriados, setListagemFeriados] = useState([]);
  const [erros, setErros] = useState([]);
  const [ano, setAno] = useState(new Date().getFullYear());
  const [uf, setUf] = useState('RJ');
  const [ufFetched, setUfFetched] = useState('');

  const { usuarios } = useContext(UsuariosContext);

  const dicionarioTipoFeriado = {
    N: 'Nacional',
    E: 'Estadual',
    M: 'Municipal',
    F: 'Facultativo',
  };

  function alterarJornada(valor, item) {
    const rawValue = valor.replace(/\D/g, '');
    item.jornada = formatTimeForInput(rawValue);
    setListagemFeriados((prevState) => {
      const index = prevState.findIndex((x) => x.dia === item.dia && x.descricao === item.descricao && x.tipo === item.tipo);
      prevState[index] = item;
      return [...prevState];
    });
  }

  function formatTime(time) {
    time = time.replace(/\D/g, '');
    const pad = (n) => n.toString().padStart(2, '0');
    const seconds = time.slice(-2);
    const minutes = time.slice(-4, -2);
    const hours = time.slice(-6, -4);
    return `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
  }

  function retornaDiaSemana(data) {
    const dias = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];
    return dias[new Date(data).getDay()];
  }

  const { mutateAsync: InserirEventoCalendario } = useMutation({
    mutationFn: (evento) => InsertCalendario(evento),
    onSuccess: (_, variable) => {
      toast.fire({
        icon: 'success',
        title: 'Evento inserido com sucesso!',
      });

      const cachedData = queryClient.getQueryData(['eventos', 'feriados', ano, ufFetched]);
      const filteredData = cachedData?.filter(
        (x) => x.dia !== variable.dia || x.descricao !== variable.descricao || x.tipo !== variable.tipo
      );
      queryClient.setQueryData(['eventos', 'feriados', ano, ufFetched], filteredData);
      setListagemFeriados(filteredData);
    },
    onError: (error) => {
      setErros((prevState) => [...prevState, error]);
    },
  });

  const { mutateAsync: EditarEventoCalendario } = useMutation({
    mutationFn: (evento) => editarEventoCalendario(evento),
    onSuccess: (_, variable) => {
      toast.fire({
        icon: 'success',
        title: 'Evento atualizado com sucesso!',
      });
      queryClient.setQueryData(['eventos', 'feriados', ano, ufFetched], (oldData) => {
        return oldData.map((x) => {
          if (x.dia === variable.dia && x.descricao === variable.descricao && x.tipo === variable.tipo)
            return { ...variable, existeNoDb: true };
          return x;
        });
      });
    },
    onError: (error) => {
      setErros((prevState) => [...prevState, error]);
    },
  });

  async function buscarFeriadosUfAno() {
    setIsLoading(true);
    const result = await queryClient.fetchQuery({
      queryKey: ['eventos', 'feriados', ano, uf],
      queryFn: () => getFeriadosDoAno({ ano, uf }),
    });

    if (result) {
      setListagemFeriados(result);
      setIsLoading(false);
      setUfFetched(uf);
    }
  }

  function formatTimeForInput(time) {
    time = time.replace(/\D/g, '');
    const pad = (n) => n.toString().padStart(2, '0');
    const seconds = time.slice(-2);
    const minutes = time.slice(-4, -2);
    const hours = time.slice(-6, -4);
    return `${pad(hours)}h ${pad(minutes)}m ${pad(seconds)}s`;
  }

  function converteMinutosParaHora(minutos, req = false) {
    const horas = Math.floor(minutos / 60);
    const minutosRestantes = minutos % 60;
    if (req) return `${horas.toString().padStart(2, 0)}:${minutosRestantes.toString().padStart(2, 0)}:00`;
    return `${horas.toString().padStart(2, 0)}h ${minutosRestantes.toString().padStart(2, 0)}m`;
  }

  const { data: listaUF } = useQuery({
    queryKey: ['ufs'],
    queryFn: GetUfs,
    staleTime: Infinity,
  });

  function addUsuariosEvento(e, item) {
    const id = e.target.value || e;
    if (id === '0') {
      if (item.usuarios?.length === usuarios?.filter((x) => x.registra_ponto === 1).length) {
        item.usuarios = [];
      } else {
        item.usuarios = usuarios?.filter((x) => x.registra_ponto === 1).map((x) => x.ID);
      }
    } else if (id !== '0') {
      if (item.usuarios?.findIndex((x) => Number(x) === Number(id)) === -1) {
        item.usuarios = [...item.usuarios, id];
      } else {
        item.usuarios = item.usuarios.filter((x) => Number(x) !== Number(id));
      }
    }

    setListagemFeriados((prevState) => {
      const index = prevState.findIndex((x) => x.dia === item.dia && x.descricao === item.descricao && x.tipo === item.tipo);
      prevState[index] = item;
      return [...prevState];
    });
  }

  function addUsuariosForm(e) {
    const id = e.target.value;
    if (id === '0') {
      if (usuariosSelecionados.length === usuarios?.filter((x) => x.registra_ponto === 1).length) {
        setUsuariosSelecionados([]);
      } else {
        setUsuariosSelecionados(usuarios?.filter((x) => x.registra_ponto === 1).map((x) => x.ID));
      }
    } else if (id !== '0') {
      if (usuariosSelecionados.findIndex((x) => Number(x) === Number(id)) === -1) {
        setUsuariosSelecionados((prevState) => [...prevState, id]);
      } else {
        setUsuariosSelecionados((prevState) => prevState.filter((x) => Number(x) !== Number(id)));
      }
    }
  }

  function removerFeriadoListagem(feriado) {
    setListagemFeriados((prevState) =>
      prevState.filter(
        (x) =>
          x.dia !== feriado.dia ||
          String(x.descricao).toLowerCase() !== String(feriado.descricao).toLowerCase() ||
          String(x.tipo).toLowerCase() !== String(feriado.tipo).toLowerCase()
      )
    );
  }

  function ReqListagemFeriados() {
    listagemFeriados.forEach(async (element) => {
      if (element.existeNoDb) return;
      if (!element.existeNoDb && element.calendarioId) {
        return await EditarEventoCalendario({
          calendarioId: element.calendarioId,
          descricao: element.descricao,
          dia: element.dia,
          jornada: formatTime(element.jornada),
          tipo: element.tipo,
          usuarios: element.usuarios,
          feriado: true,
        });
      }
      await InserirEventoCalendario({
        descricao: element.descricao,
        dia: element.dia,
        jornada: formatTime(element.jornada),
        tipo: element.tipo,
        usuarios: element.usuarios,
        feriado: true,
      });
    });
  }

  function setFeriadoParaEditar(feriado) {
    setListagemFeriados((prevState) => {
      const index = prevState.findIndex((x) => x.dia === feriado.dia && x.descricao === feriado.descricao && x.tipo === feriado.tipo);
      prevState[index].existeNoDb = false;
      return [...prevState];
    });
  }

  function inserirEventoPersonalizado() {
    if (feriado && tipo === 0) {
      return Swal.fire({
        title: 'Erro ao inserir evento',
        text: 'Evento é um feriado e o tipo não foi selecionado.',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
    }

    InserirEventoCalendario({
      descricao,
      dia,
      jornada: converteMinutosParaHora(jornadaTrabalho, true),
      tipo: feriado ? tipo : null,
      usuarios: usuariosSelecionados,
      feriado,
    });

    queryClient.invalidateQueries({
      queryKey: ['eventos', 'listagem'],
    });

    setDescricao('');
    setDia('');
    setJornadaTrabalho(0);
    setFeriado(false);
    setTipo('');
    setUsuariosSelecionados([]);
  }

  useEffect(() => {
    if (erros.length > 0) {
      Swal.fire({
        title: 'Erro ao inserir alguns eventos',
        text: erros[0],
        icon: 'error',
        confirmButtonText: 'Ok',
      });

      setErros([]);
    }
  }, [erros]);

  useEffect(() => {
    buscarFeriadosUfAno();
  }, []);

  return (
    <>
      <div className="pt-3 row text-center">
        <h5 className="text-black">Adicionar Eventos</h5>
      </div>
      <div className="my-4">
        <div className="border-top border-secondary-subtle">
          <span className="fs-5 text text-decoration-none">Inserir Jornada de Trabalho</span>
        </div>
        <div className="row">
          <div className="col-4 mb-3">
            <label htmlFor="descricao" className="input-label">
              Descrição
            </label>
            <input id="descricao" type="text" className="form-control" onChange={(e) => setDescricao(e.target.value)} />
          </div>
          <div className="col-4 mb-3">
            <label htmlFor="dia" className="input-label">
              Dia
            </label>
            <input id="dia" type="date" className="form-control" onChange={(e) => setDia(e.target.value)} />
          </div>
          <div className="col-4 mb-3">
            <label htmlFor="tempo-trabalho" className="input-label">
              Jornada de Trabalho: {converteMinutosParaHora(jornadaTrabalho)}
            </label>
            <input
              id="tempo-trabalho"
              type="range"
              value={jornadaTrabalho}
              max={480}
              min={0}
              className="form-control"
              onChange={(e) => setJornadaTrabalho(e.target.value)}
            />
          </div>
          <div className="col-4 mb-3 d-flex align-self-end">
            <div className="form-check form-switch">
              <label htmlFor="feriado" className="input-label">
                Feriado
              </label>
              <input
                id="feriado"
                type="checkbox"
                role="switch"
                className="form-check-input"
                value={feriado}
                onChange={(e) => setFeriado(e.target.checked)}
              />
            </div>
          </div>
          <div className="col-4" hidden={!feriado}>
            <label htmlFor="tipo" className="input-label">
              Tipo
            </label>
            <select id="tipo" className="form-select" value={tipo} onChange={(e) => setTipo(e.target.value)}>
              <option value="">Selecione um tipo</option>
              <option value="N">Nacional</option>
              <option value="E">Estadual</option>
              <option value="M">Municipal</option>
              <option value="F">Facultativo</option>
            </select>
          </div>

          <div className="col-4 d-flex">
            <button
              className="btn dropdown-toggle nowrap align-self-center"
              type="button"
              data-bs-auto-close="outside"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {usuariosSelecionados.length > 0 ? `${usuariosSelecionados.length} usuários selecionados.` : 'Selecione os usuários'}
            </button>
            <ul className="dropdown-menu">
              <li className="dropdown-item d-flex justify-content-center">
                <button style={{ border: 'none', background: 'none' }} type="button" className="me-1" value={0} onClick={addUsuariosForm}>
                  {usuariosSelecionados.length === usuarios?.filter((x) => x.registra_ponto === 1).length
                    ? 'Desmarcar todos'
                    : 'Marcar todos'}
                </button>
              </li>
              {usuarios?.map((usuario) => {
                if (usuario.registra_ponto === 1)
                  return (
                    <li className="dropdown-item" key={usuario.ID}>
                      <input
                        id={usuario.ID}
                        type="checkbox"
                        className="me-1"
                        checked={usuariosSelecionados.findIndex((x) => Number(x) === Number(usuario.ID)) !== -1 ? true : false}
                        value={usuario.ID}
                        onChange={addUsuariosForm}
                      />
                      <label htmlFor={usuario.ID}>{usuario.NOME}</label>
                    </li>
                  );

                return null;
              })}
            </ul>
          </div>
        </div>
        <div className="row justify-content-center mt-4">
          <div className="w-25 d-flex justify-content-center align-content-center">
            <button className="btn btn-primary" onClick={inserirEventoPersonalizado}>
              Enviar
            </button>
          </div>
        </div>
      </div>
      <div className="row my-4">
        <div className="border-top border-secondary-subtle">
          <a
            href="#buscaapi"
            className="fs-5 text text-decoration-none"
            data-bs-toggle="collapse"
            aria-expanded="false"
            aria-controls="buscaapi"
          >
            Buscar feriados <FaChevronDown />
          </a>
        </div>
        <div className="collapse" id="buscaapi">
          <div className="row">
            <div className="col-3">
              <label htmlFor="ano" className="input-label">
                Ano
              </label>
              <input
                id="ano"
                type="number"
                className="form-control"
                value={ano}
                max={2099}
                min={2000}
                onChange={(e) => setAno(e.target.value)}
              />
            </div>
            <div className="col-3">
              <label htmlFor="estado" className="input-label">
                Estado
              </label>
              <select id="estado" className="form-select" value={uf} onChange={(e) => setUf(e.target.value)}>
                <option value={0}>Selecione um estado</option>
                {listaUF?.map((u, index) => (
                  <option value={u.uf} key={u.id + String(index)}>
                    {u.uf}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-4 align-content-end">
              <button className="btn btn-primary" onClick={buscarFeriadosUfAno}>
                Buscar
              </button>
            </div>
          </div>

          <div className="row justify-content-center">
            {isLoading ? (
              <LoadingComponent />
            ) : listagemFeriados?.length === 0 ? (
              <div>
                <p>Nenhum feriado encontrado</p>
              </div>
            ) : (
              <div className="table-responsive">
                <table className="table table-hover">
                  <thead>
                    <tr>
                      <th>Descrição</th>
                      <th>Dia</th>
                      <th>Tipo</th>
                      <th>Jornada</th>
                      <th>Usuários</th>
                      <th>Ações</th>
                    </tr>
                  </thead>
                  <tbody className="table-light">
                    {listagemFeriados.map((feriado, index) => (
                      <tr
                        key={feriado.dia + index}
                        className={!feriado.existeNoDb ? 'table-danger' : ''}
                        title={
                          !feriado.existeNoDb ? 'Feriado não registrado na base de dados' : 'Feriado já está registrado na base da dados'
                        }
                      >
                        <td>{feriado.descricao}</td>
                        <td>
                          {formataData(feriado.dia)} {retornaDiaSemana(feriado.dia)}
                        </td>
                        <td>{dicionarioTipoFeriado[feriado.tipo]}</td>
                        <td className="">
                          <input
                            type="text"
                            className="form-control"
                            value={formatTimeForInput(feriado.jornada)}
                            onChange={(e) => alterarJornada(e.target.value, feriado)}
                            disabled={feriado.existeNoDb}
                          />
                        </td>
                        <td>
                          {feriado.tipo === 'N' ? (
                            'Todos Usuários'
                          ) : (
                            <>
                              <button
                                className="btn dropdown-toggle nowrap align-self-center"
                                type="button"
                                data-bs-auto-close="outside"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                {feriado.usuarios?.length > 0
                                  ? `${feriado.usuarios.length} usuários selecionados.`
                                  : feriado.existeNoDb
                                  ? 'Nenhum usuário selecionado'
                                  : 'Selecione os usuários'}
                              </button>
                              <ul className="dropdown-menu">
                                <li className="dropdown-item d-flex justify-content-center">
                                  <button
                                    id="selecionarTodos"
                                    style={{ border: 'none', background: 'none' }}
                                    type="button"
                                    className="me-1"
                                    value={0}
                                    onClick={(e) => addUsuariosEvento(e, feriado)}
                                    disabled={feriado.existeNoDb}
                                  >
                                    {feriado.usuarios?.length === usuarios?.filter((x) => x.registra_ponto === 1).length
                                      ? 'Desmarcar todos'
                                      : 'Marcar todos'}
                                  </button>
                                </li>
                                {usuarios?.map((usuario) => {
                                  if (usuario.registra_ponto === 1)
                                    return (
                                      <li className="dropdown-item" key={usuario.ID}>
                                        <input
                                          id={usuario.ID}
                                          type="checkbox"
                                          className="me-1"
                                          checked={
                                            feriado.usuarios?.findIndex((x) => Number(x) === Number(usuario.ID)) !== -1 ? true : false
                                          }
                                          value={usuario.ID}
                                          onChange={(e) => addUsuariosEvento(e, feriado)}
                                          disabled={feriado.existeNoDb}
                                        />
                                        <label htmlFor={usuario.ID}>{usuario.NOME}</label>
                                      </li>
                                    );

                                  return null;
                                })}
                              </ul>
                            </>
                          )}
                        </td>
                        <td>
                          {feriado.existeNoDb ? (
                            <button
                              className={`btn btn-outline-primary  border-0`}
                              title="Atualizar"
                              onClick={() => setFeriadoParaEditar(feriado)}
                            >
                              <FaPenToSquare />
                            </button>
                          ) : (
                            <div className="col d-flex align-items-center justify-content-start">
                              <button
                                className={`btn btn-outline-danger  border-0`}
                                onClick={() => removerFeriadoListagem(feriado)}
                                title="Excluir"
                              >
                                <FaCircleXmark />
                              </button>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <div className="row justify-content-center mt-4">
                  <div className="w-25 d-flex justify-content-center align-content-center">
                    <button className="btn btn-primary" onClick={ReqListagemFeriados}>
                      Salvar
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default AbaAdicionar;
