import { createContext, useContext } from 'react';
import api from '../service/api.js';
import { ST__TOKEN, ST__ULT_REQ_SIT_EQUIPE } from '../constants/storagekey.js';
import { UsuarioContext } from './userContext.jsx';
import { useQuery } from '@tanstack/react-query';

export const SituacaoEquipeContext = createContext({
  situacaoEquipe: null,
  setManualRefresh: () => undefined,
  loading: null,
});

export function SituacaoEquipeContextProvider({ children }) {
  const { tokenUsuario } = useContext(UsuarioContext);

  async function getSituacaoUsuarios() {
    try {
      const result = await api
      .get('/v1/situacao/usuarios')
      
      const time = new Date().getTime().toString();
      localStorage.setItem(ST__ULT_REQ_SIT_EQUIPE, time);
      return result.data;
      
    } catch (error) {
      localStorage.removeItem(ST__ULT_REQ_SIT_EQUIPE);
      throw new Error(error);
    }
  }

  const {data: situacaoEquipe, isLoading: loading, refetch } = useQuery({
    queryKey: ['situacaoUsuarios'],
    queryFn: getSituacaoUsuarios,
    enabled: !!tokenUsuario || !!localStorage.getItem(ST__TOKEN),
    staleTime:Infinity
  })


  return (
    <SituacaoEquipeContext.Provider
      value={{
        situacaoEquipe,
        setManualRefresh: refetch,
        loading,
      }}
    >
      {children}
    </SituacaoEquipeContext.Provider>
  );
}
