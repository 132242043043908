import React from "react";
import "./style.css";
import Logo from '../../assets/404.png';
import { Link } from "react-router-dom";

function Page404() {
  return (
    <main className="main">
      <section className="page404">
        <div className="page404__container page404__container">
          <div className="page404__data">
            <span className="page404__subtitle">Erro 404</span>
            <h1 className="page404__title">Página não encontrada!</h1>
            <p className="page404__description">
              Não conseguimos encontrar a página <br /> que você está procurando.
            </p>
            <Link to="/" className="page404__button">
              Voltar
            </Link>
          </div>
          <div className="page404__img">
            <img src={Logo} alt />
            <div className="page404__shadow" />
          </div>
        </div>
        <footer className="page404__footer">
          <span>desenvolvimento@cm-sistemas.com</span>
        </footer>
      </section>
    </main>
  );
}

export default Page404;
