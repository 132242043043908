import React, { useContext } from 'react';
import DashBoardInicial from '../../components/dashboard';
import './style.css';
import { UsuariosContext } from '../../context/usersContext.jsx';

function Dashboard() {
  const { usuarios } = useContext(UsuariosContext);

  return (
    <div>
      <div className="container shadow rounded-2 mt-3">
        <DashBoardInicial usuarios={usuarios} />
      </div>
    </div>
  );
}

export default Dashboard;
