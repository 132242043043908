import Modal from 'react-modal/lib/components/Modal';
import Close from '../../assets/close.png';
import React, { useState } from 'react';
import styles from './modalSenha.module.css';

export default function ModalSenhasClientes(props) {
  const [open, setOpen] = useState(false);

  function closeModal() {
    setOpen(false);
  }

  const ModalSenhas = (props) => {
    const maxHeight = window.innerHeight * 0.75;

    const handleDoubleClick = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Modal
        isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
        preventScroll={false}
        overlayClassName="react-modal-overlay"
        className="react-modal-content"
      >
        <button type="button" onClick={props.onRequestClose} className="react-modal-close">
          <img src={Close} alt="Fechar" />
        </button>
        <div className={`container-fluid`} onDoubleClick={handleDoubleClick}>
          <div className="col-12 mt-0">
            <h4 className="mt-2-mb-4">Senhas do Cliente</h4>
            <h6>{props.cliente}</h6>
            <span>{props.tel}</span>
          </div>

          <div className={styles.infoContainer} style={{ maxHeight: maxHeight }} onDoubleClick={handleDoubleClick}>
            <pre style={{ whiteSpace: 'pre-wrap' }}>{props.senhas}</pre>
          </div>
        </div>
      </Modal>
    );
  };

  return (
    <>
      <button className={props.classeCSS} onClick={() => setOpen(true)}>
        {props.children}
      </button>
      <ModalSenhas tel={props.telefones} isOpen={open} onRequestClose={closeModal} senhas={props.senhas} cliente={props.cliente} />
    </>
  );
}
