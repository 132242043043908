import React from 'react';
import styles from './table.module.css'

function ClientesInadimplentesTable({lista}) {
  return (
    <div className={`${styles.maxHeight}`}>
      <table className="table table-sm table-hover">
        <thead>
          <tr>
            <th scope="col">ID(cliente)</th>
            <th scope="col">Cliente</th>
            <th scope="col">Dias Inadimplência</th>
            <th scope="col">Qtde de contas</th>
          </tr>
        </thead>
        <tbody>
          {lista.map((cliente) => (
            <React.Fragment key={cliente.id_cliente}>
              <tr>
                <th scope="row">{cliente.id_cliente}</th>
                <td>{cliente.nome.length > 20 ? cliente.nome.substring(0, 100) + '...' : cliente.nome}</td>
                <td>
                  {cliente.dias_inadimplencia}
                </td>
                <td>{cliente.cnts.length}</td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ClientesInadimplentesTable;
