import { useEffect, useMemo, useState } from 'react';
import Swal from 'sweetalert2';
import UsuarioCardExpediente from './card-usuarios-expediente';
import { toast } from '../../../constants/toast.jsx';
import { FaBed, FaBusinessTime, FaClock, FaRocket, FaUtensils } from 'react-icons/fa6';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getRegistrosDia } from '../../../service/fetchers/horario.js';
import { RegistrarHorario } from '../../../service/mutations/horario.js';

const Expediente = () => {
  const queryClient = useQueryClient();
  const [inicioDisable, setInicioDisable] = useState(true);
  const [almocoDisable, setAlmocoDisable] = useState(true);
  const [retornoDisable, setRetornoDisable] = useState(true);
  const [fimDisable, setFimDisable] = useState(true);
  const [saidaExtraDisable, setSaidaExtraDisable] = useState(true);
  const [retornoSaidaExtraDisable, setRetornoSaidaExtraDisable] = useState(true);
  const [registrosDia, setRegistrosDia] = useState([]);
  const [registrosUsuario, setRegistrosUsuario] = useState([]);

  const regexHora = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}, (0[0-9]|1[0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/;

  const regex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}\.\d{3}Z$/;

  const actions = {
    'E-Expediente': (groupObj, horario) => {
      groupObj.entrada = horario;
    },
    'S-Almoço': (groupObj, horario) => {
      groupObj.almoco = horario;
    },
    'E-Almoço': (groupObj, horario) => {
      groupObj.retorno_almoco = horario;
    },
    'S-Expediente': (groupObj, horario) => {
      groupObj.saida = horario;
    },
    'S-Saida Extra': (groupObj, horario) => {
      groupObj.saida_extra = horario;
    },
    'E-Saida Extra': (groupObj, horario) => {
      groupObj.retorno_saida_extra = horario;
    },
  };

  const { data, isLoading, error } = useQuery({
    queryKey: ['registrosDia'],
    queryFn: getRegistrosDia,
    staleTime: 1000 * 60 * 15,
  });

  useEffect(() => {
    if (data && !isLoading) {
      const horariosAgrupados = groupObjectsByTipoAndMotivo(data);
      const objetoFormatado = horariosAgrupados.map((x) => {
        const newObj = {};
        for (const key in x) {
          let value = x[key];
          if (value === null) {
            value = '--:--';
          }
          if (regex.test(value)) {
            const date = new Date(value);
            value = new Intl.DateTimeFormat('pt-br', {
              dateStyle: 'short',
              timeStyle: 'medium',
              timeZone: 'America/Sao_Paulo',
            }).format(date);
          }
          newObj[key] = value;
        }
        return newObj;
      });

      setRegistrosDia(objetoFormatado);
      const nome = localStorage.getItem('sessionNomeUsuario');
      const registrosUsuarioTmp = objetoFormatado.filter((x) => x.nome === nome);
      setRegistrosUsuario(registrosUsuarioTmp);
    }
  }, [data, isLoading]);

  const groupObjectsByTipoAndMotivo = useMemo(() => {
    return (objects) => {
      return objects.reduce((result, obj) => {
        const { nome, controle, tipo, motivo, data_registro, horario_ajustado } = obj;

        let groupObj = result.find((group) => group.nome === nome && group.controle === controle);
        const horario = horario_ajustado || data_registro;

        if (!groupObj) {
          groupObj = {
            nome,
            controle,
            entrada: null,
            almoco: null,
            retorno_almoco: null,
            saida: null,
            tempo_trabalhado: null,
          };
          result.push(groupObj);
        }

        const chave = `${tipo}-${motivo}`;
        const action = actions[chave];
        if (action) {
          action(groupObj, horario);
        }

        if (groupObj.entrada && groupObj.saida) {
          const entradaTime = new Date(groupObj.entrada).getTime();
          const almocoTime = new Date(groupObj.almoco).getTime();
          const retornoAlmocoTime = new Date(groupObj.retorno_almoco).getTime();
          const saidaTime = new Date(groupObj.saida).getTime();
          const tempo_trabalhado =
            almocoTime && retornoAlmocoTime ? almocoTime - entradaTime + saidaTime - retornoAlmocoTime : saidaTime - entradaTime;
          groupObj.tempo_trabalhado = formataDuracao(tempo_trabalhado);
        }

        return result;
      }, []);
    };
  }, [data, isLoading]);

  function formataDuracao(duration) {
    const days = Math.floor(duration / (1000 * 60 * 60 * 24));
    const hours = Math.floor((duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((duration % (1000 * 60)) / 1000);

    return `${days > 0 ? days + 'd ' : ''}${hours}h ${minutes}m ${seconds}s`;
  }

  function resetButtons() {
    setInicioDisable(true);
    setAlmocoDisable(true);
    setRetornoDisable(true);
    setFimDisable(true);
    setSaidaExtraDisable(true);
    setRetornoSaidaExtraDisable(true);
  }

  const verificaHorarioDoUsuario = () => {
    if (registrosUsuario.length === 0) return;
    resetButtons();
    const { controle, saida_extra, retorno_saida_extra, saida, entrada, almoco, retorno_almoco } = registrosUsuario[0];

    if (controle === 0) {
      setInicioDisable(false);
      return;
    }

    if (regexHora.test(saida_extra) && (!retorno_saida_extra || retorno_saida_extra < saida_extra)) {
      setRetornoSaidaExtraDisable(false);
      return;
    }

    if (regexHora.test(saida)) {
      setInicioDisable(false);
      setSaidaExtraDisable(true);
      return;
    }

    if (regexHora.test(entrada) && !regexHora.test(almoco)) {
      setAlmocoDisable(false);
      setFimDisable(false);
      setSaidaExtraDisable(false);
      return;
    }

    if (regexHora.test(almoco) && !regexHora.test(retorno_almoco)) {
      setRetornoDisable(false);
      return;
    }

    if (regexHora.test(retorno_almoco) && !regexHora.test(saida)) {
      setFimDisable(false);
      setSaidaExtraDisable(false);
    }
  };

  useEffect(() => {
    verificaHorarioDoUsuario();
  }, [registrosDia]);

  const iniciarExpediente = () => {
    const texto = 'Deseja mesmo iniciar um outro controle de Expediente?';
    confirmacaoRegistro(texto, (validacao) => {
      if (validacao) {
        const registro = {
          usuario_id: localStorage.getItem('sessionIdUsuario'),
          data_registro: new Date(),
          tipo: 'E',
          motivo: 'Expediente',
          observacao: 'Início do Expediente.',
          controle: registrosUsuario[0].controle,
        };

        setInicioDisable(true);
        req(registro);
      }
    });
  };

  const iniciarAlmoco = () => {
    const texto = 'Deseja mesmo iniciar o Horário de Almoço?';
    confirmacaoRegistro(texto, (validacao) => {
      if (validacao) {
        const registro = {
          usuario_id: localStorage.getItem('sessionIdUsuario'),
          data_registro: new Date(),
          tipo: 'S',
          motivo: 'Almoço',
          observacao: 'Saída para o Almoço.',
          controle: registrosUsuario[0].controle,
        };
        req(registro);
        setInicioDisable(true);
        setAlmocoDisable(true);
      }
    });
  };

  const retornoAlmoco = () => {
    const texto = 'Deseja mesmo finalizar o Horário de Almoço?';
    confirmacaoRegistro(texto, (validacao) => {
      if (validacao) {
        const registro = {
          usuario_id: localStorage.getItem('sessionIdUsuario'),
          data_registro: new Date(),
          tipo: 'E',
          motivo: 'Almoço',
          observacao: 'Retorno do Almoço.',
          controle: registrosUsuario[0].controle,
        };
        const texto = 'Bom trabalho!';
        req(registro, texto);
        setInicioDisable(true);
        setAlmocoDisable(true);
        setRetornoDisable(true);
      }
    });
  };

  const fimExpediente = () => {
    const texto = 'Deseja mesmo finalizar o Expediente?';
    confirmacaoRegistro(texto, (validacao) => {
      if (validacao) {
        const registro = {
          usuario_id: localStorage.getItem('sessionIdUsuario'),
          data_registro: new Date(),
          tipo: 'S',
          motivo: 'Expediente',
          observacao: 'Fim do Expediente.',
          controle: registrosUsuario[0].controle,
        };
        const texto = 'Bom descanço!';
        req(registro, texto);
        setInicioDisable(true);
        setAlmocoDisable(true);
        setRetornoDisable(true);
        setFimDisable(true);
      }
    });
  };

  const saidaExtra = () => {
    Swal.fire({
      showCancelButton: true,
      title: 'Realizar saida extra',
      inputLabel: 'Insira alguma observação sobre a saida extra:',
      input: 'textarea',
      preConfirm: (texto) => {
        if (texto) {
          const registro = {
            usuario_id: localStorage.getItem('sessionIdUsuario'),
            data_registro: new Date(),
            tipo: 'S',
            motivo: 'Saida Extra',
            observacao: 'Saida Extra - ' + texto,
            controle: registrosUsuario[0].controle,
          };
          req(registro);
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Insira alguma observação!',
          });
        }
      },
    });
  };

  const retornoSaidaExtra = () => {
    Swal.fire({
      showCancelButton: true,
      title: 'Retornar da saida extra?',
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não',
      preConfirm: () => {
        const registro = {
          usuario_id: localStorage.getItem('sessionIdUsuario'),
          data_registro: new Date(),
          tipo: 'E',
          motivo: 'Saida Extra',
          observacao: 'Retomada de expediente',
          controle: registrosUsuario[0].controle,
        };
        req(registro);
      },
    });
  };

  const { mutateAsync: req } = useMutation({
    mutationFn: (infos) => RegistrarHorario(infos),
    onSuccess: (_, variables) => {
      toast.fire({
        icon: 'success',
        title: 'Horario registrado!',
      });
      queryClient.invalidateQueries({
        queryKey: ['registrosDia'],
      });
    },
    onError: (err) => {
      toast.fire({
        icon: 'error',
        text: err.response.data,
      });
    },
  });

  const confirmacaoRegistro = (texto, callback) => {
    Swal.fire({
      title: texto,
      showDenyButton: true,
      confirmButtonText: 'Sim',
      denyButtonText: 'Não',
    }).then((result) => {
      if (result.isConfirmed) {
        callback(true);
      } else if (result.isDenied) {
        callback(false);
      }
    });
  };

  return (
    <div className="container p-1">
      {!error ? (
        <>
          <div className="row d-flex flex-row justify-content-between">
            <div className="col-12 col-sm-9 col-md-10">
              <button className="mx-1 btn btn-outline-dark" hidden={inicioDisable} onClick={iniciarExpediente}>
                <FaBusinessTime /> Iniciar
              </button>
              <button className="mx-1 btn btn-outline-dark" onClick={iniciarAlmoco} hidden={almocoDisable}>
                <FaUtensils /> Almoço
              </button>
              <button className="mx-1 btn btn-outline-dark" onClick={retornoAlmoco} hidden={retornoDisable}>
                <FaRocket />
                Retorno
              </button>
              <button className="mx-1 btn btn-outline-dark" onClick={fimExpediente} hidden={fimDisable}>
                <FaBed size={18} /> Finalizar
              </button>
            </div>
            <div className="col-12 col-sm-3 col-md-2">
              <button className="btn btn-outline-dark" hidden={saidaExtraDisable} onClick={saidaExtra}>
                <FaClock /> Saída Extra
              </button>
              <button className="btn btn-outline-dark" hidden={retornoSaidaExtraDisable} onClick={retornoSaidaExtra}>
                <FaClock /> Retorno Saída Extra
              </button>
            </div>
          </div>
          <div className="row mt-3">
            {registrosDia.map((x) => {
              return <UsuarioCardExpediente key={x.nome} usuario={x} />;
            })}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default Expediente;
