import { useState } from 'react';
import { FaExclamationCircle } from "react-icons/fa";
import './style.css';

function CardAlerta({ alerta, onClose }) {
  const [fechou, setFechou] = useState(false);
  const handleFecharAlerta = () => {
    setFechou(true);
    setTimeout(() => {
      onClose(alerta.id);
    }, 300);
  };
  return (
    <div className={`alerta-card ${fechou ? 'alerta-card-fechar' : null}`}>
      <button className="close-button" onClick={handleFecharAlerta}>
        &times;
      </button>
      <div className="d-flex justify-content-center align-items-center">
        <FaExclamationCircle style={{ fontSize: '25px', color: '#DC3545' }}/>
      </div>
      <div className="alerta-texto">{alerta.alerta}</div>
    </div>
  );
}

export default CardAlerta;
