import { useContext } from 'react';
import api from '../../../../service/api.js';
import style from '../feriaspage.module.css';
import moment from 'moment';
import { UsuarioContext } from '../../../../context/userContext.jsx';
import { formataData } from '../../../../util/maskdoc.js';
import { toast } from '../../../../constants/toast.jsx';
import { FaCircleCheck, FaCircleXmark, FaPenToSquare, FaTrashCan } from 'react-icons/fa6';

function TabelaLinha({ ferias, onSetDataInicio, onSetDataFim }) {
  const { idUsuario, isAdminUsuario } = useContext(UsuarioContext);
  function diffDays() {
    const dInicio = moment(ferias.data_inicio.split('T')[0]);
    const dFim = moment(ferias.data_fim.split('T')[0]);
    const diffDias = dFim.diff(dInicio, 'days') + 1; // +1 para incluir o último dia
    return diffDias;
  }

  function AlterarFerias(status) {
    api
      .patch('/v1/ferias', {
        feriasId: ferias.ferias_id,
        dataInicio: ferias.data_inicio,
        dataFim: ferias.data_fim,
        status,
        usuarioAprovou: status !== 'P' && status !== 'C' ? idUsuario : null,
      })
      .then(() => {
        toast.fire({
          icon: 'success',
          title: 'Atualizado com sucesso',
        });
      })
      .catch((error) => {
        console.log('error :>> ', error);
        toast.fire({
          icon: 'error',
          text: error.response.data,
        });
      });
  }

  return (
    <tr className={`py-auto ${ferias.status === 'N' ? 'table-danger' : null}`} title={ferias.status === 'N' ? 'Negado' : null}>
      <td>{ferias.nome}</td>
      <td>
        {Number(idUsuario) === ferias.funcionario_id ? (
          <input
            value={ferias.data_inicio.split('T')[0]}
            type="date"
            className="form-control"
            onChange={(e) => onSetDataInicio(ferias.ferias_id, e.target.value)}
          />
        ) : (
          formataData(ferias.data_inicio)
        )}
      </td>
      <td>
        {Number(idUsuario) === ferias.funcionario_id ? (
          <input
            value={ferias.data_fim.split('T')[0]}
            type="date"
            className="form-control"
            onChange={(e) => onSetDataFim(ferias.ferias_id, e.target.value)}
          />
        ) : (
          formataData(ferias.data_fim)
        )}
      </td>
      <td>{diffDays()} dias</td>
      <td className="d-flex justify-content-between">
        {isAdminUsuario && isAdminUsuario === 'S' && Number(idUsuario) !== Number(ferias.funcionario_id) ? (
          <div>
            <button className={`btn btn-outline-success ${style.btnAcao}`} title="Aprovar" onClick={() => AlterarFerias('L')}>
              <FaCircleCheck/>
            </button>
            <button className={`btn btn-outline-danger ${style.btnAcao}`} title="Negar" onClick={() => AlterarFerias('N')}>
              <FaCircleXmark/>
            </button>
          </div>
        ) : (
          <button className={`btn btn-primary ${style.btnAcao}`} title="Atualizar" onClick={() => AlterarFerias('P')}>
            <FaPenToSquare/>
            <span>Atualizar</span>
          </button>
        )}
        <button className={`btn btn-danger ${style.btnAcao}`} title="Cancelar" onClick={() => AlterarFerias('C')}>
          <FaTrashCan style={{marginBottom:3}}/>
          <span>Cancelar</span>
        </button>
      </td>
    </tr>
  );
}

export default TabelaLinha;
