import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowDownShortWide, FaArrowDownWideShort, FaCaretDown, FaFilter, FaKey, FaMagnifyingGlass, FaPlus } from 'react-icons/fa6';
import './style.css';
import ClienteCard from '../../../components/cliente-card';
import { UsuarioContext } from '../../../context/userContext';
import { ROUTE_BACKUPS_CLIENTES, ROUTE_CLIENTES, ROUTE_LISTA_PARAM } from '../../../constants/routes.js';
import Titulo from '../../../components/titulo/index.jsx';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { GetCidades as GetCidadesFetch, GetClientes, GetUfs } from '../../../service/fetchers/clientes.js';
import { toast } from '../../../constants/toast.jsx';
import { LuDatabaseBackup } from 'react-icons/lu';

function ListaClientes() {
  const queryClient = useQueryClient();
  const { permissoesUsuario } = useContext(UsuarioContext);

  const navigate = useNavigate();
  const [pagina, setPagina] = useState(1);
  const [processando, setProcessando] = useState(false);
  const [mais, setMais] = useState(true);
  const [clientes, setClientes] = useState([]);
  const [tipoBusca, setTipoBusca] = useState('2');
  const [pesquisa, setPesquisa] = useState('');
  const [ativo, setAtivo] = useState('1');
  const [uf, setUf] = useState('');
  const [cidades, setCidades] = useState([]);
  const [cidade, setCidade] = useState('');
  const [ordernarPor, setOrdernarPor] = useState('0');
  const [qntPagina, setQntPagina] = useState('15');
  const [desc, setDesc] = useState(false);
  let pg = 0;

  function GetTipoBusca(e) {
    setTipoBusca(e.target.value);
  }

  function GetAtivo(e) {
    setAtivo(e.target.value);
  }

  async function GetClientesPesquisa(reset, filtros = null) {
    pg = filtros ? filtros.pagina : reset ? 1 : pagina + 1;
    const filtrosPesquisa = filtros
      ? filtros
      : {
          tipoBusca,
          pesquisa,
          ativo,
          uf,
          cidade,
          ordernarPor,
          qntPagina,
          desc,
          pagina: pg,
        };
    try {
      setProcessando(true);
      const result = await queryClient.fetchQuery({
        queryKey: ['clientes', filtrosPesquisa],
        queryFn: () =>
          GetClientes({
            tipo_busca: filtrosPesquisa.tipoBusca,
            q: filtrosPesquisa.pesquisa,
            ativo: filtrosPesquisa.ativo,
            uf: filtrosPesquisa.uf,
            cidade: filtrosPesquisa.cidade,
            order: filtrosPesquisa.ordernarPor,
            desc: filtrosPesquisa.desc ? 1 : null,
            limite: Number(filtrosPesquisa.qntPagina) * Number(filtrosPesquisa.pagina),
          }),
        staleTime: 1000 * 60 * 15,
      });
      setClientes(result);
      if (!reset) {
        setPagina(pagina + 1);
      } else {
        setPagina(1);
      }
      if (result.length >= Number(qntPagina)) {
        setMais(true);
      } else {
        setMais(false);
      }
      setProcessando(false);
      filtros && localStorage.removeItem('pesquisaCliente');
    } catch (error) {
      console.log('error :>> ', error);
    }
  }

  const handlePesquisa = () => {
    setPagina(1);
    setProcessando(true);
    GetClientesPesquisa(true);
  };

  const { data: listaUF } = useQuery({
    queryKey: ['ufs'],
    queryFn: GetUfs,
    staleTime: Number.POSITIVE_INFINITY,
  });

  function SelCliente(id) {
    if (id > 0) {
      navigate(`${ROUTE_CLIENTES}/${id}`);
      const filtro = {
        pagina,
        tipoBusca,
        pesquisa,
        ativo,
        uf,
        cidade,
        ordernarPor,
        qntPagina,
        desc,
      };
      localStorage.setItem('pesquisaCliente', JSON.stringify(filtro));
    } else {
      navigate(`${ROUTE_CLIENTES}/0`);
    }
  }
  function SelClienteAux(id) {
    if (id > 0) {
      window.open(`${ROUTE_CLIENTES}/${id}`);
    } else {
      window.open(`${ROUTE_CLIENTES}/0`);
    }
  }

  function navigateListaParam(e) {
    e.preventDefault();
    navigate(ROUTE_LISTA_PARAM);
  }
  function navigateListaBackups(e) {
    e.preventDefault();
    navigate(ROUTE_BACKUPS_CLIENTES);
  }

  function setUfBusca(ufSel) {
    setUf(ufSel);
    GetCidades(ufSel);
  }

  async function GetCidades(uf) {
    try {
      const result = await queryClient.fetchQuery({
        queryKey: ['cidades', uf],
        queryFn: () => GetCidadesFetch(uf),
        staleTime: Number.POSITIVE_INFINITY,
      });

      setCidades(result);
    } catch (error) {
      setCidades([]);
      toast.fire({
        icon: 'error',
        title: 'Erro ao buscar cidades!',
      });
      console.log('error :>> ', error);
    }
  }

  useEffect(() => {
    const pesquisaRecuperada = JSON.parse(localStorage.getItem('pesquisaCliente'));
    if (pesquisaRecuperada) {
      setPagina(pesquisaRecuperada.pagina);
      setTipoBusca(pesquisaRecuperada.tipoBusca);
      setPesquisa(pesquisaRecuperada.pesquisa);
      setAtivo(pesquisaRecuperada.ativo);
      setUf(pesquisaRecuperada.uf);
      setCidade(pesquisaRecuperada.cidade);
      setOrdernarPor(pesquisaRecuperada.ordernarPor);
      setQntPagina(pesquisaRecuperada.qntPagina);
      setDesc(pesquisaRecuperada.desc);

      if (pesquisaRecuperada.uf !== '') GetCidades(pesquisaRecuperada.uf);

      GetClientesPesquisa(true, pesquisaRecuperada);
    } else {
      GetClientesPesquisa(true);
    }
  }, []);

  return (
    <div>
      <div className="container shadow-lg rounded-2 mt-3">
        {/* BOTÕES DE NOVO REGISTRO + ATUALIZAR LISTA + PESQUISA */}
        <Titulo
          principal
          titulo="Listagem de Clientes"
          complementoTitulo={
            <div className="col-md-8 col-xl-5 mb-1">
              <div className="d-flex btn-group mt-3" role="group" aria-label="Clientes">
                {permissoesUsuario.listagem_param === 0 ? null : (
                  <button
                    className="btn btn-outline-dark d-flex align-items-center justify-content-center"
                    type="button"
                    hidden={permissoesUsuario.listagem_param === 0}
                    disabled={permissoesUsuario.listagem_param === 0}
                    onClick={(e) => navigateListaParam(e)}
                  >
                    <FaKey className="me-2" /> Renovação de Chave
                  </button>
                )}

                {permissoesUsuario.backup !== 1 ? null : (
                  <button
                    className="btn btn-outline-dark d-flex align-items-center justify-content-center"
                    type="button"
                    hidden={permissoesUsuario.backup !== 1}
                    disabled={permissoesUsuario.backup !== 1}
                    onClick={navigateListaBackups}
                  >
                    <LuDatabaseBackup className="me-2" /> Backups
                  </button>
                )}
                {permissoesUsuario.editar_cliente === 0 ? null : (
                  <button
                    className="btn btn-outline-dark d-flex align-items-center justify-content-center"
                    type="button"
                    hidden={permissoesUsuario.editar_cliente === 0}
                    disabled={permissoesUsuario.editar_cliente === 0}
                    onClick={(e) => SelCliente(0)}
                  >
                    <FaPlus className="me-2" /> Novo Cliente
                  </button>
                )}
              </div>
            </div>
          }
        >
          <div className="collapse" id="collapseExample">
            <div className="card card-body mt-3">
              <div className="row">
                <div className="col-md-3 col-sm-12">
                  <label htmlFor="cmbPosicao">Pesquisar por</label>
                  <select
                    onChange={GetTipoBusca}
                    defaultValue={'2'}
                    value={tipoBusca}
                    id="cmbPosicao"
                    className="form-select mb-2"
                    aria-label="Selecionar"
                  >
                    <option value="1">Código</option>
                    <option value="2">Razão Social/Fantasia</option>
                    <option value="4">CNPJ</option>
                    <option value="5">Contrato</option>
                  </select>
                </div>
                <div className="col-md-7 col-sm-12">
                  <label htmlFor="floatingInput">Pesquisa</label>
                  <input
                    onChange={(e) => setPesquisa(e.target.value)}
                    type="text"
                    className="form-control mb-2"
                    id="floatingInput"
                    placeholder="Pesquisar"
                    value={pesquisa}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handlePesquisa();
                      }
                    }}
                  />
                </div>
                <div className="col-md-2 col-sm-12">
                  <label htmlFor="cmbPosicao">Situação</label>
                  <select
                    onChange={GetAtivo}
                    id="cmbPosicao"
                    value={ativo}
                    className="form-select mb-2"
                    aria-label="Selecionar"
                    defaultValue={'1'}
                  >
                    <option value="2">Todos</option>
                    <option value="1">Ativo</option>
                    <option value="0">Inativo</option>
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-1 col-md-3 col-sm-2">
                  <label htmlFor="cmbUf">UF</label>
                  <select
                    onChange={(e) => setUfBusca(e.target.value)}
                    defaultValue={'0'}
                    id="cmbUf"
                    className="form-select mb-2"
                    value={uf}
                    aria-label="Selecionar"
                  >
                    <option value="0">UF</option>
                    {listaUF?.map((u, index) => {
                      return (
                        <option value={u.uf} key={u.id + String(index)}>
                          {u.uf}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="col-lg-5 col-md-9 col-sm-10">
                  <label htmlFor="cmbCidade">Cidade</label>
                  <select
                    onChange={(e) => setCidade(e.target.value)}
                    disabled={cidades.length === 0}
                    value={cidade}
                    id="cmbCidade"
                    className="form-select mb-2"
                    aria-label="Selecionar"
                    defaultValue={'0'}
                  >
                    <option value="0">Todas Cidades</option>
                    {cidades?.map((u, index) => {
                      return (
                        <option value={u.id} key={u.id + String(index)}>
                          {u.nome}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className="col-lg-3 col-md-4 col-sm-6">
                  <label htmlFor="ordernarPor">Ordernar Por</label>
                  <select
                    onChange={(e) => {
                      if (e.target.value === '') {
                        setDesc(false);
                      }
                      setOrdernarPor(e.target.value);
                    }}
                    defaultValue={''}
                    value={ordernarPor}
                    id="ordernarPor"
                    className="form-select mb-2"
                    aria-label="Selecionar"
                  >
                    <option value="">Padrão</option>
                    <option value="0">Nome</option>
                    <option value="1">ID</option>
                    <option value="2">Contrato</option>
                    <option value="3">Dt. Inicio Contrato</option>
                  </select>
                </div>
                <div className="col-lg-1 col-md-4 col-sm-3">
                  <label htmlFor="ordernarPor">Quantidade</label>
                  <select
                    onChange={(e) => setQntPagina(e.target.value)}
                    defaultValue={'15'}
                    value={qntPagina}
                    id="ordernarPor"
                    className="form-select mb-2"
                    aria-label="Selecionar"
                  >
                    <option value="15">15</option>
                    <option value="30">30</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </div>

                <div className="col-lg-2 col-md-4 col-sm-3 d-flex justify-content-center align-items-center">
                  <div className="form-check form-switch mt-2" style={{ padding: 0 }}>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      role="switch"
                      aria-checked={desc}
                      id="AscDesc"
                      autoComplete="off"
                      checked={desc}
                      onChange={() => setDesc(!desc)}
                      disabled={ordernarPor === ''}
                    />
                    <label className="form-check-label" htmlFor="AscDesc">
                      {desc ? (
                        <>
                          <span>Desc </span>
                          <FaArrowDownWideShort size={18} style={{ marginBottom: 2 }} />
                        </>
                      ) : (
                        <>
                          <span>Asc </span>
                          <FaArrowDownShortWide size={18} style={{ marginBottom: 2 }} />
                        </>
                      )}
                    </label>
                  </div>
                </div>

                {/* <div className="col-md-2 col-sm-12 text-center d-flex justify-content-center align-items-center p-md-4 p-sm-4"> */}
                <div className="col-lg-2 col-md-4 col-sm-12 align-items-center mt-4">
                  <button className="btn btn btn-primary w-100" onClick={handlePesquisa}>
                    <div>
                      <FaMagnifyingGlass className="me-2" />
                      <small>Pesquisar...</small>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Titulo>
        {/* LISTAGEM DE REGISTROS */}
        {clientes && Array.isArray(clientes) ? (
          <div className="row ps-2 mt-3">
            <div className="row mb-3 gx-2 gy-2 clientes" data-bss-baguettebox>
              {clientes.map((a, index) => {
                return (
                  <ClienteCard
                    key={a.id + String(index)}
                    id={a.id}
                    razao_social={a.razao_social}
                    nome_fantasia={a.nome_fantasia}
                    contrato={a.contrato}
                    cnpj={a.cnpj}
                    telefone1={a.telefone1}
                    telefone2={a.telefone2}
                    celular={a.celular}
                    ativo={a.ativo}
                    onClickCliente={SelCliente}
                    onClickAuxCliente={SelClienteAux}
                  />
                );
              })}
            </div>
          </div>
        ) : (
          <a
            href="#collapseExample"
            className="btn-colapse"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            <div>
              <span className="d-block text-center">
                <div>
                  <h5 className="text-secondary">Nenhum cliente encontrado!</h5>
                  <h6 className="pb-5 text-secondary">
                    Tente fazer algum filtro <FaFilter />
                  </h6>
                </div>
              </span>
            </div>
          </a>
        )}

        {!processando && mais ? (
          <span className="d-block text-center mt-3 mb-5">
            <button type="button" className="btn btn-lg btn-outline-secondary mb-3" onClick={() => GetClientesPesquisa(false)}>
              <div>
                <FaCaretDown className="me-2" />
                <small>Carregar mais clientes...</small>
              </div>
            </button>
          </span>
        ) : null}
      </div>
    </div>
  );
}

export default ListaClientes;
