import { useContext } from 'react';
import './style.css';
import UsuariosOnlineCard from './usuarios-card';
import { SituacaoEquipeContext } from '../../../context/equipeContext.jsx';
import { FaArrowsRotate, FaChevronDown } from 'react-icons/fa6';

function UsuariosOnline({ open }) {
  const { situacaoEquipe, loading, setManualRefresh } = useContext(SituacaoEquipeContext);
  const handleRefresh = () => {
    setManualRefresh((prev) => !prev); // Alternar entre true e false para forçar a execução do useEffect no contexto
  };
  return (
    <>
      <div className={`usuario-online-area ${open ? 'usuario-online-area-open' : ''}`}>
        <div className="d-flex flex-column align-items-center">
          <button className={`btn-refresh`} onClick={handleRefresh}>
            {loading ? <FaArrowsRotate className="icon-spin"/> : <FaArrowsRotate/>}
          </button>
          {situacaoEquipe?.length ? (
            <>
              <h4>Usuarios:</h4>
              <div style={{ width: '100%', borderBottom: '1px solid #000', marginBottom: '5px' }}>
                <a
                  className="d-flex justify-content-between"
                  data-bs-toggle="collapse"
                  href="#collapseSetorSuporte"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseSetorSuporte"
                >
                  Suporte <FaChevronDown />
                </a>
              </div>
              <div className="collapse" id="collapseSetorSuporte" style={{ width: '100%' }}>
                {situacaoEquipe.map((usuario) => {
                  if (usuario.SETOR === 'S') {
                    return <UsuariosOnlineCard key={usuario.ID} usuario={usuario} />;
                  } else return null;
                })}
              </div>
              <div style={{ width: '100%', borderBottom: '1px solid #000', marginBottom: '5px' }}>
                <a
                  className="d-flex justify-content-between"
                  data-bs-toggle="collapse"
                  href="#collapseSetorDesenvolvimento"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseSetorDesenvolvimento"
                >
                  Desenvolvimento <FaChevronDown />
                </a>
              </div>
              <div className="collapse" id="collapseSetorDesenvolvimento" style={{ width: '100%' }}>
                {situacaoEquipe.map((usuario) => {
                  if (usuario.SETOR === 'D') {
                    return <UsuariosOnlineCard key={usuario.ID} usuario={usuario} />;
                  } else return null;
                })}
              </div>
              <div style={{ width: '100%', borderBottom: '1px solid #000', marginBottom: '5px' }}>
                <a
                  className="d-flex justify-content-between"
                  data-bs-toggle="collapse"
                  href="#collapseSetorFinanceiro"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseSetorFinanceiro"
                >
                  Financeiro <FaChevronDown />
                </a>
              </div>
              <div className="collapse" id="collapseSetorFinanceiro" style={{ width: '100%' }}>
                {situacaoEquipe.map((usuario) => {
                  if (usuario.SETOR === 'F') {
                    return <UsuariosOnlineCard key={usuario.ID} usuario={usuario} />;
                  } else return null;
                })}
              </div>
            </>
          ) : (
            <>
              <span>Sem Usuarios</span>
              <img src="https://gw.alipayobjects.com/zos/antfincdn/ZHrcdLPrvN/empty.svg" alt="Sem Usuários!" title="Sem Usuários" />
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default UsuariosOnline;
