import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import NovoAtendimento from './Novo';
import { BuscaClienteListaAutoCompleteProvider } from '../../../context/BuscaClienteListaAutoCompleteContext';
import EditarAtendimento from './Alterar';
import { UsuariosContext } from '../../../context/usersContext.jsx';

function AtendimentoEdicao() {
  const { id } = useParams();
  const { usuarios } = useContext(UsuariosContext);

  const [tipoEdicao, setTipoEdicao] = useState(false);

  useEffect(() => {
    if (id > 0) {
      setTipoEdicao(true);
    } else {
      setTipoEdicao(false);
    }
  }, []);

  return (
    <>
      <div className="container shadow-lg rounded-2 mt-3">
        <BuscaClienteListaAutoCompleteProvider>
          {tipoEdicao ? <EditarAtendimento usuarios={usuarios} id={id} /> : <NovoAtendimento usuarios={usuarios} />}
        </BuscaClienteListaAutoCompleteProvider>
      </div>
    </>
  );
}

export default AtendimentoEdicao;
