import './style.css';
function UsuarioCardExpediente({ usuario }) {
  return (
    <div className="container">
      <div className="card-usuario-container-expediente">
        <div className="container">
          <div className="row align-items-center">
            <div className={`tag-card-cliente-ativo`}></div>
            <div className="col-12 col-sm-6 col-md-3 col-xl-2">
              <span>
                <strong>{usuario.nome}</strong>
              </span>
            </div>

            <div className="col-12 col-sm-6 col-md-4 col-xl-4">
              <div>
                <span>
                  Inicio: <strong>{usuario.entrada}</strong>
                </span>
              </div>
              {/* </div>

            <div className="col-12 col-sm-6 col-md-5 col-xl-2"> */}
              <div>
                <span>
                  Almoço: <strong>{usuario.almoco}</strong>
                </span>
              </div>
            </div>

            <div className="col-12 col-sm-6 col-md-4 col-xl-4">
              <div>
                <span>
                  Retorno: <strong>{usuario.retorno_almoco}</strong>
                </span>
              </div>
              {/* </div>

            <div className="col-12 col-sm-6 col-md-5 col-xl-2"> */}
              <div>
                <span>
                  Fim: <strong>{usuario.saida}</strong>
                </span>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-5 col-xl-2">
              <div>
                <span>
                  Duracao: <strong>{usuario.tempo_trabalhado}</strong>
                </span>
              </div>
              <div>
                <span>
                  Controle: <strong>{usuario.controle}</strong>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UsuarioCardExpediente;
