import axios from 'axios';
import { ST__IDUSUARIO } from '../constants/storagekey.js';

const url = process.env.REACT_APP_API_URL

const api = axios.create({
  baseURL: url,
});

api.interceptors.request.use(
  (req) => {
    if (localStorage.getItem('sessionToken')) {
      req.headers.Authorization = `Bearer ${localStorage.getItem('sessionToken')}`;
    }
    if (req.method.toUpperCase() === 'POST') {
      req.headers['X-User-Id'] = localStorage.getItem(ST__IDUSUARIO);
      req.headers['X-Req-Time'] = new Date().toISOString().substring(0, 16);
    }

    return req;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log('error :>> ', error.response.data.message);
    if (error.response.status === 401 && error.response.data.message === 'Token não informado') {
      localStorage.removeItem('sessionToken');
      document.location = '/login';
    }
    if (error.response.status === 403 && error.response.data.message === 'Token inválido') {
      document.location = '/login';
      localStorage.clear();
    }

    console.log('error interceptor :>> ', error);
    return Promise.reject(error);
  }
);

// 3351|uIFh5O2SwuIRp2mOG1NNpR6hAftCAYL4
//https://api.invertexto.com/v1/holidays/2023?token= 3351|uIFh5O2SwuIRp2mOG1NNpR6hAftCAYL4&state=RJ

export default api;
