import React, { useEffect, useState } from 'react';
import { formataData, formataHora } from '../../util/maskdoc';
import './style.css';

const Timeline = (props) => {
  const [primeiro, setPrimeiro] = useState(false);
  const [ultimo, setUltimo] = useState(false);
  const [solucao, setSolucao] = useState(false);
  const [cancelado, setCancelado] = useState(false);

  useEffect(() => {
    if (props.posicao === 'P') setPrimeiro(true);
    if (props.posicao === 'U') setUltimo(true);
    if (props.posicao === 'S') setSolucao(true);
    props.posicao === 'C' && setCancelado(true);
  }, []);

  const formatarOcorrencia = (ocorrencia) => {
    return ocorrencia.split('\n').map((linha, index) => <p key={index}>{linha}</p>);
  };

  return (
    <div className="timeline">
      <div className={`linha ${primeiro ? 'linha-primeiro' : solucao || cancelado ? 'linha-ultimo' : ''}`}></div>
      <div className={`circulo ${ultimo || solucao ? 'circulo-ultimo' : cancelado ? 'circulo-cancelado' : ''}`}></div>
      <div className={`timeline-card ${solucao ? 'timeline-card-solucao' : cancelado ? 'timeline-card-cancelado' : ''}`}>
        <div className="timeline-card-encaminhado">{props.encaminhado}</div>
        <div className="timeline-card-ocorrencia">{formatarOcorrencia(props.ocorrencia)}</div>
        <div className="footer">
          <div className="timeline-card-data">
            {solucao || cancelado ? `${formataData(props.data)} ${formataHora(props.data)}` : props.data}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
