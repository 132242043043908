import { useContext, useEffect, useState } from 'react';
import CardOsFinalizadas from '../../../components/os-finalizadas-card';
import LoadingComponent from '../../../components/loading-component';
import { UsuariosContext } from '../../../context/usersContext.jsx';
import { FaCaretDown, FaFilter } from 'react-icons/fa6';
import Titulo from '../../../components/titulo/index.jsx';
import { FaQuestionCircle } from 'react-icons/fa';
import { toast } from '../../../constants/toast.jsx';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { GetListaOs } from '../../../service/fetchers/atendimentos.js';

const filterInitialState = {
  numeroInicial: undefined,
  numeroFinal: undefined,
  cliente: '',
  encaminhado: 0,
  conteudo: '',
  lancado: 0,
  posicao: ['E', 'A'],
  tipoOs: '',
  pagina: 1,
  tipoData: '',
  dataInicial: '',
  dataFinal: '',
};

function OsFinalizadas() {
  const queryClient = useQueryClient();
  const { usuarios } = useContext(UsuariosContext);
  const [oS, setOs] = useState([]);
  const [mais, setMais] = useState(true);
  const [filter, setFilter] = useState(filterInitialState);

  const mapeamentoSiglas = {
    P: 'Pendente',
    T: 'Desenvolvendo',
    W: 'Aguardando',
    F: 'Finalizado',
    C: 'Cancelado',
    E: 'Testes',
    A: 'Atualizar',
  };
  function GetPosicao(e) {
    const value = e.target.value;
    const isChecked = e.target.checked;

    if (isChecked) {
      setFilter((prev) => ({ ...prev, pagina: 1, posicao: [...prev.posicao, value] }));
    } else {
      setFilter((prev) => ({ ...prev, pagina: 1, posicao: prev.posicao?.filter((x) => x !== value) }));
    }
  }

  queryClient.setQueryDefaults(['ListaOsFinalizadas', filter], {
    queryFn: async () => await GetListaOs(filter),
    staleTime: 1000 * 60 * 10,
  });

  const { data, isFetching } = useQuery({
    queryKey: ['ListaOsFinalizadas', filter],
  });

  function alterFilterOptions({ campo, valor }) {
    setFilter((prev) => ({ ...prev, [campo]: valor, pagina: 1 }));
  }

  useEffect(() => {
    if (data?.length % 50 === 0) {
      setMais(true);
    } else {
      setMais(false);
    }

    if (data && !isFetching) {
      if (filter.pagina === 1) {
        setOs(data);
      }
      if (filter.pagina > 1) {
        setOs((prev) => [...prev, ...data]);
      }
    }
    if (data?.length === 0) {
      setOs([]);
      setFilter((prev) => ({ ...prev, pagina: 1 }));
    }
  }, [data, filter, isFetching]);

  function removerOsdaListagemNegar(id) {
    setOs((prev) => prev.filter((x) => x.id !== id));
  }

  function InfoToast() {
    return toast.fire({
      icon: 'info',
      text: `A listagem de OS é ordenada pela data de finalização primariamente, se marcar situações que não estão finalizadas (Pendentes, Desenvolvendo e Aguardando) junto com finalizadas (Testes, Atualizar e Finalizado), eles seram colocados no final da listagem e depois ordenados pelo Número da OS.`,
      timer: 8000,
      width: 650,
    });
  }

  return (
    <>
      <div className="container shadow-lg rounded-2 mt-3">
        <Titulo titulo="O.S." principal={false} retorno={'atendimentos'}>
          <div className="collapse" id="collapseExample">
            <div className="card card-body mt-3">
              <div className="row">
                <div className="col-md-6 col-sm-12 nowrap">
                  <label htmlFor="conteudoAtendimento">Conteúdo da O.S.</label>
                  <input
                    value={filter.conteudo}
                    onChange={(e) => alterFilterOptions({ campo: 'conteudo', valor: e.target.value })}
                    type="text"
                    className="form-control mb-2"
                    id="conteudoAtendimento"
                    placeholder="Ocorrência e Solução"
                  />
                </div>
                <div className="col-md-6 col-sm-12 nowrap">
                  <label htmlFor="floatingInput">Cliente</label>
                  <input
                    onChange={(e) => alterFilterOptions({ campo: 'cliente', valor: e.target.value })}
                    type="text"
                    className="form-control mb-2"
                    id="floatingInput"
                    placeholder="Razão Social, nome fantasia, etc."
                    value={filter.cliente}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-3 col-sm-12 nowrap">
                  <label htmlFor="edNumInicialAtendimento">Número O.S. Inicial</label>
                  <input
                    value={filter.numeroInicial}
                    onChange={(e) => alterFilterOptions({ campo: 'numeroInicial', valor: e.target.value })}
                    type="number"
                    className="form-control mb-2"
                    id="edNumInicialAtendimento"
                    placeholder=""
                  />
                </div>
                <div className="col-md-3 col-sm-12 nowrap">
                  <label htmlFor="edNumFinalAtendimento">Número O.S. Final</label>
                  <input
                    value={filter.numeroFinal}
                    onChange={(e) => alterFilterOptions({ campo: 'numeroFinal', valor: e.target.value })}
                    type="number"
                    className="form-control mb-2"
                    id="edNumFinalAtendimento"
                    placeholder=""
                  />
                </div>
                <div className="col-md-6 col-sm-12 dropdown-filtro">
                  <label htmlFor="dropdown">
                    Posição <FaQuestionCircle size={12} style={{ marginBottom: '5px', cursor: 'pointer' }} onClick={InfoToast} />
                  </label>
                  <div className="dropdown">
                    <button
                      className="btn dropdown-toggle nowrap"
                      type="button"
                      data-bs-auto-close="outside"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {filter.posicao?.length === 0
                        ? 'Selecione'
                        : filter.posicao
                            ?.map((sigla) => mapeamentoSiglas[sigla])
                            .join(', ')
                            .replace(/,(?=[^,]*$)/, ' e')}
                    </button>
                    <ul className="dropdown-menu">
                      <li className="dropdown-item">
                        <input
                          id="Pendente"
                          type="checkbox"
                          className="me-1"
                          checked={filter.posicao?.findIndex((x) => x === 'P') !== -1 ? true : false}
                          value={'P'}
                          onChange={GetPosicao}
                        />
                        <label htmlFor="Pendente">Pendente</label>
                      </li>
                      <li className="dropdown-item">
                        <input
                          id="Aguardando"
                          type="checkbox"
                          className="me-1"
                          checked={filter.posicao?.findIndex((x) => x === 'W') !== -1 ? true : false}
                          value={'W'}
                          onChange={GetPosicao}
                        />
                        <label htmlFor="Aguardando">Aguardando</label>
                      </li>
                      <li className="dropdown-item">
                        <input
                          id="Desenvolvendo"
                          type="checkbox"
                          className="me-1"
                          checked={filter.posicao?.findIndex((x) => x === 'T') !== -1 ? true : false}
                          value={'T'}
                          onChange={GetPosicao}
                        />
                        <label htmlFor="Desenvolvendo">Desenvolvendo</label>
                      </li>
                      <li className="dropdown-item">
                        <input
                          id="Testes"
                          type="checkbox"
                          className="me-1"
                          checked={filter.posicao?.findIndex((x) => x === 'E') !== -1 ? true : false}
                          value={'E'}
                          onChange={GetPosicao}
                        />
                        <label htmlFor="Testes">Testes</label>
                      </li>
                      <li className="dropdown-item">
                        <input
                          id="Atualizar"
                          type="checkbox"
                          className="me-1"
                          checked={filter.posicao?.findIndex((x) => x === 'A') !== -1 ? true : false}
                          value={'A'}
                          onChange={GetPosicao}
                        />
                        <label htmlFor="Atualizar">Atualizar</label>
                      </li>
                      <li className="dropdown-item">
                        <input
                          id="Finalizado"
                          type="checkbox"
                          className="me-1"
                          checked={filter.posicao?.findIndex((x) => x === 'F') !== -1 ? true : false}
                          value={'F'}
                          onChange={GetPosicao}
                        />
                        <label htmlFor="Finalizado">Finalizado</label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 col-sm-12 nowrap">
                  <label htmlFor="cmbLancado">Lançado por</label>
                  <select
                    id="cmbLancado"
                    className="form-select mb-2"
                    aria-label="Selecionar"
                    onChange={(e) => alterFilterOptions({ valor: e.target.value, campo: 'lancado' })}
                    defaultValue={-1}
                    value={filter.lancado}
                  >
                    <option value={-1}>Qualquer um</option>
                    {usuarios.map((usuario) => {
                      return (
                        <option key={usuario.ID} value={usuario.ID}>
                          {usuario.NOME}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-4 col-sm-12 nowrap">
                  <label htmlFor="cmbLancado">Encaminhado para</label>
                  <select
                    id="cmbLancado"
                    className="form-select mb-2"
                    aria-label="Selecionar"
                    onChange={(e) => alterFilterOptions({ valor: e.target.value, campo: 'encaminhado' })}
                    defaultValue={-1}
                    value={filter.encaminhado}
                  >
                    <option value={-1}>Qualquer um</option>
                    {usuarios.map((usuario) => {
                      if (usuario.SETOR === 'D') {
                        return (
                          <option key={usuario.ID} value={usuario.ID}>
                            {usuario.NOME}
                          </option>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </select>
                </div>
                <div className="col-md-4 col-sm-12">
                  <label htmlFor="cmbEncaminhado">Tipo</label>
                  <select
                    id="cmbEncaminhado"
                    className="form-select mb-2"
                    aria-label="Selecionar"
                    onChange={(e) => alterFilterOptions({ valor: e.target.value, campo: 'tipoOs' })}
                    value={filter.tipoOs}
                    defaultValue={''}
                  >
                    <option value="">Qualquer um</option>
                    <option value="E">Erro</option>
                    <option value="A">Alteração</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <label htmlFor="tipoData">Data</label>
                  <select
                    id="tipoData"
                    className="form-select mb-2"
                    aria-label="Selecionar"
                    onChange={(e) => alterFilterOptions({ valor: e.target.value, campo: 'tipoData' })}
                    value={filter.tipoData}
                    defaultValue={''}
                  >
                    <option value="">Selecione</option>
                    <option value="L">Lançamento</option>
                    <option value="A">Abertura</option>
                    <option value="F">Fechamento</option>
                  </select>
                </div>
                <div className="col-md-4 col-sm-12 nowrap">
                  <label htmlFor="dataInicial">Data Inicial</label>
                  <input
                    type="date"
                    className="form-control mb-2"
                    id="dataInicial"
                    placeholder=""
                    onChange={(e) => alterFilterOptions({ campo: 'dataInicial', valor: e.target.value })}
                    value={filter.dataInicial}
                  />
                </div>
                <div className="col-md-4 col-sm-12 nowrap">
                  <label htmlFor="dataFinal">Data Final</label>
                  <input
                    type="date"
                    className="form-control mb-2"
                    id="dataFinal"
                    placeholder=""
                    onChange={(e) => alterFilterOptions({ campo: 'dataFinal', valor: e.target.value })}
                    value={filter.dataFinal}
                  />
                </div>
              </div>
            </div>
          </div>
        </Titulo>
        {isFetching && filter.pagina === 1 ? (
          <LoadingComponent />
        ) : (
          <>
            <div className="row ps-2 mt-3">
              <div className="row mb-3 gx-2 gy-2" data-bs-baguettebox>
                {oS.map((os) => (
                  <CardOsFinalizadas key={os.id} infos={os} removerOsdaListagemNegar={removerOsdaListagemNegar} />
                ))}
              </div>
            </div>
            {oS.length === 0 ? (
              <a
                href="#collapseExample"
                className="btn-colapse"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="collapseExample"
              >
                <div>
                  <span className="d-block text-center">
                    <div>
                      <h5 className="text-secondary">Nenhuma O.S. encontrada!</h5>
                      <h6 className="pb-5 text-secondary">
                        Tente fazer algum filtro <FaFilter />
                      </h6>
                    </div>
                  </span>
                </div>
              </a>
            ) : null}
            {isFetching && filter.pagina > 1 ? (
              <div className="d-block text-center mt-2 pb-2">
                <div className="lds-ellipsis">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
            ) : null}
            {!isFetching && mais ? (
              <span className="d-block text-center mt-3 mb-5">
                <button
                  className="btn btn-lg btn-outline-secondary mb-3"
                  onClick={() => setFilter((prev) => ({ ...prev, pagina: prev.pagina + 1 }))}
                >
                  <div>
                    <FaCaretDown style={{ marginBottom: 2 }} />
                    <small>Carregar mais O.S.</small>
                  </div>
                </button>
              </span>
            ) : null}
          </>
        )}
      </div>
    </>
  );
}

export default OsFinalizadas;
