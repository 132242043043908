import React from 'react';
import {  maskphone } from '../../util/maskdoc.js';
import './style.css';
import { FaUser, FaUserPlus } from 'react-icons/fa6';

function UsuarioCard(props) {
  return (
    <div className="container">
      <div className="card-cliente-container" onClick={(e) => props.onClickUsuario(props.id)}>
        <div className={`tag-card-cliente-${props.ativo === 'D' ? 'ativo' : 'inativo'}`}></div>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-sm-6 col-md-3 col-xl-1">
              <span>
                Código: <strong>{props.id}</strong>
              </span>
            </div>

            <div className="col-12 col-sm-6 col-md-4 col-xl-2">
              <span>
                Nome: <strong>{props.nome}</strong>
              </span>
            </div>

            <div className="col-12 col-sm-6 col-md-5 col-xl-4">
              <span>
                Login: <strong>{props.login}</strong>
              </span>
            </div>

            <div className="col-12 col-sm-6 col-md-3 col-xl-2">
              <span>
                Setor: <strong>{props.setor == undefined ? 'Suporte' : props.setor}</strong>
              </span>
            </div>

            <div className="col-12 col-sm-6 col-md-4 col-xl-2">
              <span>
                Telefone: <strong>{maskphone(props.telefone1)}</strong>
              </span>
            </div>

            <div className="col-12 col-sm-6 col-md-5 col-xl-1">
              <span>
                Tipo:
                <strong>
                  {props.administrador === 'S' ? (
                    <FaUserPlus className="ms-2 text-primary fs-5"/>
                  ) : (
                    <FaUser className="ms-2 text-secondary fs-6"/>
                  )}
                </strong>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default UsuarioCard;
