import { formataData, formataHora } from './maskdoc';

function validaCampos(atendimentoEditado, atendimentoOriginal, solucionar) {
  if (solucionar === false) {
    const alterado = [];

    // Função para normalizar valores
    const normalizar = (valor) => {
      return valor === undefined || valor === null || valor === 0 ? null : valor;
    };

    for (const chave in atendimentoEditado) {
      if (normalizar(atendimentoEditado[chave]) !== normalizar(atendimentoOriginal[chave])) {
        alterado.push(chave);
      }
    }

    const alteradoFiltrado = alterado.filter((campo) => {
      return !['id_cliente', 'id_equipe_suporte', 'ocorrencia'].includes(campo);
    });
    let mensagem = '';
    alteradoFiltrado.map((campo) => {
      if (campo == 'encaminhado') {
        return (mensagem += Encaminhamento(atendimentoEditado));
      }
      if (campo == 'situacao') {
        return (mensagem += trocaDeSituacao(atendimentoEditado, atendimentoOriginal));
      } else {
        return (mensagem += OutraAlteracao(atendimentoEditado, atendimentoOriginal, campo));
      }
    });

    if (atendimentoEditado.ocorrencia !== atendimentoOriginal.ocorrencia) {
      atendimentoEditado.ocorrencia += `\n${mensagem}`;
    } else {
      let date = new Date().toISOString();
      atendimentoEditado.ocorrencia =
        atendimentoOriginal.ocorrencia +
        `\n${formataData(date)} ${formataHora(date)} | ${localStorage.getItem('sessionNomeUsuario')} | ${mensagem}`;
    }
  }
}

const Encaminhamento = (atendimentoEditado) => {
  return `Encaminhado para ${atendimentoEditado.encaminhado} \n`;
};

const trocaDeSituacao = (atendimentoEditado, atendimentoOriginal) => {
  let situacaoAnterior = checkTipoESituacao(atendimentoOriginal);

  let situacaoAlterada = checkTipoESituacao(atendimentoEditado);

  function checkTipoESituacao(atendimento) {
    if (atendimento.tipo === 'O' && atendimento.situacao === 'T') {
      return objetoSituacao[atendimento.situacao][1];
    } else if (atendimento.tipo !== 'O' && atendimento.situacao === 'T') {
      return objetoSituacao[atendimento.situacao][0];
    }
    return objetoSituacao[atendimento.situacao];
  }

  if (atendimentoOriginal.situacao === 'P') {
    atendimentoEditado.data_abertura = new Date().toISOString();
    return `Atendimento alterado de pendente para ${situacaoAlterada} \n`;
  } else {
    return `Situação alterado de ${situacaoAnterior} para ${situacaoAlterada} \n`;
  }
};

const OutraAlteracao = (atendimentoEditado, atendimentoOriginal, campo) => {
  return `Alterado ${camposAlteracao[campo]} de ${tradutorDeSiglas(campo, atendimentoOriginal[campo])} para ${tradutorDeSiglas(
    campo,
    atendimentoEditado[campo]
  )} \n`;
};

const tradutorDeSiglas = (campo, sigla) => {
  sigla = sigla ? sigla : 'nenhum(a)';
  if (campo === 'tipo') {
    return objetoTipo[sigla];
  }
  if (campo === 'tipo_os') {
    return objetoTipoOs[sigla];
  }
  if (campo === 'os_relacionadas') {
    return Array.isArray(sigla) ? sigla.join(', ').replace(/,(?=[^,]*$)/, ' e') : sigla;
  }
  if (campo === 'data_agenda') {
    return sigla !== 'nenhum(a)' ? `${formataData(sigla)} ${formataHora(sigla)}` : sigla;
  }
  if (campo !== 'tipo' || campo !== 'tipo_os') {
    return sigla;
  }
};

const objetoSituacao = {
  P: 'Pendente',
  T: ['Atendendo', 'Desenvolvendo'],
  W: 'Aguardando',
};

const objetoTipo = {
  R: 'Acesso Remoto',
  T: 'Telefone',
  L: 'Visita ao Local',
  O: 'Alteração do Sistema',
  A: 'Atualização do Sistema',
};

const objetoTipoOs = {
  A: 'Alteração',
  0: 'Não se aplica',
  E: 'Erro',
};

const camposAlteracao = {
  cliente: 'Cliente',
  contato: 'Contato',
  data_agenda: 'Data de Agendamento',
  encaminhado: 'Encaminhado',
  equipe_suporte: 'Equipe de Suporte',
  id_cliente: 'ID do Cliente',
  id_equipe_suporte: 'ID da Equipe de Suporte',
  tipo: 'Tipo',
  tipo_os: 'Tipo de OS',
  os_relacionadas: 'O.S. Relacionadas',
  situacao: 'Situação',
  versao_atualizacao: 'Versão da Atualização',
  quantidade_anexos: 'Quantidade de Anexos',
};

export default validaCampos;
