import React, { useMemo, useState } from 'react';
// import AtendimentoPorClientePDF from '../../../components/pdf-make/PDFAtendimentoPorCliente.jsx';
import { useQueryClient } from '@tanstack/react-query';
import { GetAtendimentos } from '../../../service/fetchers/atendimentos.js';
import { formataData, formataHora } from '../../../util/maskdoc.js';
import Titulo from '../../../components/titulo/index.jsx';

function AtendimentoPorTipo() {
  const queryClient = useQueryClient();
  const [dataInicial, setDataInicial] = useState(new Date().toISOString().split('T')[0]);
  const [dataFinal, setDataFinal] = useState(new Date().toISOString().split('T')[0]);
  const [tipoAtendimento, setTipoAtendimento] = useState('A');
  const [total, setTotal] = useState(0);
  const [optionsTable, setOptionsTable] = useState({});
  const [openStates, setOpenStates] = useState({});
  const [listagemTotal, setListagemTotal] = useState([]);

  async function GetAtendimentosPorTipo() {
    const result = await queryClient.fetchQuery({
      queryKey: ['atendimentos', { tipoData: 'F', dataInicial, dataFinal, tipoAtendimento }],
      queryFn: () =>
        GetAtendimentos({
          indReset: true,
          filtros: { tipoData: 'F', posicao: ['F', 'E', 'A'], dataInicial, dataFinal, tipo: tipoAtendimento },
        }),
    });
    if (result) {
      const colSpan = ['R', 'T'].includes(tipoAtendimento) ? 6 : tipoAtendimento === 'L' ? 7 : 8;
      const tamanhoColunaOcorrencia = Math.round(colSpan / 2);
      const header = tabelaHeaderTipo[tipoAtendimento];
      setOptionsTable({
        colSpan,
        tamanhoColunaOcorrencia,
        header,
        filtro: { tipoData: 'F', posicao: ['F', 'E', 'A'], dataInicial, dataFinal, tipo: tipoAtendimento },
      });
      setTotal(result.length);
      setListagemTotal(result);
    }
  }
  const tabelaHeaderTipo = {
    A: (
      <>
        <th>Versão</th>
        <th>O.S. selecionadas</th>
      </>
    ),
    O: (
      <>
        <th>Tipo OS</th>
        <th>Status</th>
      </>
    ),
    L: <th>Data Visita</th>,
    R: null,
    T: null,
  };

  const situacaoObjeto = {
    F: <small className="badge text-bg-success mx-1 span-situacao ">Finalizado</small>,
    E: <small className="badge text-bg-secondary mx-1 span-situacao ">Testes</small>,
    A: <small className="badge text-bg-info mx-1 span-situacao ">Atualizar</small>,
    P: <small className="badge text-bg-danger mx-1 span-situacao ">Pendente</small>,
    W: <small className="badge text-bg-primary mx-1 span-situacao ">Aguardando</small>,
    T: <small className="badge text-bg-warning mx-1 span-situacao ">Desenvolvendo</small>,
  };

  const tipoOsObjeto = {
    E: 'Erro',
    A: 'Alteraçao',
  };

  const toogleInfos = (id) => {
    setOpenStates((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const formataOcorrencia = useMemo(() => {
    return (ocorrencia) => {
      if (!ocorrencia) return null;
      console.log('ocorrencia :>> ', ocorrencia);
      const arraySeparado = ocorrencia.split(/\n(?=\d{2}\/\d{2}\/\d{4})/);
      const objeto = arraySeparado.map((entry) => {
        const [datetime, nome, ...texto] = entry.split(' | ');
        const temp = { data: datetime, nome, texto: texto.join(' | ') };
        return (
          <p className="m-0 p-0">
            {temp.data} | {temp.nome} | {temp.texto}
          </p>
        );
      });

      return objeto;
    };
  }, [listagemTotal]);

  return (
    <>
      <div className="container shadow-lg rounded-2 mt-3">
        <Titulo principal={true} titulo={'Relatório de atendimento por tipo'} />
        <div className="mb-3" id="collapseDashBoard">
          <div className="row py-2">
            <div className="col-lg-2">
              <div>
                <label htmlFor="dataAdmissao" className="">
                  Data Inicial
                </label>
                <input
                  value={dataInicial}
                  type="date"
                  className="form-control"
                  id="dataInicial"
                  name="dataInicial"
                  placeholder="Data Inicial"
                  onChange={(e) => setDataInicial(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-2">
              <div>
                <label htmlFor="dataAdmissao" className="">
                  Data Final
                </label>
                <input
                  value={dataFinal}
                  type="date"
                  className="form-control"
                  id="DataFinal"
                  name="dataFinal"
                  placeholder="Data Final"
                  onChange={(e) => setDataFinal(e.target.value)}
                />
              </div>
            </div>
            <div className="col-lg-2">
              <div>
                <label htmlFor="tipoAtendimento" className="">
                  Tipo de atendimentos
                </label>
                <select
                  value={tipoAtendimento}
                  className="form-select"
                  id="tipoAtendimento"
                  name="tipoAtendimento"
                  onChange={(e) => setTipoAtendimento(e.target.value)}
                >
                  <option value="">Selecione</option>
                  <option value="A">Atualização</option>
                  <option value="R">Remoto</option>
                  <option value="T">Telefone</option>
                  <option value="L">Local</option>
                  <option value="O">O.S.</option>
                </select>
              </div>
            </div>

            <div className="col-lg-2 my-3 my-lg-0  d-flex justify-content-center">
              <button className="btn btn-primary my-auto" onClick={GetAtendimentosPorTipo}>
                Buscar
              </button>
            </div>
          </div>

          {listagemTotal.length > 0 ? (
            <>
              <div className="row mt-2">
                <div className="col">
                  <p>Total de atendimentos finalizados: {total}</p>
                </div>
                {/* <div className="col">
                  <AtendimentoPorClientePDF atendimentos={{ clientes: listagemTotal, total }} filtros={{ dataInicial, dataFinal }} />
                </div> */}
              </div>
              <div className="row">
                <div className="col">
                  <table className="table table-bordered table-striped table-hover">
                    <thead>
                      <tr className="text-center">
                        <th>Numero</th>
                        <th className="text-start">Cliente</th>
                        <th>Lançado</th>
                        <th>Encaminhado</th>
                        <th>Tempo</th>
                        <th>Dt. Fechamento</th>
                        {optionsTable.header}
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {listagemTotal.map((atendimento) => (
                        <React.Fragment key={atendimento.id}>
                          <tr onClick={() => toogleInfos(atendimento.id)}>
                            <td className="text-center">{atendimento.id}</td>
                            <td className="text-start">{String(atendimento.cliente).substring(0, 30)}</td>
                            <td>{atendimento.lancamento}</td>
                            <td>{atendimento.encaminhado}</td>
                            <td>{atendimento.tempo_atendimento}</td>
                            <td>
                              {formataData(atendimento.data_fechamento)} {formataHora(atendimento.data_fechamento)}
                            </td>
                            {optionsTable.filtro.tipo === 'A' ? (
                              <>
                                <td>{atendimento.versao_atualizacao}</td>
                                <td>{Array(atendimento.os_relacionadas).join('/ ')}</td>
                              </>
                            ) : optionsTable.filtro.tipo === 'O' ? (
                              <>
                                <td>{tipoOsObjeto[atendimento.tipo_os]}</td>
                                <td>{situacaoObjeto[atendimento.situacao]}</td>
                              </>
                            ) : optionsTable.filtro.tipo === 'L' ? (
                              <td>{formataData(atendimento.data_agenda)}</td>
                            ) : null}
                          </tr>
                          {openStates[atendimento.id] && (
                            <tr>
                              <td colSpan={optionsTable.colSpan}>
                                <div className="row w-100">
                                  <div className="col-7 border-end">
                                    <div className="row">
                                      <h6>Ocorrências</h6>
                                    </div>
                                    <div className="row">
                                      <div className="col text-start">{formataOcorrencia(atendimento.ocorrencia)}</div>
                                    </div>
                                  </div>
                                  <div className="col-5">
                                    <div className="row">
                                      <h6>Solução</h6>
                                    </div>
                                    <div className="row">
                                      <div className="col">{atendimento.solucao}</div>
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
          ) : (
            <div className="text-center my-4 pb-4">
              <h6>Nenhum atendimento encontrado</h6>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default AtendimentoPorTipo;
