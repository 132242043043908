import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';
import api from '../../service/api';
import { UsuarioContext } from '../../context/userContext';
import {
  ST__CONFIG_USUARIO,
  ST__EMAILUSUARIO,
  ST__IDUSUARIO,
  ST__ISADMIN,
  ST__NOMEUSUARIO,
  ST__PERMISSOES,
  ST__SETORUSUARIO,
  ST__TOKEN,
} from '../../constants/storagekey';
import { UsuariosContext } from '../../context/usersContext.jsx';
import { ClientesContext } from '../../context/clientesContext.jsx';
import { ROUTE_ATENDIMENTOS } from '../../constants/routes.js';

function Login() {
  const {
    setEmailUsuario,
    setIdUsuario,
    setIsAdminUsuario,
    setNomeUsuario,
    setPermissoesUsuario,
    setSetorUsuario,
    setTokenUsuario,
    setVisualizacao,
  } = useContext(UsuarioContext);

  const { GetUsers } = useContext(UsuariosContext);
  const { GetListaClientes } = useContext(ClientesContext);

  const ano = new Date().getFullYear();

  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [msg, setMsg] = useState('');
  const [loading, setLoading] = useState(false);

  function ProcessaLogin(e) {
    e.preventDefault();
    setMsg('');
    setLoading(true);

    api
      .post('v1/usuarios/login', {
        email,
        senha,
      })
      .then((response) => {
        const { token, nome, id, login, setor, administrador, permissoes, configuracoes } = response.data;

        setTokenUsuario(token);
        setIdUsuario(id);
        setNomeUsuario(nome);
        setEmailUsuario(login);
        setSetorUsuario(setor);
        setIsAdminUsuario(administrador);
        setPermissoesUsuario(permissoes);

        const { cardSlim, listagemSetor } = configuracoes;
        cardSlim && setVisualizacao('cardSlim', Number(cardSlim) === 1);
        listagemSetor && setVisualizacao('listagemSetor', Number(listagemSetor) === 1);

        localStorage.setItem(ST__TOKEN, token);
        localStorage.setItem(ST__IDUSUARIO, id);
        localStorage.setItem(ST__NOMEUSUARIO, nome);
        localStorage.setItem(ST__EMAILUSUARIO, login);
        localStorage.setItem(ST__SETORUSUARIO, setor);
        localStorage.setItem(ST__ISADMIN, administrador);
        localStorage.setItem(ST__PERMISSOES, JSON.stringify(permissoes));
        localStorage.setItem(ST__CONFIG_USUARIO, JSON.stringify(configuracoes));

        setMsg('');
        GetUsers();
        GetListaClientes();
        navigate(ROUTE_ATENDIMENTOS);
      })
      .catch((err) => {
        console.log('err :>> ', err);
        if (err.message === 'Network Error') {
          setMsg('Falha ao acessar API da aplicação!');
        } else {
          setMsg('Falha ao acessar API da aplicação:\n\n' + err.response.data.erro);
        }
        setLoading(false);
      });
  }

  return (
    <div className="d-flex align-items-center text-center form-container">
      <form className="form-signin">
        <img className="mb-4" src="" alt="" />
        <h1 className="h3 mb-3 fw-normal">SAC - CM Sistemas</h1>

        <div className="form-floating">
          <input onChange={(e) => setEmail(e.target.value)} type="email" className="form-control" id="floatingInput" placeholder="E-mail" />
          <label htmlFor="floatingInput">E-mail</label>
        </div>

        <div className="form-floating">
          <input
            onChange={(e) => setSenha(e.target.value)}
            type="password"
            className="form-control"
            id="floatingPassword"
            placeholder="Senha"
          />
          <label htmlFor="floatingPassword">Senha</label>
        </div>

        <button onClick={ProcessaLogin} className="btn btn-lg btn-primary w-100" disabled={loading}>
          {loading ? (
            <div>
              <span className="spinner-border spinner-border-sm text-light" role="status"></span>
              <span className="ms-2">Validando...</span>
            </div>
          ) : (
            <span className="ms-2">Acessar</span>
          )}
        </button>
        {msg ? (
          <div className="alert alert-danger mt-2" role="alert">
            {msg}
          </div>
        ) : null}

        <p className="mt-5 mb-3 text-muted">Copyright&nbsp;© {ano} CM Sistemas</p>
      </form>
    </div>
  );
}

export default Login;
