import { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal/lib/components/Modal';
import Close from '../../../assets/close.png';
import { UsuarioContext } from '../../../context/userContext.jsx';
import api from '../../../service/api.js';
import styles from './infos.module.css';
import { formataData, maskdoc } from '../../../util/maskdoc.js';
import BtnCopy from '../../../components/btn-copy/index.jsx';
import { FaChevronDown } from 'react-icons/fa6';

const initialState = {
  ocorrencias: [],
  cnts: [],
};

export default function ModalInfosParam(props) {
  const [state, setState] = useState(initialState);
  const { permissoesUsuario } = useContext(UsuarioContext);

  function getCntsVencidas() {
    api
      .get(`/v1/financeiro/cntreceber/${props.cliente.codigo_sac_local}`)
      .then((result) => {
        const resultData = result.data.sort((a, b) => (a.documento > b.documento ? 1 : b.documento > a.documento ? -1 : 0));
        const uniqueResultData = resultData.filter((item, index, array) => {
          return index === array.findIndex((t) => t.documento === item.documento);
        });
        setState((prevState) => ({
          ...prevState,
          cnts: uniqueResultData,
        }));
      })
      .catch((err) => console.log('err :>> ', err));
  }

  function getOcorrencias() {
    if (props.cliente.codigo_sac_local) {
      const data = new Date();
      const month = data.getMonth();
      const newMonth = month - 6;
      const dataFormatada = new Date(data.setMonth(newMonth)).toISOString().split('T')[0];
      api
        .get('/v1/financeiro/ocorrenciaclientes', {
          params: {
            codigo_sac_local: props.cliente.codigo_sac_local,
            data_inicial: dataFormatada,
          },
        })
        .then((result) => {
          setState((prevState) => ({
            ...prevState,
            ocorrencias: result.data,
          }));
        })
        .catch((error) => {
          console.log('error :>> ', error);
        });
    }
  }

  useEffect(() => {
    if (props.isOpen === true && state.cnts.length === 0 && state.ocorrencias.length === 0) {
      getOcorrencias();
      getCntsVencidas();
    }
  }, [props.isOpen]);

  useEffect(() => {
    if (props.isOpen === false) {
      setState(initialState);
    }
  }, [props.isOpen]);

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      preventScroll={false}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button type="button" onClick={props.onRequestClose} className="react-modal-close">
        <img src={Close} alt="Fechar" />
      </button>
      <div className={`container-fluid endereco-modal ${styles.infoContainer}`}>
        <div className="col-12 mt-0">
          <h4 className="mt-2-mb-4">Informações do Cliente</h4>
        </div>
        <div>
          <h5>{props.cliente.cliente}</h5>
          <h6>
            CNPJ/CPF: {maskdoc(props.cliente.cnpj)} <BtnCopy text={props.cliente.cnpj} />
          </h6>
          <p>
            Contas vencidas:
            {props.cliente.cnt_receber_expirados === 0 ? ' Nenhuma conta vencida' : ` ${props.cliente.cnt_receber_expirados} vencidas`}
            {props.cliente.cnt_receber_expirados > 0 ? (
              <a
                className="ms-2"
                data-bs-toggle="collapse"
                href={`#tabela-cnts-${props.cliente.contrato}-${props.cliente.cnpj}`}
                role="button"
                aria-expanded="false"
                aria-controls={`tabela-cnts-${props.cliente.contrato}-${props.cliente.cnpj}`}
              >
                <FaChevronDown style={{marginBottom:2}}/>
              </a>
            ) : null}
          </p>

          <div className={`table-responsive collapse`} id={`tabela-cnts-${props.cliente.contrato}-${props.cliente.cnpj}`}>
            <table className="table w-70">
              <thead>
                <tr>
                  <th className="text-center" scope="col">
                    Documento
                  </th>
                  <th className="text-center" scope="col">
                    Vencimento
                  </th>
                  <th className="text-center" scope="col">
                    Nosso número
                  </th>
                  <th className="text-center" scope="col">
                    Linha Digitavel
                  </th>
                </tr>
              </thead>
              <tbody>
                {state.cnts.length > 0 &&
                  state.cnts.map((cnt, index) => (
                    <tr
                      className={new Date(cnt.dt_vencimento) < new Date() && cnt.posicao === 'CR' ? 'table-danger' : null}
                      key={cnt.documento + index}
                      title={
                        new Date(cnt.dt_vencimento) < new Date() && cnt.posicao === 'CR'
                          ? 'Conta vencida'
                          : cnt.posicao === 'PG'
                          ? 'Conta paga'
                          : 'Conta a vencer'
                      }
                    >
                      <th className="text-center">{cnt.documento}</th>
                      <td className="text-center">{formataData(cnt.dt_vencimento)}</td>
                      <td className="text-center">{cnt.nossonum}</td>
                      <td className="text-center">{cnt.linha_digitavel ? <BtnCopy text={cnt.linha_digitavel} /> : 'Não possui'}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <p>{props.cliente.mensagem}</p>
          <p>Ocorrências de cobrança nos últimos 6 meses: {state.ocorrencias.length}</p>
          {permissoesUsuario.financeiro === 1
            ? state.ocorrencias.map((ocorrencia, index) => {
                return (
                  <div id={ocorrencia.id} key={index}>
                    <p className="text-justify">
                      <b>{formataData(ocorrencia.data)}</b> - {ocorrencia.descricao}
                    </p>
                  </div>
                );
              })
            : null}
        </div>
      </div>
    </Modal>
  );
}
