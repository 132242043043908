import React from 'react';
import PropTypes from 'prop-types';
import PdfMake from './PdfMake.jsx';
import { formataData, maskdoc } from '../../util/maskdoc.js';

function PDFClientesSemCodFinanceiro(props) {
  const { listagemClientes, filtros } = props;
  const titulo = 'Listagem de Clientes cadastrados no Período';

  const info = {
    title: titulo,
    author: 'CM Sistemas',
  };

  function formataFiltros() {
    let filtro = [];
    filtro.push({ text: 'Data de cadastro do cliente', bold: true, fontSize: 12, margin: [0, 10, 0, 0] });
    if (filtros.dataInicio && filtros.dataFim) {
      filtro.push({ text: `Data Inicial: ${formataData(filtros.dataInicio)} - Data Final: ${formataData(filtros.dataFim)}` });
    }
    if (filtros.codigoFinanceiro === '') {
      filtro.push({ text: `Sem código financeiro.` });
    }
    return filtro.flat();
  }

  function formataHeaderTabela() {
    return [
      { text: 'ID SAC', bold: true, alignment: 'center' },
      { text: 'Contrato', bold: true, alignment: 'center' },
      { text: 'Nome', bold: true },
      { text: 'CPF/CNPJ', bold: true, alignment: 'center' },
      { text: 'Data de Cadastro', bold: true, alignment: 'center' },
    ];
  }

  function formataWidthTabela() {
    return ['auto', 'auto', '*', 'auto', 'auto'];
  }

  function formataDadosTabela() {
    return listagemClientes.map((cliente) => [
      { text: cliente.id, fontSize: 10, fillColor: cliente.codigo_sac_local ? '' : '#FF0000', fillOpacity: 0.3 },
      { text: cliente.contrato, fontSize: 10, fillColor: cliente.codigo_sac_local ? '' : '#FF0000', fillOpacity: 0.3, alignment: 'center' },
      { text: cliente.nome_fantasia, fontSize: 10, fillColor: cliente.codigo_sac_local ? '' : '#FF0000', fillOpacity: 0.3 },
      { text: maskdoc(cliente.cnpj), fontSize: 10, fillColor: cliente.codigo_sac_local ? '' : '#FF0000', fillOpacity: 0.3 },
      {
        text: `${formataData(cliente.data_inc)}`,
        fontSize: 10,
        fillColor: cliente.codigo_sac_local ? '' : '#FF0000',
        fillOpacity: 0.3,
        alignment: 'center',
      },
    ]);
  }

  function retornaLegendaTabela() {
    return {
      layout: 'noBorders',
      table: {
        widths: [50, 'auto'],
        body: [
          [
            { text: '', fillColor: '#FF0000', fillOpacity: 0.3, margin: [0, 0, 0, 0] },
            { text: 'Clientes sem código financeiro', fontSize: 10, margin: [10, 0, 0, 0] },
          ],
        ],
      },
    };
  }

  const content = [
    {
      text: titulo,
      style: { fontSize: 18, bold: true, alignment: 'justify' },
      alignment: 'center',
      margin: [0, 15, 0, 5],
    },
    formataFiltros(),
    { text: 'Total de clientes: ' + listagemClientes.length, margin: [0, 10, 0, 0] },
    {
      text: 'Total de clientes sem código financeiro: ' + listagemClientes.filter((x) => !x.codigo_sac_local).length,
      margin: [0, 0, 0, 10],
    },
    retornaLegendaTabela(),
    {
      table: {
        headerRows: 1,
        widths: formataWidthTabela(),
        body: [formataHeaderTabela(), ...formataDadosTabela()],
      },
      margin: [0, 15, 0, 0],
    },
  ];

  return <PdfMake content={content} info={info} orientation="portrait" />;
}

PDFClientesSemCodFinanceiro.propTypes = {
  listagemClientes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      contrato: PropTypes.string.isRequired,
      nome: PropTypes.string.isRequired,
      cnpj: PropTypes.string.isRequired,
      dtCadastro: PropTypes.string.isRequired,
      codigo_sac_local: PropTypes.string,
    })
  ).isRequired,
  filtros: PropTypes.shape({
    dataInicio: PropTypes.string.isRequired,
    dataFim: PropTypes.string.isRequired,
    ativo: PropTypes.bool.isRequired,
  }).isRequired,
};

export default PDFClientesSemCodFinanceiro;
