import { Navigate } from 'react-router-dom';
import BtnLaterais from '../btns-laterais/index.jsx';
import CardSenhaMaster from '../cardsenhamaster/index.jsx';
import { ROUTE_LOGIN } from '../../constants/routes.js';
import Navbar from '../navbar/index.jsx';
import React from 'react';
// import { ServidorEventos } from '../EventosComponente/ServidorEventos.jsx';

function PrivateRoute({ children }) {
  const isAuthenticated = localStorage.getItem('sessionToken') ? true : false;
  return isAuthenticated ? (
    <>
      <BtnLaterais />
      <CardSenhaMaster />
      <Navbar/>
      {/* <ServidorEventos/> */}
      {children}
    </>
  ) : (
    <Navigate to={ROUTE_LOGIN} />
  );
}

export default PrivateRoute;
