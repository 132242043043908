import { useState } from 'react';
import './style.css';
import { formataData, formataHora } from '../../../../util/maskdoc.js';
import { FaEnvelope, FaEnvelopeOpen, FaSquareArrowUpRight } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { ROUTE_ATENDIMENTOS, ROUTE_FINANCEIRO_CONFERENCIA } from '../../../../constants/routes.js';

export default function NotificationCard({ lerNotificacao, notification }) {
  const [mouseOver, setMouseOver] = useState(false);
  const [read, setRead] = useState(false);
  const handleReadNotification = () => {
    setRead(true);
    lerNotificacao(notification.notificacao_id);
  };

  const navigate = useNavigate();

  function navigationTo() {
    if (notification.atendimento_id) {
      navigate(ROUTE_ATENDIMENTOS + '/' + notification.atendimento_id);
    } else {
      navigate(ROUTE_FINANCEIRO_CONFERENCIA);
    }
  }

  if (notification) {
    return (
      <div className={`d-flex flex-column notification-div w-100 ${read ? 'notification-div-close' : null}`}>
        <span>
          <strong>{notification.titulo}</strong>
        </span>
        {notification.atendimento_id ? (
          <span>
            <strong>Numero:</strong> {notification.atendimento_id}
          </span>
        ) : null}

        {notification.cliente ? (
          <span>
            <strong>Cliente:</strong> {notification.cliente}
          </span>
        ) : null}
        {notification.usuario_lancamento_nome ? (
          <span>
            <strong>Usuario:</strong> {notification.usuario_lancamento_nome}
          </span>
        ) : null}
        <span>
          <strong>Mensagem:</strong> {notification.mensagem}
        </span>
        <span>
          <strong>Data:</strong> {`${formataData(notification.data)} ${formataHora(notification.data)}`}
        </span>
        {Number(notification.lida) === 1 ? null : (
          <button
            className="read-button"
            onMouseOver={() => setMouseOver(true)}
            onMouseLeave={() => setMouseOver(false)}
            onClick={handleReadNotification}
          >
            {!mouseOver ? <FaEnvelope /> : <FaEnvelopeOpen />}
          </button>
        )}
        <button className="go-button" onClick={navigationTo}>
          <FaSquareArrowUpRight size={13} />
        </button>
      </div>
    );
  } else {
    return null;
  }
}
