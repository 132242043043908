import { useContext, useEffect, useState } from 'react';
import Modal from 'react-modal/lib/components/Modal';
import Close from '../../assets/close.png';
import styles from './upload.module.css';

import api from '../../service/api.js';
import { toast } from '../../constants/toast.jsx';
import Swal from 'sweetalert2';
import { UsuarioContext } from '../../context/userContext.jsx';
import { FaDownload } from 'react-icons/fa6';

export default function ModalUploadArquivos(props) {
  const { idUsuario, isAdminUsuario } = useContext(UsuarioContext);
  const [fileList, setFileList] = useState([]);
  const [fileListServer, setFileListServer] = useState([]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size <= 1024 * 1024 * 10) {
      file.id = file.name + new Date().getTime();
      file.atendimento = props.id || props.atendimento.id;
      setFileList([...fileList, file]);

      e.target.value = null;
    } else {
      e.target.value = null;
      toast.fire({
        icon: 'error',
        title: `Arquivo muito grande, o limite é ${formatBytes(1024 * 1024 * 10)}`,
      });
    }
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  const filesInServer = () => {
    if (props.id === 0 || (props.atendimento.quantidade_anexos && props.atendimento.quantidade_anexos === 0)) {
      return;
    }
    api.get(`/v1/atendimentos/files/${props.id}`).then((result) => {
      if (result.data?.length !== Number(props.atendimento.quantidade_anexos)) {
        props.atendimento.quantidade_anexos = result.data.length;
      }
      setFileListServer(result.data);
    });
  };

  const removeItemFromList = (id) => {
    const result = fileList.filter((file) => file.id !== id);
    setFileList(result);
  };

  const CancelUpload = () => {
    setFileList([]);
    props.onRequestClose();
  };

  const HandleUploadFilesToServer = (e) => {
    e.preventDefault();
    if (
      Number(idUsuario) !== Number(props.atendimento.id_equipe_suporte) &&
      Number(idUsuario) !== Number(props.atendimento.id_equipe_lancamento) &&
      isAdminUsuario !== 'S'
    ) {
      return toast.fire({
        icon: 'error',
        title: 'Sem permissão para subir arquivos, não é o mesmo usuário do atendimento.',
      });
    }
    if (fileList.length < 1) {
      return toast.fire({ icon: 'error', title: 'Nenhum arquivo selecionado' });
    }
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append(file.atendimento, file);
    });

    api
      .post('/v1/atendimentos/files', formData)
      .then((response) => {
        setFileList([]);
        toast.fire({ icon: 'success', title: 'Sucesso no upload de arquivos' });
      })
      .catch((err) => {
        const response = err.response.data;
        if (response.hasOwnProperty('fileErrors')) {
          const title = 'Arquivos com erros:';
          let text = '';
          for (const item in response.fileErrors) {
            text = `${response.fileErrors[item].fileName}:\n${response.fileErrors[item].messages.join(', ')}\n`;
          }
          toast.fire({
            icon: 'error',
            title: title,
            text,
            width: '50%',
          });
          setFileList([]);
        } else {
          toast.fire({
            icon: 'error',
            title: err.response.data.error,
          });
        }
      })
      .finally(() => {
        setTimeout(() => {
          filesInServer();
        }, 1000);
      });
  };

  const Download = (file, e) => {
    e.preventDefault();
    api
      .get(`/v1/atendimentos/files/${props.id}/${file.nome_formatado}`)
      .then((response) => {
        const link = document.createElement('a');
        link.href = response.data.Url;
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        toast.fire({
          icon: 'error',
          title: error.response.data.error,
        });
        console.error('Erro durante o download:', error);
      });
  };

  const ExcluirArquivoDoAtendimento = (file, e) => {
    if (
      Number(idUsuario) !== Number(props.atendimento.id_equipe_suporte) &&
      Number(idUsuario) !== Number(props.atendimento.id_equipe_lancamento) &&
      isAdminUsuario !== 'S'
    ) {
      return toast.fire({
        icon: 'error',
        title: 'Sem permissão para exclusão, não é o mesmo usuário do atendimento.',
      });
    }
    e.preventDefault();
    Swal.fire({
      title: 'Exclusão de arquivo!',
      text: 'Deseja realmente excluir esse arquivo do atendimento?',
      showCancelButton: false,
      showDenyButton: true,
      confirmButtonText: 'Sim',
      denyButtonText: `Não`,
    }).then((result) => {
      if (result.isConfirmed) {
        api
          .delete(`/v1/atendimentos/files/${props.id}/${file}`)
          .then((response) => {
            toast.fire({
              icon: 'info',
              title: response.data,
            });
            filesInServer();
          })
          .catch((error) => {
            toast.fire({
              icon: 'error',
              title: error.response.data.error,
            });
            console.error('Erro durante o download:', error);
          });
      } else if (result.isDenied) {
        return;
      }
    });
  };

  useEffect(() => {
    filesInServer();
  }, [props.atendimento]);

  return (
    <Modal
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      preventScroll={false}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button type="button" onClick={CancelUpload} className="react-modal-close">
        <img src={Close} alt="Fechar" />
      </button>
      <div className={`container-fluid endereco-modal ${styles.Container}`}>
        <div className="col-12 mt-0">
          <h4 className="mt-2-mb-4">Upload de arquivos</h4>
          <h6 className="mt-2-mb-4">Atendimento {props.id}</h6>
        </div>
        <form id="form" method="POST" encType="multipart/form-data">
          <div className="form-group">
            <label htmlFor="file">Subir arquivos:</label>
            <input type="file" className="form-control" id="file" name="file" onChange={handleFileChange} />
            <ul className="list-group my-2">
              {fileList.map((file) => (
                <li className="list-group-item d-flex justify-content-between" key={file.id}>
                  <span>
                    <b className="me-2">{file.name}</b>

                    {formatBytes(file.size)}
                  </span>
                  <button className={styles.btnRemoverItem} onClick={() => removeItemFromList(file.id)}>
                    &times;
                  </button>
                </li>
              ))}
            </ul>

            <div className="d-flex justify-content-center mt-3">
              <button className="btn btn-primary mx-2" onClick={(e) => HandleUploadFilesToServer(e)}>
                Upload
              </button>
              <button className="btn btn-danger mx-2" onClick={CancelUpload}>
                Cancelar
              </button>
            </div>
          </div>
        </form>
        <div>
          {fileListServer.length > 0 ? (
            <>
              <h6>Arquivos no servidor:</h6>
              <ul className="list-group my-2">
                {fileListServer.map((file) => (
                  <li className="d-flex justify-content-between list-group-item" key={file.id}>
                    <span>
                      <b className="me-2">{file.nome_real}</b>
                      <button className={styles.btnDownloadItem} onClick={(e) => Download(file, e)}>
                        <FaDownload />
                      </button>
                    </span>
                    <button
                      className={styles.btnRemoverItem}
                      onClick={(e) => ExcluirArquivoDoAtendimento(file.nome_formatado, e)}
                      title="Excluir arquivo do Atendimento"
                    >
                      &times;
                    </button>
                  </li>
                ))}
              </ul>
            </>
          ) : null}
        </div>
      </div>
    </Modal>
  );
}
