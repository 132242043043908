import { useState } from 'react';
import moment from 'moment';
import { useEffect } from 'react';
import api from '../../../service/api';

const Registro = () => {
  const [data, setData] = useState(moment().format('YYYY-MM-DD'));
  const [registros, setRegistros] = useState([]);

  const regex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12][0-9]|3[01])$/;

  const getRegistros = () => {
    api
      .get('/v1/horario/listarRegistro', {
        params: {
          data,
        },
      })
      .then((response) => {
        for (let i = 0; i < response.data.length; i++) {
          for (let key in response.data[i]) {
            if (key === 'horario_ajustado') {
            }
            if (key === 'data_registro' || (key === 'horario_ajustado' && response.data[i][key])) {
              let newDate = new Date(response.data[i][key]);
              response.data[i][key] = new Intl.DateTimeFormat('pt-br', {
                timeStyle: 'medium',
                timeZone: 'America/Sao_Paulo',
              }).format(newDate);
            }
          }
        }
        setRegistros(response.data);
      })
      .catch((error) => {
        setRegistros([]);
        console.log(error);
      });
  };

  useEffect(() => {
    getRegistros();
  }, []);

  useEffect(() => {
    if (regex.test(data)) {
      getRegistros();
    }
  }, [data]);

  return (
    <div className="pb-3">
      <div className="row">
        <div className="col col-sm-6 col-md-3">
          <div className="form-floating">
            <input
              type="date"
              className="form-control mb-2"
              id="data"
              onChange={(e) => {
                setData(e.target.value);
              }}
              value={data}
              placeholder=""
            />
            <label htmlFor="edDataInicial">Data</label>
          </div>
        </div>
      </div>
      {registros.length !== 0 ? (
        <div className="table-responsive">
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th scope="col">Código</th>
                <th scope="col">Nome</th>
                <th scope="col">Hora</th>
                <th scope="col">Observação</th>
              </tr>
            </thead>
            <tbody>
              {registros.map((registro) => {
                return (
                  <tr key={registro.registro_horario_id}>
                    <th>{registro.registro_horario_id}</th>
                    <td>{registro.usuario}</td>
                    <td>{registro.horario_ajustado ? registro.horario_ajustado + '*' : registro.data_registro}</td>
                    <td>{registro.observacao}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <span className="d-block text-center mt-3 mb-3">
          <div>
            <h5 className="text-secondary">Nenhum registro de horário encontrado!</h5>
          </div>
        </span>
      )}
    </div>
  );
};

export default Registro;
