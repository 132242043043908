import { createContext, useState } from 'react';

export const BuscaClienteListaAutoCompleteContext = createContext({
  cliente: null,
  setCliente: () => undefined,
  clienteId: null,
  setClienteId: () => undefined,
  cursor: null,
  setCursor: () => undefined,
  focus: false,
  setFocus: () => undefined,
  achouCliente: false,
  setAchouCliente: () => undefined,
});

export function BuscaClienteListaAutoCompleteProvider({ children }) {
  const [cliente, setCliente] = useState('');
  const [clienteId, setClienteId] = useState(0);

  const [focus, setFocus] = useState(false);
  const [cursor, setCursor] = useState(-1);
  const [achouCliente, setAchouCliente] = useState(false);

  return (
    <BuscaClienteListaAutoCompleteContext.Provider
      value={{
        cliente,
        setCliente,
        clienteId,
        setClienteId,
        cursor,
        setCursor,
        focus,
        setFocus,
        achouCliente,
        setAchouCliente,
      }}
    >
      {children}
    </BuscaClienteListaAutoCompleteContext.Provider>
  );
}
