import { useEffect, useState } from 'react';
import api from '../../service/api';
import { Chart } from 'react-google-charts';
import { FaChartPie } from 'react-icons/fa6';

const initSeries = {
  0: { hidden: false, altColor: 'transparent', lineWidth: 2, isVisible: true },
  1: { hidden: false, altColor: 'transparent', lineWidth: 2, isVisible: true },
  2: { hidden: false, altColor: 'transparent', lineWidth: 2, isVisible: true },
  3: { hidden: false, altColor: 'transparent', lineWidth: 2, isVisible: true },
  4: { hidden: false, altColor: 'transparent', lineWidth: 2, isVisible: true },
  5: { hidden: false, altColor: 'transparent', lineWidth: 2, isVisible: true },
};

export default function DashBoardInicial() {
  const [series, setSeries] = useState(initSeries);
  const [atendimentosDoDia, setAtendimentosDoDia] = useState([]);
  const [render, setRender] = useState(false);
  const [dataInicial, setDataInicial] = useState(new Date().toISOString().split('T')[0]);
  const [dataFinal, setDataFinal] = useState(new Date().toISOString().split('T')[0]);

  const GetDadosAtendimentos = () => {
    api
      .get('/v1/atendimentosDoDia', {
        params: {
          dataInicial,
          dataFinal,
        },
      })
      .then((response) => {
        const dataBar = response.data.DataBar;
        response.data.DataBar = dataBar.filter((x) => !['Administrador SAC', 'Ana Carolina', 'Joaquim'].includes(x[0]));
        setAtendimentosDoDia(response.data);
      })
      .finally(() => {
        setRender(true);
      });
  };

  const options = {
    title: 'Atendimentos pelo período',
    chartArea: { width: '50%', height: '90%' },
    colors: ['#000', '#DC3912', '#FFCB2F', '#712CF9', '#28A745', '#198754'],
    hAxis: {
      title: 'Atendimentos',
      minValue: 0,
    },
    vAxis: {
      title: 'Equipe',
    },
    legend: {
      position: 'right',
    },
    bar: { groupWidth: '80%' },
    series: series,
  };

  useEffect(() => {
    GetDadosAtendimentos();
  }, []);

  return (
    <div className="row justify-content-between align-items-center mb-2 mt-3">
      <div className="col-md-6 col-xl-9 mb-1">
        <h5 className="text-dark mt-3 titulo">
          <FaChartPie /> Dashboard
        </h5>
      </div>
      <div className="mb-3" id="collapseDashBoard">
        <div className="row py-2">
          <div className="col-lg-2">
            <div className="form-floating">
              <input
                value={dataInicial}
                type="date"
                className="form-control"
                id="dataInicial"
                name="dataInicial"
                placeholder="Data Inicial"
                onChange={(e) => setDataInicial(e.target.value)}
              />
              <label htmlFor="dataAdmissao" className="">
                Data Inicial
              </label>
            </div>
          </div>
          <div className="col-lg-2">
            <div className="form-floating">
              <input
                value={dataFinal}
                type="date"
                className="form-control"
                id="DataFinal"
                name="dataFinal"
                placeholder="Data Final"
                onChange={(e) => setDataFinal(e.target.value)}
              />
              <label htmlFor="dataAdmissao" className="">
                Data Final
              </label>
            </div>
          </div>
          <div className="col-lg-2 my-3 my-lg-0  d-flex justify-content-center">
            <button className="btn btn-primary my-auto" onClick={GetDadosAtendimentos}>
              Buscar
            </button>
          </div>
        </div>
        <p>Total de atendimentos lançados: {atendimentosDoDia.Total}</p>
        {render ? (
          <div className="row">
            <div className="col-12 p-0" style={{ height: '1200px' }}>
              <Chart
                chartType="BarChart"
                height="100%"
                options={options}
                data={atendimentosDoDia.DataBar}
                chartEvents={[
                  {
                    eventName: 'select',
                    callback: ({ chartWrapper }) => {
                      console.count();
                      const chart = chartWrapper.getChart();
                      const selection = chart.getSelection();

                      if (selection.length === 1) {
                        const [selectedItem] = selection;
                        const { column } = selectedItem;

                        if (selection[0].row === null) {
                          const seriesIndex = column - 1;
                          const isVisible = !series[seriesIndex].isVisible;

                          setSeries((prevSeries) => ({
                            ...prevSeries,
                            [seriesIndex]: {
                              ...prevSeries[seriesIndex],
                              color: isVisible ? options.colors[seriesIndex] : 'white',
                              tooltip: isVisible,
                              isVisible: isVisible,
                            },
                          }));
                        }
                      }
                    },
                  },
                ]}
              />
            </div>
          </div>
        ) : // )
        null}
      </div>
    </div>
  );
}
