import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import UsuarioCard from '../../../components/usuario-card';
import api from '../../../service/api';
import './style.css';
import { UsuarioContext } from '../../../context/userContext';
import { ROUTE_USUARIOS, ROUTE_USUARIOS_FERIAS } from '../../../constants/routes.js';
import { FaAirbnb, FaCaretDown, FaFilter, FaMagnifyingGlass, FaPlus } from 'react-icons/fa6';
import Titulo from '../../../components/titulo/index.jsx';

function ListaUsuarios() {
  const { permissoesUsuario } = useContext(UsuarioContext);

  const navigate = useNavigate();

  const [pagina, setPagina] = useState(1);
  const [processando, setProcessando] = useState(false);
  const [mais, setMais] = useState(true);
  const [usuarios, setUsuarios] = useState([]);
  const [tipoBusca, setTipoBusca] = useState('2');
  const [pesquisa, setPesquisa] = useState('');
  const [ativo, setAtivo] = useState('D');

  var pg = 0;

  function SelUsuarios(id) {
    if (id > 0) {
      navigate(`${ROUTE_USUARIOS}/${id}`);
    } else {
      navigate(`${ROUTE_USUARIOS}/0`);
    }
  }

  function navigateControleFerias() {
    navigate(ROUTE_USUARIOS_FERIAS);
  }

  function GetUsuarios(indReset) {
    pg = indReset ? 1 : pagina + 1;
    setMais(false);

    api
      .get('/v1/usuarios', {
        params: {
          tipo_busca: tipoBusca,
          q: pesquisa,
          ativo,
          pagina: pg,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          if (indReset) {
            setUsuarios(response.data);
          } else {
            setUsuarios((oldArray) => [...oldArray, ...response.data]);
            setPagina(pagina + 1);
          }
        } else {
          if (response.status === 401) {
            if (indReset) {
              setUsuarios([]);
            }
            setMais(false);
          } else {
            console.log('Ocorreu algum erro na requisição de usuários: ' + response.statusText);
          }
        }
        setProcessando(false);
        setMais(response.data.length >= 15);
      })
      .catch((err) => {
        setProcessando(false);
        if (err.response.status === 401) {
          if (indReset) {
            setUsuarios([]);
          }
          setMais(false);
        } else {
          console.log(err);
        }
      });
  }

  function GetAtivo(e) {
    setAtivo(e.target.value);
  }

  useEffect(() => {
    GetUsuarios(true);
  }, []);

  return (
    <div>
      <div className="container shadow-lg rounded-2 mt-3">
        {/* BOTÕES DE NOVO REGISTRO + ATUALIZAR LISTA + PESQUISA */}
        <Titulo
          principal
          titulo="Listagem de Usuários"
          complementoTitulo={
            <div className="col-md-6 col-xl-4 mb-1">
              <div className="d-flex btn-group mt-3" role="group" aria-label="Clientes">
                <button
                  onClick={(e) => SelUsuarios(0)}
                  className="btn btn-outline-dark"
                  hidden={permissoesUsuario.editar_incluir_usuario === 0}
                  disabled={permissoesUsuario.editar_incluir_usuario === 0}
                >
                  <div className="">
                    <span>
                      {' '}
                      <FaPlus style={{ marginBottom: 3 }} /> Novo Usuário
                    </span>
                  </div>
                </button>

                <button onClick={() => navigateControleFerias()} className="btn btn-outline-dark">
                  <div className="">
                    <span>
                      {' '}
                      <FaAirbnb size={17} style={{ marginBottom: 3 }} /> Controle de Férias
                    </span>
                  </div>
                </button>
              </div>
            </div>
          }
        >
          <div className="collapse" id="collapseExample">
            <div className="card card-body mt-3">
              <div className="row">
                <div className="col-md-2 col-sm-12">
                  <label htmlFor="cmbPosicao">Pesquisar por</label>
                  <select onChange={setTipoBusca} id="cmbPosicao" defaultValue={'2'} className="form-select mb-2" aria-label="Selecionar">
                    <option value="1">Código</option>
                    <option value="2">Nome/Login</option>
                  </select>
                </div>

                <div className="col-md-6 col-sm-12">
                  <label htmlFor="floatingInput">Pesquisa</label>
                  <input
                    onChange={(e) => setPesquisa(e.target.value)}
                    type="text"
                    className="form-control mb-2"
                    id="floatingInput"
                    placeholder="Pesquisar"
                  />
                </div>

                <div className="col-md-2 col-sm-12">
                  <label htmlFor="cmbPosicao">Situação</label>
                  <select onChange={GetAtivo} id="cmbPosicao" defaultValue={'D'} className="form-select mb-2" aria-label="Selecionar">
                    <option value="T">Todos</option>
                    <option value="D">Ativo</option>
                    <option value="I">Inativo</option>
                  </select>
                </div>

                <div className="col-md-2 col-sm-12 align-items-center mt-4">
                  <button onClick={(e) => GetUsuarios(true)} className="btn btn btn-primary w-100">
                    <div>
                      <FaMagnifyingGlass className="me-2" />
                      <small>Pesquisar...</small>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Titulo>
        {/* LISTAGEM DE REGISTROS */}
        <div className="row ps-2 mt-3">
          <div className="row mb-3 gx-2 gy-2 clientes" data-bss-baguettebox>
            {usuarios.map((a) => {
              return (
                <UsuarioCard
                  key={a.ID}
                  id={a.ID}
                  nome={a.NOME}
                  login={a.LOGIN}
                  setor={a.departamento}
                  administrador={a.ADMINISTRADOR}
                  telefone1={a.telefone_1}
                  telefone2={a.telefone_2}
                  celular={a.celular}
                  ativo={a.STATUS}
                  onClickUsuario={SelUsuarios}
                />
              );
            })}
          </div>
        </div>

        {/* BUSCAR MAIS REGISTROS */}
        {usuarios.length === 0 ? (
          <a
            href="#collapseExample"
            className="btn-colapse"
            data-bs-toggle="collapse"
            role="button"
            aria-expanded="false"
            aria-controls="collapseExample"
          >
            <div>
              <span className="d-block text-center">
                <div>
                  <h5 className="text-secondary">Nenhum usuário encontrado!</h5>
                  <h6 className="pb-5 text-secondary">
                    Tente fazer algum filtro <FaFilter />
                  </h6>
                </div>
              </span>
            </div>
          </a>
        ) : null}

        {!processando && mais ? (
          <span className="d-block text-center mt-3 mb-5">
            <button onClick={(e) => GetUsuarios(false)} className="btn btn-lg btn-outline-secondary mb-3">
              <div>
                <FaCaretDown className="me-2 mb-1" />
                <small>Carregar mais usuários...</small>
              </div>
            </button>
          </span>
        ) : null}
      </div>
    </div>
  );
}

export default ListaUsuarios;
