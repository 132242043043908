import api from '../api.js';

async function getCalendarioSAC(filter) {
  try {
    const response = await api.get('/v1/calendario', {
      params: filter,
    });

    return response.data;
  } catch (error) {
    throw new Error(error);
  }
}

async function getFeriadosDoAno({ ano, uf }) {
  try {
    const response = await api.get('/v1/calendario/feriados', {
      params: {
        ano,
        uf,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
}

export { getCalendarioSAC, getFeriadosDoAno };
