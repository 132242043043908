import React from "react";
import {useNavigate} from 'react-router-dom'
import { formataData, formataHora, maskdoc } from "../../util/maskdoc.js";
import PropTypes from "prop-types";

function CardBackup({ backup }) {
	const navigate = useNavigate();
	function formatarFrequencia(frequencia) {
		const { tipo, hora, minuto, diasSemana } = frequencia;

		switch (tipo) {
			case "HoraMinuto":
				return `Executa a cada ${hora ? `${hora} hora${hora > 1 ? "s" : ""}` : ""}${hora && minuto ? " e " : ""}${minuto ? `${minuto} minuto${minuto > 1 ? "s" : ""}` : ""}.`;

			case "Diaria":
				return `Executa diariamente às ${hora || "00"}:${minuto || "00"} h.`;

			case "Semanal": {
				const diasFormatados =
					diasSemana.length > 0
						? diasSemana.join(", ")
						: "sem dias especificados";
				return `Executa semanalmente às ${hora || "00"}:${minuto || "00"} h nos dias: ${diasFormatados}.`;
			}

			default:
				return "Tipo de frequência desconhecido.";
		}
	}

	function navigateToLogsCliente() {
		navigate(`/clientes/backups/${backup.contrato}/${backup.cnpj}`);
	}

	function calcularProximaExecucao(frequencia, dtUltimaExecucao) {
		const { tipo, hora, minuto, diasSemana } = frequencia;
		const dtUltimaExecucaoDate = new Date(dtUltimaExecucao);

		if(tipo === "HoraMinuto"){
			const proximaExecucao = new Date(dtUltimaExecucaoDate.getTime() + (hora * 60 * 60 * 1000) + (minuto * 60 * 1000));
			return proximaExecucao;
		}
		if(tipo === "Diaria"){
			const proximaExecucao = new Date(dtUltimaExecucaoDate.getTime() + (24 * 60 * 60 * 1000));
			proximaExecucao.setHours(hora);
			proximaExecucao.setMinutes(minuto);
			return proximaExecucao;
		}
		if(tipo === "Semanal"){
			const diasSemanaNumeros = diasSemana.map(dia => {
				switch(dia){
					case "Domingo": return 0;
					case "Segunda-Feira": return 1;
					case "Terça-Feira": return 2;
					case "Quarta-Feira": return 3;
					case "Quinta-Feira": return 4;
					case "Sexta-Feira": return 5;
					case "Sábado-Feira": return 6;
					default: return -1;
				}
			});

			const diaSemanaAtual = dtUltimaExecucaoDate.getDay();

			const proximoDiaSemana = diasSemanaNumeros.sort((a, b) => a-b).find(dia => dia > diaSemanaAtual);

			const proximaExecucao = new Date(dtUltimaExecucaoDate.getTime() + ((proximoDiaSemana - diaSemanaAtual) * 24 * 60 * 60 * 1000));

			proximaExecucao.setHours(hora);
			proximaExecucao.setMinutes(minuto);

			return proximaExecucao;
		}
	}

	//Verificar se a próxima execução está dentro do prazo de 24 horas
	const proximaExecucao = calcularProximaExecucao(backup.frequencia, backup.dtRegistro);
	const atraso = new Date().getTime() - proximaExecucao.getTime();
	const atrasoHoras = atraso / (60 * 60 * 1000);
	const estaAtrasado = atrasoHoras > 24;

	return (
		<div className="container">
			<div className="card-cliente-container">
				<div className="tag-card-cliente-ativo" />
				<div className="container" onClick={()=> navigateToLogsCliente()}>
					<div className="row">
						<div className="col">
							<div className="row py-1" style={{ overflow: "hidden" }}>
								<span>
									<strong>{backup.nomeCliente}</strong>
								</span>
							</div>
							<div className="row py-1" style={{ overflow: "hidden" }}>
								<span>
									Contrato: <strong>{backup.contrato}</strong>
								</span>
							</div>
							<div className="row py-1" style={{ overflow: "hidden" }}>
								<span>
									CNPJ: <strong>{maskdoc(backup.cnpj)}</strong>
								</span>
							</div>
						</div>
						<div className="col">
							<div className="row py-1" style={{ overflow: "hidden" }}>
								<span>
									Última Rotina: <strong>{backup.ultimaRotina}</strong>
								</span>
							</div>
							<div className="row py-1" style={{ overflow: "hidden" }}>
								<span>
									Banco: <strong>{backup.banco}</strong>
								</span>
							</div>
							<div className="row py-1" style={{ overflow: "hidden" }}>
								<span>
									Dt Registro:{" "}
									<strong>{`${formataData(backup.dtRegistro)} ${formataHora(backup.dtRegistro)}`}</strong>
								</span>
							</div>
						</div>
						<div className="col">
							<div className="row py-1" style={{ overflow: "hidden" }}>
								<span>
									Status: <strong>{backup.status ? "Sucesso" : "Falha"}</strong>
								</span>
							</div>
							<div className="row py-1" style={{ overflow: "hidden" }}>
								<span>
									Frequencia:{" "}
									<strong>{formatarFrequencia(backup.frequencia)}</strong>
								</span>
							</div>
							<div className="row py-1" style={{ overflow: "hidden" }}>
								<span className={estaAtrasado? 'text-danger':'text-primary'}>
									Próxima execução:{" "}
									<strong>{`${formataData(proximaExecucao)} ${formataHora(proximaExecucao)}`}</strong>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default CardBackup;

CardBackup.propTypes = {
	backup: PropTypes.shape({
		nomeCliente: PropTypes.string,
		contrato: PropTypes.string,
		cnpj: PropTypes.string,
		ultimaRotina: PropTypes.string,
		banco: PropTypes.string,
		dtRegistro: PropTypes.string,
		status: PropTypes.bool,
		frequencia: PropTypes.shape({
			tipo: PropTypes.string,
			hora: PropTypes.string,
			minuto: PropTypes.string,
			diasSemana: PropTypes.arrayOf(PropTypes.string),
		}),
	})
};
