import api from '../api.js';

export async function RegistrarHorario(infos) {
  try {
    const response = await api.post('/v1/horario/inserir', infos);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
}
